import { OperationOption } from '@app/task/create-new/model/task-creation';

export const OPERATIONS: OperationOption[] = [
  {
    operation: 'ENABLE_MAINTENANCE',
    fontIcon: 'mdi-wrench-outline',
    params: [
      {
        name: 'maintenance-type',
        optional: false
      },
      {
        name: 'connectorId',
        optional: false
      }
    ],
    extendedParams: [
      {
        name: 'startTime',
        optional: false
      },
      {
        name: 'stopTime',
        optional: false
      }
    ]
  },
  {
    operation: 'DISABLE_MAINTENANCE',
    svgIcon: 'wrench-off-outline',
    params: [
      {
        name: 'connectorId',
        optional: false
      }
    ]
  },
  { operation: 'HARD_RESET', fontIcon: 'mdi-power' },
  {
    operation: 'UNLOCK_CONNECTOR',
    fontIcon: 'mdi-lock-open-outline',
    params: [
      {
        name: 'connectorId',
        optional: false
      }
    ]
  },
  { operation: 'CLEAR_CACHE', fontIcon: 'mdi-eraser' },
  {
    operation: 'CLEAR_LOCAL_BADGES',
    fontIcon: 'mdi-eraser'
  },
  {
    operation: 'REQUEST_START_TRANSACTION',
    fontIcon: 'mdi-play-circle-outline',
    params: [
      {
        name: 'connectorId',
        optional: false
      },
      {
        name: 'idTag',
        optional: false
      }
    ]
  },
  { operation: 'REQUEST_STOP_TRANSACTION', fontIcon: 'mdi-stop-circle-outline' },
  {
    operation: 'CHANGE_CONFIGURATION',
    svgIcon: 'settings-transfer-outline',
    params: [
      {
        name: 'key',
        optional: false
      },
      {
        name: 'value',
        optional: false
      }
    ]
  },
  { operation: 'ENABLE_RFID_READER', fontIcon: 'mdi-nfc-variant' },
  { operation: 'DISABLE_RFID_READER', fontIcon: 'mdi-nfc-variant-off' },
  {
    operation: 'UPDATE_FIRMWARE',
    fontIcon: 'mdi-update',
    params: [
      {
        name: 'location',
        optional: false
      }
    ],
    extendedParams: [
      {
        name: 'retrieveDate',
        optional: false
      },
      {
        name: 'retries',
        optional: true
      },
      {
        name: 'retryInterval',
        optional: true
      }
    ]
  },
  {
    operation: 'EVC_UPDATE',
    fontIcon: 'mdi-update',
    params: [
      {
        name: 'FTP_user',
        optional: false
      },
      {
        name: 'FTP_password',
        optional: false
      },
      {
        name: 'FTP_path',
        optional: false
      },
      {
        name: 'Target_version',
        optional: false
      }
    ]
  },
  {
    operation: 'GET_DIAGNOSTICS',
    fontIcon: 'mdi-file-chart-outline',
    params: [
      {
        name: 'location',
        optional: false
      }
    ],
    extendedParams: [
      {
        name: 'startTime',
        optional: true
      },
      {
        name: 'stopTime',
        optional: true
      },
      {
        name: 'retries',
        optional: true
      },
      {
        name: 'retryInterval',
        optional: true
      }
    ]
  },
  {
    operation: 'GET_CONFIGURATION',
    svgIcon: 'settings'
  }
];
