import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { interval, Observable, of, Subject } from 'rxjs';
import { debounce, filter, take, takeUntil } from 'rxjs/operators';
import { fadeInOut, FilterTipsContainerComponent, SearchFilter } from '@app/shared';
import { searchInOut } from './keyword-search-animations';
import { AppNavigationService, KeywordSearchAutocompleteOption } from '@app/navigation';
import { ReportingService } from '@app/reporting/reporting.service';
import { LoadingService } from '@app/shared/loading-manager/loading-service.service';
import { UserGroup } from '@app/core';
import { UserService } from '@app/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UserPageSelectionOption } from '@app/user/main-list/main-list.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { InfrastructureService } from '@app/infrastructure';

const OPTIONS = [
  { display: 'user.OPTIONS.USER_MANAGEMENT', value: UserPageSelectionOption.USER_MANAGEMENT },
  { display: 'user.OPTIONS.USER_GROUP', value: UserPageSelectionOption.USER_GROUP }
];

@Component({
  selector: 'app-navigation-keyword-search',
  templateUrl: 'keyword-search.component.html',
  styleUrls: ['keyword-search.component.scss'],
  animations: [fadeInOut, searchInOut]
})
export class NavigationKeywordSearchComponent implements OnInit, OnChanges, OnDestroy {
  value: string;
  isInAdminGroup: Observable<boolean>;
  hasAdminProfile: Observable<boolean>;
  isEmspUser: boolean;
  filterNumber: number;
  isUserTab = false;
  isEvUserTab = false;
  isPurchaseOrderTab = false;
  isAlertTab = false;
  isTimelineTab = false;
  private currentConnectedUser: string;
  private eligibleGroups: UserGroup[];
  isTaskCsv = false;
  group: string;

  disable = true;
  disableIfNoTimeRange = true;
  massActionsToggle = false;

  @Input() placeholder: string;
  @Input() autoOptions: KeywordSearchAutocompleteOption[];
  @Input() searchFilter: SearchFilter;
  @Input() isUserReader: SearchFilter;
  @Input() isSearchOptimized: boolean;
  @Input() displaySearchIcon: boolean;
  @Input() shouldDisplaySearchEngineSwitch: boolean;

  private _destroyed = new Subject();
  private inputChanges = new Subject<string>();

  @ViewChild('filterTemplate', { static: true }) private filterTemplate: TemplateRef<any>;
  @ViewChild('filterButton', { static: false }) private filterButton: MatButton;

  private triggerHovered = new Subject();
  private filterTipsOverlay: OverlayRef;
  private filterTipsContainer: FilterTipsContainerComponent;
  private readonly keywordRegex: RegExp;

  readonly options = OPTIONS;
  userPageSelection = UserPageSelectionOption.USER_MANAGEMENT;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    public loadingService: LoadingService,
    private overlay: Overlay,
    private serviceUser: UserService,
    private changeDetector: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    private navService: AppNavigationService,
    private reportingService: ReportingService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private infrastructureService: InfrastructureService
  ) {
    try {
      this.keywordRegex = new RegExp(
        '[\\w\\p{General_Category=L}\\*_\\+@\\-\\.]+|"(?:\\\\"|[^"])+"',
        'gu'
      );
    } catch (err) {
      try {
        this.keywordRegex = new RegExp(
          '[\\wàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\\*_\\+@\\-\\.]+|"(?:\\\\"|[^"])+"',
          'gu'
        );
      } catch (err) {
        this.keywordRegex = new RegExp(
          '[\\wàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\\*_\\+@\\-\\.]+|"(?:\\\\"|[^"])+"',
          'g'
        );
      }
    }
  }

  ngOnInit(): void {
    this.navService.userId.pipe(takeUntil(this._destroyed)).subscribe(userId => {
      this.currentConnectedUser = userId.replace('user/', '');
      this.isInAdminGroup = this.serviceUser.isUserInAdminGroup(this.currentConnectedUser);
      this.hasAdminProfile = this.serviceUser.isUserHasAdminProfile(this.currentConnectedUser);
    });

    this.navService.navItems.pipe(takeUntil(this._destroyed)).subscribe(items => {
      this.disable = items.find(i => i.path === 'admin')
        ? !this.route.snapshot.queryParams.emsp
        : false;
      this.disableIfNoTimeRange = !this.route.snapshot.queryParams.range;
    });

    this.updateFilterNumber();
    if (this.route.snapshot.queryParams.keyword) {
      this.value = this.route.snapshot.queryParams.keyword.replace(/,/g, ' ');
    }

    this.inputChanges
      .pipe(
        takeUntil(this._destroyed),
        debounce(() => interval(200))
      )
      .subscribe(value => {
        const keywordParam = this.keywordToQueryParam(value);
        const params: any = Object.assign({}, this.route.snapshot.queryParams);

        if (keywordParam) {
          params.keyword = keywordParam;
        } else {
          delete params.keyword;
        }
        this.router.navigate([], {
          replaceUrl: true,
          queryParams: params
        });
      });

    this.router.events
      .pipe(
        takeUntil(this._destroyed),
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        // Clear input if query params cleared
        this.updateFilterNumber();
        if (
          this.value &&
          this.keywordToQueryParam(this.value) !== this.route.snapshot.queryParams.keyword
        ) {
          this.value = '';
        }
      });

    this.triggerHovered.pipe(takeUntil(this._destroyed)).subscribe(hovered => {
      if (hovered) {
        this.openFilterTips();
      } else {
        this.closeFilterTips();
      }
    });

    this.userSelectionChange(UserPageSelectionOption.USER_MANAGEMENT);

    this.isEmspUser = this.navService.isEmspUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchFilter && changes.searchFilter.currentValue) {
      this.searchFilter.clearFilter.subscribe(clear => {
        if (clear) {
          this.clear();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.snackBar.dismiss();
  }

  onInput(value: string): void {
    if (!value || !this.isSearchOptimized) {
      this.inputChanges.next(value);
    }
  }

  manualSearch(value: string): void {
    this.inputChanges.next(value);
  }

  onSwitch(): void {
    this.massActionsToggle = !this.massActionsToggle;
    this.navService.setAppBarMassOptionsChecked(this.massActionsToggle);
  }

  clear(): void {
    this.value = '';
    this.onInput('');
  }

  openFilter(): void {
    this.filterButton._elementRef.nativeElement.blur();
    this.dialog.open(this.filterTemplate, { disableClose: true });
  }

  onTriggerHovered(hovered: boolean): void {
    this.triggerHovered.next(hovered);
  }

  private keywordToQueryParam(keyword: string): string {
    let keywords = keyword?.match(this.keywordRegex);
    if (!keywords) {
      keywords = [];
    } else if (keywords.length > 0 && keywords[0] === '') {
      keywords.shift();
    }
    if (keywords.length > 0) {
      return keywords.join(',');
    } else {
      return undefined;
    }
  }

  private updateFilterNumber(): void {
    this.isEvUserTab = this.router.url.indexOf('/emsp/user') > -1;
    this.isUserTab = !this.isEvUserTab && this.router.url.indexOf('/user') > -1;
    this.isPurchaseOrderTab = this.router.url.indexOf('/emsp/order') > -1;
    this.isAlertTab = this.router.url.indexOf('/alert') > -1;
    this.isTimelineTab = this.router.url.indexOf('/history') > -1;
    this.isTaskCsv = this.router.url.indexOf('/task') > -1;

    let paramLength = 0;
    for (const param of Object.keys(this.route.snapshot.queryParams)) {
      if (this.route.snapshot.queryParams[param] instanceof Array) {
        paramLength += this.route.snapshot.queryParams[param].length;
      } else {
        paramLength += 1;
      }
    }

    if (this.route.snapshot.queryParams.keyword) {
      paramLength--;
    }
    this.filterNumber = paramLength;
  }

  private openFilterTips(): void {
    if (
      this.filterNumber <= 0 ||
      (this.filterTipsOverlay && this.filterTipsOverlay.hasAttached())
    ) {
      return;
    }
    const strategry = this.overlay
      .position()
      .flexibleConnectedTo(this.filterButton._elementRef)
      .withLockedPosition()
      .withPositions([{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }]);
    this.filterTipsOverlay = this.overlay.create({
      positionStrategy: strategry,
      panelClass: 'applied-filter-tips'
    });
    const containerPortal = new ComponentPortal(
      FilterTipsContainerComponent,
      this.viewContainerRef
    );
    const portal = new TemplatePortal(this.filterTemplate, this.viewContainerRef, {
      $implicit: true
    } as any);
    const containerRef = this.filterTipsOverlay.attach<FilterTipsContainerComponent>(
      containerPortal
    );
    this.filterTipsContainer = containerRef.instance;
    this.filterTipsContainer.attachTemplatePortal(portal);
    this.filterTipsContainer._animationStateChanged
      .pipe(
        filter(event => event.phaseName === 'done' && event.toState === 'exit'),
        take(1)
      )
      .subscribe(() => this.filterTipsOverlay.dispose());
  }

  private closeFilterTips(): void {
    if (this.filterTipsOverlay && this.filterTipsOverlay.hasAttached()) {
      this.filterTipsContainer._startExitAnimation();
    }
  }

  download(): void {
    this.snackBar.dismiss();
    this.loadingService.show();
    this.disable = true;
    this.reportingService.getUsersReport(this.route.snapshot.queryParams).subscribe(
      () => {
        this.loadingService.hide();
        this.disable = false;
        this.changeDetector.markForCheck();
      },
      error => {
        this.loadingService.hide();
        this.disable = false;
        if (error.status === 408) {
          this.snackBar.open(this.translateService.instant('REPORTING.ERROR.TIMEOUT'));
        } else {
          this.snackBar.open(this.translateService.instant('REPORTING.ERROR.INTERNAL_ERROR'));
        }
        this.changeDetector.markForCheck();
      }
    );
  }

  downloadAlert(): void {
    this.snackBar.dismiss();
    this.reportingService.getAlertReport(this.route.snapshot.queryParams).subscribe(
      () => {},
      error => {
        this.loadingService.hide();
        this.disableIfNoTimeRange = false;
        if (error.status === 408) {
          this.snackBar.open(this.translateService.instant('REPORTING.ERROR.TIMEOUT'));
        } else {
          this.snackBar.open(this.translateService.instant('REPORTING.ERROR.INTERNAL_ERROR'));
        }
        this.changeDetector.markForCheck();
      }
    );
  }

  downloadTimeline(): void {
    this.snackBar.dismiss();
    this.reportingService.getTimelineReport(this.route.snapshot.queryParams).subscribe(
      () => {},
      error => {
        this.loadingService.hide();
        this.disableIfNoTimeRange = false;
        if (error.status === 408) {
          this.snackBar.open(this.translateService.instant('REPORTING.ERROR.TIMEOUT'));
        } else {
          this.snackBar.open(this.translateService.instant('REPORTING.ERROR.INTERNAL_ERROR'));
        }
        this.changeDetector.markForCheck();
      }
    );
  }

  downloadTaskCsv(): void {
    this.reportingService.getTaskReport(this.route.snapshot.queryParams).subscribe(
      () => {},
      error => {
        this.loadingService.hide();
        this.disableIfNoTimeRange = false;
        if (error.status === 408) {
          this.snackBar.open(this.translateService.instant('REPORTING.ERROR.TIMEOUT'));
        } else {
          this.snackBar.open(this.translateService.instant('REPORTING.ERROR.INTERNAL_ERROR'));
        }
        this.changeDetector.markForCheck();
      }
    );
  }

  userSelectionChange($event: string): void {
    this.navService.setPageUserSelection($event);
  }

  isAdminOrEmspUser() {
    return this.isInAdminGroup || of(this.isEmspUser) || this.hasAdminProfile;
  }

  changeSearchEngine(toggleValue: MatSlideToggleChange): void {
    this.infrastructureService.setIsNewSearchEngine(toggleValue.checked);
  }
}
