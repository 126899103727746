import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Emails } from './notification';
import { AVAILABLE_LOCALES } from '../../shared/locale-selector/locale-selector.component';

@Component({
  selector: 'app-infra-notification-emails-component',
  templateUrl: 'emails.component.html',
  styleUrls: ['emails.component.scss']
})
export class EmailsComponent {
  public locales = AVAILABLE_LOCALES;

  @Input()
  public emailsForm: FormGroup;
  @Input()
  public emails: Emails;
}
