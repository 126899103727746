import { Injectable } from '@angular/core';
import { EnergySourceType } from '@app/core/park/location/energySource-type';
import { EnvironmentImpactType } from '@app/core/park/location/environmentImpact-type';

export class ItemProperty {
  readonly value;

  constructor(value) {
    this.value = value;
  }

  isNotFilled(): boolean {
    return (
      !this.value || this.value === '' || (Array.isArray(this.value) && this.value.length === 0)
    );
  }

  isToDelete(): boolean {
    return this.value.toString().toLowerCase() === 'delete';
  }

  isToUpdate(otherValue): boolean {
    // If new and old values are undefined, nothing to update
    if (this.isNotFilled() && !otherValue) {
      return false;
    } else if (this.isBooleanValue(otherValue)) {
      return otherValue?.toString().toLowerCase() !== this.value?.toString().toLowerCase();
    } else {
      return this.value?.toString() !== otherValue?.toString();
    }
  }

  isBooleanValue(otherValue): boolean {
    return ['true', 'false'].some(
      item =>
        [otherValue?.toString().toLowerCase(), this.value?.toString()?.toLowerCase()].indexOf(
          item
        ) >= 0
    );
  }
}

export class TempEmsp {
  number?: boolean;
  order?: boolean;
  name?: boolean;

  isToUpdate(): boolean {
    return this.number || this.order || this.name;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CompareService {
  private hasSubObject = ['address', 'coordinates', 'ocpp'];

  private static mergeValues(newValue, oldValue): any {
    const itemProp = new ItemProperty(newValue);
    if (itemProp.isNotFilled()) {
      return oldValue;
    }
    return itemProp.isToDelete() ? undefined : itemProp.value;
  }

  private static mergeEnergyMixValues(newValue, oldValue): any {
    for (const k of Object.keys(newValue)) {
      switch (k) {
        case 'energySources':
          newValue[k] = CompareService.mergeEnergySourcesValues(newValue, oldValue);
          break;
        case 'environImpact':
          newValue[k] = CompareService.mergeEnvironImpact(newValue, oldValue);
          break;
        default:
          newValue[k] = CompareService.mergeValues(newValue[k], oldValue ? oldValue[k] : null);
      }
    }
    return newValue;
  }

  private static mergeEnergySourcesValues(newValue, oldValue): any {
    oldValue?.energySources?.forEach(element => {
      if (!newValue['energySources']?.find(value => value.source === element.source)) {
        newValue['energySources'].push({ source: element.source, percentage: element.percentage });
      }
    });
    let deletedItem = 0;
    const newValueClone = newValue['energySources']?.slice();
    newValueClone?.forEach((element, index) => {
      const oldPercentage = oldValue
        ? oldValue['energySources']?.find(value => value.source === element.source)?.percentage
        : null;
      const newPercentage = element.percentage;
      const oldProp = new ItemProperty(oldPercentage);
      const itemProp = new ItemProperty(newPercentage);
      if ((itemProp.isNotFilled() && oldProp.isNotFilled()) || itemProp.isToDelete()) {
        newValue['energySources'].splice(index - deletedItem, 1);
        deletedItem++;
      } else if (itemProp.isNotFilled()) {
        element.percentage = oldPercentage;
      }
    });
    return newValue['energySources'];
  }

  private static mergeEnvironImpact(newValue, oldValue): any {
    oldValue?.environImpact?.forEach(element => {
      if (!newValue['environImpact']?.find(value => value.source === element.source)) {
        newValue['environImpact'].push({ source: element.source, amount: element.amount });
      }
    });
    let deletedItem = 0;
    const newValueClone = newValue['environImpact']?.slice();
    newValueClone?.forEach((element, index) => {
      const oldAmount = oldValue
        ? oldValue['environImpact']?.find(value => value.source === element.source)?.amount
        : null;
      const newAmount = element.amount;
      const oldProp = new ItemProperty(oldAmount);
      const itemProp = new ItemProperty(newAmount);
      if ((itemProp.isNotFilled() && oldProp.isNotFilled()) || itemProp.isToDelete()) {
        newValue['environImpact'].splice(index - deletedItem, 1);
        deletedItem++;
      } else if (itemProp.isNotFilled()) {
        element.amount = oldAmount;
      }
    });
    return newValue['environImpact'];
  }

  private static mergeProp(property: string, newObject, oldObject): any {
    switch (property) {
      case 'extraInfo':
        newObject.extraInfo = { ...oldObject.extraInfo, ...newObject.extraInfo };
        for (const [key, value] of Object.entries(newObject.extraInfo)) {
          if (new ItemProperty(value).isToDelete()) {
            delete newObject.extraInfo[key];
          }
        }
        return newObject.extraInfo;
      case 'openingTimes':
      case 'relatedLocations':
        newObject[property] = oldObject[property];
        return newObject[property];
      case 'gireveOperatorId':
        return CompareService.mergeValues(
          newObject[property],
          oldObject.emsps.find(emsp => emsp.name === 'GIREVE')?.operatorId
        );
      case 'kiwhiCode':
        return CompareService.mergeValues(
          newObject[property],
          oldObject.emsps.find(emsp => emsp.name === 'KIWHI')?.kiwhiCode
        );
      case 'emsps':
        if (newObject[property].length === 0) {
          return oldObject.emsps.map(emsp => emsp.name);
        } else if (newObject[property][0] === 'delete') {
          return [];
        } else {
          return newObject[property];
        }
      case 'model':
        return CompareService.mergeValues(newObject[property], oldObject[property].id);
      case 'suboperatorName':
        // The field is named suboperatorName in the csv file but is sent as location.suboperator.name to the api
        const mergedValue = CompareService.mergeValues(
          newObject[property],
          oldObject.suboperator?.name
        );
        newObject.suboperator = { name: mergedValue };
        return mergedValue;
      default:
        return CompareService.mergeValues(newObject[property], oldObject[property]);
    }
  }

  private static isValuesDiffer(newValue, oldValue): boolean {
    return new ItemProperty(newValue).isToUpdate(oldValue);
  }

  private static isPropsDiffer(property: string, newObject, oldObject): boolean {
    switch (property) {
      case 'line':
      case 'currentStatus':
      case 'emspStatus':
      case 'cpoId':
      case 'locationId':
      case 'chargingStationId':
      case 'suboperator':
      case 'operatorId':
      case 'legalEntityId':
        return false;
      case 'gireveOperatorId':
        return CompareService.isValuesDiffer(
          newObject[property],
          oldObject.emsps.find(emsp => emsp.name === 'GIREVE')?.operatorId
        );
      case 'kiwhiCode':
        return CompareService.isValuesDiffer(
          newObject[property],
          oldObject.emsps.find(emsp => emsp.name === 'KIWHI')?.kiwhiCode
        );
      case 'model':
        return CompareService.isValuesDiffer(newObject[property], oldObject[property].id);
      case 'suboperatorName':
        return CompareService.isValuesDiffer(newObject[property], oldObject.suboperator?.name);
      default:
        return CompareService.isValuesDiffer(newObject[property], oldObject[property]);
    }
  }

  mergeObjects(newObject, oldObject): any {
    for (const prop of Object.getOwnPropertyNames(newObject)) {
      if (this.hasSubObject.includes(prop)) {
        for (const k of Object.keys(newObject[prop])) {
          newObject[prop][k] = CompareService.mergeValues(newObject[prop][k], oldObject[prop][k]);
        }
      } else if (prop === 'energyMix') {
        newObject[prop] = CompareService.mergeEnergyMixValues(newObject[prop], oldObject[prop]);
      } else {
        newObject[prop] = CompareService.mergeProp(prop, newObject, oldObject);
      }
    }
    return newObject;
  }

  private compareEmsp(emspToUpdate: string[], emspRef: any): TempEmsp {
    const temp = new TempEmsp();
    // Check number of EMSP
    if (emspToUpdate?.length !== emspRef?.length) {
      temp.number = true;
    }
    // Check order of EMSP
    for (const key of Object.keys(emspRef)) {
      emspToUpdate.forEach((emsp, idx) => {
        if (emsp !== emspRef[idx]?.name) {
          temp.order = true;
        }
      });
    }
    // Check name of EMSP
    for (const key of Object.keys(emspRef)) {
      if (!emspToUpdate.includes(emspRef[key]?.name)) {
        temp.name = true;
      }
    }
    return temp.isToUpdate() ? temp : undefined;
  }

  compareObjects(newObject, oldObject): Map<string, any> {
    const toUpdateInObject = new Map<string, any>();
    for (const prop of Object.getOwnPropertyNames(newObject)) {
      if ((this.hasSubObject.includes(prop) && prop !== 'energyMix') || prop === 'extraInfo') {
        for (const k of Object.keys(newObject[prop])) {
          if (CompareService.isValuesDiffer(newObject[prop][k], oldObject[prop][k])) {
            toUpdateInObject.set(prop, { key: k });
          }
        }
      } else if (prop === 'energyMix') {
        this.compareEnergyMix(newObject[prop], oldObject[prop], toUpdateInObject);
      } else if (prop === 'emsps') {
        const compareEmsp = this.compareEmsp(newObject[prop], oldObject[prop]);
        if (compareEmsp) {
          toUpdateInObject.set(prop, this.compareEmsp(newObject[prop], oldObject[prop]));
        }
      } else if (CompareService.isPropsDiffer(prop, newObject, oldObject)) {
        toUpdateInObject.set(prop, newObject[prop]);
      }
    }
    return [...toUpdateInObject.keys()].length > 0 ? toUpdateInObject : undefined;
  }

  private compareEnergyMix(energyMixToUpdate: any, energyMixRef: any, toUpdateInObject) {
    if (energyMixRef) {
      energyMixRef.nuclear = energyMixRef.energySources?.find(obj => {
        return obj.source === EnergySourceType.NUCLEAR;
      })?.percentage;
      energyMixRef.generalFossil = energyMixRef.energySources?.find(obj => {
        return obj.source === EnergySourceType.GENERAL_FOSSIL;
      })?.percentage;
      energyMixRef.coal = energyMixRef.energySources?.find(obj => {
        return obj.source === EnergySourceType.COAL;
      })?.percentage;
      energyMixRef.gas = energyMixRef.energySources?.find(obj => {
        return obj.source === EnergySourceType.GAS;
      })?.percentage;
      energyMixRef.generalGreen = energyMixRef.energySources?.find(obj => {
        return obj.source === EnergySourceType.GENERAL_GREEN;
      })?.percentage;
      energyMixRef.solar = energyMixRef.energySources?.find(obj => {
        return obj.source === EnergySourceType.SOLAR;
      })?.percentage;
      energyMixRef.wind = energyMixRef.energySources?.find(obj => {
        return obj.source === EnergySourceType.WIND;
      })?.percentage;
      energyMixRef.water = energyMixRef.energySources?.find(obj => {
        return obj.source === EnergySourceType.WATER;
      })?.percentage;
      energyMixRef.nuclearWaste = energyMixRef.environImpact?.find(obj => {
        return obj.source === EnvironmentImpactType.NUCLEAR_WASTE;
      })?.amount;
      energyMixRef.carbonDioxide = energyMixRef.environImpact?.find(obj => {
        return obj.source === EnvironmentImpactType.CARBON_DIOXIDE;
      })?.amount;
    }
    for (const k of Object.keys(energyMixToUpdate)) {
      if (
        CompareService.isValuesDiffer(energyMixToUpdate[k], energyMixRef ? energyMixRef[k] : null)
      ) {
        toUpdateInObject.set('energyMix', { key: k });
      }
    }
  }
}
