import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';

import { AppNavigationService } from '@app/navigation';
import { AlertFilter } from '../filter/filter';

const SEARCH_BOX_PLACE_HOLDER = 'alert.Search by EVSE, alert type...';

@Component({ selector: 'app-alert-main-list', templateUrl: 'main-list.component.html' })
export class AlertMainListComponent implements OnDestroy, OnInit {
  private filter: AlertFilter;

  constructor(private ngZone: NgZone, private navService: AppNavigationService) {
    this.filter = new AlertFilter(ngZone, navService);
  }

  ngOnInit() {
    this.navService.setAppBarSearchEnabled(true);
    this.navService.setAppBarSearchPlaceHolder(SEARCH_BOX_PLACE_HOLDER);
    this.navService.setAppBarSearchFilter(this.filter);
  }

  ngOnDestroy() {
    this.navService.setAppBarSearchEnabled(false);
    this.navService.setAppBarSearchFilter(undefined);
  }
}
