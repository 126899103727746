import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { merge, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { ChartLabel, fadeInOut } from '@app/shared';
import { BaseChartComponent } from '../../base-chart/base-chart.component';
import { WidgetService } from '../../widget.service';
import { AlertCount } from './alert-count';

const SEVERITY_LEVELS = ['Signal', 'Info', 'Warning', 'Critical'];

const DEFAULT_TITLE = 'widget.alertCount.Opened alerts';

@Component({
  selector: 'app-widget-alert-count',
  templateUrl: 'alert-count.component.html',
  styleUrls: ['alert-count.component.scss'],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetAlertCountComponent extends BaseChartComponent
  implements AfterViewInit, OnChanges, OnInit {
  chartMarginBottom = 8;

  levelResults: any[] = [];
  typeResults: any[] = [];
  total: number;
  totalLonging: number;

  // options
  showXAxis = true;
  showYAxis = true;
  showGridLines = false;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel: string;
  showYAxisLabel = false;
  yAxisLabel: string;
  barPadding = 24;
  barPaddingDense = 24;

  levelScheme = { domain: ['#bdbdbd', '#9fa8da', '#ff9800', '#f44336'] };
  typeScheme = 'vivid';
  schemeType = 'time';

  private data: AlertCount[];
  private ltAckLabel: ChartLabel;
  private ltNackLabel: ChartLabel;
  private gtAckLabel: ChartLabel;
  private gtNackLabel: ChartLabel;
  private decimalPipe = new DecimalPipe('en-US');

  constructor(
    private ngZone: NgZone,
    private service: WidgetService,
    private translate: TranslateService,
    elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef,
    dialog: MatDialog
  ) {
    super(elementRef, changeDetectorRef, dialog);

    this.ltAckLabel = new ChartLabel('widget.alertCount.Opened less than 4h (Managed)', () =>
      translate.instant('widget.alertCount.≤ 4h (ACK)')
    );
    this.ltNackLabel = new ChartLabel('widget.alertCount.Opened less than 4h (Unmanaged)', () =>
      translate.instant('widget.alertCount.≤ 4h (NACK)')
    );
    this.gtAckLabel = new ChartLabel('widget.alertCount.Opened more than 4h (Managed)', () =>
      translate.instant('widget.alertCount.> 4h (ACK)')
    );
    this.gtNackLabel = new ChartLabel('widget.alertCount.Opened more than 4h (Unmanaged)', () =>
      translate.instant('widget.alertCount.> 4h (NACK)')
    );

    of(DEFAULT_TITLE)
      .pipe(
        takeUntil(this._destroyed),
        switchMap(key =>
          merge(
            this.translate.stream(key),
            this.translate.onTranslationChange.pipe(switchMap(() => this.translate.get(key)))
          )
        )
      )
      .subscribe(val => {
        this._defaultTitle = val;
        if (!this.title) {
          this.title = undefined;
        }
      });
  }

  ngOnInit() {
    this.fetchData();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.option) {
      this.fetchData(changes.option.isFirstChange());
    }
  }

  yAxisTickFormattingFn(tick: string) {
    return this.decimalPipe.transform(tick, '1.0-2', this.translate.currentLang);
  }

  toggleShowStat() {
    if (!this.option) {
      this.option = { showStat: true };
    } else {
      this.option.showStat = !this.option.showStat;
    }
    this.onParentResize();
    this.optionChanged.emit(this.option);
    this.changeDetectorRef.markForCheck();
  }

  setDisplayOption(option: string) {
    if (!this.option) {
      this.option = {};
    }
    this.option.alertType = option === 'type';
    this.optionChanged.emit(this.option);
    this.changeDetectorRef.markForCheck();
  }

  private fetchData(firstTime?: boolean) {
    if (firstTime && this.cacheKey && BaseChartComponent.DATA_CACHE.has(this.cacheKey)) {
      this.data = BaseChartComponent.DATA_CACHE.get(this.cacheKey);
      this.displayData();
      return;
    }

    this.data = [];
    this.levelResults = [];
    this.typeResults = [];
    this.total = 0;
    this.totalLonging = 0;

    this.stopQuerying.next();
    this.changeDetectorRef.markForCheck();

    this.ngZone.runOutsideAngular(() => {
      const params: any = {};
      const cpoGroupFilter = this.parseFilters('cpoGroup');
      if (cpoGroupFilter.length > 0) {
        params.cpoGroup = cpoGroupFilter;
      }
      const cpoFilter = this.parseFilters('cpo');
      if (cpoFilter.length > 0) {
        params.cpo = cpoFilter;
      }
      const locationFilter = this.parseFilters('location');
      if (locationFilter.length > 0) {
        params.location = locationFilter;
      }

      this.service
        .getAlertAggregation(params)
        .pipe(
          takeUntil(this._destroyed),
          takeUntil(this.stopQuerying),
          tap(counts => this.onDataLoaded(counts))
        )
        .subscribe(() => {
          if (this.cacheKey) {
            BaseChartComponent.DATA_CACHE.set(this.cacheKey, this.data);
          }
        });
    });
  }

  private onDataLoaded(counts: AlertCount[]) {
    this.data = counts;
    this.displayData();
  }

  private displayData() {
    let total = 0;
    let totalLonging = 0;

    const levelResults = [];
    const typeResults = [];

    this.retrieveBarResults(levelResults, typeResults, 'ltack', this.ltAckLabel);
    this.retrieveBarResults(levelResults, typeResults, 'ltnack', this.ltNackLabel);
    this.retrieveBarResults(levelResults, typeResults, 'gtack', this.gtAckLabel);
    this.retrieveBarResults(levelResults, typeResults, 'gtnack', this.gtNackLabel);
    for (let i = 0; i < levelResults.length; i++) {
      levelResults[i].series.forEach(series => {
        total += series.value;
        if (i > 1) {
          totalLonging += series.value;
        }
      });
    }

    this.total = total;
    this.totalLonging = totalLonging;
    this.displayResults(levelResults, typeResults);
  }

  private retrieveBarResults(levelResults, typeResults, name, label) {
    const bar = this.data.find(v => v.name === name);
    const levelSeries = [];
    const typeSeries = [];
    if (bar) {
      for (const key in bar.count) {
        if (bar.count.hasOwnProperty(key)) {
          if (SEVERITY_LEVELS.indexOf(key) > -1) {
            levelSeries.push({ name: key, value: bar.count[key] });
          } else {
            typeSeries.push({ name: key, value: bar.count[key] });
          }
        }
      }
    }
    if (levelSeries.length > 0) {
      for (const level of SEVERITY_LEVELS) {
        if (levelSeries.findIndex(s => s.name === level) < 0) {
          levelSeries.push({ name: level, value: 0 });
        }
      }
      levelSeries.sort((a, b) => SEVERITY_LEVELS.indexOf(a.name) - SEVERITY_LEVELS.indexOf(b.name));
    }
    levelResults.push({ name: label, series: levelSeries });
    typeResults.push({ name: label, series: typeSeries });
  }

  private displayResults(levelResults: any[], typeResults: any[]) {
    this.ngZone.run(() => {
      this.levelResults = levelResults;
      this.typeResults = typeResults;
      this.changeDetectorRef.markForCheck();
    });
  }
}
