import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-select-all',
  templateUrl: './select-all.component.html'
})
export class SelectAllComponent implements OnInit {
  @Input() model: NgModel;
  @Input() values = [];
  @Input() text = '';
  @Input() nbMaxElement: number;

  @Output() checkAll = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  isChecked(): boolean {
    return this.model.value && this.values.length && this.model.value.length === this.values.length;
  }

  isIndeterminate(): boolean {
    return (
      this.values.length &&
      this.model.value?.length > 0 &&
      this.model.value?.length < this.values?.length
    );
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.model.update.emit(this.values);
    } else {
      this.model.update.emit([]);
    }
    this.checkAll.emit();
  }
}
