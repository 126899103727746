import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  ChargingLocation,
  Cpo,
  EmspChannel,
  EmspServiceProfile,
  Evse,
  User,
  UserPreferences
} from '@app/core';
import { CpoWithLocations } from '@app/core/infrastructure/cpo-with-locations';
import { CpoGroup } from '@app/core/infrastructure/cpo_group';
import { Emsp } from '@app/core/infrastructure/emsp';
import { Location } from '@app/core/infrastructure/location';
import { ConfigService } from '../../environments/config.service';
import { FilterAutocompleteOption, SearchFilter } from '@app/shared';
import * as fileSaver from 'file-saver';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { concatMap, filter, map, tap } from 'rxjs/operators';
import { FabAction } from './fab/fab.component';
import { KeywordSearchAutocompleteOption } from './keyword-search/keyword-search-autocomplete-option';
import { APP_ITEMS, NavItem } from './nav-item';
import { ToolbarAction } from './toolbar/toolbar-action';
import { AuthenticationService } from '@app/auth/auth.service';
import { USER_GROUP_ALL_ACCESS, UserService } from '@app/user';

@Injectable({ providedIn: 'root' })
export class AppNavigationService {
  private _navItems = new ReplaySubject<NavItem[]>(1);
  private _mainNavItems = new ReplaySubject<NavItem[]>(1);
  private _emspNavItems = new ReplaySubject<NavItem[]>(1);
  private _dFleetNavItems = new ReplaySubject<NavItem[]>(1);
  private _settingsNavItems = new ReplaySubject<NavItem[]>(1);
  private _commissioningNavItems = new ReplaySubject<NavItem[]>(1);
  private _selectedNavItem = new BehaviorSubject<NavItem>(undefined);
  private _sidenavLockedOpened = new BehaviorSubject<boolean>(false);
  private _fabAction = new BehaviorSubject<FabAction>(undefined);
  private _pullDownToRefreshDisabled = new BehaviorSubject<boolean>(undefined);

  private _appBarTitle = new BehaviorSubject<string>(undefined);
  private _appBarSearchEnabled = new BehaviorSubject<boolean>(false);
  private _appBarMassOptionsEnabled = new BehaviorSubject<boolean>(false);
  private _appBarMassOptionsChecked = new BehaviorSubject<boolean>(false);
  private _appBarProgress = new BehaviorSubject<boolean>(undefined);
  private _backUrlFallback = new BehaviorSubject<string>(undefined);
  private _appBarSearchPlaceHolder = new BehaviorSubject<string>(undefined);
  private _appBarSearchAutoOptions = new BehaviorSubject<KeywordSearchAutocompleteOption[]>(
    undefined
  );
  private _appBarSearchFilter = new BehaviorSubject<SearchFilter>(undefined);
  private _appBarAction = new BehaviorSubject<ToolbarAction[]>([]);

  private _userId = new BehaviorSubject<string>(undefined);
  private _preferences = new BehaviorSubject<UserPreferences>(undefined);
  private _groups = new BehaviorSubject<string[]>(undefined);

  private _accessTask = new BehaviorSubject<boolean>(undefined);
  private _pendingTasks = new BehaviorSubject<number>(undefined);

  private _userPageSelection = new BehaviorSubject<string>(undefined);

  // TODO: follow naming conventions: suffix observables by $ sign
  // https://angular.io/guide/rx-library#naming-conventions-for-observables
  readonly navItems = this._navItems.asObservable();
  readonly mainNavItems = this._mainNavItems.asObservable();
  readonly emspNavItems = this._emspNavItems.asObservable();
  readonly dFleetNavItems = this._dFleetNavItems.asObservable();
  readonly settingsNavItems = this._settingsNavItems.asObservable();
  readonly commissioningNavItems = this._commissioningNavItems.asObservable();
  readonly selectedNavItem = this._selectedNavItem.asObservable();
  readonly fabAction = this._fabAction.asObservable();
  readonly pullDownToRefreshDisabled = this._pullDownToRefreshDisabled.asObservable();
  readonly appBarTitle = this._appBarTitle.asObservable();
  readonly appBarSearchEnabled = this._appBarSearchEnabled.asObservable();
  readonly appBarMassOptionsChecked = this._appBarMassOptionsChecked.asObservable();
  readonly appBarProgress = this._appBarProgress.asObservable();
  readonly appBarSearchPlaceHolder = this._appBarSearchPlaceHolder.asObservable();
  readonly appBarSearchAutoOptions = this._appBarSearchAutoOptions.asObservable();
  readonly appBarSearchFilter = this._appBarSearchFilter.asObservable();
  readonly appBarAction = this._appBarAction.asObservable();
  readonly backUrlFallback = this._backUrlFallback.asObservable();
  readonly sidenavLockedOpened = this._sidenavLockedOpened.asObservable();
  readonly userPreferences = this._preferences.asObservable();

  private cpoApiUrl = 'api/cpo';
  private revokeTokenUrl = 'api/oauth2/revoke-token';
  private externalOperators = 'api/infrastructure/external-cpos';
  private infraApiUrl = 'api/infrastructure';
  private emspApi = 'api/emsp';
  private emspServiceProfileApi = 'api/offers';

  cpoOptions: FilterAutocompleteOption[];
  cpoGroupOptions: FilterAutocompleteOption[];
  locationOptions: FilterAutocompleteOption[];
  equipmentOptions: FilterAutocompleteOption[];
  emspNetworkOptions: FilterAutocompleteOption[];
  emspOptions: FilterAutocompleteOption[];
  emspChannelOptions: FilterAutocompleteOption[];
  subscriptionOfferOptions: FilterAutocompleteOption[];

  constructor(
    private auth: AuthenticationService,
    private configService: ConfigService,
    private ngZone: NgZone,
    private http: HttpClient,
    private router: Router,
    private userService: UserService
  ) {
    this.auth.events
      .pipe(
        filter(e => e.type === 'token_received'),
        concatMap(() => this.userService.getAuthenticatedUser()),
        tap(user => this.updateUser(user))
      )
      .subscribe();

    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      // Watch route change to update selected nav item
      this.updateNavItems();
    });
  }

  getCpoList(): Observable<Cpo[]> {
    return this.http.get<Cpo[]>(this.cpoApiUrl);
  }

  getCposWithLocations(): Observable<CpoWithLocations[]> {
    return this.http.get<CpoWithLocations[]>(this.cpoApiUrl + '/cposWithLocations');
  }

  getExternalCpoOperatorsList(): Observable<string[]> {
    return this.http.get<string[]>(this.externalOperators);
  }

  getCpoGroupList(): Observable<CpoGroup[]> {
    return this.http.get<Cpo[]>(`${this.cpoApiUrl}/groups`);
  }

  getLocationHints(): Observable<ChargingLocation[]> {
    return this.http.get<ChargingLocation[]>(`${this.infraApiUrl}/locationHint`);
  }

  getEvseHints(): Observable<Evse[]> {
    return this.http.get<Evse[]>(`${this.infraApiUrl}/evseHint`);
  }

  getCpoHints(): Observable<Cpo[]> {
    return this.http.get<Cpo[]>(`${this.cpoApiUrl}/cpoHint`);
  }

  getEmspNetworkList(): Observable<Evse[]> {
    return this.http.get<Evse[]>(`${this.infraApiUrl}/emspEmobility`);
  }

  getLocationsByUser(): Observable<Location[]> {
    return this.http.get<Location[]>(`${this.infraApiUrl}/locationsByUser`);
  }

  getEmspList(): Observable<Emsp[]> {
    return this.http.get<Emsp[]>(`${this.emspApi}`);
  }

  getSubscriptionOffers(): Observable<EmspServiceProfile[]> {
    let filters = new HttpParams();
    filters = filters.append('type', 'ACCESS_TOKEN_RENEW');

    return this.http.get<EmspServiceProfile[]>(`${this.emspServiceProfileApi}`, {
      params: filters
    });
  }

  getSubscriptionOffersByEmsp(emspKey: string): Observable<EmspServiceProfile[]> {
    let filters = new HttpParams();
    filters = filters.append('emspKey', emspKey);
    filters = filters.append('type', 'ACCESS_TOKEN_RENEW');

    return this.http.get<EmspServiceProfile[]>(`${this.emspServiceProfileApi}`, {
      params: filters
    });
  }

  // TODO: remove this getter and expose a userId$.
  get userId(): BehaviorSubject<string> {
    return this._userId;
  }

  // TODO: remove this getter and expose a userGroups$.
  get userGroups(): BehaviorSubject<string[]> {
    return this._groups;
  }

  // TODO: remove this getter and expose a accessTask$.
  get accessTask(): BehaviorSubject<boolean> {
    return this._accessTask;
  }

  // TODO: remove this getter and expose a pendingTasks$.
  get pendingTasks(): BehaviorSubject<number> {
    return this._pendingTasks;
  }

  // TODO: remove this getter and expose a userPageSelection$.
  get userPageSelection(): BehaviorSubject<string> {
    return this._userPageSelection;
  }

  get isUserConnected(): boolean {
    return !!this.userId.getValue();
  }

  setSidenavLockedOpened(lockedOpened: boolean) {
    this._sidenavLockedOpened.next(lockedOpened);
  }

  setFabAction(fabAction: FabAction) {
    this._fabAction.next(fabAction);
  }

  setPullDownToRefreshDisabled(disabled: boolean) {
    this._pullDownToRefreshDisabled.next(disabled);
  }

  setAppBarTitle(title: string) {
    this._appBarTitle.next(title);
  }

  setAppBarSearchEnabled(show: boolean) {
    this._appBarSearchEnabled.next(show);
  }

  setAppBarMassOptionsEnabled(show: boolean) {
    this._appBarMassOptionsEnabled.next(show);
  }

  setAppBarMassOptionsChecked(show: boolean) {
    this._appBarMassOptionsChecked.next(show);
  }

  setAppBarProgress(enabled: boolean) {
    this._appBarProgress.next(enabled);
  }

  setAppBarSearchPlaceHolder(placeHolder: string) {
    this._appBarSearchPlaceHolder.next(placeHolder);
  }

  setAppBarSearchAutoOptions(options: KeywordSearchAutocompleteOption[]) {
    this._appBarSearchAutoOptions.next(options);
  }

  setAppBarSearchFilter(searchFilter: SearchFilter) {
    this._appBarSearchFilter.next(searchFilter);
  }

  setToolbarAction(actions: ToolbarAction[]) {
    this._appBarAction.next(actions);
  }

  setBackUrlFallback(fallback: string) {
    this._backUrlFallback.next(fallback);
  }

  setPendingTasks(pendingTasks: number) {
    this._pendingTasks.next(pendingTasks > 0 ? pendingTasks : null);
  }

  setPageUserSelection(selection: string): void {
    this._userPageSelection.next(selection);
  }

  updatePreference(preferences: UserPreferences) {
    const _userId = this._userId.getValue();
    if (_userId) {
      const url = `api/${_userId}`;
      this.http.put(url, { preferences }).subscribe(() => {
        this.auth.refreshToken().then();
      });
    }
  }

  loadCpoGroupAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return this.getCpoGroupList().pipe(
      map(cpoGroup => {
        this.cpoGroupOptions = cpoGroup.map(cp => {
          return { display: cp.name || cp._key, value: cp._key };
        });
        return this.cpoGroupOptions;
      })
    );
  }

  loadCpoAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return this.getCpoList().pipe(
      map(cpoList => {
        this.cpoOptions = cpoList.map(cpo => {
          return { display: cpo.name || cpo._key, value: cpo._key };
        });
        return this.cpoOptions;
      })
    );
  }

  loadLocationAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return new Observable(observer => {
      if (this.locationOptions) {
        observer.next(this.locationOptions);
        observer.complete();
      } else {
        this.ngZone.runOutsideAngular(() => {
          this.getLocationHints().subscribe(locations => {
            this.locationOptions = locations.map(loc => {
              return { display: loc.name || loc._key, value: loc._key };
            });
            this.ngZone.run(() => {
              observer.next(this.locationOptions);
              observer.complete();
            });
          });
        });
      }
    });
  }

  loadEvseAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return new Observable(observer => {
      if (this.equipmentOptions) {
        observer.next(this.equipmentOptions);
        observer.complete();
      } else {
        this.ngZone.runOutsideAngular(() => {
          this.getEvseHints().subscribe(evses => {
            this.equipmentOptions = evses.map(evse => {
              return { display: evse._key, value: evse._key };
            });
            this.ngZone.run(() => {
              observer.next(this.equipmentOptions);
              observer.complete();
            });
          });
        });
      }
    });
  }

  loadEmspNetworkAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return new Observable(observer => {
      if (this.emspNetworkOptions) {
        observer.next(this.emspNetworkOptions);
        observer.complete();
      } else {
        this.ngZone.runOutsideAngular(() => {
          this.getEmspNetworkList().subscribe(emspList => {
            this.emspNetworkOptions = emspList.map(emsp => {
              return { display: emsp.name || emsp._key, value: emsp._key };
            });
            this.ngZone.run(() => {
              observer.next(this.emspNetworkOptions);
              observer.complete();
            });
          });
        });
      }
    });
  }

  loadEmspAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return this.getEmspList().pipe(
      map(emspList => {
        this.emspOptions = emspList.map(emsp => {
          return { display: emsp.name || emsp._key, value: emsp._key };
        });
        return this.emspOptions;
      })
    );
  }

  loadSubscriptionOfferAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return this.subscriptionOffersByUserGroup().pipe(
      map(serviceProfile => {
        this.subscriptionOfferOptions = serviceProfile.map(sp => {
          let displayName: string = sp.name || '';
          displayName = sp.emsp ? displayName.concat(' ', '(', sp.emsp, ')') : displayName;
          const subOfferId: string = sp._key;

          return { display: displayName, value: subOfferId };
        });
        return this.subscriptionOfferOptions;
      })
    );
  }

  loadEmspChannelAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return new Observable(observer => {
      if (this.emspChannelOptions) {
        observer.next(this.emspChannelOptions);
        observer.complete();
      } else {
        this.ngZone.runOutsideAngular(() => {
          this.emspChannelOptions = Object.values(EmspChannel).map(emspChannel => {
            return { display: emspChannel, value: emspChannel };
          });
          this.ngZone.run(() => {
            observer.next(this.emspChannelOptions);
            observer.complete();
          });
        });
      }
    });
  }

  downLoadFile(response: HttpResponse<ArrayBuffer>): void {
    const headers = response.headers;
    const contentDisposition = headers.get('content-disposition') || '';
    const matches = /filename="([^;]+)"/gi.exec(contentDisposition);
    const fileName = (matches[1] || 'untitled').trim();

    const file = new File([response.body], fileName, {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    fileSaver.saveAs(file);
  }

  public isEmspUser(): boolean {
    return this.userEmspGroup() !== undefined;
  }

  private userEmspGroup(): string {
    const emspGroupPrefix = 'EMSP_';
    let userGroups: string[] = [];
    this._groups.subscribe(grps => (userGroups = grps));

    return userGroups.find(ug => ug.startsWith(emspGroupPrefix));
  }

  public updateNavItems(): void {
    const grantedScopes = this.configService.getUserGrantedScopes();

    const navItems = APP_ITEMS.filter(item => {
      if (this.isFeatureDisabled(item)) {
        return false;
      }

      if (!item.scopes || !item.scopes.length) {
        return true;
      }

      return item.scopes.some(scope => grantedScopes.includes(scope));
    });

    this._accessTask.next(navItems.findIndex(i => i.path === 'task') > -1);
    this._navItems.next(navItems);
    this._mainNavItems.next(navItems.filter(i => i.category === 'main'));
    this._emspNavItems.next(navItems.filter(i => i.category === 'emsp'));
    this._dFleetNavItems.next(navItems.filter(i => i.category === 'dedicated-fleet'));
    this._settingsNavItems.next(navItems.filter(i => i.category === 'settings'));
    this._commissioningNavItems.next(navItems.filter(i => i.category === 'commissioning'));
    this._selectedNavItem.next(navItems.find(i => this.router.url.startsWith('/' + i.path)));
  }

  private isFeatureDisabled(navItem: NavItem): boolean {
    if (navItem.featureCode === undefined) {
      // A navItem without feature_code don't use the new Feature Flag mechanism, so it's never disabled
      return false;
    }
    return !this.configService.isFeatureEnabledNew(navItem.featureCode);
  }

  public updateUser(user: User): void {
    this._userId.next(user._id);
    this._groups.next(user.groups);
    this._preferences.next(user.preferences);
  }

  private subscriptionOffersByUserGroup(): Observable<EmspServiceProfile[]> {
    const emspGroupPrefix = 'EMSP_';
    const userEmspGroup = this.userEmspGroup();

    let _subscriptionOffers: Observable<EmspServiceProfile[]> = this.getSubscriptionOffers();

    if (userEmspGroup) {
      const emspKey = userEmspGroup.split(emspGroupPrefix).pop();
      _subscriptionOffers = this.getSubscriptionOffersByEmsp(emspKey);
    }

    return _subscriptionOffers;
  }

  public isConnectedUserAdmin(): Observable<boolean> {
    const userId = this.userId.getValue().replace('user/', '');
    return this.userService.getUserGroups(userId).pipe(
      map(userGroups => {
        const currentUserUserGroup = userGroups[0]?._key;
        const currentUserScopes = this.auth.getGrantedScopes()?.[0]?.split(' ');
        // TODO Refactor this to avoid hard-coding userGroup in next ACT batch
        return (
          currentUserUserGroup === USER_GROUP_ALL_ACCESS && currentUserScopes?.includes('admin')
        );
      })
    );
  }
}
