import { ContentChild, Directive, ElementRef, OnDestroy } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { fromEvent, Subject, merge, timer } from 'rxjs';
import { takeUntil, tap, switchMap } from 'rxjs/operators';

import { NavigationFabComponent } from '../fab/fab.component';
import { NavigationToolbarComponent } from './toolbar.component';

@Directive({ selector: '[appToolbarScroll]' })
export class NavigationToolbarScrollDirective implements OnDestroy {
  @ContentChild(NavigationToolbarComponent, { static: true })
  private appBar: NavigationToolbarComponent;
  @ContentChild(NavigationFabComponent, { static: false }) private fab: NavigationFabComponent;

  private _destroyed = new Subject();
  private scrolling: boolean;
  private wheelEventCount = 0;

  constructor(media: MediaObserver, private elRef: ElementRef) {
    merge(
      fromEvent(elRef.nativeElement, 'scroll', { passive: true }).pipe(
        tap(() => (this.scrolling = true))
      ),
      fromEvent(elRef.nativeElement, 'wheel').pipe(
        tap((e: WheelEvent) => {
          this.wheelEventCount++;
          if (this.elRef.nativeElement.scrollTop < 200) {
            if (this.scrolling) {
              let delta = e.deltaY;
              if (delta > 24) {
                delta = 24;
              }
              e.preventDefault();
              let scrollTop = this.elRef.nativeElement.scrollTop + delta;
              if (scrollTop < 0) {
                scrollTop = 0;
              }
              this.elRef.nativeElement.scrollTop = scrollTop;
            } else if (this.wheelEventCount < 2) {
              e.preventDefault();
            }
          }
        })
      )
    )
      .pipe(
        takeUntil(this._destroyed),
        switchMap(() => {
          this.onChange();
          return timer(100);
        })
      )
      .subscribe(() => {
        this.scrolling = false;
        this.wheelEventCount = 0;
      });

    media
      .asObservable()
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => this.onChange(true));
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  private onChange(mediaChange?: boolean) {
    if (this.appBar) {
      this.appBar.scrolled(this.elRef.nativeElement.scrollTop, mediaChange);
    }
    if (this.fab) {
      this.fab.setTranslateY(this.appBar.toolbarTranslate);
    }
  }
}
