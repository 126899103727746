import { AppNavigationService } from '@app/navigation';
import { FilterAutocompleteOption, FilterOption, SearchFilter } from '@app/shared';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { UserService } from '@app/user';
import { UserType } from '@app/core/user-data/user-type';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../environments/config.service';
import { FeatureCode } from '../../../environments/feature-code';

export const INPUT_FILTER_OPTIONS: FilterOption[] = [
  { name: 'user.User group', param: 'group' },
  { name: 'user.Profile', param: 'profile', type: 'option' }
  // { name: 'user.User Type', param: 'userType', type: 'option' } Added in constructor ONLY if the feature MANAGE_USER_MACHINE is enabled
];

class UserFilterOptions {
  profile: FilterAutocompleteOption[];
  userType: FilterAutocompleteOption[];
  group: FilterAutocompleteOption[];

  constructor(
    group?: FilterAutocompleteOption[],
    profile?: FilterAutocompleteOption[],
    userType?: FilterAutocompleteOption[]
  ) {
    this.group = group;
    this.profile = profile;
    this.userType = userType;
  }

  getOptionsOfFilter(filter: FilterOption): FilterAutocompleteOption[] {
    const filterNameTrimmed = filter.param.trim();
    if (!this.hasOwnProperty(filterNameTrimmed)) {
      console.error(
        'UserGroupFilter - Selected filter param is unknown: "',
        filterNameTrimmed + '"'
      );
      return undefined;
    }
    return this[filterNameTrimmed];
  }
}

export class UserGroupFilter extends SearchFilter {
  darkerTheme = true;

  inputFilters: FilterOption[] = INPUT_FILTER_OPTIONS.map(f => Object.assign({}, f));
  ranges = undefined;
  features: FilterOption[] = [];

  private groupOptions: FilterAutocompleteOption[];
  private profileOptions: FilterAutocompleteOption[];
  private userTypeOptions: FilterAutocompleteOption[];

  constructor(
    protected navService: AppNavigationService,
    protected userService: UserService,
    protected translate: TranslateService,
    protected configService: ConfigService
  ) {
    super();
    if (configService.isFeatureEnabledNew(FeatureCode.MANAGE_USER_MACHINE)) {
      this.inputFilters.push({ name: 'user.User Type', param: 'userType', type: 'option' });
    }
  }

  getInputAutoCompleteOptions(selected: FilterOption): Observable<FilterAutocompleteOption[]> {
    if (!selected) {
      return undefined;
    }

    let selectedFilterOptions;
    if (selected.param === 'group') {
      selectedFilterOptions = this.groupOptions;
    } else if (selected.param === 'profile') {
      selectedFilterOptions = this.profileOptions;
    } else if (selected.param === 'userType') {
      selectedFilterOptions = this.userTypeOptions;
    }

    if (selectedFilterOptions) {
      return of(selectedFilterOptions);
    }

    return this.loadAllAutoCompleteOptions().pipe(
      map((options: UserFilterOptions) => {
        return options.getOptionsOfFilter(selected);
      })
    );
  }

  onInputFilterAdded(filter: FilterOption): void {
    if (filter.param === 'group') {
      this.removeFeatureFilterByParam('noGroup');
    }
    if (filter.param === 'profile') {
      this.removeFeatureFilterByParam('noProfile');
    }
  }

  parseOptionFilterParam(filter: FilterOption, value: any): Observable<FilterAutocompleteOption> {
    const allOptions = new UserFilterOptions(
      this.groupOptions,
      this.profileOptions,
      this.userTypeOptions
    );

    const filterOptions = allOptions.getOptionsOfFilter(filter);

    // Call loadAllAutoCompleteOptions only if the FilterAutocompleteOption of the given filter is not already loaded
    // (for example if this.groupOptions is undefined and filter.param == 'group')
    const allOptions$: Observable<UserFilterOptions> = filterOptions
      ? of(allOptions)
      : this.loadAllAutoCompleteOptions();

    return allOptions$.pipe(
      map((userFilterOptions: UserFilterOptions) => {
        return userFilterOptions[filter.param].find(o => o.value === value);
      })
    );
  }

  protected loadAllAutoCompleteOptions(): Observable<UserFilterOptions> {
    return this.navService.userId.pipe(
      switchMap(userId => {
        const key = userId.replace('user/', '');
        return forkJoin([
          this.userService.getEligibleGroups(key),
          this.userService.getUserByKey(key)
        ]);
      }),
      tap(([userGroups]) => {
        this.groupOptions = userGroups.map(group => ({ display: group._key, value: group._key }));
      }),
      tap(([userGroups, user]) => {
        const profiles = userGroups
          .filter(userGroup => user.groups.includes(userGroup._key))
          .filter(userGroup => userGroup.profiles)
          .map(userGroup => userGroup.profiles)
          .flat();
        const uniqueProfiles = [...new Set(profiles)];
        this.profileOptions = uniqueProfiles.map(profile => ({
          display: `user.profile.${profile}`,
          value: profile,
          translate: true
        }));
      }),
      tap(() => {
        this.userTypeOptions = Object.values(UserType).map(userType => ({
          display: this.translate.instant('user.USER_TYPE_' + userType),
          value: userType
        }));
      }),
      map(() => new UserFilterOptions(this.groupOptions, this.profileOptions, this.userTypeOptions))
    );
  }
}
