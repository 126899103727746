import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of, Observable } from 'rxjs';
import { tap, startWith, map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';

export interface TimeZoneOption {
  display: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class TimeZoneOptionService {
  options$: Observable<TimeZoneOption[]>;

  private locales: { [key: string]: any } = {};
  private metaZones: any;

  constructor(private http: HttpClient, private translate: TranslateService) {
    this.options$ = this.translate.onLangChange.pipe(
      startWith({}),
      switchMap(() => {
        if (!this.metaZones) {
          return this.loadMetaTimeZones().pipe(switchMap(() => this.loadTimeZoneNames()));
        } else {
          return this.loadTimeZoneNames();
        }
      }),
      map(data => this.createTimeZoneOptions(data))
    );
  }

  private createTimeZoneOptions(localeData: any): TimeZoneOption[] {
    const localizedZones = this.createLocalizedTimeZones(localeData);

    const now = moment();
    const zones: any[] = [];
    const momentZones = moment.tz.names();
    for (const zone of localizedZones) {
      if (momentZones.indexOf(zone.value) > -1) {
        const tz = now.tz(zone.value);
        const offset = tz.utcOffset();
        // let offsetZone = zones.find(z => z.abbr === abbr && z.offset === offset);
        // if (!offsetZone) {
        const offsetZone = {
          name: zone.name,
          value: zone.value,
          offset,
          utcOffset: tz.format('Z'),
          exemplarCity: zone.exemplarCity
        };
        const metazoneInfo = localeData.metazone[zone.metazone];
        if (metazoneInfo) {
          offsetZone.name = metazoneInfo.long.generic || metazoneInfo.long.standard;
        }
        zones.push(offsetZone);
      }
    }
    return zones
      .sort((a, b) => {
        if (a.offset === b.offset) {
          return a.name < b.name ? -1 : 1;
        }
        return a.offset - b.offset;
      })
      .map(z => ({
        display: `(GMT${z.utcOffset}) ${z.name} - ${z.exemplarCity}`,
        value: z.value
      }));
  }

  /**
   * Creates time zone list with localized name and metazone.
   * @param data locale data
   */
  private createLocalizedTimeZones(data: any): any[] {
    const zones = [];
    for (const continent in data.zone) {
      if (data.zone.hasOwnProperty(continent)) {
        const continentZones = data.zone[continent];
        for (const cityOrCountry in continentZones) {
          if (continentZones.hasOwnProperty(cityOrCountry)) {
            if (continentZones[cityOrCountry].exemplarCity) {
              const metazone = this.getMetazone(continent, cityOrCountry);
              zones.push({
                metazone,
                value: continent + '/' + cityOrCountry,
                exemplarCity: continentZones[cityOrCountry].exemplarCity
              });
            } else {
              for (const city in continentZones[cityOrCountry]) {
                if (continentZones[cityOrCountry].hasOwnProperty(city)) {
                  const metazone = this.getMetazone(continent, cityOrCountry, city);
                  zones.push({
                    metazone,
                    name: continent + '/' + city,
                    exemplarCity: continentZones[cityOrCountry][city].exemplarCity
                  });
                }
              }
            }
          }
        }
      }
    }
    return zones;
  }

  private loadMetaTimeZones(): Observable<any> {
    return this.http
      .get(`assets/i18n/timezone/metaZones.json`)
      .pipe(tap(data => (this.metaZones = data.supplemental.metaZones.metazoneInfo.timezone)));
  }

  private loadTimeZoneNames(): Observable<any> {
    let lang = this.translate.currentLang;
    if (lang.startsWith('en-')) {
      lang = 'en';
    }
    if (lang.startsWith('fr-')) {
      lang = 'fr';
    }
    if (lang.startsWith('es-')) {
      lang = 'es';
    }
    if (lang.startsWith('nl-')) {
      lang = 'nl';
    }
    if (lang.startsWith('it-')) {
      lang = 'it';
    }
    if (!this.locales.hasOwnProperty(lang)) {
      return this.http.get(`assets/i18n/timezone/${lang}/timeZoneNames.json`).pipe(
        map((data: any) => {
          this.locales[lang] = data.main[lang].dates.timeZoneNames;
          return this.locales[lang];
        })
      );
    } else {
      return of(this.locales[lang]);
    }
  }

  private getMetazone(continent: string, cityOrCountry: string, city?: string) {
    if (this.metaZones.hasOwnProperty(continent)) {
      const continentZones = this.metaZones[continent];
      if (continentZones.hasOwnProperty(cityOrCountry)) {
        const cityOrCountryZones = continentZones[cityOrCountry];
        let zones: any[];
        if (city && cityOrCountryZones.hasOwnProperty(city)) {
          zones = cityOrCountryZones[city];
        } else {
          zones = cityOrCountryZones;
        }
        if (zones && zones.length > 0) {
          return zones[zones.length - 1].usesMetazone._mzone;
        }
      }
    }
    return undefined;
  }
}
