import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Task, TaskResult } from '@app/core';
import { map } from 'rxjs/operators';
import { FlyingTaskBadgeDirective } from '@app/shared';

export interface CreateTaskResult extends CancelTaskResult {
  pendingTasks: number;
}

export interface CancelTaskResult {
  taskId: string;
}

export interface TaskAvatar {
  color?: string;
  fontIcon?: string;
  svgIcon?: string;
  tip?: string;
}

export interface FailedSubtaskSummary {
  nbFailedSubtasks: number;
  targets: string[];
}

export interface TaskDisplay {
  task: Task;
  targets: string;
  avatar: TaskAvatar;
  finished: boolean;
  failure?: string;
  nbAcceptedSubtasks: number;
  nbCancelledSubtasks: number;
  failedSubtaskSummary: FailedSubtaskSummary;
  nbUndefinedSubtasks: number;
}

export enum MaintenanceUseCase {
  SERVICE_STATUS_TO_UPDATE = 'SERVICE_STATUS_TO_UPDATE',
  SERVICE_STATUS_ALREADY_UPDATED = 'SERVICE_STATUS_ALREADY_UPDATED'
}

@Injectable({ providedIn: 'root' })
export class TaskService {
  private taskApiUrl = 'api/task';

  constructor(private http: HttpClient) {}

  createTask$(task: Task): Observable<CreateTaskResult> {
    if (task.operation === 'ENABLE_MAINTENANCE' || task.operation === 'DISABLE_MAINTENANCE') {
      task.params['useCase'] = MaintenanceUseCase.SERVICE_STATUS_TO_UPDATE;
    }
    return this.http.post<CreateTaskResult>(this.taskApiUrl, task);
  }

  cancelTask$(taskId: string): Observable<CreateTaskResult> {
    return this.http.put<CreateTaskResult>(`${this.taskApiUrl}/cancel`, { taskId });
  }

  getTasks(params?: HttpParams): Observable<Task[]> {
    return this.http.get<Task[]>(this.taskApiUrl, { params });
  }

  acknowledgeTask$(ids: string[]): Observable<number> {
    let params = new HttpParams();
    for (const id of ids) {
      params = params.append('id', id);
    }
    return this.http
      .post<any>(`${this.taskApiUrl}/ack`, null, {
        params
      })
      .pipe(map(result => result.count));
  }

  getPendingTaskCount$(): Observable<number> {
    return this.http.get<any>(`${this.taskApiUrl}/pending`).pipe(map(result => result.count));
  }

  getTaskDisplay(task: Task): TaskDisplay {
    const taskDisplay = this.addFinishedTaskSummary(task);
    const keys = task.targets.map(target => target.substring(target.indexOf('/') + 1));
    const targets = keys.join(' ');
    taskDisplay.avatar = this.getAvatar(task, task.failure);
    taskDisplay.task = task;
    taskDisplay.targets = targets;
    taskDisplay.failure = task.failure;
    return taskDisplay;
  }

  private addFinishedTaskSummary(task: Task): TaskDisplay {
    const taskDisplay = {} as TaskDisplay;
    task.calculatedStatus = 'EXECUTING';
    if (task.status === 'DONE' || task.status === 'FAILED') {
      taskDisplay.nbAcceptedSubtasks = 0;
      taskDisplay.nbUndefinedSubtasks = 0;
      taskDisplay.nbCancelledSubtasks = 0;
      taskDisplay.failedSubtaskSummary = { nbFailedSubtasks: 0, targets: [] };
      if (task.subtasks.length > 1) {
        task.calculatedStatus = 'DONE';
        task.subtasks.forEach(t => {
          if (
            t.status === 'FAILED' ||
            (t.status === 'DONE' && t.result === 'REJECTED') ||
            ((task.operation === 'REQUEST_START_TRANSACTION' ||
              task.operation === 'REQUEST_STOP_TRANSACTION') &&
              !t.reply?.includes('Accepted'))
          ) {
            taskDisplay.failedSubtaskSummary.nbFailedSubtasks++;
            taskDisplay.failedSubtaskSummary.targets.push(t.target);
            task.calculatedStatus = 'FAILED';
          } else if (t.status === 'DONE') {
            taskDisplay.nbAcceptedSubtasks++;
          } else if (t.status === 'CANCELLED') {
            taskDisplay.nbCancelledSubtasks++;
          } else {
            taskDisplay.nbUndefinedSubtasks++;
          }
        });
      }
      taskDisplay.finished = true;
      return taskDisplay;
    }
    taskDisplay.finished = false;
    return taskDisplay;
  }

  private getAvatar(task: Task, failure: string): TaskAvatar {
    let color: string;
    let fontIcon: string;
    if (failure) {
      color = 'app-background-lightgreen-default';
      fontIcon = 'mdi-alert-outline';
    } else if (task.status === 'DONE') {
      if (task.subtasks.length === 1) {
        if (task.subtasks[0].status === 'DONE') {
          if (
            task.operation === 'REQUEST_START_TRANSACTION' ||
            task.operation === 'REQUEST_STOP_TRANSACTION'
          ) {
            if (task.subtasks[0].reply?.includes('Accepted')) {
              task.subtasks[0].result = TaskResult.ACCEPTED;
            } else {
              task.subtasks[0].result = TaskResult.REJECTED;
            }
          }
          switch (task.subtasks[0].result) {
            case TaskResult.ACCEPTED:
              color = 'app-background-lightgreen-default';
              fontIcon = 'mdi-check';
              break;
            case TaskResult.SCHEDULED:
              color = 'app-background-lightgreen-default';
              fontIcon = 'mdi-clock-outline';
              break;
            default:
              color = 'app-background-warn-default';
              fontIcon = 'mdi-cancel';
          }
        } else {
          color = 'app-background-warn-default';
          fontIcon = 'mdi-alert-outline';
        }
      } else {
        if (task.calculatedStatus === 'DONE') {
          color = 'app-background-lightgreen-default';
          fontIcon = 'mdi-check';
        } else if (task.calculatedStatus === 'FAILED') {
          color = 'app-background-warn-default';
          fontIcon = 'mdi-alert-outline';
        } else {
          color = 'app-background-lightgreen-default';
          fontIcon = 'mdi-timer-sand';
        }
      }
    } else {
      color = 'app-background-lightgreen-default';
      fontIcon = 'mdi-timer-sand';
    }
    return { color, fontIcon };
  }

  retrySubtask(
    taskDisplay: TaskDisplay,
    taskTargets: string[],
    flyingBadge: FlyingTaskBadgeDirective
  ): void {
    const task: Task = {
      targets: taskTargets,
      operation: taskDisplay.task.operation,
      params: taskDisplay.task.params
    };
    this.createTask$(task).subscribe(result => flyingBadge.playAnimation(result.pendingTasks));
  }

  getNbDocuments(httpParams: HttpParams): Observable<number> {
    return this.http.get<number>(`${this.taskApiUrl}/count`, { params: httpParams });
  }
}
