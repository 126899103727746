import { KeyValue } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  applyTimeRange,
  TimeRange,
  TimeRangeEnum
} from '@app/core/smart-charging/chart/time-range';
import * as moment from 'moment';

const ISO_DATE_FORMAT = 'YYYY-MM-DDTHH:mm';
@Component({
  selector: 'app-dialog-period',
  templateUrl: './dialog-period.component.html',
  styleUrls: ['./dialog-period.component.scss']
})
export class DialogPeriodComponent implements OnInit {
  stopDateBeforeStartDate: boolean;
  formRange = this.formBuilder.group({});
  private timeRange: TimeRange;
  timeRangeAvailable = TimeRangeEnum;
  order = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => 0;

  constructor(private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data: any) {
    this.timeRange = data;
  }

  ngOnInit(): void {
    this.formRange = this.formBuilder.group({
      periodBegin: [undefined, Validators.required],
      periodEnd: [undefined, Validators.required]
    });
    this.changeFormRange();
  }

  selectedPeriod(): TimeRange {
    return {
      startDate: new Date(this.startDate),
      stopDate: new Date(this.stopDate)
    };
  }

  onTimeRangeSelected(timeRangeSelected: TimeRangeEnum) {
    this.timeRange = applyTimeRange(timeRangeSelected);
    this.changeFormRange();
  }

  validHour(periodBegins: Date, periodEnds: Date): void {
    this.stopDateBeforeStartDate = periodEnds < periodBegins;
  }

  get startDate(): any {
    return this.formRange.get('periodBegin').value;
  }

  get stopDate(): any {
    return this.formRange.get('periodEnd').value;
  }

  private changeFormRange() {
    this.formRange.get('periodBegin').setValue(
      moment(this.timeRange.startDate)
        .format(ISO_DATE_FORMAT)
        .toString()
    );
    this.formRange.get('periodEnd').setValue(
      moment(this.timeRange.stopDate)
        .format(ISO_DATE_FORMAT)
        .toString()
    );
  }
}
