import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlyingTaskBadgeDirective } from '@app/shared';

import { TranslateService } from '@ngx-translate/core';

import { TaskDisplay, TaskService } from '../../task.service';

@Component({
  selector: 'app-task-display-header',
  templateUrl: './task-display-header.component.html',
  styleUrls: ['./task-display-header.component.scss']
})
export class TaskDisplayHeaderComponent {
  @Input() display: TaskDisplay;
  @Output() taskChange = new EventEmitter();

  constructor(public translate: TranslateService, public taskService: TaskService) {}

  retrySubtask(flyingBadge: FlyingTaskBadgeDirective) {
    this.taskService.retrySubtask(
      this.display,
      this.display.failedSubtaskSummary.targets,
      flyingBadge
    );
  }

  isFinishedTaskWithOneSubtask() {
    return this.display?.finished && this.display?.task?.subtasks?.length === 1;
  }

  isFinishedMassTask() {
    return this.display?.finished && this.display?.task?.subtasks?.length > 1;
  }
}
