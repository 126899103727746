import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';

import { SharedModule } from '@app/shared';
import { AlertBaseModule } from '@app/alert/base/alert-base.module';
import { InfrastructureBaseModule } from '@app/infrastructure/base/infrastructure-base.module';

import { BouncingDotProgressComponent } from './bouncing-dot-progress/bouncing-dot-progress.component';

import { DualYAxisLineChartComponent } from './chart/dual-y-axis-line-chart/dual-y-axis-line-chart.component';
import { WidgetTxTimelineComponent } from './chart/tx-timeline-chart/tx-timeline-chart.component';

import { WidgetCpoLocationFilterComponent } from './cpo-location-filter/cpo-location-filter.component';
import { WidgetAlertCountComponent } from './alert/alert-count/alert-count.component';
import { WidgetAlertRecentListComponent } from './alert/recent-list/recent-list.component';
import { WidgetAlertFilterComponent } from './alert/recent-list/filter.component';
import { WidgetEmspUserCreationTrendComponent } from './emsp/emsp-user-creation-trend/emsp-user-creation-trend.component';
import { WidgetEvseOccupancyTrendComponent } from './infrastructure/evse-occupancy-trend/evse-occupancy-trend.component';
import { WidgetTxComplianceTrendComponent } from './transaction/tx-compliance-trend/tx-compliance-trend.component';
import { WidgetTxConsumptionTrendComponent } from './transaction/tx-consumption-trend/tx-consumption-trend.component';
import { WidgetTxDurationTrendComponent } from './transaction/tx-duration-trend/tx-duration-trend.component';
import { WidgetTxEmspDistributionComponent } from './transaction/tx-emsp-distribution/tx-emsp-distribution.component';
import { WidgetTxEmspTrendComponent } from './transaction/tx-emsp-trend/tx-emsp-trend.component';
import { WidgetTxRoamingDistributionComponent } from './transaction/tx-roaming-distribution/tx-roaming-distribution.component';
import { WidgetTxRoamingTrendComponent } from './transaction/tx-roaming-trend/tx-roaming-trend.component';

import { WidgetLocationMapComponent } from './infrastructure/location-map/location-map.component';
import { WidgetLocationListComponent } from './infrastructure/location-list/location-list.component';

import { BarChartPlaceholderComponent } from './placeholder/bar-chart-placeholder/bar-chart-placeholder.component';
import { PieChartPlaceholderComponent } from './placeholder/pie-chart-placeholder/pie-chart-placeholder.component';
import { TextPlaceholderComponent } from './placeholder/text-placeholder/text-placeholder.component';
import { MatIconRegistry } from '@angular/material/icon';
import { SimConsumptionChartComponent } from './chart/sim-consumption-chart/sim-consumption-chart.component';
import { PowerLineChartComponent } from './chart/power-line-chart/power-line-chart.component';
import { PowerComboAreaLineComponent } from './chart/power-combo-area-line/power-combo-area-line.component';
import { CustomTooltipComponent } from '@app/smart-charging/base/chart/tooltip/custom-tooltip/custom-tooltip.component';
import { BaseWidgetComponent } from './base-widget/base-widget.component';
import { BaseChartComponent } from './base-chart/base-chart.component';

@NgModule({
  declarations: [
    BaseWidgetComponent,
    BaseChartComponent,
    BouncingDotProgressComponent,
    DualYAxisLineChartComponent,
    WidgetTxTimelineComponent,
    WidgetCpoLocationFilterComponent,
    WidgetAlertCountComponent,
    WidgetAlertRecentListComponent,
    WidgetAlertFilterComponent,
    WidgetEmspUserCreationTrendComponent,
    WidgetEvseOccupancyTrendComponent,
    WidgetTxComplianceTrendComponent,
    WidgetTxConsumptionTrendComponent,
    WidgetTxDurationTrendComponent,
    WidgetTxEmspDistributionComponent,
    WidgetTxEmspTrendComponent,
    WidgetTxRoamingDistributionComponent,
    WidgetTxRoamingTrendComponent,
    WidgetLocationMapComponent,
    WidgetLocationListComponent,
    PowerLineChartComponent,
    PowerComboAreaLineComponent,
    CustomTooltipComponent,
    // Placeholder
    BarChartPlaceholderComponent,
    PieChartPlaceholderComponent,
    TextPlaceholderComponent,
    SimConsumptionChartComponent
  ],
  imports: [
    NgxChartsModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    SharedModule,
    AlertBaseModule,
    InfrastructureBaseModule
  ],
  entryComponents: [
    WidgetCpoLocationFilterComponent,
    WidgetAlertCountComponent,
    WidgetAlertRecentListComponent,
    WidgetAlertFilterComponent,
    WidgetEmspUserCreationTrendComponent,
    WidgetLocationListComponent,
    WidgetLocationMapComponent,
    WidgetEvseOccupancyTrendComponent,
    WidgetTxComplianceTrendComponent,
    WidgetTxConsumptionTrendComponent,
    WidgetTxDurationTrendComponent,
    WidgetTxEmspDistributionComponent,
    WidgetTxEmspTrendComponent,
    WidgetTxRoamingDistributionComponent,
    WidgetTxRoamingTrendComponent,
    PowerLineChartComponent,
    PowerComboAreaLineComponent
  ],
  exports: [
    BouncingDotProgressComponent,
    WidgetAlertCountComponent,
    WidgetAlertRecentListComponent,
    WidgetEmspUserCreationTrendComponent,
    WidgetEvseOccupancyTrendComponent,
    WidgetTxComplianceTrendComponent,
    WidgetTxConsumptionTrendComponent,
    WidgetTxDurationTrendComponent,
    WidgetTxEmspDistributionComponent,
    WidgetTxEmspTrendComponent,
    WidgetTxRoamingDistributionComponent,
    WidgetTxRoamingTrendComponent,
    WidgetLocationMapComponent,
    WidgetLocationListComponent,
    WidgetTxTimelineComponent,
    PowerLineChartComponent,
    PowerComboAreaLineComponent,
    CustomTooltipComponent,
    // Placeholder
    BarChartPlaceholderComponent,
    PieChartPlaceholderComponent,
    TextPlaceholderComponent
  ]
})
export class WidgetModule {
  constructor(sanitizer: DomSanitizer, iconRegistry: MatIconRegistry) {
    // Lazy load the style bundle
    new Promise(resolve => {
      const styleElement = document.createElement('link');
      styleElement.rel = 'stylesheet';
      styleElement.href = 'leaflet-styles.css';
      styleElement.onload = resolve;
      document.body.appendChild(styleElement);
    }).then();

    iconRegistry.addSvgIcon(
      'alert-off-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_alert_off_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'format-color-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_format_color_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'ev-station-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_ev_station_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'ev-station-alert',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_ev_station_alert.svg')
    );
    iconRegistry.addSvgIcon(
      'ev-station-ok',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_ev_station_ok.svg')
    );
    iconRegistry.addSvgIcon(
      'ev-station-off-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_ev_station_off_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'battery-charging-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_battery_charging_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'ev-station-unknown',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_ev_station_unknown.svg')
    );
    iconRegistry.addSvgIcon(
      'prepare-session',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_prepare_session.svg')
    );
    iconRegistry.addSvgIcon(
      'sort-alert',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_sort_alert.svg')
    );
    iconRegistry.addSvgIcon(
      'wrench-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_wrench_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'power-off-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_power_off_outline.svg')
    );
  }
}
