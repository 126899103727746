import { Component, EventEmitter, Input, Output } from '@angular/core';

export type BinaryChoice = 1 | 2;

@Component({
  selector: 'app-binary-choice-selector',
  templateUrl: './binary-choice-selector.component.html',
  styleUrls: ['./binary-choice-selector.component.scss']
})
export class BinaryChoiceSelectorComponent {
  @Input() firstChoiceName: string;
  @Input() secondChoiceName: string;
  @Input() firstValue: any;
  @Input() secondValue: any;

  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  selectFirst(): void {
    this.value = this.firstValue;
    this.valueChange.emit(this.value);
  }

  selectSecond(): void {
    this.value = this.secondValue;
    this.valueChange.emit(this.value);
  }
}
