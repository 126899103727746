import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { SmartChargingPeriod } from '@app/core/smart-charging/profile/charging-period/charging-period';
import { FormArray, FormGroup } from '@angular/forms';
import { PeriodType } from '@app/core/smart-charging/profile/charging-period/period-type';

@Component({
  selector: 'app-hub-period',
  templateUrl: './hub-period.component.html',
  styleUrls: ['./hub-period.component.scss']
})
export class HubPeriodComponent implements OnInit, OnChanges {
  @Input() icon: string;
  @Input() isSvgIcon = false;
  @Input() isActiveProfile: boolean;
  @Input() editing = false;
  @Input() periodType: PeriodType;
  @Input() parentForm: FormGroup;
  @Input() formGroupName: string;
  @Input() periodList: SmartChargingPeriod[];

  @Output() addPeriod = new EventEmitter<void>();
  @Output() removePeriod = new EventEmitter<number>();
  PeriodType = PeriodType;

  constructor() {}

  ngOnInit(): void {
    this.updatePeriodList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.periodList || changes.parentForm) {
      this.updatePeriodList();
    }
  }

  private updatePeriodList(): void {
    if (this.parentForm && this.periods) {
      this.periodList = this.periods.value;
      this.periodList.sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
    }
  }

  get periods(): FormArray {
    return this.parentForm.get(this.formGroupName).get('periods') as FormArray;
  }

  formatValue(value: string): string {
    if (this.periodType === PeriodType.power) {
      return `${value} kVA`;
    }
  }

  formatName(period: SmartChargingPeriod): string {
    return `${period.startTime.toString().replace(':', 'h')} - ${period.endTime
      .toString()
      .replace(':', 'h')}`;
  }

  addNewPeriod(): void {
    this.addPeriod.emit();
  }

  onRemovePeriod(index: number): void {
    this.removePeriod.emit(index);
  }
}
