import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Hub } from '@app/core/smart-charging/hub';
import { TranslateService } from '@ngx-translate/core';
import { chevron } from '@app/infrastructure/location-info/location-info-animations';
import { SmartChargingService } from '@app/smart-charging/smart-charging.service';

@Component({
  selector: 'app-hub-display-header',
  templateUrl: './hub-display-header.component.html',
  styleUrls: ['./hub-display-header.component.scss'],
  animations: [chevron]
})
export class HubDisplayHeaderComponent implements OnInit {
  get comColor(): string {
    return this._comColor;
  }
  @Input() hub: Hub;
  @Input() readOnly = false;
  private _comColor: string;
  comMessage: string;

  constructor(
    public translate: TranslateService,
    private smartChargingService: SmartChargingService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.smartChargingService.getLastComDate(this.hub?._key).subscribe(lastDate => {
      if (lastDate) {
        const secondsSinceLastCom = (Date.now() - Date.parse(lastDate)) / 1000;
        if (secondsSinceLastCom > 7200) {
          this._comColor = 'red';
          this.comMessage = 'smartCharging.OLD_COM';
          this.ref.markForCheck();
        } else {
          this._comColor = 'green';
          this.comMessage = 'smartCharging.RECENT_COM';
          this.ref.markForCheck();
        }
      } else {
        this._comColor = 'grey';
        this.comMessage = 'smartCharging.NEVER_COM';
        this.ref.markForCheck();
      }
    });
  }

  hasSyncError() {
    return (
      (!!this.hub && !!this.hub.errors && this.hub.errors.length > 0) ||
      (!!this.hub.subNodes &&
        this.hub.subNodes.length > 0 &&
        this.hub.subNodes.some(node => !!node.errors && node.errors.length > 0))
    );
  }
  getSyncErrorsNumber() {
    return (
      (!!this.hub && !!this.hub.errors ? this.hub.errors.length : 0) +
      (!!this.hub.subNodes &&
        this.hub.subNodes.length > 0 &&
        this.hub.subNodes
          .map(node => (!!node.errors ? node.errors.length : 0))
          .reduce((x, y) => x + y))
    );
  }
}
