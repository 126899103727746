import { Component, Input, OnInit } from '@angular/core';
import { DEFAULT_LOCALE, EvpLocale, LOCALE_KEY } from '@app/shared/language-selector/evp-locale';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  @Input()
  localeOptions: EvpLocale[];

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    const currentLocale = localStorage.getItem(LOCALE_KEY) ?? this.getDefaultLocale();
    this.changeLocale(currentLocale);
  }

  changeLocale(newLocaleValue: string): void {
    this.localeOptions.forEach(locale => {
      locale.selected = locale.value === newLocaleValue;
    });
    localStorage.setItem(LOCALE_KEY, newLocaleValue);
    this.translateService.use(newLocaleValue);
  }

  get selectedLocale(): EvpLocale {
    return this.localeOptions?.find(locale => locale.selected) || DEFAULT_LOCALE;
  }

  private getDefaultLocale(): string {
    const browserLocale = this.translateService.getBrowserCultureLang();
    return this.localeOptions.map(locale => locale.value).includes(browserLocale)
      ? browserLocale
      : DEFAULT_LOCALE.value;
  }
}
