import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { forkJoin, merge, Subject, timer } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { AppNavigationService } from '@app/navigation';
import { ChartLabel, fadeInOut } from '@app/shared';
import { BaseChartComponent } from '../../base-chart/base-chart.component';
import { DatavizChartTimeRange } from '../../base-chart/chart-time-range';
import { WidgetService } from '../../widget.service';
import { TxComplianceCount } from './tx-compliance-count';
import { ChartColorScheme } from '@app/shared/interface/shared-type';

const MIN_LOADING_TIME = 1000;

const DEFAULT_TITLE_PER_DAY = 'widget.complianceTrend.Transactions per day';
const DEFAULT_TITLE_PER_WEEK = 'widget.complianceTrend.Transactions per week';
const DEFAULT_TITLE_PER_MONTH = 'widget.complianceTrend.Transactions per month';

@Component({
  selector: 'app-widget-tx-compliance-trend',
  templateUrl: 'tx-compliance-trend.component.html',
  styleUrls: ['tx-compliance-trend.component.scss'],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetTxComplianceTrendComponent extends BaseChartComponent
  implements AfterViewInit, OnChanges {
  results: any[] = [];
  compliantResults: any[] = [];
  total: number;
  totalCompliant: number;

  querying: boolean;

  // options
  showXAxis = false;
  showYAxis = true;
  showGridLines = false;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel: string;
  showYAxisLabel = false;
  yAxisLabel: string;
  barPadding = 1;

  colorScheme: ChartColorScheme = { domain: ['#fff'] };
  schemeType = 'time';

  // data
  sampleRate: moment.unitOfTime.Base = 'days';
  timeRange: DatavizChartTimeRange;
  timezone: string;

  @ViewChild('primaryColorGetter', { static: true }) private primaryColorGetter: ElementRef;
  @ViewChild('primaryLighterColorGetter', { static: true })
  private primaryLighterColorGetter: ElementRef;

  private data: TxComplianceCount[];
  private compliantLabel: ChartLabel;
  private incompliantLabel: ChartLabel;
  private decimalPipe = new DecimalPipe('en-US');
  private defaultTitle$ = new Subject<string>();

  constructor(
    private ngZone: NgZone,
    private navService: AppNavigationService,
    private service: WidgetService,
    public translate: TranslateService,
    elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef,
    dialog: MatDialog
  ) {
    super(elementRef, changeDetectorRef, dialog);

    this.compliantLabel = new ChartLabel('Compliant', () =>
      translate.instant('widget.complianceTrend.Compliant')
    );
    this.incompliantLabel = new ChartLabel('Incompliant', () =>
      translate.instant('widget.complianceTrend.Incompliant')
    );

    this.defaultTitle$
      .pipe(
        takeUntil(this._destroyed),
        switchMap(key =>
          merge(
            this.translate.stream(key),
            this.translate.onTranslationChange.pipe(switchMap(() => this.translate.get(key)))
          )
        )
      )
      .subscribe(val => {
        this._defaultTitle = val;
        if (!this.title) {
          this.title = undefined;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.option) {
      const sampleRate = this.parseSampleRateOption();
      const rateChanged = sampleRate !== this.sampleRate;
      this.sampleRate = sampleRate;
      if (changes.option.isFirstChange() || rateChanged) {
        this.updateDefaultTitle();
      }

      this.timeRange = this.parseTimeRangeOption();

      if (this.timezone) {
        this.fetchData(changes.option.isFirstChange());
      } else {
        this.navService.userPreferences.pipe(takeUntil(this._destroyed)).subscribe(prefs => {
          const tz = prefs.timeZone || moment.tz.guess();
          if (!this.timezone || this.timezone !== tz) {
            this.timezone = tz;
            this.fetchData(changes.option.isFirstChange());
          }
        });
      }
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    setTimeout(() => {
      const primaryStyle = getComputedStyle(this.primaryColorGetter.nativeElement);
      const primaryLighterStyle = getComputedStyle(this.primaryLighterColorGetter.nativeElement);

      this.colorScheme = {
        domain: [primaryStyle.backgroundColor, primaryLighterStyle.backgroundColor]
      };
    });
  }

  yAxisTickFormattingFn(tick: string) {
    return this.decimalPipe.transform(tick, '1.0-2', this.translate.currentLang);
  }

  toggleCompliantOnly() {
    if (!this.option) {
      this.option = { compliantOnly: true };
    } else {
      this.option.compliantOnly = !this.option.compliantOnly;
    }
  }

  private updateDefaultTitle() {
    let title;
    switch (this.sampleRate) {
      case 'weeks':
        title = DEFAULT_TITLE_PER_WEEK;
        break;
      case 'months':
        title = DEFAULT_TITLE_PER_MONTH;
        break;
      default:
        title = DEFAULT_TITLE_PER_DAY;
    }
    this.defaultTitle$.next(title);
  }

  private fetchData(firstTime?: boolean) {
    if (firstTime && this.cacheKey && BaseChartComponent.DATA_CACHE.has(this.cacheKey)) {
      this.data = BaseChartComponent.DATA_CACHE.get(this.cacheKey);
      this.displayData();
      return;
    }

    this.data = [];
    this.results = [];
    this.compliantResults = [];
    this.total = 0;
    this.totalCompliant = 0;

    this.stopQuerying.next();
    this.changeDetectorRef.markForCheck();

    this.ngZone.runOutsideAngular(() => {
      const params: any = {};
      const cpoGroupFilter = this.parseFilters('cpoGroup');
      if (cpoGroupFilter.length > 0) {
        params.cpoGroup = cpoGroupFilter;
      }
      const cpoFilter = this.parseFilters('cpo');
      if (cpoFilter.length > 0) {
        params.cpo = cpoFilter;
      }
      const locationFilter = this.parseFilters('location');
      if (locationFilter.length > 0) {
        params.location = locationFilter;
      }

      this.querying = true;
      forkJoin([
        timer(MIN_LOADING_TIME),
        this.service.getTxComplianceTrend(
          Object.assign(params, {
            after: this.timeRange.start.toISOString(),
            before: this.timeRange.end.toISOString(),
            rate: this.sampleRate,
            timezone: this.timezone
          })
        )
      ])
        .pipe(
          takeUntil(this._destroyed),
          takeUntil(this.stopQuerying),
          tap(result => this.onDataLoaded(result[1]))
        )
        .subscribe({
          next: () => {
            if (this.cacheKey) {
              BaseChartComponent.DATA_CACHE.set(this.cacheKey, this.data);
            }
          },
          complete: () => {
            this.querying = false;
            this.changeDetectorRef.markForCheck();
          }
        });
    });
  }

  private onDataLoaded(trend: TxComplianceCount[]) {
    trend.forEach(count => {
      const index = this.data.findIndex(i => i.name === count.name);
      if (index > -1) {
        this.data[index].compliant += count.compliant;
        this.data[index].incompliant += count.incompliant;
      } else {
        this.data.push(count);
      }
    });
    this.displayData();
  }

  private displayData() {
    let total = 0;
    let totalCompliant = 0;
    const results = this.data.map(count => {
      const result = { name: count.name, series: [] };
      const sum = count.compliant + count.incompliant;
      total += sum;
      totalCompliant += count.compliant;
      if (count.compliant) {
        result.series.push({ name: this.compliantLabel, value: count.compliant });
      }
      if (count.incompliant) {
        result.series.push({ name: this.incompliantLabel, value: count.incompliant });
      }
      return result;
    });

    this.total = total;
    this.totalCompliant = totalCompliant;

    this.fillMissingSeriesAndSort(results);
    const compliantResults = results.map(result => {
      const v = { name: result.name, series: [] };
      const find = result.series.find(s => s.name === this.compliantLabel);
      if (find) {
        v.series.push(find);
      }
      return v;
    });
    this.displayResults(results, compliantResults);
  }

  private fillMissingSeriesAndSort(results: any[]) {
    let dateFormat;
    switch (this.sampleRate) {
      case 'weeks':
        dateFormat = 'YYYY-ww';
        break;
      case 'months':
        dateFormat = 'YYYY-MM';
        break;
      default:
        dateFormat = 'YYYY-MM-DD';
    }

    const dateIterator = this.timeRange.start.clone();
    while (dateIterator.isBefore(this.timeRange.end)) {
      const date = dateIterator.format(dateFormat);
      if (results.findIndex(i => i.name === date) < 0) {
        results.push({ name: date, series: [] });
      }
      dateIterator.add(1, this.sampleRate);
    }
    results.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  }

  private displayResults(results: any[], compliantResults: any[]) {
    this.ngZone.run(() => {
      this.results = results;
      this.compliantResults = compliantResults;
      this.changeDetectorRef.markForCheck();
    });
  }
}
