import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

export const chipListInOut = trigger('chipListInOut', [
  transition(':enter', [
    style({ height: '0', opacity: 0 }),
    animate(
      `${AnimationDurations.COMPLEX} ${AnimationCurves.DECELERATION_CURVE}`,
      style({ height: '*', opacity: 1 })
    )
  ]),

  transition(':leave', [
    style({ height: '!', opacity: 1 }),
    animate(
      `${AnimationDurations.COMPLEX} ${AnimationCurves.ACCELERATION_CURVE}`,
      style({ height: '0', opacity: 0 })
    )
  ])
]);

export const chipInOut = trigger('chipInOut', [
  transition(':enter', [
    style({ paddingLeft: '0', paddingRight: '0', transform: 'scale(0)', width: '0' }),
    animate(
      '400ms cubic-bezier(.4, 0, .2, 1)',
      keyframes([
        style({
          paddingLeft: '0',
          paddingRight: '0',
          transform: 'scale(0)',
          width: '0',
          offset: 0
        }),
        style({
          paddingLeft: '*',
          paddingRight: '*',
          transform: 'none',
          width: '32px',
          offset: 0.2
        }),
        style({ width: '*', offset: 1.0 })
      ])
    )
  ]),

  transition(':leave', [
    animate(
      '400ms cubic-bezier(.4, 0, .2, 1)',
      keyframes([
        style({ width: '*', offset: 0 }),
        style({ paddingLeft: '*', paddingRight: '*', transform: '*', width: '32px', offset: 0.8 }),
        style({
          paddingLeft: '0',
          paddingRight: '0',
          transform: 'scale(0)',
          width: '0',
          offset: 1.0
        })
      ])
    )
  ])
]);
