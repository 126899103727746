const SVG_START = `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px" y="0px" viewBox="0 0 128 128">
  <image overflow="visible" opacity="0.16" width="83" height="101"
  xlink:href="data:image/png;base64,
  iVBORw0KGgoAAAANSUhEUgAAAFMAAABlCAYAAAAxkd+wAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
  GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAACkJJREFUeNrsnQlTIkkQhasPDvEY
  ndHZ/f+/btUZR1FAQKiFiMrwzTPraGigGyUig9F1G/rj5dlVjTFfj9oeWcvfk/2C+fG1sw3elw38
  bD8DzEx5RttUmRbMKP8+KpgIKgd4OZgGNgYSbQnPS+W/7xxsuQeImQKvUIzB8nE0t16SLciWBHmn
  YaDcI8TCvZ5YhwyhZkpMtYoqEeDbyubO3sgWBH0nUMsduXOuQFwD6zrrOevD7zru73IPUA3kEoDN
  nE2dyc9zMFZsrUCzHagxVyAivJOVDdyzWM8BLUGhMWWiIgXc68om9CyGcN92AbWsWY0aRAR46uzM
  2cBZX1FnijLFtUWRE2ejlY3peQKAEaqpK0GVNcZGduc+AFyDO3d24Z4RqA8me4+l5KPBFHgvzp6d
  yc8C+BXe+6IOhZY1ubWAZIgC7xvYhbMz9zcDxc1TlYmJZ+oAIcw1xOHKnsB68KFN3P8nKt0KaFkD
  SHTpE4Ao8K5WdgkmyjwFVfYgy0u4MAGYhsohVCcCFZiPihfwB2cgDm8EtNwCpCQKVOOZA7iG9t2B
  /A5Avykgu57SyATc3HgyOip04tQ5hLDCMDmkzAFqZaDlFiBFkahGgfhjZdfu+TuAFNfGDN6hoj2r
  ANPn8qJSTnYnFeJzZaDlFjFSQJ4510WINwDzClwb3Zrryrxif249dSeWS30A2IfX5g/R93qVgJYb
  xsgugPzmQN44+wkwr0CRg0A9ue2gg6Fyk9BVmoMi0L6y6pOAVlWmKLLvXEgU+dPZP86u3e8vCGRK
  21g17Fj6d6b0/9jGFoH4rD2Ss3yZ+Iax/Ok5OOegyDXIf53duN+LIk8gW7Ma6+jCfEMRbTJVJCiS
  hyc8edoYZsi9L11MvHFq/NdB/QFZu08gs0jZU/fAWZtY+cZ8mMhCMO02yswV95Y4eQ3ufeNAXjqQ
  JxAfN0kwdUHVZqX8+py8QhOnjWBqqhxACfQDks41uDaC7ETUsO/JfulJYlj8z2EoMgOgCwgfdhNl
  5p6kcwVlUAxkvic1xpJUTuesjfKwi3qFUR62m5Vhck3JpdAVFORXkLX7DQMZA2pIldiOjpUpU1Cd
  RaQMKiB7X0D2lhLoJ6jylMqfpoD0ubyh0GOVwQkOmqPzzyLg3jn03acuTl4r9eQlqTI29WkKUAZp
  FXefelxddfMiUld2QZVXpEqJledQlGt1pGkoUF8i4sk9xk7M7lZTYKzb6UG85OGu9L2dhisyZRaL
  I8QLmMPKlKtPpd6H88wThhk8p+RxVsrAoMlQcSbbh0nTBZzvwAMzqkwNJl5+OIVxVpe6m6xFQDMF
  KM9mz2ls6Bsqe0sj7dPCi2E4j2yzKhFoSDwazCRlal2PuPmABqydFqsyxRvxnAeeOcNf55wHPiWU
  PQ9Zu0egylC3xyI6STnvMiEBdWlS3fVktTar0qfOnjKh39jN+aC9WBBu8cNXyYSm9F43N4qbo+RD
  a4KOAaQ2CC9TQWoxUytmOwD1GEH61Jmbvy93xK4bRYt2hMnXULa5dtMGdWrrSH3T+ixWtOPBNJBt
  TzwhZeIK59z4VzgbLWZmnoPlRr+ad4wuHoMbFU8eGU/lnmfzCWBq6stihWrqMDULvMixQqxc9ZvA
  fM/3vPdtIXt82E3PK/fA07aDWBqK2iN3cxsQVBCmDyRvB9Ggfgag2p6iJDfXQOIF+UXqwVsOjzdp
  RVd45J6D8XrHuQeoOSKXtwExaZu0PgDNA58Kw+QrdMfo6r61ntpSmaQLanwQ3pzEV+iOVZUopKRz
  j7k5qnJ65OoM7S/ic1eBhhJQygHtkcXMZeTcZyawECEWM/mA2sX4ZcuBWkWVEt5wu+BUEZJNLY0k
  bk4Vm4UO2nJlMkjeJpjs5j5l4kHR3RdHEjc1ATHIaRU31w6swZwEJG+PwMV5HyYuLeTFW0nZHJfX
  IUw8+PRI1Mniwd3Blc43T4gfvB9x7IkhbVYnZnCBOaLz1UJb5QQ0I5hi8mm9tVSdmhciSNlSPYaY
  GUy6eeKnxVuP8UVmLXZ1LYPzeY5AmcHFrnmkE8A4Mjbve7jxRV7N3+u92+DqNiAY3PAvbj6NJZ+Q
  Mg3If67IH1/sVXF30xKgrMoRnNszeeA8VlfnCZ/cglxANsQPCai2iL6tqnwCmOziyyrDYX5RfMER
  wRxGPj3bQJBGKf0mBFLObURVS3BSFttUxRuO8O4C61s18D5ybbeF7JtpygOHvjMIXwJS7IXi5SIm
  jiLhxX33K8IbPuEyQ21xU9Yg97YkjjXEPyv7tbK7ld2u7H5lD0406OZBbysSQeK/tRVi2kqxurdC
  1+neErZenAp/OYAC8rf7vVQs85TSL1WZxnxc6aGtkNPUmTcIKHZ1I6e83w7gnbN7p9Rn5+az1OlY
  kfhGMsXlRX0dguq7G8yh3F0bZITcG1X5mqrKKjBjQH3rOA+tTq0BEfd+BFXeKqqcVFHlJsrk3/Eq
  W4bpW2mb7TlOiiqlnhy6BIMg79zvRJXTWPu4jTJ9KtUWxnaUZLRPoFYp7abQdIh73wLM306tL1Qz
  7wxm5lGnptAO/A4Xi5o9AtVq5D8O3J3i3kNIOm9VO7liyzecBYCWHqDZjoH6WuFniJN3oMp7xb3n
  ZoN1AXXA1BRaGP0eQj5Xz3YEkuvJB4iP/7nnX+DelZNOnTEzJdMX8PMugfoK85ED+QcSDoLk7F0p
  6dSpTBPI8rkCMd8RUEt9NyacoQKSW0YZ1LyZLZb9FDW6l6Za7a5XdQO1nqHMGAYyDJLLoI2yd9Wp
  UUq2FADTCLSU95FXnDIxyDll7ieXcKT3vgfXHiogD3rPYY5Rsa0eWY1AfZelx5C5H6BdFJAPMGLT
  FGkPBROV8ZbQNZkaFMqdzULpuR+hw7kDVbJr17rMp6wxXi4VhW4CNQRUA4nXqF4A5F2gwxkr7WIj
  7tOOJ7mo2N9rCQzfV+aZlHMdOYFJ+YNSAgnIZ6XDqe0SdZ1fx5ACNOSyHAf5zthaiyhzyWdKNtwq
  Ygm0s2U9dX+3RQpQG0kmDBRdXbs0O4Q2EZONKNJXAtV+sW8X37pSVaG+rSIL8z65x/uoz6BFFJBY
  +tzDkPcRSiCtw6kV6C46IJ8b804G314i7V7svLLEFx9vqU2sfLm2acqs6vK850aWoayfT8375WMp
  ynkmeR+oI5OXtjQdZgiob88R39RO7lssMGdKZ3NHnY3ER8natRXlh4bpA+orvPG+lVI3DgDmlOaS
  AvE3uLV0NtoSwFZ/h5oPaAgmZur1ipET9z6XNOR9gGz9GIiPe1tIti+YDDQGU1R5rsCU+eSjsyco
  xnnd+V7XjO4TptbL20CP/WLe71tZuL/jdaK4Ug2L8YNsqdk3TAaqbfzEEkjuDCh9Oi7/891g+WB7
  Og8BM9RGsjrxztrW6N/i15gtNE1YnRb6bkpcxMALVLVty8YccE1ok277zVB5Us/xVbs7wcFPwjQI
  KCs1U+aZ2vf02CadgGkoVGP8w2Fjvr7fvJb39xnuXPP1+HrU9PhfgAEAsNajdVOczbkAAAAASUVO
  RK5CYII=" transform="matrix(1 0 0 1 22.3333 10.8333)">
  </image>
  <image overflow="visible" opacity="0.23" width="83" height="101"
  xlink:href="data:image/png;base64,
  iVBORw0KGgoAAAANSUhEUgAAAFMAAABlCAYAAAAxkd+wAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
  GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAACkJJREFUeNrsnQlTIkkQhasPDvEY
  ndHZ/f+/btUZR1FAQKiFiMrwzTPraGigGyUig9F1G/rj5dlVjTFfj9oeWcvfk/2C+fG1sw3elw38
  bD8DzEx5RttUmRbMKP8+KpgIKgd4OZgGNgYSbQnPS+W/7xxsuQeImQKvUIzB8nE0t16SLciWBHmn
  YaDcI8TCvZ5YhwyhZkpMtYoqEeDbyubO3sgWBH0nUMsduXOuQFwD6zrrOevD7zru73IPUA3kEoDN
  nE2dyc9zMFZsrUCzHagxVyAivJOVDdyzWM8BLUGhMWWiIgXc68om9CyGcN92AbWsWY0aRAR46uzM
  2cBZX1FnijLFtUWRE2ejlY3peQKAEaqpK0GVNcZGduc+AFyDO3d24Z4RqA8me4+l5KPBFHgvzp6d
  yc8C+BXe+6IOhZY1ubWAZIgC7xvYhbMz9zcDxc1TlYmJZ+oAIcw1xOHKnsB68KFN3P8nKt0KaFkD
  SHTpE4Ao8K5WdgkmyjwFVfYgy0u4MAGYhsohVCcCFZiPihfwB2cgDm8EtNwCpCQKVOOZA7iG9t2B
  /A5Avykgu57SyATc3HgyOip04tQ5hLDCMDmkzAFqZaDlFiBFkahGgfhjZdfu+TuAFNfGDN6hoj2r
  ANPn8qJSTnYnFeJzZaDlFjFSQJ4510WINwDzClwb3Zrryrxif249dSeWS30A2IfX5g/R93qVgJYb
  xsgugPzmQN44+wkwr0CRg0A9ue2gg6Fyk9BVmoMi0L6y6pOAVlWmKLLvXEgU+dPZP86u3e8vCGRK
  21g17Fj6d6b0/9jGFoH4rD2Ss3yZ+Iax/Ok5OOegyDXIf53duN+LIk8gW7Ma6+jCfEMRbTJVJCiS
  hyc8edoYZsi9L11MvHFq/NdB/QFZu08gs0jZU/fAWZtY+cZ8mMhCMO02yswV95Y4eQ3ufeNAXjqQ
  JxAfN0kwdUHVZqX8+py8QhOnjWBqqhxACfQDks41uDaC7ETUsO/JfulJYlj8z2EoMgOgCwgfdhNl
  5p6kcwVlUAxkvic1xpJUTuesjfKwi3qFUR62m5Vhck3JpdAVFORXkLX7DQMZA2pIldiOjpUpU1Cd
  RaQMKiB7X0D2lhLoJ6jylMqfpoD0ubyh0GOVwQkOmqPzzyLg3jn03acuTl4r9eQlqTI29WkKUAZp
  FXefelxddfMiUld2QZVXpEqJledQlGt1pGkoUF8i4sk9xk7M7lZTYKzb6UG85OGu9L2dhisyZRaL
  I8QLmMPKlKtPpd6H88wThhk8p+RxVsrAoMlQcSbbh0nTBZzvwAMzqkwNJl5+OIVxVpe6m6xFQDMF
  KM9mz2ls6Bsqe0sj7dPCi2E4j2yzKhFoSDwazCRlal2PuPmABqydFqsyxRvxnAeeOcNf55wHPiWU
  PQ9Zu0egylC3xyI6STnvMiEBdWlS3fVktTar0qfOnjKh39jN+aC9WBBu8cNXyYSm9F43N4qbo+RD
  a4KOAaQ2CC9TQWoxUytmOwD1GEH61Jmbvy93xK4bRYt2hMnXULa5dtMGdWrrSH3T+ixWtOPBNJBt
  TzwhZeIK59z4VzgbLWZmnoPlRr+ad4wuHoMbFU8eGU/lnmfzCWBq6stihWrqMDULvMixQqxc9ZvA
  fM/3vPdtIXt82E3PK/fA07aDWBqK2iN3cxsQVBCmDyRvB9Ggfgag2p6iJDfXQOIF+UXqwVsOjzdp
  RVd45J6D8XrHuQeoOSKXtwExaZu0PgDNA58Kw+QrdMfo6r61ntpSmaQLanwQ3pzEV+iOVZUopKRz
  j7k5qnJ65OoM7S/ic1eBhhJQygHtkcXMZeTcZyawECEWM/mA2sX4ZcuBWkWVEt5wu+BUEZJNLY0k
  bk4Vm4UO2nJlMkjeJpjs5j5l4kHR3RdHEjc1ATHIaRU31w6swZwEJG+PwMV5HyYuLeTFW0nZHJfX
  IUw8+PRI1Mniwd3Blc43T4gfvB9x7IkhbVYnZnCBOaLz1UJb5QQ0I5hi8mm9tVSdmhciSNlSPYaY
  GUy6eeKnxVuP8UVmLXZ1LYPzeY5AmcHFrnmkE8A4Mjbve7jxRV7N3+u92+DqNiAY3PAvbj6NJZ+Q
  Mg3If67IH1/sVXF30xKgrMoRnNszeeA8VlfnCZ/cglxANsQPCai2iL6tqnwCmOziyyrDYX5RfMER
  wRxGPj3bQJBGKf0mBFLObURVS3BSFttUxRuO8O4C61s18D5ybbeF7JtpygOHvjMIXwJS7IXi5SIm
  jiLhxX33K8IbPuEyQ21xU9Yg97YkjjXEPyv7tbK7ld2u7H5lD0406OZBbysSQeK/tRVi2kqxurdC
  1+neErZenAp/OYAC8rf7vVQs85TSL1WZxnxc6aGtkNPUmTcIKHZ1I6e83w7gnbN7p9Rn5+az1OlY
  kfhGMsXlRX0dguq7G8yh3F0bZITcG1X5mqrKKjBjQH3rOA+tTq0BEfd+BFXeKqqcVFHlJsrk3/Eq
  W4bpW2mb7TlOiiqlnhy6BIMg79zvRJXTWPu4jTJ9KtUWxnaUZLRPoFYp7abQdIh73wLM306tL1Qz
  7wxm5lGnptAO/A4Xi5o9AtVq5D8O3J3i3kNIOm9VO7liyzecBYCWHqDZjoH6WuFniJN3oMp7xb3n
  ZoN1AXXA1BRaGP0eQj5Xz3YEkuvJB4iP/7nnX+DelZNOnTEzJdMX8PMugfoK85ED+QcSDoLk7F0p
  6dSpTBPI8rkCMd8RUEt9NyacoQKSW0YZ1LyZLZb9FDW6l6Za7a5XdQO1nqHMGAYyDJLLoI2yd9Wp
  UUq2FADTCLSU95FXnDIxyDll7ieXcKT3vgfXHiogD3rPYY5Rsa0eWY1AfZelx5C5H6BdFJAPMGLT
  FGkPBROV8ZbQNZkaFMqdzULpuR+hw7kDVbJr17rMp6wxXi4VhW4CNQRUA4nXqF4A5F2gwxkr7WIj
  7tOOJ7mo2N9rCQzfV+aZlHMdOYFJ+YNSAgnIZ6XDqe0SdZ1fx5ACNOSyHAf5zthaiyhzyWdKNtwq
  Ygm0s2U9dX+3RQpQG0kmDBRdXbs0O4Q2EZONKNJXAtV+sW8X37pSVaG+rSIL8z65x/uoz6BFFJBY
  +tzDkPcRSiCtw6kV6C46IJ8b804G314i7V7svLLEFx9vqU2sfLm2acqs6vK850aWoayfT8375WMp
  ynkmeR+oI5OXtjQdZgiob88R39RO7lssMGdKZ3NHnY3ER8natRXlh4bpA+orvPG+lVI3DgDmlOaS
  AvE3uLV0NtoSwFZ/h5oPaAgmZur1ipET9z6XNOR9gGz9GIiPe1tIti+YDDQGU1R5rsCU+eSjsyco
  xnnd+V7XjO4TptbL20CP/WLe71tZuL/jdaK4Ug2L8YNsqdk3TAaqbfzEEkjuDCh9Oi7/891g+WB7
  Og8BM9RGsjrxztrW6N/i15gtNE1YnRb6bkpcxMALVLVty8YccE1ok277zVB5Us/xVbs7wcFPwjQI
  KCs1U+aZ2vf02CadgGkoVGP8w2Fjvr7fvJb39xnuXPP1+HrU9PhfgAEAsNajdVOczbkAAAAASUVO
  RK5CYII=" transform="matrix(1 0 0 1 22.3333 16.8333)">
  </image>`;
const SVG_END = `
</svg>`;
const MARKER_PATH = `<path fill="___COLOR___"
d="M96,54.9c0,29.9-32,50.3-32,50.3S32,84.7,32,54.9c0-17.7,14.3-32,32-32S96,37.2,96,54.9z"/>`;

export const USER_ICON = `
<path fill="___COLOR___" d="M64,44.9c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3s-3.3-1.5-3.3-3.3C60.7,46.4,62.2,44.9,64,44.9 M64,59.9
	c4.5,0,9.7,2.2,10,3.3v1.7H54v-1.7C54.3,62,59.5,59.9,64,59.9 M64,41.5c-3.7,0-6.7,3-6.7,6.7c0,3.7,3,6.7,6.7,6.7s6.7-3,6.7-6.7
  C70.7,44.5,67.7,41.5,64,41.5z M64,56.5c-4.5,0-13.3,2.2-13.3,6.7v5h26.7v-5C77.3,58.8,68.4,56.5,64,56.5z"/>`;

export const EV_STATION_OUTLINE_ICON = `<g>
<path fill="___COLOR___" d="M76.9,46.9L76.9,46.9l-6.2-6.2L69,42.5l3.5,3.5c-1.6,0.6-2.7,2.1-2.7,3.9c0,2.3,1.9,4.2,4.2,4.2
c0.6,0,1.2-0.1,1.7-0.3v12c0,0.9-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7v-7.5c0-1.8-1.5-3.3-3.3-3.3h-1.7V43.2c0-1.8-1.5-3.3-3.3-3.3
H54c-1.8,0-3.3,1.5-3.3,3.3v26.7h16.7V57.4h2.5v8.3c0,2.3,1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2V49.9C78.2,48.7,77.7,47.7,76.9,46.9z
 M64,53.2V54v3.3v9.2H54v-9.2V43.2h10V53.2z M74,51.5c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7
C75.7,50.8,74.9,51.5,74,51.5z"/>
<polygon fill="___COLOR___" points="60.7,44.9 54,57.4 57.3,57.4 57.3,64.9 64,53.2 60.7,53.2"/>
</g>`;

export const EV_STATION_ALERT_OUTLINE_ICON = `<g>
<rect fill="___COLOR___" x="57.3" y="46.5" width="3.3" height="10"/>
<rect fill="___COLOR___" x="57.3" y="59.9" width="3.3" height="3.3"/>
<path fill="___COLOR___" d="M77,46.9L77,46.9l-6.2-6.2L69,42.5l3.5,3.5c-1.5,0.5-2.7,2-2.7,3.8c0,2.3,1.8,4.2,4.2,4.2c0.7,0,1.2-0.2,1.7-0.3v12
c0,0.8-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7v-7.5c0-1.8-1.5-3.3-3.3-3.3h-1.7V43.2c0-1.8-1.5-3.3-3.3-3.3H54c-0.5,0-0.8,0.2-1.3,0.3
c-0.2,0-0.3,0.2-0.5,0.2c-0.3,0.3-0.7,0.7-1,1c-0.3,0.5-0.5,1.2-0.5,1.8v26.7h16.7V57.4h2.5v8.3c0,2.3,1.8,4.2,4.2,4.2
s4.2-1.8,4.2-4.2V49.9C78.2,48.7,77.7,47.7,77,46.9z M64,53.2V54v3.3v9.2H54v-9.2V43.2h10V53.2z M74,51.5c-0.8,0-1.7-0.8-1.7-1.7
c0-0.8,0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7C75.7,50.7,74.8,51.5,74,51.5z"/>
</g>`;

export const EV_STATION_UNKNOWN_OUTLINE_ICON = `<g>
<rect fill="___COLOR___" x="57.8" y="60.9" width="2.3" height="2.3"/>
<path fill="___COLOR___" d="M59,48.9c1.3,0,2.3,1,2.3,2.3c0,0.7-0.3,1.3-0.7,1.7l-1.5,1.5c-0.8,0.8-1.3,2-1.3,3.3v0.7h2.3c0-1.8,0.5-2.5,1.3-3.3
  l1-1.2c0.7-0.7,1.2-1.7,1.2-2.7c0.2-2.5-2-4.7-4.7-4.7s-4.8,2.2-4.8,4.8h2.3C56.7,50,57.7,48.9,59,48.9z"/>
<path fill="___COLOR___" d="M77,46.9L77,46.9l-6.2-6.2L69,42.5l3.5,3.5c-1.5,0.5-2.7,2-2.7,3.8c0,2.3,1.8,4.2,4.2,4.2c0.7,0,1.2-0.2,1.7-0.3v12
  c0,0.8-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7v-7.5c0-1.8-1.5-3.3-3.3-3.3h-1.7V43.2c0-0.7-0.2-1.3-0.5-1.8c-0.2-0.3-0.5-0.7-0.8-0.8
  c-0.2-0.2-0.3-0.2-0.5-0.3c-0.7-0.2-1-0.3-1.5-0.3H54c-1.8,0-3.3,1.5-3.3,3.3v26.7h16.7v-11v-1.5h2.5v8.3c0,2.3,1.8,4.2,4.2,4.2
  s4.2-1.8,4.2-4.2V49.9C78.2,48.7,77.7,47.7,77,46.9z M64,53.2V54v3.3v9.2H54v-9.2V43.2h10V53.2z M74,51.5c-0.8,0-1.7-0.8-1.7-1.7
  c0-0.8,0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7C75.7,50.7,74.8,51.5,74,51.5z"/>
</g>`;

export function getMarkerSvg(markerColor: string, iconPath?: string, iconColor?: string) {
  return (
    'data:image/svg+xml;charset=UTF-8,' +
    encodeURIComponent(
      SVG_START +
        MARKER_PATH.replace('___COLOR___', markerColor) +
        (iconPath ? iconPath.replace(/___COLOR___/g, iconColor || '') : '') +
        SVG_END
    )
  );
}
