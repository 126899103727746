import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';

import { timer } from 'rxjs';

import { AppUpdateDialogComponent } from './update-dialog/update-dialog.component';

const CHECK_UPDATE_FIRST_DELAY = 5 * 1000;
const CHECK_UPDATE_INTERVAL = 5 * 60 * 1000;

@Injectable({ providedIn: 'root' })
export class AppSwUpdateService {
  constructor(ngZone: NgZone, dialog: MatDialog, update: SwUpdate) {
    if (update.isEnabled) {
      update.available.subscribe(() => {
        dialog
          .open(AppUpdateDialogComponent)
          .afterClosed()
          .subscribe(confirm => {
            if (confirm) {
              update.activateUpdate().then(() => document.location.reload());
            }
          });
      });
      ngZone.runOutsideAngular(() =>
        timer(CHECK_UPDATE_FIRST_DELAY, CHECK_UPDATE_INTERVAL).subscribe(() =>
          update.checkForUpdate()
        )
      );
    }
  }
}
