import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, UserGroup } from '@app/core';
import { Observable, of, throwError } from 'rxjs';
import { AccessTarget } from '@app/user/user-group-list/access-target.model';
import { catchError, map } from 'rxjs/operators';

export interface UserApi {
  items: User[];
  totalCount: number;
}

export const ADMIN = 'ADMIN';
export const USER_GROUP_ALL_ACCESS = 'ALL_ACCESS_TCS_CENTRAL';

@Injectable({ providedIn: 'root' })
export class UserService {
  private userApiUrl = 'api/user';
  private userGroupApiUrl = 'api/user-group';

  constructor(private http: HttpClient) {}

  getUsers(params?: HttpParams): Observable<UserApi> {
    return this.http.get<UserApi>(this.userApiUrl, { params });
  }

  getUserByKey(key: string): Observable<User> {
    const url = `${this.userApiUrl}/${key}`;
    return this.http.get<User>(url);
  }

  getAuthenticatedUser(): Observable<User> {
    return this.http.get<User>(`${this.userApiUrl}/authenticated-user`).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of(undefined);
        }
        return throwError(error);
      })
    );
  }

  getUserByArangoId(arangoId: string): Observable<User> {
    const key = arangoId.replace('user/', '');
    return this.getUserByKey(key);
  }

  getUserScope(): Observable<string> {
    return this.http.get<{ scope: string }>(`${this.userApiUrl}/scope`).pipe(map(dto => dto.scope));
  }

  getBatchOfUserInfos(userIds: string[]): Observable<User[]> {
    const url = `${this.userApiUrl}/users`;
    const queryParams = {
      ids: userIds
        .map(value => (value.startsWith('user') ? value.split('/')[1] : value))
        .reduce((a, b, i) => {
          if (i > 0) {
            a += ';';
          }
          return a.concat(b);
        }, '')
    };

    return this.http.get<User[]>(url, { params: new HttpParams({ fromObject: queryParams }) });
  }

  updateUser(key: string, update: any): Observable<any> {
    const url = `${this.userApiUrl}/${key}`;
    return this.http.put<User>(url, update);
  }

  getUserGroups(key: string): Observable<UserGroup[]> {
    const url = `${this.userApiUrl}/${key}/group`;
    return this.http.get<UserGroup[]>(url);
  }

  getEligibleGroups(userKey: string, params?: HttpParams): Observable<UserGroup[]> {
    const url = `${this.userApiUrl}/${userKey}/eligibleGroup`;
    return this.http.get<UserGroup[]>(url, { params });
  }

  getAccessTarget(_key: string): Observable<AccessTarget> {
    return this.http.get<AccessTarget>(`${this.userGroupApiUrl}/accessTarget`);
  }

  getUserGroupById(_key: string): Observable<UserGroup> {
    return this.http.get<UserGroup>(`${this.userGroupApiUrl}/${_key}`);
  }

  createUserGroup(userGroup: UserGroup): Observable<UserGroup> {
    return this.http.post<UserGroup>(this.userGroupApiUrl, { userGroup });
  }

  updateUserGroup(userGroup: UserGroup): Observable<UserGroup> {
    const url = `${this.userGroupApiUrl}/${userGroup._key}`;
    return this.http.put<UserGroup>(url, { userGroup });
  }

  isUserInAdminGroup(userKey: string): Observable<boolean> {
    if (!userKey) {
      return of(false);
    }
    return this.getEligibleGroups(userKey).pipe(
      map(groups => groups?.some(group => group._key.includes(ADMIN)))
    );
  }

  isUserHasAdminProfile(userKey: string): Observable<boolean> {
    if (!userKey) {
      return of(false);
    }
    return this.getUserByKey(userKey).pipe(map(user => user?.profiles.includes(ADMIN)));
  }
}
