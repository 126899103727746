import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  Input
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';

import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { AppNavigationService } from '@app/navigation';
import { chipInOut, chipListInOut, FilterAutocompleteOption, FilterOption } from '@app/shared';
import { SearchFilter } from '../../shared/filter/search-filter';
import { ServiceStatus } from '../../core/infrastructure/evse-service-status';
import { MatChip } from '@angular/material/chips';

export const INPUT_FILTER_OPTIONS: FilterOption[] = [
  { name: 'widget.filter.CPO', param: 'cpo', type: 'option', sort: true },
  { name: 'widget.filter.CPO group', param: 'cpoGroup', type: 'option', sort: true },
  { name: 'widget.filter.Location', param: 'location', type: 'option', sort: true }
];

export const CHIP_FILTER_STATUS: FilterOption[] = [
  {
    name: 'widget.filter.Status',
    param: 'status',
    type: 'option',
    sort: true,
    selected: false,
    value: { display: 'infra.status.IN_SERVICE', value: ServiceStatus.IN_SERVICE }
  },
  {
    name: 'widget.filter.Status',
    param: 'status',
    type: 'option',
    sort: true,
    selected: false,
    value: { display: 'infra.status.PROVISIONING', value: ServiceStatus.PROVISIONING }
  },
  {
    name: 'widget.filter.Status',
    param: 'status',
    type: 'option',
    sort: true,
    selected: false,
    value: { display: 'infra.status.SCHEDULED', value: ServiceStatus.SCHEDULED }
  },
  {
    name: 'widget.filter.Status',
    param: 'status',
    type: 'option',
    sort: true,
    selected: false,
    value: { display: 'infra.status.SUSPENDED', value: ServiceStatus.SUSPENDED }
  }
];

interface SelectableMatChip extends MatChip {
  selected: boolean;
}
@Component({
  selector: 'app-widget-cpo-location-filter',
  templateUrl: 'cpo-location-filter.component.html',
  styleUrls: ['cpo-location-filter.component.scss'],
  animations: [chipInOut, chipListInOut]
})
export class WidgetCpoLocationFilterComponent implements OnDestroy, OnInit {
  @Input() searchFilter: SearchFilter;
  inputFilters: FilterOption[] = INPUT_FILTER_OPTIONS.map(f => Object.assign({}, f));
  chipFilters: FilterOption[] = CHIP_FILTER_STATUS.map(y => Object.assign({}, y));
  selectedInputFilter: FilterOption;

  appliedFilters: FilterOption[] = [];
  appliedStatus: FilterOption[] = [];
  storeStatus: FilterOption[] = [];
  disableForCpoTab: boolean;
  displayServiceStatus: boolean;
  @ViewChild('stringInput', { static: true }) stringInput: MatInput;

  stringInputControl = new FormControl();
  autoCompleteOptions: Observable<FilterAutocompleteOption[]>;
  _autoCompleteOptions: FilterAutocompleteOption[];

  private _destroyed = new Subject<void>();
  private cpoOptions: FilterAutocompleteOption[];
  private cpoGroupOptions: FilterAutocompleteOption[];
  private locationOptions: FilterAutocompleteOption[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      appliedFilters: FilterOption[];
      cpoOptions: FilterAutocompleteOption[];
      cpoGroupOptions: FilterAutocompleteOption[];
      locationOptions: FilterAutocompleteOption[];
      disableForCpoTab: boolean;
      displayServiceStatus: boolean;
    },
    private translate: TranslateService,
    private navService: AppNavigationService
  ) {}

  ngOnInit() {
    this.disableForCpoTab =
      this.data && this.data.disableForCpoTab ? this.data.disableForCpoTab : false;
    if (this.disableForCpoTab) {
      this.stringInputControl.disable();
    } else {
      this.stringInputControl.enable();
    }

    this.displayServiceStatus = this.data.displayServiceStatus
      ? this.data.displayServiceStatus
      : false;

    if (this.data && this.data.appliedFilters) {
      this.data.appliedFilters.forEach(element => {
        const indexSelected = this.chipFilters.findIndex(
          el => element.value.value.indexOf(el.value.value) > -1
        );
        if (indexSelected > -1) {
          this.chipFilters[indexSelected].selected = true;
          CHIP_FILTER_STATUS[indexSelected].selected = true;
          this.storeStatus.push(this.chipFilters[indexSelected]);
        }
      });

      this.appliedStatus = this.storeStatus;
      this.appliedFilters =
        this.data.appliedFilters.filter(el => el.param.indexOf('status') < 0) || [];
    }

    this.cpoOptions = this.data.cpoOptions || [];
    this.cpoGroupOptions = this.data.cpoGroupOptions || [];
    this.locationOptions = this.data.locationOptions || [];

    if (
      !this.data ||
      !this.data.cpoOptions ||
      !this.data.cpoGroupOptions ||
      !this.data.locationOptions
    ) {
      forkJoin([
        this.navService.loadCpoAutoCompleteOptions(),
        this.navService.loadCpoGroupAutoCompleteOptions(),
        this.navService.loadLocationAutoCompleteOptions()
      ])
        .pipe(takeUntil(this._destroyed))
        .subscribe(result => {
          this.cpoOptions = result[0];
          this.cpoGroupOptions = result[1];
          this.locationOptions = result[2];
          this.onSelectedInputChange();
        });
    }

    this.autoCompleteOptions = this.stringInputControl.valueChanges.pipe(
      takeUntil(this._destroyed),
      startWith(''),
      map(value => this._filterAutocompleteOptions(value))
    );

    this.selectedInputFilter = this.inputFilters[0];
    this.onSelectedInputChange();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  displayOptionFn(option: FilterAutocompleteOption) {
    if (!option) {
      return '';
    }
    if (this.selectedInputFilter.type !== 'option') {
      return option.value;
    }
    if (option.translate) {
      return this.translate.instant(option.display);
    }
    return option.display;
  }

  onSelectedInputChange(): void {
    if (this.selectedInputFilter) {
      switch (this.selectedInputFilter.param) {
        case 'cpo':
          this._autoCompleteOptions = this.cpoOptions;
          break;
        case 'cpoGroup':
          this._autoCompleteOptions = this.cpoGroupOptions;
          break;
        case 'location':
          this._autoCompleteOptions = this.locationOptions;
          break;
        default:
          this._autoCompleteOptions = [];
      }
      this.stringInputControl.patchValue(this.stringInputControl.value);
    }
  }

  addInputFilter() {
    if (!this.selectedInputFilter) {
      return;
    }
    let value = this.stringInputControl.value;
    if (typeof value === 'string') {
      value = { display: value, value };
    }
    if (!this.appliedFilters) {
      this.appliedFilters = [];
    }
    const inputFilter = {
      name: this.selectedInputFilter.name,
      param: this.selectedInputFilter.param,
      type: this.selectedInputFilter.type,
      value
    };
    this.appliedFilters.push(inputFilter);
    this.stringInput.value = '';
    this.stringInputControl.patchValue('');

    this._changeDetectorRef.markForCheck();
  }

  removeInputFilter(index: number) {
    this.appliedFilters.splice(index, 1);
    this._changeDetectorRef.markForCheck();
  }

  clearInputFilters() {
    this.appliedFilters = [];
    this._changeDetectorRef.markForCheck();
  }

  applyFilter() {
    const appliedAll = this.appliedStatus.concat(this.appliedFilters);
    this.dialogRef.close(appliedAll);
  }

  private _filterAutocompleteOptions(value: string): FilterAutocompleteOption[] {
    if (!this._autoCompleteOptions) {
      return [];
    }
    this._autoCompleteOptions.forEach(o => {
      if (o.translate) {
        o.translateValue = this.translate.instant(o.display);
      }
    });
    let options = [];
    if (!value) {
      options = [].concat(this._autoCompleteOptions);
    } else if (typeof value === 'string') {
      value = value.toLowerCase();
      options = this._autoCompleteOptions.filter(
        o => (o.translateValue || o.display || o.value).toLowerCase().indexOf(value) > -1
      );
    }
    const sort = this.selectedInputFilter ? this.selectedInputFilter.sort : false;
    if (sort) {
      options = options.sort((a, b) => {
        a = (a.translateValue || a.display || a.value).toLowerCase();
        b = (b.translateValue || b.display || b.value).toLowerCase();
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    }
    return options;
  }

  onStatusChipClick(chip: SelectableMatChip) {
    const index = this.chipFilters.findIndex(el => el.value.value.indexOf(chip.value) > -1);
    if (!chip.selected) {
      CHIP_FILTER_STATUS[index].selected = true;
      this.appliedStatus.push(this.chipFilters[index]);
      this._changeDetectorRef.markForCheck();
    } else if (chip.selected) {
      const removeIndex = this.appliedStatus.findIndex(
        el => el.value.value.indexOf(chip.value) > -1
      );
      this.appliedStatus.splice(removeIndex, 1);
      CHIP_FILTER_STATUS[index].selected = false;
    }
    chip.selected = !chip.selected;
  }
}
