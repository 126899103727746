import { Pipe, PipeTransform } from '@angular/core';
import { ChargingProgress } from '@app/core/dedicated-fleet/charging-progress';
import { FleetBadgeStatus } from '@app/core/dedicated-fleet/fleet-badge-status';
import { FleetBadgeWithTransaction } from '@app/core/dedicated-fleet/fleet-badge';

interface Result {
  translationKey: string;
  status: FleetBadgeStatus;
}
const STATUS = ChargingProgress;

@Pipe({
  name: 'chargeStatus'
})
export class ChargeStatusPipe implements PipeTransform {
  defaultStatus: Result = {
    translationKey: 'fleetView.NOT_CONNECTED',
    status: FleetBadgeStatus.NOT_CONNECTED
  };

  constructor() {}

  transform(value: FleetBadgeWithTransaction): Result {
    switch (value.transaction?.lastStatusCharge) {
      case STATUS.START_TRANSACTION:
      case STATUS.CHARGE_UP:
      case STATUS.CHARGE:
        if (value.transaction && !value.transaction.stopDate) {
          return {
            translationKey: 'fleetView.IN_PROGRESS_LOAD',
            status: FleetBadgeStatus.IN_PROGRESS
          };
        } else {
          return this.defaultStatus;
        }
      case STATUS.CHARGE_END:
      case STATUS.CHARGE_FULL:
        if (value.transaction && !value.transaction.stopDate) {
          return {
            translationKey: 'fleetView.COMPLETED_CHARGE',
            status: FleetBadgeStatus.CHARGE_COMPLETED
          };
        } else {
          return this.defaultStatus;
        }
      case STATUS.CHARGE_DOWN:
        if (value.transaction && !value.transaction.stopDate) {
          return {
            translationKey: 'fleetView.END_LOAD',
            status: FleetBadgeStatus.END_OF_LOAD
          };
        } else {
          return this.defaultStatus;
        }
      case STATUS.PAUSE:
      case STATUS.NO_CHARGE:
        if (value.transaction && !value.transaction.stopDate) {
          return {
            translationKey: 'fleetView.CHARGE_SUSPENDED',
            status: FleetBadgeStatus.PAUSE
          };
        } else {
          return this.defaultStatus;
        }
      case STATUS.STOP_TRANSACTION:
      default:
        return this.defaultStatus;
    }
  }
}
