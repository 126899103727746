import { animate, style, transition, trigger } from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

export const swipeInOut = trigger('swipeInOut', [
  transition(':enter', [
    style({ height: '0' }),
    animate(
      `${AnimationDurations.COMPLEX} ${AnimationCurves.DECELERATION_CURVE}`,
      style({ height: '*' })
    )
  ]),

  transition(':leave', [
    style({ height: '!' }),
    animate(
      `${AnimationDurations.COMPLEX} ${AnimationCurves.ACCELERATION_CURVE}`,
      style({ height: '0' })
    )
  ])
]);
