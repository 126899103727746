import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { NetworkDisplayHeaderComponent } from './network-display-header/network-display-header.component';
import { NetworkDisplayContentComponent } from './network-display-content/network-display-content.component';
import { WidgetModule } from '@app/widget';

@NgModule({
  imports: [SharedModule, WidgetModule],
  declarations: [NetworkDisplayHeaderComponent, NetworkDisplayContentComponent],
  exports: [NetworkDisplayContentComponent, NetworkDisplayHeaderComponent]
})
export class NetworkBaseModule {}
