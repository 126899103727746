import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  Input,
  HostBinding,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ElementRef
} from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';

import { timer, forkJoin, of, merge } from 'rxjs';
import { takeUntil, tap, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ChargingLocation, Cpo } from '@app/core';
import { InfrastructureService } from '@app/infrastructure/infrastructure.service';
import { WidgetService } from '../../widget.service';
import { chevron, footerAnim, listAnim, showHideEvse } from './location-list-animations';
import { BaseChartComponent } from '../../base-chart/base-chart.component';
import { fadeInOut } from '@app/shared';
import { MatDialog } from '@angular/material/dialog';
import { LocationClusterOptons } from '../location-map/location-cluster';

const FETCH_LIMIT = 10;
const MIN_LOADING_TIME = 300;
const DEFAULT_TITLE = 'infra.Charging location list';
const MIN_EXPANDED_ACTIONS_WIDTH = 500;

const AVAILABLE_COLOR = '#64dd17';
const ALERT_COLOR = '#f44336';
const DISCONNECTED_COLOR = '#A9A9A9';

export interface LocationDisplay {
  location: ChargingLocation;
  hideEvse?: boolean;
  background: BackgroundIcon;
}
interface BackgroundIcon {
  backgroundImage: string;
  backgroundColor: string;
}

@Component({
  selector: 'app-widget-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [chevron, listAnim, showHideEvse, fadeInOut, footerAnim]
})
export class WidgetLocationListComponent extends BaseChartComponent
  implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class.app-widget-location-list') listClass = true;
  @Input() locationData: ChargingLocation[];
  @Input() widget = true;
  @Input() fetchData = true;
  @Input() standalone: boolean;
  @Input() hideActions = false;
  @Input() cpo: Cpo;
  @Input() optionShowHideChanged: boolean;

  locationDisplays: LocationDisplay[] = [];
  querying: boolean;
  totalCount: number;

  actionsExpanded: boolean;

  constructor(
    private ngZone: NgZone,
    private scrollDispatcher: ScrollDispatcher,
    private translate: TranslateService,
    private infraService: InfrastructureService,
    private widgetService: WidgetService,
    elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef,
    dialog: MatDialog
  ) {
    super(elementRef, changeDetectorRef, dialog);

    of(DEFAULT_TITLE)
      .pipe(
        takeUntil(this._destroyed),
        switchMap(key =>
          merge(
            this.translate.stream(key),
            this.translate.onTranslationChange.pipe(switchMap(() => this.translate.get(key)))
          )
        )
      )
      .subscribe(val => {
        this._defaultTitle = val;
        if (!this.title) {
          this.title = undefined;
        }
      });
  }

  ngOnInit() {
    this.overflow = this.widget ? 'hidden' : 'visible';
    this.scrollDispatcher
      .scrolled()
      .pipe(takeUntil(this._destroyed))
      .subscribe(scrollable => {
        if (scrollable) {
          const nativeEl = scrollable.getElementRef().nativeElement;
          if (nativeEl.scrollTop + nativeEl.offsetHeight > nativeEl.scrollHeight - 48) {
            this.onScrolled();
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.locationData && this.locationData) {
      this.totalCount = this.locationData.length;
      this.locationDisplays = [];
      this.fetchMoreLocations();
    } else {
      if (changes.option) {
        if (!changes.option.isFirstChange()) {
          this.optionChanged.emit(this.option);
        }
      }
      this.totalCount = undefined;
      this.locationDisplays = [];
      this.fetchMoreLocations();
    }
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  updateChartDimension() {
    const width = this.standalone
      ? this.elementRef.nativeElement.parentNode.offsetWidth
      : this.elementRef.nativeElement.offsetWidth;
    this.actionsExpanded = width >= MIN_EXPANDED_ACTIONS_WIDTH;
    this.changeDetectorRef.markForCheck();
  }

  trackByFn(index: number, location: ChargingLocation) {
    return location._key;
  }

  toggleAlertAtTop() {
    if (!this.option) {
      this.option = { alertAtTop: true };
    } else {
      this.option.alertAtTop = !this.option.alertAtTop;
    }
    // this.sortEvses();

    this.totalCount = undefined;
    this.locationDisplays = [];
    this.fetchMoreLocations();
    this.optionChanged.emit(this.option);
    this.changeDetectorRef.markForCheck();
  }

  toggleHideEvse() {
    if (!this.option) {
      this.option = { hideEvse: true };
    } else {
      this.option.hideEvse = !this.option.hideEvse;
    }

    this.locationDisplays.forEach(d => (d.hideEvse = this.option && this.option.hideEvse));
    this.optionChanged.emit(this.option);
    this.changeDetectorRef.markForCheck();
  }

  onScrolled() {
    if (this.querying || this.locationDisplays.length >= this.totalCount) {
      return;
    }
    this.ngZone.run(() => this.fetchMoreLocations());
  }

  fetchMoreLocations() {
    if (this.locationData) {
      const slice = this.locationData.slice(
        this.locationDisplays.length,
        this.locationDisplays.length + FETCH_LIMIT
      );
      this.locationDisplays = this.locationDisplays.concat(
        slice.map(l => ({
          location: l,
          hideEvse: this.option && this.option.hideEvse,
          background: this.iconCreateFunction(l)
        }))
      );
      this.changeDetectorRef.markForCheck();
    } else {
      this.querying = true;
      this.changeDetectorRef.markForCheck();
      this.ngZone.runOutsideAngular(() => {
        const params: any = {
          fetch: true,
          map: true,
          alertAtTop: this.option && this.option.alertAtTop,
          limit: FETCH_LIMIT,
          skip: this.locationDisplays.length
        };

        const cpoGroupFilter = this.parseFilters('cpoGroup');
        const cpoFilter = this.parseFilters('cpo');
        const locationFilter = this.parseFilters('location');
        if (cpoGroupFilter.length > 0) {
          params.cpoGroup = cpoGroupFilter;
        }
        if (cpoFilter.length > 0) {
          params.cpo = cpoFilter;
        }
        if (locationFilter.length > 0) {
          params.location = locationFilter;
        }
        forkJoin([
          this.widgetService.doRequest(
            this.infraService.getLocations(params).pipe(
              tap(result => {
                this.totalCount = result.totalCount;
                this.locationDisplays = this.locationDisplays.concat(
                  result.items.map(i => ({
                    location: i,
                    hideEvse: this.option && this.option.hideEvse,
                    background: this.iconCreateFunction(i)
                  }))
                );
              })
            )
          ),
          timer(MIN_LOADING_TIME)
        ])
          .pipe(takeUntil(this._destroyed))
          .subscribe(() =>
            this.ngZone.run(() => {
              this.querying = false;
              this.changeDetectorRef.markForCheck();
            })
          );
      });
    }
  }

  private iconCreateFunction(location: ChargingLocation): BackgroundIcon {
    return this.getBackgoundColor(location, {
      defaultBackgroundColor: '',
      cluster: false,
      markerColor: this.option && this.option.markerColor,
      showAlert: this.option && this.option.showAlert,
      showDisconnected: true
    });
  }

  private getBackgoundColor(
    location: ChargingLocation,
    clusterOptions: LocationClusterOptons
  ): BackgroundIcon {
    let totalCount = 0;
    let availableCount = 0;
    let warnCount = 0;
    let disconnectedCount = 0;
    if (location.chargingStations) {
      location.chargingStations.forEach(cs => {
        totalCount += cs.evses.length;
        cs.evses.forEach(e => {
          if (e) {
            if (e.status === 'Available' || e.status === 'Occupied') {
              availableCount++;
            }
            if (e.status === 'Faulted' || e.status === 'Unavailable') {
              warnCount++;
            }
            if (e.comStatus === 'DOWN') {
              disconnectedCount++;
            }
          }
        });
      });
    }

    let backgroundColor = clusterOptions.defaultBackgroundColor;
    let backgroundImage = clusterOptions.defaultBackgroundColor;

    const alertOn = totalCount > 0 && warnCount > 0 && clusterOptions.showAlert;
    const availableOn = availableCount > 0 && clusterOptions.markerColor;
    const disconnectedOn =
      totalCount > 0 && disconnectedCount > 0 && clusterOptions.showDisconnected;
    if (alertOn && availableOn) {
      backgroundImage = `linear-gradient(135deg, ${AVAILABLE_COLOR} 50%, ${ALERT_COLOR} 50%)`;
    }
    if (disconnectedOn && availableOn) {
      backgroundImage = `linear-gradient(135deg, ${AVAILABLE_COLOR} 50%, ${DISCONNECTED_COLOR} 50%)`;
    }
    if (disconnectedOn && alertOn) {
      backgroundImage = `linear-gradient(135deg, ${ALERT_COLOR} 50%, ${DISCONNECTED_COLOR} 50%)`;
    } else if (alertOn) {
      backgroundColor = ALERT_COLOR;
    } else if (availableOn) {
      backgroundColor = AVAILABLE_COLOR;
    } else if (disconnectedOn) {
      backgroundColor = DISCONNECTED_COLOR;
    } else {
      backgroundColor = clusterOptions.defaultBackgroundColor;
    }
    return { backgroundImage, backgroundColor };
  }
}
