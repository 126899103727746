import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-validation-btn',
  templateUrl: './validation-btn.component.html',
  styleUrls: ['./validation-btn.component.scss']
})
export class ValidationBtnComponent {
  @Input() isLoading = false;
  @Input() isDisabled = false;
  @Input() btnSaveDisplayedValue: 'common.Save' | 'common.SAVE_2' | 'common.Confirm' =
    'common.Confirm';

  @Output() cancelClick = new EventEmitter<any>();
  @Output() validateClick = new EventEmitter<any>();
  constructor() {}

  onCancelClick(value: any): any {
    this.cancelClick.emit(value);
  }

  onValidateClick(value: any): any {
    this.validateClick.emit(value);
  }
}
