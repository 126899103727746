import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FormBuilder, Validators } from '@angular/forms';
import { EvseCurrentConfig } from '@app/core/infrastructure/evse-current-config';
import { EvseRefConfig } from '@app/core/infrastructure/evse-ref-config';
import { Task, TaskOperation } from '@app/core/task';
import { AppNavigationService } from '@app/navigation';
import { fadeInOut, FlyingTaskBadgeDirective } from '@app/shared';
import { TaskService } from '@app/task';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { actions } from '../base/evse-display-header/evse-actions-animations';
import { InfrastructureService } from '../infrastructure.service';

@Component({
  selector: 'app-infra-evse-config',
  templateUrl: 'evse-config.component.html',
  styleUrls: ['evse-config.component.scss'],
  animations: [actions, fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfraEvseConfigComponent implements OnDestroy, OnInit {
  error: any;
  confNotExists: any;
  evseCurrentConfig: EvseCurrentConfig;
  evseRefConfig: EvseRefConfig;
  displayConfForm: boolean;
  confForm: any;
  loadConf: boolean;
  confValidation: boolean;
  valid: boolean;
  private keepBackUrlWhenDestroy: boolean;
  classCompareConf = 'diff';
  private GET_CONFIGURATION: TaskOperation = 'GET_CONFIGURATION';

  // displayedColumns: string[] = ['key', 'current', 'action'];
  displayedColumns: string[] = ['key', 'current'];

  private _destroyed = new Subject<void>();
  evseId: string;

  constructor(
    public translate: TranslateService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private infraService: InfrastructureService,
    private navService: AppNavigationService,
    public router: Router,
    private route: ActivatedRoute,
    public taskService: TaskService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.evseId = params.id;
      this.fetchEvseConfig(this.evseId);
      this.navService.setBackUrlFallback(`/infrastructure/evse/${this.evseId}`);
    });

    this.confForm = this.formBuilder.group({
      key: ['', Validators.required],
      value: ['', Validators.required]
    });

    this.confForm.statusChanges.subscribe(
      validation => (this.confValidation = validation === 'VALID')
    );
  }

  onLinkClick(e: MouseEvent) {
    if ((e.button !== 0 && e.button !== 1) || e.metaKey || e.ctrlKey) {
      return;
    }
    this.keepBackUrlWhenDestroy = true;
    this.navService.setBackUrlFallback(`/infrastructure/evse/${this.evseId}/config`);
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
    if (!this.keepBackUrlWhenDestroy) {
      this.navService.setBackUrlFallback(undefined);
    }
  }

  private fetchEvseConfig(id: string) {
    this.navService.setAppBarProgress(true);
    this.error = undefined;
    this.confNotExists = undefined;
    this.changeDetectorRef.markForCheck();
    this.ngZone.runOutsideAngular(() =>
      forkJoin([this.infraService.getEvseCurrentConfig(id), this.infraService.getEvseRefConfig(id)])
        .pipe(takeUntil(this._destroyed))
        .subscribe(
          result => this.ngZone.run(() => this.onEvseConfigLoaded(result[0], result[1])),
          err =>
            this.ngZone.run(() => {
              this.navService.setAppBarProgress(false);
              this.error = true;
              this.changeDetectorRef.markForCheck();
            })
        )
    );
  }

  getConf(taskBadge: FlyingTaskBadgeDirective) {
    const task: Task = {
      targets: ['evse/' + this.evseId],
      operation: this.GET_CONFIGURATION,
      params: {}
    };
    this.navService.setAppBarProgress(true);
    this.taskService
      .createTask$(task)
      .pipe(takeUntil(this._destroyed))
      .subscribe(
        result => {
          taskBadge.playAnimation(result.pendingTasks);
          setTimeout(() => {
            this.fetchEvseConfig(this.evseId);
            this.navService.setAppBarProgress(false);
          }, 10000);
        },
        () => {
          this.navService.setAppBarProgress(false);
        }
      );
  }

  saveCurrentConfAsRefConf() {
    this.navService.setAppBarProgress(true);
    this.infraService
      .saveCurrentConfigAsReferenceConfig(this.evseId)
      .pipe(takeUntil(this._destroyed))
      .subscribe(
        result => {
          if (result === true) {
            this.fetchEvseConfig(this.evseId);
            this.navService.setAppBarProgress(false);
          } else {
            this.navService.setAppBarProgress(false);
          }
        },
        () => {
          this.navService.setAppBarProgress(false);
        }
      );
  }

  private onEvseConfigLoaded(evseCurrentConfig: EvseCurrentConfig, evseRefConfig: EvseRefConfig) {
    this.navService.setAppBarProgress(false);
    this.evseCurrentConfig = evseCurrentConfig;
    this.evseRefConfig = evseRefConfig;
    if (this.evseRefConfig) {
      const index: number = this.displayedColumns.indexOf('reference');
      if (index === -1) {
        // this.displayedColumns.splice(2);
        this.displayedColumns.push('reference');
        // this.displayedColumns.push('action');
      }
    }
    if (!this.evseCurrentConfig) {
      this.confNotExists = true;
    }
    this.changeDetectorRef.markForCheck();
  }

  keys(): Array<string> {
    return Object.keys(this.evseCurrentConfig.config);
  }

  getCurrentValue(key: string): string {
    return this.evseCurrentConfig?.config[key]?.value;
  }

  getRefValue(key: string): string {
    return this.evseRefConfig?.config[key]?.value;
  }

  compare(key: string): string {
    if (this.evseCurrentConfig && this.evseRefConfig) {
      if (this.evseCurrentConfig.config[key]?.value !== this.evseRefConfig.config[key]?.value) {
        return this.classCompareConf;
      }
    }
  }

  resetConfForm() {
    this.confForm.reset();
    this.displayConfForm = false;
    this.confForm.get('key').enable();
  }

  addConf() {}

  updateConf(key) {
    this.displayConfForm = true;
    this.confForm.get('key').setValue(key);
    this.confForm.get('key').disable();
    this.confForm.get('value').setValue(this.evseCurrentConfig.config[key]?.value);
  }

  cancel() {}

  saveConf() {}
}
