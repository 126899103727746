import { Injectable } from '@angular/core';
import { SnackBarService } from '@app/commissioning/service/snack-bar.service';
import * as moment from 'moment';
import { ConfigService } from 'src/environments/config.service';
import { AppNavigationService } from './navigation.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  constructor(
    private configService: ConfigService,
    private navService: AppNavigationService,
    private snackBarService: SnackBarService
  ) {}

  display(sideNavMargin = true): void {
    const feature = this.configService.getFeature('banner');
    const now = moment();
    const isSidenavOpen$ = this.navService.sidenavLockedOpened;

    if (!feature.enabled || feature.startDate?.isAfter(now) || feature.endDate?.isBefore(now)) {
      return;
    }

    isSidenavOpen$.pipe(first()).subscribe(isSidenavOpen => this.openBanner(isSidenavOpen));
  }

  private openBanner(applySidenavMargin: boolean): void {
    this.snackBarService.displayWarning('sidenav.BANNER', {
      duration: 0,
      verticalPosition: 'top',
      panelClass: ['banner'].concat(applySidenavMargin ? 'banner-sidenav' : [])
    });
  }
}
