import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/auth/auth.service';
import { AppNavigationService, FabAction } from '@app/navigation';
import { SearchFilter } from '@app/shared';
import { UserService } from '@app/user';
import { UserGroupFilter } from '@app/user/filter/user-group-filter';
import { UserGroupListComponent } from '@app/user/user-group-list/user-group-list.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserFilter } from '../filter/user-filter';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../environments/config.service';

const USER_SEARCH_BOX_PLACE_HOLDER = 'user.Search by name, e-mail...';
const USER_GROUP_SEARCH_BOX_PLACE_HOLDER = 'user.SEARCH_BY_ID';

export enum UserPageSelectionOption {
  USER_MANAGEMENT = 'userManagement',
  USER_GROUP = 'userGroup'
}

@Component({
  selector: 'app-user-main-list',
  templateUrl: 'main-list.component.html',
  styleUrls: ['./main-list.component.scss']
})
export class UserMainListComponent implements OnInit, OnDestroy {
  isAdmin = false;

  userPageSelection: string;
  readonly UserPageSelectionOption = UserPageSelectionOption;

  @ViewChild(UserGroupListComponent) private userGroupListComponent: UserGroupListComponent;

  private userGroupFabAction: FabAction = {
    name: 'New userGroup',
    fontIcon: 'mdi-account-multiple-plus-outline',
    onAction: () => this.userGroupListComponent.onAdd()
  };

  private readonly userFilter: UserFilter;
  private readonly userGroupFilter: UserGroupFilter;

  private _destroyed = new Subject();

  constructor(
    private router: Router,
    private navService: AppNavigationService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private auth: AuthenticationService,
    protected translate: TranslateService,
    protected configService: ConfigService
  ) {
    this.userFilter = new UserFilter(navService, userService, translate, configService);
    this.userGroupFilter = new UserGroupFilter(navService, userService, translate, configService);
    this.isAdmin = this.isAdminUser();
  }

  private isAdminUser(): boolean {
    const scopes: string[] = this.auth.getGrantedScopes()[0].split(' ');
    return scopes.find(scope => scope === 'admin')?.length > 0;
  }

  ngOnInit(): void {
    this.navService.setAppBarSearchEnabled(true);
    this.navService.userPageSelection.pipe(takeUntil(this._destroyed)).subscribe(value => {
      this.userPageSelection = value;

      this.userFilter.setClearFilter(true);
      this.userGroupFilter.setClearFilter(true);

      if (this.userPageSelection === UserPageSelectionOption.USER_MANAGEMENT) {
        this.setNavServiceFields(
          'user.OPTIONS.USER_MANAGEMENT',
          USER_SEARCH_BOX_PLACE_HOLDER,
          this.userFilter
        );
      } else if (this.userPageSelection === UserPageSelectionOption.USER_GROUP) {
        this.setNavServiceFields(
          'user.OPTIONS.USER_GROUP',
          USER_GROUP_SEARCH_BOX_PLACE_HOLDER,
          this.userGroupFilter,
          this.isAdmin ? this.userGroupFabAction : undefined
        );
      }
    });
  }

  private setNavServiceFields(
    title: string,
    searchPlaceHolder: string,
    searchFilter: SearchFilter,
    fabAction?: FabAction
  ) {
    this.navService.setAppBarTitle(title);
    this.navService.setAppBarSearchPlaceHolder(searchPlaceHolder);
    this.navService.setAppBarSearchFilter(searchFilter);
    this.navService.setFabAction(fabAction);
  }

  ngOnDestroy(): void {
    this.navService.setAppBarSearchEnabled(false);
    this.setNavServiceFields(undefined, undefined, undefined, undefined);
    this._destroyed.next();
    this._destroyed.complete();
    this.changeDetectorRef.markForCheck();
  }

  private addUser(): void {
    this.router.navigate(['user', 'new']);
  }
}
