import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { WorkSheet } from 'xlsx';
import {
  ChargingStationMassImportMapper,
  ConnectorMassImportMapper,
  EvseMassImportMapper,
  TokenMassImportMapper
} from '@app/referencial/park/core/download/json-mapper';
import { InfraChargingStation } from '@app/core/park/charging-station/infraChargingStation';
import { InfraEvse } from '@app/core/park/evse/infraEvse';
import { Connectors } from '@app/core/park/evse/connector';
import { EvseService } from '@app/referencial/park/core/http-services/evse.service';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { LocalToken } from '@app/infrastructure';

const CSV_SEPARATOR = ';';
const CSV_TYPE = 'csv';

interface CsvFile {
  content: string;
  name: string;
}

export interface LocalTokenDisplay {
  localToken: LocalToken;
  labelModel: string;
  active: boolean;
  editing: boolean;
  editingActive: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class XlsxService {
  chargingStationMapper = new ChargingStationMassImportMapper();
  evseMapper = new EvseMassImportMapper();
  connectorMapper = new ConnectorMassImportMapper();
  tokenMapper = new TokenMassImportMapper();

  constructor(private evseService: EvseService) {}

  exportChargingStationModel(chargingStation: InfraChargingStation, locationId: string): void {
    let chargingStationFile;
    let evseFile;
    let connectorFile;

    this.evseService.getAllEvsesOfChargingStation(chargingStation.id).subscribe(evses => {
      chargingStationFile = this.createChargingStationFile(chargingStation, locationId);

      evseFile = this.createEvseFile(evses, chargingStation.id);

      const connectors: Connectors[] = evses
        .map(evse => {
          return evse.connectors.map(
            connector => ({ ...connector, evseId: evse.id } as Connectors)
          );
        })
        .reduce((connector1, connector2) => connector1.concat(connector2), []);
      connectorFile = this.createConnectorFile(connectors);

      const chargingStationCsv: CsvFile = {
        content: XLSX.utils.sheet_to_csv(chargingStationFile, { FS: CSV_SEPARATOR }),
        name: `${chargingStation.id}-cs.${CSV_TYPE}`
      };

      const evseCsv: CsvFile = {
        content: XLSX.utils.sheet_to_csv(evseFile, { FS: CSV_SEPARATOR }),
        name: `${chargingStation.id}-evse.${CSV_TYPE}`
      };

      const connectorsCsv: CsvFile = {
        content: XLSX.utils.sheet_to_csv(connectorFile, { FS: CSV_SEPARATOR }),
        name: `${chargingStation.id}-connector.${CSV_TYPE}`
      };

      this.toZip(chargingStation.id, chargingStationCsv, connectorsCsv, evseCsv);
    });
  }

  private toZip(name: string, ...csvFiles: CsvFile[]): void {
    const zip = new JSZip();

    csvFiles
      .filter(file => file.content.trim().length !== 0)
      .forEach(file => {
        zip.file(file.name, file.content);
      });

    zip.generateAsync({ type: 'blob' }).then(content => saveAs(content, `${name}.zip`));
  }

  private createChargingStationFile(
    chargingStation: InfraChargingStation,
    locationId: string
  ): WorkSheet {
    return XLSX.utils.json_to_sheet([
      this.chargingStationMapper.toJson(chargingStation, locationId)
    ]);
  }

  private createEvseFile(evses: InfraEvse[], chargingStationId: string): WorkSheet {
    return XLSX.utils.json_to_sheet(
      evses.map(evse => this.evseMapper.toJson(evse, chargingStationId))
    );
  }

  private createConnectorFile(connectors: Connectors[]): WorkSheet {
    return XLSX.utils.json_to_sheet(
      connectors.map(connector => this.connectorMapper.toJson(connector))
    );
  }

  exportTokens(token2Export: LocalToken[]) {
    let tokenFile;
    tokenFile = this.createTokenFile(token2Export);
    const tokensCsv: CsvFile = {
      content: XLSX.utils.sheet_to_csv(tokenFile, { FS: CSV_SEPARATOR }),
      name: `token.${CSV_TYPE}`
    };

    this.toZip('token', tokensCsv);
  }

  private createTokenFile(tokens: LocalToken[]): WorkSheet {
    return XLSX.utils.json_to_sheet(tokens.map(token => this.tokenMapper.toJson(token)));
  }
}
