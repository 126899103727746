export enum TimeRangeEnum {
  LAST_3_HOURS = 'smartCharging.graph.LAST_3_HOURS',
  LAST_6_HOURS = 'smartCharging.graph.LAST_6_HOURS',
  LAST_12_HOURS = 'smartCharging.graph.LAST_12_HOURS',
  LAST_24_HOURS = 'smartCharging.graph.LAST_24_HOURS',
  LAST_2_DAYS = 'smartCharging.graph.LAST_2_DAYS',
  LAST_7_DAYS = 'smartCharging.graph.LAST_7_DAYS'
}
export interface TimeRange {
  startDate: Date;
  stopDate: Date;
}

export function applyTimeRange(enumRange: TimeRangeEnum): TimeRange {
  const dateNow = new Date(Date.now());
  const dateTmp = new Date(dateNow.getTime());

  switch (enumRange) {
    case TimeRangeEnum.LAST_3_HOURS:
      return {
        startDate: new Date(dateTmp.setHours(dateTmp.getHours() - 3)),
        stopDate: dateNow
      };
    case TimeRangeEnum.LAST_6_HOURS:
      return {
        startDate: new Date(dateTmp.setHours(dateTmp.getHours() - 6)),
        stopDate: dateNow
      };
    case TimeRangeEnum.LAST_12_HOURS:
      return {
        startDate: new Date(dateTmp.setHours(dateTmp.getHours() - 12)),
        stopDate: dateNow
      };
    case TimeRangeEnum.LAST_24_HOURS:
      return {
        startDate: new Date(dateTmp.setDate(dateTmp.getDate() - 1)),
        stopDate: dateNow
      };
    case TimeRangeEnum.LAST_2_DAYS:
      return {
        startDate: new Date(dateTmp.setDate(dateTmp.getDate() - 2)),
        stopDate: dateNow
      };
    case TimeRangeEnum.LAST_7_DAYS:
      return {
        startDate: new Date(dateTmp.setDate(dateTmp.getDate() - 7)),
        stopDate: dateNow
      };
  }
}
