import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-infra-evse-action-confirm-dialog',
  templateUrl: './evse-action-confirm-dialog.component.html',
  styleUrls: ['./evse-action-confirm-dialog.component.scss']
})
export class EvseActionConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string },
    public dialogRef: MatDialogRef<any>
  ) {}
}
