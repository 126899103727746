import { Component, OnDestroy, OnInit } from '@angular/core';

import { AppNavigationService } from '@app/navigation';
import { TxFilter } from '../filter/filter';

const SEARCH_BOX_PLACE_HOLDER = 'transaction.Search by token, location, plug...';

@Component({ selector: 'app-tx-main-list', templateUrl: 'main-list.component.html' })
export class TxMainListComponent implements OnDestroy, OnInit {
  private filter: TxFilter;

  constructor(private navService: AppNavigationService) {
    this.filter = new TxFilter(navService);
  }

  ngOnInit() {
    this.navService.setAppBarSearchEnabled(true);
    this.navService.setAppBarSearchPlaceHolder(SEARCH_BOX_PLACE_HOLDER);
    this.navService.setAppBarSearchFilter(this.filter);
  }

  ngOnDestroy() {
    this.navService.setAppBarSearchEnabled(false);
    this.navService.setAppBarSearchFilter(undefined);
  }
}
