import { Component, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Transaction } from '@app/core';
import { MatDialog } from '@angular/material/dialog';
import { TxService } from '../tx.service';
import { TaskService } from '@app/task';
import { EvseActionConfirmDialogComponent } from '@app/infrastructure/base/evse-action-confirm-dialog/evse-action-confirm-dialog.component';
import { FlyingTaskBadgeDirective } from '@app/shared';
import { CloseTxDialogComponent } from '../../shared/close-tx-dialog/close-tx-dialog.component';
import { AppNavigationService } from '@app/navigation';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tx-actions',
  templateUrl: './tx-actions.component.html',
  styleUrls: ['./tx-actions.component.scss']
})
export class TxActionsComponent {
  @Input() transaction: Transaction;
  @Input() icon: boolean;

  private _destroyed = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private navService: AppNavigationService,
    private txService: TxService,
    private taskService: TaskService,
    private router: Router
  ) {}

  ngOnDestroyed() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  stopTransaction(taskBadge: FlyingTaskBadgeDirective) {
    this.dialog
      .open(EvseActionConfirmDialogComponent, {
        data: { title: 'infra.evse.action.Stop current transaction?' },
        hasBackdrop: true
      })
      .afterClosed()
      .pipe(takeUntil(this._destroyed))
      .subscribe(confirm => {
        if (confirm) {
          this.taskService
            .createTask$({
              targets: [this.buildEvseId()],
              params: { transactionId: this.transaction.id },
              operation: 'REQUEST_STOP_TRANSACTION'
            })
            .pipe(takeUntil(this._destroyed))
            .subscribe(result => {
              taskBadge.playAnimation(result.pendingTasks);
            });
        }
      });
  }

  closeTransaction() {
    this.dialog
      .open(CloseTxDialogComponent, {
        data: { transaction: this.transaction },
        hasBackdrop: true
      })
      .afterClosed()
      .pipe(takeUntil(this._destroyed))
      .subscribe(params => {
        if (params) {
          this.navService.setAppBarProgress(true);
          this.txService
            .closeTransaction({
              transactionId: this.transaction.id,
              timestamp: params.stopDate,
              evseId: this.transaction.evseId,
              manualClosed: true
            })
            .pipe(takeUntil(this._destroyed))
            .subscribe(
              res => {
                if (res && res.status === 'Accepted') {
                  this.router.navigate(['transaction'], {
                    queryParams: { uuid: this.transaction.uuid }
                  });
                }
                this.navService.setAppBarProgress(false);
              },
              () => {
                this.navService.setAppBarProgress(false);
              }
            );
        }
      });
  }

  private buildEvseId(): string {
    return 'evse/' + this.transaction.evseId;
  }
}
