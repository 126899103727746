import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base-widget',
  template: ''
})
export class BaseWidgetComponent implements OnChanges, OnDestroy {
  static DATA_CACHE = new Map<string, any>();

  @HostBinding('class.app-widget') widgetClass = true;
  @HostBinding('style.overflow') overflow = 'hidden';

  @Input() option: any;
  @Input() cacheKey: string;
  @Input() renameEnabled: boolean;
  @Input() additionalMenuPortal: TemplatePortal;

  @Output() titleChanged = new EventEmitter<string>();
  @Output() optionChanged = new EventEmitter<any>();

  _title: string;
  _titleModel: string;

  protected _destroyed = new Subject();
  protected _defaultTitle: string;

  get title() {
    return this._title;
  }

  @Input()
  set title(title: string) {
    this._title = title;
    this._titleModel = title || this._defaultTitle;
    this.changeDetectorRef.markForCheck();
  }

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(_changes: SimpleChanges) {
    if (_changes.option && !_changes.option.isFirstChange()) {
      this.optionChanged.next(_changes.option.currentValue);
    }
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  onTitleEdit(e: Event) {
    this.titleChanged.next((e.target as any).value);
  }
}
