import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InfraEvse } from '@app/core/park/evse/infraEvse';
import { Emsp } from '@app/core/park/evse/emsp';
import { EvseLinkableToQrCode } from '@app/referencial/shared/qr-code/evse-linkable-to-qr-code';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EvseService {
  private parkUrl = 'api/park';
  private evseOfChargingStation = `${this.parkUrl}/chargingStation/evse`;
  private evseUrl = `${this.parkUrl}/evse`;
  private emspOfEvseUrl = `${this.parkUrl}/evse/emsp`;
  private emi3Url = `${this.evseUrl}/emi3`;
  private closeByLocation = `${this.evseUrl}/closeByLocation`;
  private evseLinkableToQrCodeUrl = `${this.evseUrl}/linkableToQrCode`;

  constructor(private httpClient: HttpClient) {}

  getAllEvsesOfChargingStation(chargingStationId: string): Observable<InfraEvse[]> {
    return this.httpClient
      .get<InfraEvse[]>(this.evseOfChargingStation, {
        params: { chargingStationId }
      })
      .pipe(map(arrayEvse => InfraEvse.toDisplayedEvseArray(arrayEvse)));
  }

  saveEvse(chargingStationId: string, evse: InfraEvse): Observable<InfraEvse> {
    const evseRequest = InfraEvse.toEvseRequest(evse);
    return this.httpClient
      .post<InfraEvse>(this.evseOfChargingStation, { chargingStationId, evse: evseRequest })
      .pipe(map(evseResponse => InfraEvse.toDisplayedEvse(evseResponse)));
  }

  updateEvse(evse: InfraEvse): Observable<InfraEvse> {
    const evseRequest = InfraEvse.toEvseRequest(evse);
    return this.httpClient
      .put<InfraEvse>(this.evseUrl, { evse: evseRequest })
      .pipe(map(evseResponse => InfraEvse.toDisplayedEvse(evseResponse)));
  }

  getEvseDetails(evseId: string): Observable<InfraEvse> {
    return this.httpClient
      .get<InfraEvse>(this.evseUrl, { params: { evseId } })
      .pipe(map(evseResponse => InfraEvse.toDisplayedEvse(evseResponse)));
  }

  getEvsesDetails(evseIds: string[]): Observable<InfraEvse[]> {
    return this.httpClient
      .get<InfraEvse[]>(this.evseUrl + 's', { params: { evseIds } })
      .pipe(map(arrayEvse => InfraEvse.toDisplayedEvseArray(arrayEvse)));
  }

  saveEmspForEvse(evseId: string, form: object): Observable<Emsp[]> {
    return this.httpClient.put<Emsp[]>(this.emspOfEvseUrl, { evseId, form });
  }

  updateEmsp(form: object): Observable<Emsp> {
    return this.httpClient.put<Emsp>(this.emspOfEvseUrl, { form });
  }

  doesEmi3AlreadyExists(emi3: string): Observable<boolean> {
    return this.httpClient.get<boolean>(this.emi3Url, { params: { emi3 } });
  }

  closeAllEvseByLocation(locationId: string): Observable<void> {
    return this.httpClient.put<void>(this.closeByLocation, { locationId });
  }

  fetchEvseLinkableToQrCode(chargingStationId: string): Observable<EvseLinkableToQrCode> {
    const params = new HttpParams().set('chargingStationId', chargingStationId);
    return this.httpClient.get<EvseLinkableToQrCode>(this.evseLinkableToQrCodeUrl, { params });
  }
}
