import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { timer } from 'rxjs';

@Component({
  selector: 'app-code-scanner',
  templateUrl: './code-scanner.component.html',
  styleUrls: ['./code-scanner.component.scss']
})
export class CodeScannerComponent {
  @Input() enabledFormats = [BarcodeFormat.EAN_13, BarcodeFormat.QR_CODE];
  @Input() shouldDisplayScannedValue = true;

  @Output() scannedValue = new EventEmitter<string>();
  @Output() loaded = new EventEmitter<void>();

  availableDevices: MediaDeviceInfo[];
  hasDevices: boolean;
  hasPermission: boolean;
  value: string;
  loading = true;

  clearResult(): void {
    this.value = undefined;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = !!devices?.length;
    this.endLoading();
  }

  valueFound(scannedValue: string): void {
    this.value = scannedValue;
    this.scannedValue.emit(scannedValue);
  }

  onHasPermission(hasPermission: boolean): void {
    this.hasPermission = hasPermission;
    this.endLoading();
  }

  private endLoading(): void {
    // wait a bit for the video to be completely displayed after the loading is done
    timer(700).subscribe(() => {
      this.loading = false;
      this.loaded.emit();
    });
  }
}
