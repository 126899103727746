import {
  Component,
  OnInit,
  ElementRef,
  NgZone,
  ChangeDetectorRef,
  OnDestroy,
  ChangeDetectionStrategy
} from '@angular/core';
import { ChargingCpo } from '@app/core';
import { AlertDisplay, AlertService } from '@app/alert';
import { TxDisplay, TxService } from '@app/transaction';
import { Subject, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppNavigationService } from '@app/navigation';
import { InfrastructureService } from '../infrastructure.service';
import { HttpParams } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { fadeInOut } from '@app/shared';
import { chevron } from '@app/alert/base/alert-display-content/alert-display-content-animation';
import { showHideAddress } from '../location-info/location-info-animations';
import { infraPanel } from '../main-view/main-view-animations';
import { BaseChartComponent } from '@app/widget';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RfidCardCredentielDialogComponent } from '../common/rfid-card-credentiel-dialog/rfid-card-credentiel-dialog.component';
import { SmartChargingGuard } from '@app/smart-charging/smart-charging-guard';
import { AlertSummary } from '@app/alert';
import { UserService } from '@app/user';

@Component({
  selector: 'app-cpo-info',
  templateUrl: './cpo-info.component.html',
  styleUrls: ['./cpo-info.component.scss'],
  animations: [fadeInOut, chevron, showHideAddress, infraPanel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfraCpoInfoComponent extends BaseChartComponent implements OnInit, OnDestroy {
  error: any;
  cpo: ChargingCpo;

  alertDisplays: AlertDisplay[];
  alertsSummary: AlertSummary;
  txDisplays: TxDisplay[];

  disabledEdit = true;
  optionShowHideChanged: boolean;
  layoutOption: any = {};

  accessToAlert: boolean;
  isParkAdmin: boolean;
  isParkManager: boolean;

  private cpoId: string;
  private manageRfidCardDialogRef: MatDialogRef<RfidCardCredentielDialogComponent>;

  _destroyed = new Subject();

  constructor(
    public translate: TranslateService,
    private ngZone: NgZone,
    elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private navService: AppNavigationService,
    private infraService: InfrastructureService,
    private alertService: AlertService,
    private txService: TxService,
    private userService: UserService,
    dialog: MatDialog,
    private router: Router,
    private smartChargingGuard: SmartChargingGuard
  ) {
    super(elementRef, changeDetectorRef, dialog);
  }

  ngOnInit() {
    this.navService.setBackUrlFallback('/infrastructure');
    this.navService.navItems.pipe(takeUntil(this._destroyed)).subscribe(items => {
      this.accessToAlert = items.findIndex(item => item.path.indexOf('alert') > -1) > -1;
    });

    this.userService
      .getUserByKey(this.navService.userId.value.replace('user/', ''))
      .subscribe(user => {
        this.isParkAdmin = user.profiles?.includes('INFRASTRUCTURE_ADMIN');
        this.isParkManager = user.profiles?.includes('INFRASTRUCTURE_MANAGER');
      });

    this.error = undefined;
    this.route.params.subscribe(params => {
      this.cpoId = params.id;
      this.fetchCpo(params.id);
    });

    this.getAlertsSummary();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  trackAlertFn(_i: number, item: AlertDisplay) {
    return item.alert._id;
  }

  private fetchCpo(id: string) {
    this.ngZone.runOutsideAngular(() =>
      forkJoin([
        this.infraService.getCpo(id, false),
        this.alertService.getAlerts(
          new HttpParams({
            fromObject: {
              cpo: this.cpoId,
              status: 'Opened',
              severityGte: 'Warning',
              limit: '5'
            }
          })
        ),
        this.txService.getTransactions(
          new HttpParams({
            fromObject: {
              cpo: this.cpoId,
              limit: '5'
            }
          })
        )
      ])
        .pipe(takeUntil(this._destroyed))
        .subscribe(
          results =>
            this.ngZone.run(() => {
              this.cpo = results[0];
              this.loadOptions();
              setTimeout(() => {
                this.alertDisplays = results[1].items.map(a =>
                  this.alertService.getAlertDisplay(a)
                );
                this.txDisplays = results[2].items.map(t =>
                  this.txService.getTransactionDisplay(t)
                );
                this.changeDetectorRef.markForCheck();
              });
            }),
          err =>
            this.ngZone.run(() => {
              this.navService.setAppBarProgress(false);
              this.error = err;
              this.changeDetectorRef.markForCheck();
            })
        )
    );
  }

  private getAlertsSummary() {
    this.ngZone.runOutsideAngular(() => {
      this.alertService
        .getAlertsSummary(
          new HttpParams({
            fromObject: {
              cpo: this.cpoId,
              status: 'Opened',
              severityGte: 'Warning'
            }
          })
        )
        .pipe(takeUntil(this._destroyed))
        .subscribe(result =>
          this.ngZone.run(() => {
            if (result) {
              this.alertsSummary = this.alertService.getSummary(result);
              this.changeDetectorRef.markForCheck();
            }
          })
        );
    });
  }

  private loadOptions() {
    this.layoutOption = {
      alertAtTop: false,
      showAlert: true,
      markerColor: true,
      showDisconnected: true,
      hideEvse: true,
      cluster: false,
      filter: [
        {
          name: 'widget.filter.CPO',
          param: 'cpo',
          type: 'option',
          value: { display: this.cpo.name, value: this.cpoId }
        }
      ],
      summary: false
    };
  }

  onListOptionChanged(option: any) {
    this.layoutOption = option;
    this.optionShowHideChanged = !this.optionShowHideChanged;
  }

  openInfraLink(): void {
    this.router.navigateByUrl(`/park/cpo/${this.cpo._key}`);
  }

  hasParkAccess(): boolean {
    return this.isParkAdmin || this.isParkManager;
  }

  openMngRfidCardDialog() {
    if (this.manageRfidCardDialogRef) {
      return;
    }

    this.manageRfidCardDialogRef = this.dialog.open(RfidCardCredentielDialogComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      panelClass: 'content-dialog',
      data: { cpoId: this.cpoId }
    });
    this.manageRfidCardDialogRef
      .afterClosed()
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {
        this.manageRfidCardDialogRef = undefined;
      });
  }

  getCpoGroup() {
    return this.cpo.cpoGroup.name || this.cpo.cpoGroup._key;
  }

  openMngService(): void {
    this.router.navigate(['/smart-charging/services'], { queryParams: { cpoId: this.cpoId } });
  }

  canMngSmartChargingService(): boolean {
    return this.smartChargingGuard.canActivate(null, null);
  }
}
