import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AllocationStrategy } from '@app/core/smart-charging/allocation-strategy';
import { SmartChargingService } from '@app/smart-charging/smart-charging.service';
import { PeriodType } from '@app/core/smart-charging/profile/charging-period/period-type';
import { SmartChargingPeriod } from '@app/core/smart-charging/profile/charging-period/charging-period';

@Component({
  selector: 'app-hub-strategy',
  templateUrl: './hub-strategy.component.html',
  styleUrls: ['hub-strategy.component.scss']
})
export class HubStrategyComponent implements OnChanges {
  constructor(private smartChargingService: SmartChargingService, private ref: ChangeDetectorRef) {}
  @Input() hubId: string;
  @Input() currentAllocationStrategy: AllocationStrategy;
  @Input() periods: SmartChargingPeriod[];

  isEditing = false;
  isLoadingContent = false;
  eco = PeriodType.eco;

  pendingChanges: {
    currentAllocationStrategy: AllocationStrategy;
  } = {
    currentAllocationStrategy: undefined
  };

  protected readonly AllocationStrategy = AllocationStrategy;

  editCurrentStrategy(): void {
    this.isEditing = true;
  }

  cancelCurrentStrategy(): void {
    this.isEditing = false;
    this.pendingChanges.currentAllocationStrategy = this.currentAllocationStrategy;
  }

  onSaveHubStrategy(): void {
    this.currentAllocationStrategy = this.pendingChanges.currentAllocationStrategy;
    this.isLoadingContent = true;
    this.smartChargingService
      .setCurrentAllocationStrategy(this.hubId, this.currentAllocationStrategy)
      .subscribe(
        () => {
          this.isLoadingContent = false;
          this.cancelCurrentStrategy();
          this.ref.markForCheck();
        },
        _error => {
          this.isLoadingContent = false;
          this.cancelCurrentStrategy();
          this.ref.markForCheck();
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentAllocationStrategy) {
      this.pendingChanges.currentAllocationStrategy =
        changes.currentAllocationStrategy.currentValue;
      this.currentAllocationStrategy = changes.currentAllocationStrategy.currentValue;
    }
  }
}
