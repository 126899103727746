import { Icon } from 'leaflet';

import {
  getMarkerSvg,
  EV_STATION_OUTLINE_ICON
} from '@app/widget/infrastructure/location-map/marker';

export class LocationMarker extends Icon {
  constructor(private backgroundColor: string, private iconColor: string) {
    super({
      iconUrl: '',
      iconSize: [96, 96],
      iconAnchor: [48, 72],
      className: 'location-cluster mat-body-1 app-typo-mono'
    });
  }

  createIcon(): HTMLElement {
    const div = document.createElement('div');
    (this as any)._setIconStyles(div, 'icon');
    div.style.backgroundImage = `url('${getMarkerSvg(
      this.backgroundColor,
      EV_STATION_OUTLINE_ICON,
      this.iconColor
    )}')`;
    return div;
  }
}
