import { UserType } from '@app/core/user-data/user-type';

export class IdentityProvider {
  clientId: string;
  name: string;
  userType: UserType;

  constructor(identityProvider: IdentityProvider) {
    this.clientId = identityProvider.clientId;
    this.name = identityProvider.name;
    this.userType = identityProvider.userType;
  }
}
