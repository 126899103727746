import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddElementDialogComponent } from '@app/shared/chip-list/add-element-dialog/add-element-dialog.component';

/**
 * This component is used to display an editable chipList
 *
 * To use : use the <app-chip-list> selector
 *
 * Inputs : - entryList : the list containing the autocomplete options
 *          - selectedList : the list where the selected elements should be stored
 *          - displayAttributeName : the name of the object attribute that should be displayed (usually name or label)
 *
 */
@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html'
})
export class ChipListComponent implements OnInit {
  @Input() entryList: any[];
  @Input() displayAttributeName: string;
  @Input() selectedList: any[];
  @Input() customDialog = false;
  @Input() hideAddButton = false;

  @Output() addElements: EventEmitter<any> = new EventEmitter<any>();
  @Output() openCustomDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  private addElementRef: MatDialogRef<AddElementDialogComponent>;

  constructor(private addElementDialog: MatDialog, private ref: ChangeDetectorRef) {}

  ngOnInit(): void {}

  remove(index: number): void {
    this.selectedList.splice(index, 1);
  }

  openDialog(): void {
    if (this.customDialog) {
      this.openCustomDialog.emit(true);
      return;
    }

    this.addElementRef = this.addElementDialog.open(AddElementDialogComponent, {
      disableClose: true,
      width: '30%',
      data: {
        elements: this.entryList,
        selectedElements: this.selectedList,
        attribute: this.displayAttributeName
      }
    });
    this.addElementRef.afterClosed().subscribe(result => {
      if (result.applyChanges) {
        this.addElements.emit(result.selectedElements);
      }
      this.ref.markForCheck();
    });
  }
}
