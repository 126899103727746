import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export class DialogConfirmationData {
  title: string;
  confirmation: string;
  cancel: string;
  message?: string;

  constructor(title: string, confirmation: string, cancel: string, message?: string) {
    this.title = title;
    this.confirmation = confirmation;
    this.cancel = cancel;
    this.message = message;
  }
}

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: [
    './dialog-confirmation.component.scss',
    '../../referencial/park/core/shared/form.scss'
  ]
})
export class DialogConfirmationComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogConfirmationData) {}
}
