import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EventRecord } from './event-record';
import { EventRecordService } from './event-record.service';

@Component({
  selector: 'app-infra-event-record-component',
  templateUrl: 'event-record.component.html',
  styleUrls: ['./event-record.component.scss']
})
export class EventRecordComponent implements OnInit {
  @Input()
  public id: string;
  @Input()
  public type: string;

  hasData: boolean;
  private maxSize = '3';
  historyDisplay: EventRecord[];
  @ViewChild(MatTable, { static: false }) private historyTable: MatTable<EventRecord>;

  constructor(
    private eventRecordService: EventRecordService,
    private changeDetector: ChangeDetectorRef,
    public router: Router,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.hasData = false;
    if (this.type === 'location') {
      this.eventRecordService.getLocationEventRecords(this.id, this.maxSize).subscribe(response => {
        if (response) {
          this.historyDisplay = response.body;
          this.changeDetector.markForCheck();
          if (this.historyDisplay && this.historyDisplay.length > 0) {
            this.hasData = true;
          }
        }
      });
    } else if (this.type === 'evse') {
      this.eventRecordService.getEvseEventRecords(this.id, this.maxSize).subscribe(response => {
        if (response) {
          this.historyDisplay = response.body;
          this.changeDetector.markForCheck();
          if (this.historyDisplay && this.historyDisplay.length > 0) {
            this.hasData = true;
          }
        }
      });
    }
  }

  toHistoryDetails() {
    this.router.navigateByUrl(
      `/infrastructure/${this.type}/${this.id}/history?range=last7Days&limit=50`
    );
  }
}
