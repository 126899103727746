import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { latLng, Map as LeaftletMap, MapOptions, Marker, marker, tileLayer } from 'leaflet';

import { ChargingLocation, ChargingStation, Evse } from '@app/core';
import { AppNavigationService } from '@app/navigation';
import { fadeInOut } from '@app/shared';
import { InfrastructureService } from '../infrastructure.service';
import { LocationMarker } from './location-marker';
import { AlertDisplay, AlertService, AlertSummary } from '@app/alert';
import { TxDisplay, TxService } from '@app/transaction';
import { HttpParams } from '@angular/common/http';
import { chevron, showHideAddress } from './location-info-animations';
import { NetworkService } from '../../network/network.service';
import { SmartChargingService } from '@app/smart-charging/smart-charging.service';
import { Hub } from '@app/core/smart-charging/hub';
import { RfidCardCredentielDialogComponent } from '@app/infrastructure/common/rfid-card-credentiel-dialog/rfid-card-credentiel-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '@app/auth/auth.service';
import { ConfigService } from '../../../environments/config.service';
import { FeatureCode } from '../../../environments/feature-code';

@Component({
  selector: 'app-location-info',
  templateUrl: './location-info.component.html',
  styleUrls: ['./location-info.component.scss'],
  animations: [fadeInOut, chevron, showHideAddress],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfraLocationInfoComponent implements OnInit, OnDestroy {
  error: any;
  location: ChargingLocation;
  alertDisplays: AlertDisplay[];
  txDisplays: TxDisplay[];

  hideAddress = true;
  hidePointCharge = false;

  accessToAlert: boolean;
  canAccessEvc: boolean;
  locationEditAccess: boolean;
  isSmartChargingInfoDisplay: boolean;
  hubs: Hub[] = [];

  options: MapOptions = {
    layers: [
      tileLayer('https://a.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      })
    ],
    zoomControl: false
  };
  center = latLng(48.8566, 2.3522);
  zoom = 10;
  markers: Marker[] = [];

  summary = {
    available: 0,
    occupied: 0,
    unavailable: 0,
    lost: 0
  };

  private locationId: string;

  @ViewChild('backgroundColorGetter', { static: true }) private backgroundColorGetter: ElementRef;
  private defaultMarkerColor: string;
  private defaultMarkerIconColor: string;

  private manageRfidCardDialogRef: MatDialogRef<RfidCardCredentielDialogComponent>;

  private _destroyed = new Subject();
  alertsSummary: AlertSummary;

  constructor(
    public translate: TranslateService,
    private authService: AuthenticationService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private navService: AppNavigationService,
    private infraService: InfrastructureService,
    private alertService: AlertService,
    private txService: TxService,
    private netService: NetworkService,
    private router: Router,
    private smartChargingService: SmartChargingService,
    public dialog: MatDialog,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    this.navService.setBackUrlFallback('/infrastructure');
    this.navService.navItems.pipe(takeUntil(this._destroyed)).subscribe(items => {
      const navItemsPaths = items.map(item => item.path);
      this.accessToAlert = items.findIndex(item => item.path.indexOf('alert') > -1) > -1;
      this.locationEditAccess = items.findIndex(item => item.path.indexOf('park') > -1) > -1;
      this.canAccessEvc =
        this.configService.isFeatureEnabledNew(FeatureCode.LOCATION_GET_EVC) &&
        (navItemsPaths.includes('admin') || navItemsPaths.includes('network-gateway'));
    });

    this.error = undefined;
    this.route.params.subscribe(params => {
      this.locationId = params.id;
      this.fetchLocation(params.id);
      if (this.canAccessEvc) {
        this.fetchNetworkGateways();
      }
    });

    const styles = getComputedStyle(this.backgroundColorGetter.nativeElement);
    this.defaultMarkerColor = styles.backgroundColor;
    this.defaultMarkerIconColor = styles.color;

    this.displaySmartChargingInfo();

    this.getAlertsSummary();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  trackCsByFn(index: number, item: ChargingStation) {
    return item._key;
  }

  trackEvseByFn(index: number, item: Evse) {
    return item._key;
  }

  trackAlertFn(_i: number, item: AlertDisplay) {
    return item.alert._id;
  }

  openInfraLink(): void {
    this.router.navigateByUrl(`/park/location/${this.location._key}`);
  }

  onMapReady(leafletMap: LeaftletMap) {
    const locLatLng = latLng(
      this.location.coordinates.latitude,
      this.location.coordinates.longitude
    );
    this.markers = [
      marker(locLatLng, {
        icon: new LocationMarker(this.defaultMarkerColor, this.defaultMarkerIconColor)
      })
    ];

    const point = leafletMap.latLngToContainerPoint(locLatLng);
    point.y -= 12;
    leafletMap.setView(leafletMap.containerPointToLatLng(point), this.zoom);
    this.changeDetectorRef.markForCheck();
  }

  openMngRfidCardDialog() {
    if (this.manageRfidCardDialogRef) {
      return;
    }

    this.manageRfidCardDialogRef = this.dialog.open(RfidCardCredentielDialogComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      panelClass: 'content-dialog',
      data: {
        locationId: this.locationId,
        cpoKey: this.location.cpo ? this.location.cpo._key : null
      }
    });
    this.manageRfidCardDialogRef
      .afterClosed()
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {
        this.manageRfidCardDialogRef = undefined;
      });
  }

  private fetchLocation(id: string) {
    this.ngZone.runOutsideAngular(() =>
      forkJoin([
        this.infraService.getLocation(id),
        this.alertService.getAlerts(
          new HttpParams({
            fromObject: {
              location: this.locationId,
              status: 'Opened',
              severityGte: 'Warning',
              limit: '5'
            }
          })
        ),
        this.txService.getTransactions(
          new HttpParams({
            fromObject: {
              location: this.locationId,
              limit: '5'
            }
          })
        )
      ])
        .pipe(takeUntil(this._destroyed))
        .subscribe(
          results =>
            this.ngZone.run(() => {
              this.onLocationLoaded(results[0]);
              setTimeout(() => {
                this.alertDisplays = results[1].items.map(a =>
                  this.alertService.getAlertDisplay(a)
                );
                this.txDisplays = results[2].items.map(t =>
                  this.txService.getTransactionDisplay(t)
                );
                this.changeDetectorRef.markForCheck();
              });
            }),
          err =>
            this.ngZone.run(() => {
              this.navService.setAppBarProgress(false);
              this.error = err;
              this.changeDetectorRef.markForCheck();
            })
        )
    );
  }

  private fetchNetworkGateways() {
    this.ngZone.runOutsideAngular(() => {
      this.netService
        .getNetworks(
          new HttpParams({
            fromObject: {
              location: this.locationId
            }
          })
        )
        .pipe(takeUntil(this._destroyed))
        .subscribe(result =>
          this.ngZone.run(() => {
            this.changeDetectorRef.markForCheck();
          })
        );
    });
  }

  private getAlertsSummary() {
    this.ngZone.runOutsideAngular(() => {
      this.alertService
        .getAlertsSummary(
          new HttpParams({
            fromObject: {
              location: this.locationId,
              severityGte: 'Warning',
              status: 'Opened'
            }
          })
        )
        .pipe(takeUntil(this._destroyed))
        .subscribe(result =>
          this.ngZone.run(() => {
            if (result) {
              this.alertsSummary = this.alertService.getSummary(result);
              this.changeDetectorRef.markForCheck();
            }
          })
        );
    });
  }

  private onLocationLoaded(location: ChargingLocation) {
    this.location = location;
    let nbrNoShowPointCharge = 0;
    this.summary.available = 0;
    this.summary.occupied = 0;
    this.summary.unavailable = 0;
    this.summary.lost = 0;
    for (const cs of location.chargingStations) {
      if (cs.evses.length) {
        for (const e of cs.evses) {
          if (e) {
            if (e.comStatus === 'DOWN') {
              this.summary.lost++;
            } else {
              switch (e.status) {
                case 'Available':
                  this.summary.available++;
                  break;
                case 'Unavailable':
                  this.summary.unavailable++;
                  break;
                default:
                  this.summary.occupied++;
                  if (e.tx) {
                    e.currentTxDisplay = this.txService.getCurrentTransactionDisplay(e.tx);
                  }
              }
            }
          }
        }
      } else {
        nbrNoShowPointCharge++;
      }
    }
    if (nbrNoShowPointCharge === location.chargingStations.length) {
      this.hidePointCharge = true;
    }
    this.changeDetectorRef.markForCheck();
  }

  private displaySmartChargingInfo(): void {
    const scopes = this.authService.getGrantedScopes()[0].split(' ');
    if (scopes) {
      this.isSmartChargingInfoDisplay = scopes.includes('local-elec-management');
    }
    if (!this.isSmartChargingInfoDisplay) {
      return;
    }
    this.smartChargingService.getHubsLight(this.locationId).subscribe(r => {
      r.items.forEach(hub => this.hubs.push(hub));
      this.changeDetectorRef.markForCheck();
    });
  }
}
