import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface LocaleOption {
  locale: string;
  cca3: string;
}

export const AVAILABLE_LOCALES: LocaleOption[] = [
  { locale: 'en-US', cca3: 'usa' },
  { locale: 'en-GB', cca3: 'gbr' },
  { locale: 'fr-FR', cca3: 'fra' },
  { locale: 'es-ES', cca3: 'esp' },
  { locale: 'nl-NL', cca3: 'ner' },
  { locale: 'de-DE', cca3: 'deu' },
  { locale: 'it-IT', cca3: 'ita' }
];

@Component({
  selector: 'app-locale-selector',
  templateUrl: './locale-selector.component.html',
  styleUrls: ['./locale-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocaleSelectorComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject<void>();

  @Input() locales: LocaleOption[];
  @Input() iconSize: string;
  @Input() iconMargin: string;
  @Input() changeLang = true;
  @Input() showUnderline: boolean;

  @Output() selectChange = new EventEmitter<string>();

  currentLocale: LocaleOption;

  constructor(private cd: ChangeDetectorRef, private translateService: TranslateService) {}

  ngOnInit() {
    this.onLocaleChange();
    this.translateService.onLangChange
      .asObservable()
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => this.onLocaleChange());
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onSelectLocale(change: MatSelectChange) {
    if (this.changeLang) {
      this.translateService.use(change.value.locale);
    } else {
      this.currentLocale = change.value;
    }
    this.selectChange.emit(change.value.locale);
  }

  private onLocaleChange() {
    if (this.locales && (this.changeLang || !this.currentLocale)) {
      this.currentLocale = this.locales.find(
        locale =>
          locale.locale === (this.translateService.currentLang || this.translateService.defaultLang)
      );
      this.cd.markForCheck();
    }
  }
}
