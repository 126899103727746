import { FeatureCode } from '../../environments/feature-code';

export type NavItemCategory =
  | 'main'
  | 'emsp'
  | 'dedicated-fleet'
  | 'settings'
  | 'hidden'
  | 'commissioning';

/**
 * Navivagion item.
 */
export interface NavItem {
  path: string;
  queryParams?: { [key: string]: string };
  scopes?: string[];
  category: NavItemCategory;
  icon?: string;
  svgIcon?: string;
  title: string;
  brandingLogo?: string;
  theme: string;
  featureCode?: FeatureCode;
}

export const APP_ITEMS: NavItem[] = [
  {
    path: 'dashboard',
    scopes: ['alert', 'infrastructure', 'emsp-user', 'cpo-tx', 'emsp-tx', 'dataviz', 'emsp-reader'],
    category: 'main',
    icon: 'mdi-view-dashboard-outline',
    title: 'Dashboard',
    theme: 'dashboard-theme'
  },
  {
    path: 'infrastructure',
    scopes: ['infrastructure'],
    category: 'main',
    svgIcon: 'ev-station-outline',
    title: 'Infrastructure',
    theme: 'infrastructure-theme'
  },
  {
    path: 'alert',
    queryParams: { status: 'Opened', severityGte: 'Warning' },
    scopes: ['alert'],
    category: 'main',
    icon: 'mdi-alert-outline',
    title: 'Alerts',
    theme: 'alert-theme'
  },
  {
    path: 'accor-hotels',
    scopes: ['accor-hotels'],
    category: 'main',
    svgIcon: 'accor-hotels',
    title: 'AccorHotels',
    brandingLogo: 'assets/images/accor-hotels-swan-logo.svg',
    theme: 'accor-hotels-theme'
  },
  {
    path: 'transaction',
    scopes: ['cpo-tx'],
    category: 'main',
    svgIcon: 'view-day-outline',
    title: 'Transactions',
    theme: 'transaction-theme'
  },
  {
    path: 'dedicated-fleet/view',
    scopes: ['fleet'],
    category: 'dedicated-fleet',
    svgIcon: 'emoji-transportation',
    title: 'Fleet view',
    theme: 'fleet-view-theme'
  },
  {
    path: 'task',
    scopes: ['task'],
    category: 'main',
    icon: 'mdi-timer-sand',
    title: 'Tasks',
    theme: 'task-theme'
  },
  {
    path: 'reporting',
    scopes: ['reporting'],
    category: 'main',
    icon: 'mdi-clipboard-text-outline',
    title: 'Reporting',
    theme: 'reporting-theme'
  },
  {
    path: 'dataviz',
    scopes: ['dataviz', 'emsp-reader'],
    category: 'main',
    icon: 'mdi-equalizer-outline',
    title: 'Data visualization',
    theme: 'dataviz-theme'
  },
  {
    path: 'emsp/user',
    scopes: ['emsp-user', 'emsp-reader'],
    category: 'emsp',
    svgIcon: 'steering-outline',
    title: 'EV users',
    theme: 'emsp-user-theme'
  },
  {
    path: 'emsp/tx',
    scopes: ['emsp-tx', 'emsp-reader'],
    category: 'emsp',
    svgIcon: 'view-day-outline',
    title: 'eMSP transactions',
    theme: 'emsp-tx-theme'
  },
  {
    path: 'emsp/reporting',
    scopes: ['emsp-reporting', 'emsp-reader'],
    category: 'emsp',
    icon: 'mdi-clipboard-text-outline',
    title: 'eMSP Reporting',
    theme: 'emsp-reporting-theme'
  },
  {
    path: 'dedicated-fleet/admin',
    scopes: ['admin'],
    category: 'dedicated-fleet',
    icon: 'mdi-bus-multiple',
    title: 'Dedicated fleet admin',
    theme: 'dedicated-fleet-theme'
  },
  {
    path: 'dedicated-fleet/management',
    scopes: ['fleet-management'],
    category: 'dedicated-fleet',
    icon: 'mdi-train-car',
    title: 'Fleet management',
    theme: 'dedicated-fleet-theme'
  },
  {
    path: 'dedicated-fleet/activity-management',
    scopes: ['activity-management'],
    category: 'dedicated-fleet',
    icon: 'mdi-badge-account',
    title: 'Activity management',
    theme: 'dedicated-fleet-theme'
  },
  {
    path: 'emsp/order',
    scopes: ['emsp-order', 'emsp-reader'],
    category: 'emsp',
    icon: 'mdi-receipt',
    title: 'eMSP Purchase Order',
    theme: 'purchase-order-theme'
  },
  {
    path: 'emsp/badges',
    scopes: ['emsp-badges', 'emsp-reader'],
    category: 'emsp',
    icon: 'mdi-cards-outline',
    title: 'Card Order Files',
    theme: 'badge-deliveries-theme'
  },
  {
    path: 'admin',
    scopes: ['admin'],
    category: 'settings',
    icon: 'mdi-tune-vertical',
    title: 'Admin',
    theme: 'admin-theme'
  },
  {
    path: 'network-mobility',
    scopes: ['network-mobility'],
    category: 'settings',
    icon: 'mdi-file-document',
    title: 'Emobility network',
    theme: 'admin-theme'
  },
  {
    path: 'token-fleet',
    scopes: ['admin'],
    category: 'settings',
    icon: 'mdi-card-bulleted-outline',
    title: 'Fleet token',
    theme: 'admin-theme'
  },
  {
    path: 'marketplace',
    scopes: ['marketplace'],
    category: 'settings',
    icon: 'mdi-store-outline',
    title: 'Marketplaces',
    theme: 'marketplace-theme'
  },
  {
    path: 'vendor',
    scopes: ['marketplace'],
    category: 'settings',
    icon: 'mdi-store-outline',
    title: 'Vendors',
    theme: 'marketplace-theme'
  },
  {
    path: 'price-management',
    scopes: ['price-management'],
    category: 'settings',
    icon: 'mdi-currency-eur',
    title: 'Marketplace prices',
    theme: 'marketplace-theme'
  },
  {
    path: 'park',
    scopes: ['park-management'],
    category: 'settings',
    svgIcon: 'charging-outline',
    title: 'Park management',
    theme: 'park-theme'
  },
  {
    path: 'roaming',
    scopes: ['roaming-admin'],
    category: 'settings',
    icon: 'mdi-file-document-edit-outline',
    title: 'Roaming',
    theme: 'park-theme'
  },
  {
    path: 'commissioning',
    scopes: ['commissioning'],
    category: 'settings',
    icon: 'mdi-truck',
    title: 'COMMISSIONING',
    theme: 'park-theme'
  },
  {
    path: 'commissioning/stock-zone',
    scopes: ['commissioning'],
    category: 'commissioning',
    icon: '',
    title: 'STOCK_ZONE',
    theme: 'park-theme'
  },
  {
    path: 'commissioning/perimeters',
    scopes: ['commissioning'],
    category: 'commissioning',
    icon: '',
    title: 'PERIMETERS',
    theme: 'park-theme'
  },
  {
    path: 'commissioning/qr-codes',
    scopes: ['commissioning'],
    category: 'commissioning',
    icon: '',
    title: 'QR_CODES',
    theme: 'park-theme',
    featureCode: FeatureCode.QR_CODE_REDIRECTION
  },
  {
    path: 'user',
    scopes: ['user'],
    category: 'settings',
    svgIcon: 'group-outline',
    title: 'Users management',
    theme: 'user-theme'
  },
  {
    path: 'settings',
    category: 'settings',
    icon: 'mdi-cog-outline',
    title: 'Settings',
    theme: 'settings-theme'
  },
  {
    path: 'smart-charging',
    category: 'hidden',
    icon: 'mdi-delete',
    title: 'Smart-Charging',
    theme: 'smart-charging-theme'
  },
  {
    path: 'network-gateway',
    scopes: ['admin', 'network-gateway'],
    category: 'hidden',
    icon: 'mdi-network-outline',
    title: 'network-gateway',
    theme: 'network-gateway-theme'
  }
];
