import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AppNavigationService } from '../navigation.service';

@Injectable({ providedIn: 'root' })
export class ScopeGuard implements CanLoad {
  constructor(private router: Router, private navService: AppNavigationService) {}

  /**
   * Cheks if navigation items contains item having the same route path.
   * If no item found then redirect to empty path (which defaluts to the first
   * item accessible).
   *
   * @param  route activated route snapshot
   * @return Observalbe<boolean>
   */
  canLoad(route: Route): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
    return this.navService.navItems.pipe(
      take(1),
      map(
        navItems =>
          navItems.some(i => i.path === route.path) || this.router.createUrlTree([navItems[0].path])
      )
    );
  }
}
