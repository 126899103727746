import { UserGroup } from '@app/core';

export interface Theme {
  name: string;
  value?: string;
  logo?: string;
}

export const THEMES: Theme[] = [
  { name: 'Alizé', value: 'alize-theme', logo: 'alize.png' },
  { name: 'La Poste', value: 'la-poste-theme', logo: 'la-poste.png' },
  { name: 'AccorHotels', value: 'accor-hotels-theme', logo: 'accor-hotels.svg' },
  { name: 'Total', value: 'total-theme', logo: 'total.png' },
  { name: 'Enedis', value: 'enedis-theme', logo: 'enedis.png' },
  { name: 'SMEG', value: 'smeg-theme', logo: 'evzen.png' },
  { name: 'e-Charge50', value: 'echarge50-theme', logo: 'echarge50.png' }
];

export function getThemeOptions(groups: UserGroup[]): Theme[] {
  const groupThemes = []
    .concat(...groups.map(g => g.themes || []))
    .filter((o, i, a) => i === a.indexOf(o));
  return THEMES.filter(t => groupThemes.indexOf(t.value) > -1);
}
