import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';

import { environment } from '../environments/environment';

import { Router } from '@angular/router';
import { AppTranslateModule, SharedModule } from '@app/shared';
import { of } from 'rxjs';
import { catchError, concatMap, filter, tap } from 'rxjs/operators';
import { ConfigService } from '../environments/config.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationService } from './auth/auth.service';
import { BearerTokenInterceptor } from './auth/cognito-authentication/bearer-token.interceptor';
import { CognitoAuthenticationModule } from './auth/cognito-authentication/cognito-authentication.module';
import { UnauthorizedInterceptor } from './auth/cognito-authentication/unauthorized.interceptor';
import { ApiTokenInterceptor } from './auth/interceptor/api-token.interceptor';
import { FeatureDto } from './commissioning/model/feature-dto';
import { RouteUtils } from './core/utils/route.utils';
import { AppNavigationService } from './navigation';
import { AppNavigationModule } from './navigation/navigation.module';
import { AppSwUpdateService } from './navigation/sw-update.service';
import { UserService } from './user';

// TODO Angular 12: replace .toPromise() with RXJS 7 operator
function refreshToken(
  authService: AuthenticationService,
  navigationService: AppNavigationService,
  router: Router,
  userService: UserService
): () => Promise<object> {
  return () => {
    const isInstaller = RouteUtils.isInstallerRoute();
    const isLoginCallback = location.pathname.includes(`/login/callback`);
    if (isInstaller) {
      return of(undefined).toPromise();
    }
    return authService
      .refreshTokenObservable()
      .pipe(
        concatMap(() => userService.getAuthenticatedUser()),
        filter(user => !!user),
        tap(user => navigationService.updateUser(user)),
        tap(() => navigationService.updateNavItems()),
        catchError(() => {
          if (!isLoginCallback) {
            router.navigate(['/', 'login']);
          }
          return of(undefined);
        })
      )
      .toPromise();
  };
}

// TODO Angular 12: replace .toPromise() with RXJS 7 operator
function checkFeatureFlag(configService: ConfigService): () => Promise<FeatureDto[]> {
  return () => configService.initializeFeatures().toPromise();
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppTranslateModule.forRoot(),
    SharedModule.forRoot(),
    CognitoAuthenticationModule,
    AppRoutingModule,
    AppNavigationModule,
    ServiceWorkerModule.register('sw-master.js', { enabled: environment.production })
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: refreshToken,
      deps: [AuthenticationService, AppNavigationService, Router, UserService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: checkFeatureFlag,
      deps: [ConfigService],
      multi: true
    }
  ]
})
export class AppModule {
  constructor(public update: AppSwUpdateService, iconRegistry: MatIconRegistry) {
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeEs, 'es-ES');
    registerLocaleData(localeDe, 'de-DE');
    registerLocaleData(localeNl, 'nl-NL');
    registerLocaleData(localeIt, 'it-IT');
    iconRegistry.registerFontClassAlias('mdi', 'mdi');
    iconRegistry.setDefaultFontSetClass('mdi');
  }
}
