import { Component, OnDestroy } from '@angular/core';
import { AppNavigationService } from '@app/navigation';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent implements OnDestroy {
  constructor(private navService: AppNavigationService) {
    navService.setAppBarTitle('common.Home');
  }

  ngOnDestroy(): void {
    this.navService.setAppBarTitle(undefined);
  }
}
