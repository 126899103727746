import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { SmartChargingService } from '@app/smart-charging/smart-charging.service';
import { TranslateService } from '@ngx-translate/core';
import { Hub } from '@app/core/smart-charging/hub';
import { SmartChargingProfile } from '@app/core/smart-charging/profile/profile';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PeriodType } from '@app/core/smart-charging/profile/charging-period/period-type';
import { SmartChargingProfileType } from '@app/core/smart-charging/profile/profile-type';
import { WeeklyProfile } from '@app/core/smart-charging/profile/weeklyProfile';
import { HubSettingsAbstractComponent } from '@app/smart-charging/base/hub-settings.component';
import { SmartChargingPeriod } from '@app/core/smart-charging/profile/charging-period/charging-period';
import { mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-hub-settings-power-form',
  templateUrl: './hub-settings-power-form.component.html',
  styleUrls: ['./hub-settings-power-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HubSettingsPowerFormComponent extends HubSettingsAbstractComponent
  implements OnChanges {
  @Input() hub: Hub;
  @Input() profile: SmartChargingProfile;
  @Input() ceiling: number;

  PeriodType = PeriodType;
  hubCeilingForm: FormGroup;
  isLoadingContent = false;
  oldCeilingValue: number;
  oldDynamicCeilingForm: FormGroup;
  editingCeiling = false;
  communicationError = false;
  errorMessage = '';

  constructor(
    private smartChargingService: SmartChargingService,
    private ref: ChangeDetectorRef,
    public translateService: TranslateService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ceiling) {
      this.oldCeilingValue = this.ceiling;
    }
    if (changes.profile) {
      this.initCeilingForm();
      this.hubCeilingForm.valueChanges.subscribe(value => this.updateCeiling(value));
    }
  }

  private initCeilingForm(): void {
    this.hubCeilingForm = this.formBuilder.group({
      staticCeiling: [
        this.ceiling ? this.ceiling : '',
        [Validators.required, Validators.pattern('^[0-9]+$')]
      ],
      dynamicCeiling: this.formBuilder.group({
        periods: this.formBuilder.array([]),
        activeProfile: this.formBuilder.control(this.profile?.active)
      })
    });

    if (this.profile?.periods && this.profile?.periods.length > 0) {
      this.profile.periods.forEach(period => {
        const newGroup = this.createNewGroup(period);
        this.periods.push(newGroup);
      });
    } else {
      this.addEmptyPeriod();
    }
  }

  private createNewGroup(period: SmartChargingPeriod): FormGroup {
    const newGroup = this.formBuilder.group({
      type: new FormControl(period.type, [Validators.required]),
      startTime: new FormControl(period.startTime, [Validators.required]),
      endTime: new FormControl(period.endTime, [Validators.required])
    });
    if (period.type === PeriodType.power) {
      newGroup.addControl(
        'power',
        this.formBuilder.control(period.power, [
          Validators.required,
          Validators.pattern('^[0-9]*$')
        ])
      );
    }
    return newGroup;
  }

  addEmptyPeriod(): void {
    if (this.periods.length === 0) {
      this.activeProfile.enable();
    }
    const period: SmartChargingPeriod = {
      endTime: null,
      type: PeriodType.power,
      startTime:
        this.periods.length > 0 ? this.periods.value[this.periods.length - 1]?.endTime : null,
      power: null
    };
    const newGroup = this.createNewGroup(period);
    this.periods.push(newGroup);
  }

  updateCeiling($event): void {
    this.ceiling = Number($event.staticCeiling);
  }

  get dynamicCeiling(): FormGroup {
    return this.hubCeilingForm.get('dynamicCeiling') as FormGroup;
  }

  get periods(): FormArray {
    return this.dynamicCeiling.get('periods') as FormArray;
  }

  get activeProfile(): FormControl {
    return this.dynamicCeiling.get('activeProfile') as FormControl;
  }

  get staticCeiling(): FormControl {
    return this.hubCeilingForm.get('staticCeiling') as FormControl;
  }

  onEdit(): void {
    this.editingCeiling = true;
    this.oldDynamicCeilingForm = this.dynamicCeiling.value;
  }

  onCancelClick(): void {
    this.resetOldFormValues();
    this.editingCeiling = false;
    this.errorMessage = '';
  }

  resetOldFormValues(): void {
    if (this.editingCeiling) {
      this.initCeilingForm();
      this.hubCeilingForm.get('staticCeiling').setValue(this.oldCeilingValue);
    }
  }

  onSave(): void {
    this.isLoadingContent = true;
    this.smartChargingService
      .setCeiling(this.hub._key, this.ceiling * 1000)
      .pipe(
        mergeMap(() => {
          if (this.canSaveDynamicCeiling()) {
            return this.saveNewProfile();
          }
          return of(null);
        })
      )
      .subscribe(
        () => {
          this.editingCeiling = false;
          this.stopLoading(false);
        },
        error => {
          this.updateErrorMessage(error);
          this.stopLoading(true);
        }
      );
  }

  private stopLoading(isCommError: boolean): void {
    this.isLoadingContent = false;
    this.communicationError = isCommError;
    this.ref.markForCheck();
  }

  private mapProfileToSend(profile: SmartChargingProfile): SmartChargingProfile {
    if (this.isAValidProfile(profile)) {
      const prof = JSON.parse(JSON.stringify(profile)); // this is an ugly copy
      prof.periods.forEach(period => {
        period.startTime = this.formatToUTC(period.startTime);
        period.endTime = this.formatToUTC(period.endTime);
        period.power = period.power * 1000;
      });
      return prof;
    }
    return undefined;
  }

  private saveNewProfile(): Observable<string> {
    if (this.arePeriodsEmpty()) {
      return this.clearProfilePeriods();
    }

    this.profile = {
      type: SmartChargingProfileType.DAILY,
      periods: this.periods.controls
        .map(period => period.value)
        .sort((a, b) => a.startTime - b.startTime),
      active: this.activeProfile.value,
      ceiling: this.staticCeiling.value,
      name: SmartChargingProfileType.DAILY
    };

    return this.smartChargingService.setProfiles(this.hub._key, this.ceiling * 1000, [
      this.mapProfileToSend(this.profile)
    ]);
  }

  private clearProfilePeriods() {
    const emptyPeriodsProfile: SmartChargingProfile = {
      type: SmartChargingProfileType.DAILY,
      periods: [],
      active: this.activeProfile.value,
      ceiling: this.staticCeiling.value,
      name: SmartChargingProfileType.DAILY
    };
    return this.smartChargingService.setProfiles(this.hub._key, this.ceiling * 1000, [
      emptyPeriodsProfile
    ]);
  }

  private isAValidProfile(profile: SmartChargingProfile): boolean {
    if (profile.periods.length <= 0) {
      return false;
    }
    if (!profile.periods.every(period => period.startTime && period.endTime && period.power)) {
      return false;
    }
    if (profile.type === 'WEEKLY' && (profile as WeeklyProfile).applicationDays.length === 0) {
      return false;
    }
    return !profile.periods.some(period => period.startTime > period.endTime);
  }

  displayPeriodsComponent(): boolean {
    if (this.editingCeiling) {
      return true;
    }
    return this.profile && this.profile.periods && this.profile.periods.length > 0;
  }

  canValidate(): boolean {
    if (this.isLoadingContent) {
      return false;
    }
    return this.canSaveStaticCeiling() || this.canSaveDynamicCeiling();
  }

  private canSaveStaticCeiling(): boolean {
    const form = this.hubCeilingForm.get('staticCeiling');
    return form.valid && (form.dirty || form.touched);
  }

  private canSaveDynamicCeiling(): boolean {
    const form = this.hubCeilingForm.get('dynamicCeiling');
    return (form.valid && (form.dirty || form.touched)) || this.arePeriodsEmpty();
  }

  private arePeriodsEmpty(): boolean {
    return this.periods.length === 0;
  }

  private updateErrorMessage(error: any): void {
    switch (error.status) {
      case 400:
        if (error.error === 'Mismatch') {
          this.errorMessage = 'smartCharging.getConf.MISMATCH';
          break;
        }
        this.errorMessage = 'smartCharging.getConf.BAD_REQUEST';
        break;
      case 404:
        this.errorMessage = 'smartCharging.getConf.NOT_FOUND';
        break;
      case 408:
        this.errorMessage = 'smartCharging.getConf.TIMEOUT';
        break;
    }
  }

  onRemovePeriod(index: number) {
    this.periods.removeAt(index);
    if (this.periods.length === 0) {
      this.activeProfile.setValue(true);
      this.activeProfile.disable();

      this.profile = {
        type: SmartChargingProfileType.DAILY,
        periods: null,
        active: this.activeProfile.value,
        ceiling: this.staticCeiling.value,
        name: SmartChargingProfileType.DAILY
      };
    }
  }
}
