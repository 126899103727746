import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as shape from 'd3-shape';
import { CurveFactory } from 'd3-shape';
import { TranslateService } from '@ngx-translate/core';
import { ChartData } from '@app/smart-charging/base/chart/chart.component';
import { Point } from '@app/smart-charging/smart-charging.service';
import { Unit } from '@app/smart-charging/base/chart/filter-chart/available-units';
import { ChartColorScheme } from '@app/shared/interface/shared-type';

@Component({
  selector: 'app-power-line-chart',
  templateUrl: './power-line-chart.component.html'
})
export class PowerLineChartComponent implements OnInit {
  private readonly DEFAULT_VIEW_HEIGHT = 300;
  private readonly DEFAULT_VIEW_WIDTH = 800;
  readonly curve: CurveFactory = shape.curveStepAfter;

  @Input() xMin: Date;
  @Input() xMax: Date;
  @Input() colorScheme: ChartColorScheme;
  @Input() data: ChartData[];
  @Input() customColors: any[];
  @Input() staticCeiling: number;
  @Input() refLines: Point[];
  @Input() isLarge = false;
  @Input() isMiniChart = false;
  @Input() yAxisLabel = '';
  @Input() selectedUnit: Unit;

  view: [number, number] = [this.DEFAULT_VIEW_WIDTH, this.DEFAULT_VIEW_HEIGHT];
  viewHeight: number;
  // options
  animations = true;
  legend = false;
  legendTitle = 'smartCharging.LEGEND_TITLE';
  timeline = false;
  xAxis = true;
  xAxisLabel = 'Date';
  yAxis = true;
  showXAxisLabel = false;
  showYAxisLabel = true;
  dividerSize: number;

  constructor(public translate: TranslateService, protected elementRef: ElementRef) {}

  ngOnInit(): void {
    this.initChartView();
  }

  private initChartView(): void {
    /* three different charts are displayed :
     * 1- main chart
     * 2- mini chart (chart by phase)
     * 3- large chart (full screen / in a dialog)
     *
     * dividerSize and viewHeight are used to make the chart "responsive"
     * the value has to be updated according to the chart type
     * */
    if (this.isLarge && !this.isMiniChart) {
      this.dividerSize = 1.45;
      this.viewHeight = 550;
    } else if (this.isMiniChart && !this.isLarge) {
      this.dividerSize = 5.5;
      this.viewHeight = 200;
    } else {
      this.dividerSize = 1.75;
      this.viewHeight = this.DEFAULT_VIEW_HEIGHT;
    }

    this.view = [window.innerWidth / this.dividerSize, this.viewHeight];
  }

  xTickFormat(date: Date): string {
    return moment(date).format('DD MMM - HH:mm');
  }

  @HostListener('window:resize', ['$event'])
  onResize(uiEvent) {
    this.view = [uiEvent.target.innerWidth / this.dividerSize, this.viewHeight];
  }
}
