import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ChargingProfileKindType,
  ChargingProfilePurposeType,
  ChargingRateUnitType,
  RecurrencyKindType,
  SetChargingProfileRequest
} from '@app/infrastructure/base/set-charging-profile-request.model';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

function integerPattern(): ValidatorFn {
  const INTEGER_PATTERN = /^-?[0-9]+$/;
  return validatorPatternWithMessage(INTEGER_PATTERN, 'infra.evse.error.INTEGER_PATTERN');
}

function validatorPatternWithMessage(pattern: string | RegExp, message: string): ValidatorFn {
  return control => {
    const error = Validators.pattern(pattern)(control);
    return error ? { pattern: { ...error, message } } : null;
  };
}

function decimalPattern(): ValidatorFn {
  return validatorPatternWithMessage(/^-?[0-9]+(\.[0-9])?$/, 'infra.evse.error.DECIMAL_PATTERN');
}

@Component({
  selector: 'app-infra-evse-set-charging-profile-dialog',
  templateUrl: './evse-set-charging-profile-dialog.component.html',
  styleUrls: ['./evse-set-charging-profile-dialog.component.scss']
})
export class EvseSetChargingProfileDialogComponent implements OnDestroy {
  noConnectorError = false;

  readonly chargingProfilePurposeTypeValues: Array<ChargingProfilePurposeType> = Object.values(
    ChargingProfilePurposeType
  );
  readonly chargingProfileKindTypeValues: Array<ChargingProfileKindType> = Object.values(
    ChargingProfileKindType
  );
  readonly recurencyKindTypeValues: Array<RecurrencyKindType> = Object.values(RecurrencyKindType);
  readonly schedulingUnitTypeValues: Array<ChargingRateUnitType> = Object.values(
    ChargingRateUnitType
  );
  readonly connectorIdOptions: Array<ConnectorIdOption> = this.getConnectorIdOptions();

  setChargingProfileRequestForm: FormGroup;

  private _destroyed = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<EvseSetChargingProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: EvseSetChargingProfileDialogData
  ) {
    this.initSetChargingProfileRequestForm();

    this.onChargingProfilePurposeControlChanges();
  }

  private initSetChargingProfileRequestForm(): void {
    this.setChargingProfileRequestForm = new FormGroup({
      connectorId: new FormControl(undefined, [Validators.required, integerPattern()]),
      csChargingProfiles: new FormGroup({
        chargingProfileId: new FormControl(undefined, [Validators.required, integerPattern()]),
        transactionId: new FormControl(undefined),
        stackLevel: new FormControl(undefined, [
          Validators.required,
          Validators.min(0),
          integerPattern()
        ]),
        chargingProfilePurpose: new FormControl(undefined, Validators.required),
        chargingProfileKind: new FormControl(undefined, Validators.required),
        recurrencyKind: new FormControl(),
        validFrom: new FormControl(),
        validTo: new FormControl(),
        chargingSchedule: new FormGroup({
          duration: new FormControl(undefined, integerPattern()),
          startSchedule: new FormControl(),
          chargingRateUnit: new FormControl(undefined, Validators.required),
          chargingSchedulePeriod: new FormArray([], Validators.required),
          minChargingRate: new FormControl(undefined, decimalPattern())
        })
      })
    });
    this.disableTransactionIdControl();
  }

  private getConnectorIdOptions(): Array<ConnectorIdOption> {
    if (!this.data.evse.connectors?.length) {
      this.noConnectorError = true;
      return [];
    }
    return [{ connectorId: 0, type: '' }, ...(this.data.evse.connectors || [])]
      .map(connector => new Connector(connector.connectorId, connector.type))
      .map(
        connector =>
          new ConnectorIdOption(
            connector.connectorId,
            connector.type,
            connector.connectorId === 0
              ? 'infra.evse.action.CONNECTOR_O'
              : 'infra.evse.action.Connector {connectorId}'
          )
      );
  }

  private onChargingProfilePurposeControlChanges(): void {
    this.chargingProfilePurposeControl.valueChanges
      .pipe(
        takeUntil(this._destroyed),
        filter(chargingProfilePurposeValue => !!chargingProfilePurposeValue)
      )
      .subscribe(chargingProfilePurposeValue => {
        if (chargingProfilePurposeValue === ChargingProfilePurposeType.TxProfile) {
          this.transactionIdControl.enable();
          this.transactionIdControl.setValidators([Validators.required, integerPattern()]);
        } else {
          this.disableTransactionIdControl();
        }
      });
  }

  private disableTransactionIdControl(): void {
    this.transactionIdControl.disable();
    this.transactionIdControl.setValidators(null);
  }

  addChargingSchedulePeriodFormGroup(): void {
    const newChargingSchedulePeriod = new FormGroup({
      startPeriod: new FormControl(undefined, [Validators.required, integerPattern()]),
      limit: new FormControl(undefined, [Validators.required, decimalPattern()]),
      numberPhases: new FormControl(undefined, integerPattern())
    });
    if (this.chargingSchedulePeriodArray.length === 0) {
      const startPeriodControl = newChargingSchedulePeriod.get('startPeriod');
      startPeriodControl.setValue(0);
      startPeriodControl.disable();
    }

    this.chargingSchedulePeriodArray.push(newChargingSchedulePeriod);
  }

  removeChargingSchedulePeriodFormGroup(index: number): void {
    this.chargingSchedulePeriodArray.removeAt(index);
  }

  get setChargingProfileRequest(): SetChargingProfileRequest {
    return new SetChargingProfileRequest(
      this.setChargingProfileRequestForm.getRawValue() as SetChargingProfileRequest
    );
  }

  // TODO Angular 14: remove getters
  get connectorIdControl(): FormControl {
    return this.setChargingProfileRequestForm.get('connectorId') as FormControl;
  }

  get csChargingProfilesGroup(): FormGroup {
    return this.setChargingProfileRequestForm.get('csChargingProfiles') as FormGroup;
  }

  get chargingProfileIdControl(): FormControl {
    return this.csChargingProfilesGroup.get('chargingProfileId') as FormControl;
  }

  get transactionIdControl(): FormControl {
    return this.csChargingProfilesGroup.get('transactionId') as FormControl;
  }

  get stackLevelControl(): FormControl {
    return this.csChargingProfilesGroup.get('stackLevel') as FormControl;
  }

  get chargingProfilePurposeControl(): FormControl {
    return this.csChargingProfilesGroup.get('chargingProfilePurpose') as FormControl;
  }

  get chargingProfileKindControl(): FormControl {
    return this.csChargingProfilesGroup.get('chargingProfileKind') as FormControl;
  }

  get recurrencyKindControl(): FormControl {
    return this.csChargingProfilesGroup.get('recurrencyKind') as FormControl;
  }

  get validFromControl(): FormControl {
    return this.csChargingProfilesGroup.get('validFrom') as FormControl;
  }

  get validToControl(): FormControl {
    return this.csChargingProfilesGroup.get('validTo') as FormControl;
  }

  get chargingScheduleGroup(): FormGroup {
    return this.csChargingProfilesGroup.get('chargingSchedule') as FormGroup;
  }

  get durationControl(): FormControl {
    return this.chargingScheduleGroup.get('duration') as FormControl;
  }

  get startScheduleControl(): FormControl {
    return this.chargingScheduleGroup.get('startSchedule') as FormControl;
  }

  get chargingRateUnitControl(): FormControl {
    return this.chargingScheduleGroup.get('chargingRateUnit') as FormControl;
  }

  get minChargingRateControl(): FormControl {
    return this.chargingScheduleGroup.get('minChargingRate') as FormControl;
  }

  get chargingSchedulePeriodArray(): FormArray {
    return this.chargingScheduleGroup.get('chargingSchedulePeriod') as FormArray;
  }

  startPeriodControl(index: number): FormControl {
    return this.chargingSchedulePeriodArray.at(index).get('startPeriod') as FormControl;
  }

  limitControl(index: number): FormControl {
    return this.chargingSchedulePeriodArray.at(index).get('limit') as FormControl;
  }

  numberPhasesControl(index: number): FormControl {
    return this.chargingSchedulePeriodArray.at(index).get('numberPhases') as FormControl;
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}

class Connector {
  connectorId: number;
  type: string;

  constructor(connectorId: number, type: string) {
    this.connectorId = connectorId;
    this.type = type;
  }
}

interface Evse {
  connectors: Array<Connector>;
}

export interface EvseSetChargingProfileDialogData {
  evse: Evse;
}

class ConnectorIdOption {
  connectorId: number;
  type: string;
  translation: string;

  constructor(connectorId: number, type: string, translation: string) {
    this.connectorId = connectorId;
    this.type = type;
    this.translation = translation;
  }
}
