import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { speedDialFabAnimations } from './speed-dial-fab.animations';

@Component({
  selector: 'app-speed-dial-fab',
  templateUrl: './speed-dial-fab.component.html',
  styleUrls: ['./speed-dial-fab.component.scss'],
  animations: speedDialFabAnimations,
  changeDetection: ChangeDetectionStrategy.Default
})
export class SpeedDialFabComponent {
  @Input() options;
  @Input() icon: string;
  @Input() tooltip: string;
  @Output() selected = new EventEmitter<string>();
  buttons = [];
  fabToggleState = 'inactive';

  constructor() {}

  showItems(): void {
    this.fabToggleState = 'active';
    this.buttons = this.options;
  }

  hideItems(): void {
    this.fabToggleState = 'inactive';
    this.buttons = [];
  }

  onToggleFab(): void {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  onClick(id: string): void {
    this.selected.emit(id);
  }
}
