import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { forkJoin, merge, of, Subject, timer } from 'rxjs';
import { bufferTime, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { Alert } from '@app/core';
import { AppNavigationService } from '@app/navigation';
import { FilterOption } from '@app/shared';
import { AlertDisplay, AlertService } from '@app/alert';
import { BaseWidgetComponent } from '../../base-widget/base-widget.component';
import { WidgetAlertFilterComponent } from './filter.component';
import { accordionAnim, alertPanel } from './list-animations';

const MIN_LOADING_TIME = 1000;

const DEFAULT_LIMIT = 10;
const DEFAULT_TITLE = 'alert.Recent alerts';

@Component({
  selector: 'app-alert-recent-list',
  templateUrl: 'recent-list.component.html',
  styleUrls: ['recent-list.component.scss'],
  animations: [accordionAnim, alertPanel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetAlertRecentListComponent extends BaseWidgetComponent
  implements OnChanges, OnDestroy, OnInit {
  displays: AlertDisplay[] = [];
  querying: boolean;
  routeQueryParams: any;

  private stopAlertUpdateSub = new Subject<string>();
  private queryParams: any;
  private notifications = new Subject<string>();
  private stopQuery = new Subject();

  private keepBackUrlWhenDestroy: boolean;

  constructor(
    public translate: TranslateService,
    private ngZone: NgZone,
    private router: Router,
    private dialog: MatDialog,
    private navService: AppNavigationService,
    private alertService: AlertService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);

    of(DEFAULT_TITLE)
      .pipe(
        takeUntil(this._destroyed),
        switchMap(key =>
          merge(
            this.translate.stream(key),
            this.translate.onTranslationChange.pipe(switchMap(() => this.translate.get(key)))
          )
        )
      )
      .subscribe(val => {
        this._defaultTitle = val;
        if (!this.title) {
          this.title = undefined;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.option) {
      if (!changes.option.isFirstChange()) {
        this.optionChanged.emit(this.option);
      }
      this.fetchData();
    }
  }

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this.notifications.pipe(takeUntil(this._destroyed), bufferTime(1000)).subscribe(alertIds => {
        if (alertIds.length > 0) {
          this.fetchAlerts(alertIds);
        }
      });
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (!this.keepBackUrlWhenDestroy) {
      this.navService.setBackUrlFallback(undefined);
    }
  }

  trackByFn(_index: number, item: AlertDisplay) {
    return item.alert._id;
  }

  onAlertLinkClick(e: MouseEvent) {
    if ((e.button !== 0 && e.button !== 1) || e.metaKey || e.ctrlKey) {
      return;
    }
    this.keepBackUrlWhenDestroy = true;
    this.navService.setBackUrlFallback(this.router.url);
  }

  openFilter() {
    this.dialog
      .open(WidgetAlertFilterComponent, {
        data: { appliedFilters: this.option ? this.option.filter : undefined },
        disableClose: true
      })
      .afterClosed()
      .pipe(takeUntil(this._destroyed))
      .subscribe(filters => {
        if (filters) {
          this.setFilter(filters);
        }
      });
  }

  setFilter(filters: FilterOption[]) {
    const option = Object.assign({}, this.option || {}, { filter: filters });
    const change = new SimpleChange(this.option, option, false);
    this.option = option;
    this.ngOnChanges({ option: change });
  }

  private parseFilters(param: string): string[] {
    if (this.option && this.option.filter) {
      return (this.option.filter as FilterOption[])
        .filter(f => f.param === param)
        .map(f => f.value.value);
    }
    return [];
  }

  private paramsFromFilters(): any {
    const params: any = {};
    const statusFilter = this.parseFilters('status');
    if (statusFilter.length > 0) {
      params.status = statusFilter;
    } else {
      params.status = 'Opened';
    }
    const alertTypeFilter = this.parseFilters('alertType');
    if (alertTypeFilter.length > 0) {
      params.alertType = alertTypeFilter;
    }
    const severityFilter = this.parseFilters('severity');
    if (severityFilter.length > 0) {
      params.severity = severityFilter;
    } else {
      params.severityGte = 'Warning';
    }
    const limitFilter = this.parseFilters('limit');
    if (limitFilter.length > 0) {
      params.limit = limitFilter[0];
    } else {
      params.limit = DEFAULT_LIMIT;
    }
    const cpoGroupFilter = this.parseFilters('cpoGroup');
    if (cpoGroupFilter.length > 0) {
      params.cpoGroup = cpoGroupFilter;
    }
    const cpoFilter = this.parseFilters('cpo');
    if (cpoFilter.length > 0) {
      params.cpo = cpoFilter;
    }
    const locationFilter = this.parseFilters('location');
    if (locationFilter.length > 0) {
      params.location = locationFilter;
    }
    const equipmentIdFilter = this.parseFilters('equipmentId');
    if (equipmentIdFilter.length > 0) {
      params.equipmentId = equipmentIdFilter;
    }
    const errorCodeFilter = this.parseFilters('errorCode');
    if (errorCodeFilter.length > 0) {
      params.errorCode = errorCodeFilter;
    }
    return params;
  }

  private fetchData() {
    this.querying = true;
    this.stopQuery.next();
    this.changeDetectorRef.markForCheck();

    this.ngZone.runOutsideAngular(() => {
      this.queryParams = this.paramsFromFilters();
      this.routeQueryParams = Object.assign({}, this.queryParams);
      delete this.routeQueryParams.limit;
      forkJoin([timer(MIN_LOADING_TIME), this.alertService.getAlerts(this.queryParams)])
        .pipe(takeUntil(this._destroyed), takeUntil(this.stopQuery))
        .subscribe(results => this.ngZone.run(() => this.onAlertsLoaded(results[1].items)));
    });
  }

  private onAlertsLoaded(alerts: Alert[]) {
    this.displays = [];
    alerts.forEach(alert => {
      const i = this.displays.findIndex(d => d.alert._id === alert._id);
      const display = this.alertService.getAlertDisplay(alert);
      if (i > -1) {
        if (display.closed && !this.displays[i].closed) {
          // Notify alert stop to unsubscribe
          this.stopAlertUpdateSub.next(alert._id);
        }
        this.displays[i] = display;
      } else {
        this.displays.push(display);
      }
    });
    this.ngZone.run(() => this.sortAlerts());
  }

  private onAlertRequestedMissing(alertId: string) {
    const i = this.displays.findIndex(d => d.alert._id === alertId);
    if (i > 0) {
      this.stopAlertUpdateSub.next(alertId);
      this.displays.splice(i, 1);
    }
  }

  private sortAlerts() {
    this.displays.sort((a, b) => {
      if (a.alert.openDate < b.alert.openDate) {
        return 1;
      }
      if (a.alert.openDate > b.alert.openDate) {
        return -1;
      }
      return 0;
    });
    this.querying = false;
    this.changeDetectorRef.markForCheck();
  }

  private fetchAlerts(alertIds: string[]) {
    const chunks = [];
    const chunkLength = 3;
    for (let i = 0; i < alertIds.length; i += chunkLength) {
      // chunks.push(alertIds.slice(i, i + chunkLength));
      chunks.push(alertIds.slice(i, i + 1));
    }
    chunks.map(idList => {
      const queryParams = Object.assign({}, this.queryParams, { id: idList.join(',') });
      this.alertService
        .getAlerts(new HttpParams({ fromObject: queryParams }))
        .pipe(takeUntil(this._destroyed), takeUntil(this.stopQuery))
        .subscribe(res => {
          const alerts = res.items;
          alertIds.forEach(id => {
            if (alerts.findIndex(a => a._id === id) < 0) {
              this.onAlertRequestedMissing(id);
            }
          });
          this.onAlertsLoaded(alerts);
        });
    });
  }
}
