import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';
import { AnimationCurves } from '@angular/material/core';

export const routerFade = trigger('routerFade', [
  transition('* => *', [
    style({ position: 'relative' }),
    query(':enter, :leave', [style({ position: 'absolute', top: 0, left: 0, width: '100%' })], {
      optional: true
    }),
    query(':enter', [style({ opacity: '0' })], { optional: true }),
    query(':leave', animateChild({ duration: 3000 }), { optional: true }),
    group([
      query(':leave', [animate(`500ms ${AnimationCurves.STANDARD_CURVE}`, style({ opacity: 0 }))], {
        optional: true
      }),

      query(
        ':enter',
        [animate(`500ms 250ms ${AnimationCurves.STANDARD_CURVE}`, style({ opacity: 1 }))],
        { optional: true }
      )
    ]),
    query(':enter', animateChild(), { optional: true })
  ])
]);
