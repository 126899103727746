import { Customer } from './customer';
import { PaymentMethod } from './payment-method';
import { PaymentDetails } from './payment/payment-details';
import { PurchaseOrderItem, PurchaseOrderOld } from './purchase-order-item';
import { TermsAcceptance } from './terms-acceptance';
import { FiservPaymentDetails } from '@app/core/purchase-order/payment/fiserv-payment-details';

export interface PurchaseOrder {
  _id?: string;
  emsp: string;

  customer: Customer;
  termsAcceptances?: TermsAcceptance[];
  vendor?: string;

  locale?: string;
  currency: string;

  items?: PurchaseOrderItem[];
  order?: PurchaseOrderOld[];

  preAuthorization?: number;
  paymentMethod: PaymentMethod;
  paymentDetails: PaymentDetails;
  fiservPaymentDetails: FiservPaymentDetails;

  subtotalTaxExclusive: number;
  totalTaxInclusive: number;
  taxes?: number;

  status: string;
  paymentStatus?: string;
  deliveryStatus?: string;
  failedReason?: string;

  creationDate: string;
  verificationCode?: string;
  confirmDate?: string;

  successfulPaymentId?: string;

  billingStatus?: string;

  validationAssets?: ValidationAsset[];

  // specific for te61 orders
  tosAccepted?: boolean;
  touMangopayAccepted?: boolean;
  tosAcceptedTime?: string;
  touMangopayAcceptedTime?: string;
  subtotal?: number;
  userId?: string;
}

export interface ValidationAsset {
  name: string;
  id: string;
  status: ValidationStatus;
  uploadDate?: string;
  validationDate?: string;
  publicationDate?: string;
}

enum ValidationStatus {
  WAITING_VALIDATION,
  UNREADABLE,
  INVALID,
  VALIDATED
}
