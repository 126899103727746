import { SmartChargingPeriod } from './charging-period/charging-period';
import { SmartChargingProfileType } from './profile-type';

export class SmartChargingProfile {
  name: string;
  type: SmartChargingProfileType;
  periods: SmartChargingPeriod[];
  active: boolean;
  ceiling: number;

  constructor(profile: SmartChargingProfile) {
    this.name = profile.name;
    this.type = profile.type;
    this.periods = profile.periods;
    this.active = profile.active;
    this.ceiling = profile.ceiling;
  }
}
