import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SmartChargingService } from '@app/smart-charging/smart-charging.service';
import { ChargingServicePlan } from '@app/core/smart-charging/charging-service-plan';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
import { AppNavigationService } from '@app/navigation';
import { OwnerType } from '@app/core/smart-charging/owner-type';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss']
})
export class SmartChargingServiceListComponent implements OnInit {
  servicePlans: ChargingServicePlan[];
  displayedColumns: string[] = ['Label', 'Energy', 'Batches', 'Version', 'Type'];
  @Input() fleetId;

  constructor(
    private navService: AppNavigationService,
    private smartChargingService: SmartChargingService,
    private router: Router,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        switchMap(param => {
          if (this.fleetId) {
            return this.smartChargingService.getServicesByFleet(this.fleetId);
          }
          this.navService.setBackUrlFallback(`/infrastructure/cpo/${param.cpoId}`);
          return this.smartChargingService.getServicesByCpo(param.cpoId);
        })
      )
      .subscribe(servicePlans => {
        servicePlans.sort((a, b) => a.label.localeCompare(b.label));
        this.servicePlans = servicePlans;
        this.ref.markForCheck();
      });
  }

  createNewService(): void {
    if (this.fleetId) {
      this.router.navigate([`smart-charging/services/new/0`], {
        queryParams: { fleetId: this.fleetId }
      });
    } else {
      this.router.navigate(['smart-charging/services/new/0'], { queryParamsHandling: 'preserve' });
    }
  }

  navigateToService(index: number): void {
    const chargingServicePlan = this.servicePlans[index];
    if (this.fleetId) {
      this.router.navigate(
        [
          `smart-charging/services/${chargingServicePlan.versioningId}/${chargingServicePlan.version}`
        ],
        {
          queryParams: { fleetId: this.fleetId }
        }
      );
    } else {
      this.router.navigate(
        [
          `smart-charging/services/${chargingServicePlan.versioningId}/${chargingServicePlan.version}`
        ],
        {
          queryParamsHandling: 'preserve'
        }
      );
    }
  }
  getServiceType(service: ChargingServicePlan): string {
    return service.editable ? 'User' : 'Default';
  }
}
