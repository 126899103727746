import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { CanColor, mixinColor, ThemePalette } from '@angular/material/core';

export class BouncingDotProgressBase {
  constructor(public _elementRef: ElementRef) {}
}

export const _BouncingDotProgressBase = mixinColor(BouncingDotProgressBase);

@Component({
  selector: 'app-bouncing-dot-progress',
  templateUrl: 'bouncing-dot-progress.component.html',
  styleUrls: ['bouncing-dot-progress.component.scss']
})
export class BouncingDotProgressComponent extends _BouncingDotProgressBase implements CanColor {
  @HostBinding('class.app-bouncing-dot-progress') progressClass = true;
  @Input() color: ThemePalette;
  @Input() diameter: string;
  defaultColor: ThemePalette;

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }
}
