import { Component } from '@angular/core';

@Component({
  selector: 'app-text-placeholder',
  template: `
    <div
      fxFill
      fxLayout="column"
      fxLayoutAlign="space-evenly center"
      class="text-placeholder-container"
    >
      <div class="text-placeholder"></div>
      <div class="text-placeholder"></div>
      <div class="text-placeholder"></div>
    </div>
  `,
  styleUrls: ['text-placeholder.component.scss']
})
export class TextPlaceholderComponent {}
