import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: '0' }),
    group([
      animate(`${AnimationDurations.COMPLEX} ${AnimationCurves.STANDARD_CURVE}`),
      query('@*', animateChild(), { optional: true })
    ])
  ]),

  transition(
    ':leave',
    group([
      query('@*', animateChild(), { optional: true }),
      animate(
        `${AnimationDurations.COMPLEX} ${AnimationCurves.STANDARD_CURVE}`,
        style({ opacity: '0' })
      )
    ])
  )
]);
