import { EmspTxStatus } from './emsp-tx-status-enum';
import { TokenType } from './token-type-enum';
import { EmspUser } from '../user-data';
import { Cpo } from '../infrastructure/cpo';
import { ServiceProfile } from '../purchase-order';
import { Emsp } from '../infrastructure/emsp';
import { TransactionType } from './transaction-type-enum';

export interface EmspTxApi {
  items: EmspTx[];
  totalCount: number;
}

export interface EmspTxValorisation {
  status: string;
  type: string;
  taxedPrice: number;
  currency: string;
  vatRate: number;
  nonTaxedPrice: number;
  valorizationDate: number;
  valorizationPeriods: ValorizationPeriod[];
}
export interface ValorizationPeriod {
  priceType: string;
  cpoArticle: string;
  subPrice: string;
  energy?: number;
  duration: number;
  startDate?: string;
  endDate?: string;
}

export class EmspTx {
  id: string;
  transactionId: string;
  purchaseOrderId?: string;

  serviceProfileId: string;
  serviceProfile: ServiceProfile;

  cpoId: string;
  cpo: Cpo;

  emspId: string;
  emsp: Emsp;

  evseId: string;
  evseEmi3?: string;

  userId?: string;
  evUser?: EmspUser;

  locationId: string;
  locationName?: string;
  locationAddress: any;
  city: string;

  identification?: {
    idTag: string;
    type: TokenType;
    serial?: string;
  };

  status: EmspTxStatus;
  creationDate: string;
  authorizeDate: string;

  remoteStartDate?: string;
  startDate: string;
  stopDate?: string;

  startCharge?: string;
  stopCharge?: string;

  lastMeterDate?: string;
  consumption: number;

  valorization?: EmspTxValorisation;
  type?: TransactionType;
}
