import { HttpUrlEncodingCodec } from '@angular/common/http';

export class CustomEndingCodec extends HttpUrlEncodingCodec {
  encodeKey(k: string): string {
    return encodeURIComponent(k)
      .replace(/%40/gi, '@')
      .replace(/%3A/gi, ':')
      .replace(/%24/gi, '$')
      .replace(/%2C/gi, ',')
      .replace(/%3B/gi, ';')
      .replace(/%2B/gi, '+')
      .replace(/%3D/gi, '=')
      .replace(/%3F/gi, '?')
      .replace(/%2F/gi, '/')
      .replace(/%26/gi, '&');
  }
}
