import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  DialogConfirmationComponent,
  DialogConfirmationData
} from '@app/shared/dialog-confirmation/dialog-confirmation.component';

interface Description {
  language: string;
  message: string;
}

export interface EventDescription {
  newDescription: {};
  isDescriptionUpdated: boolean;
}

@Component({
  selector: 'app-banner-description',
  templateUrl: './description-editor.component.html',
  styleUrls: ['description-editor.component.scss', '../../referencial/park/core/shared/form.scss']
})
export class BannerDescriptionEditorComponent implements OnInit {
  @Input() componentName: string;
  @Input() componentPlaceholder: string;
  @Input() componentType: string;
  @Input() required: boolean;
  @Input() description: {};
  @Output() descriptionChange = new EventEmitter<EventDescription>();

  descriptionDataSource: Description[] = [];
  displayedColumns = ['language', 'message', 'action'];
  displayDescriptionForm: boolean;
  placeholder: string;
  msgError: string;

  oldDescription: {};

  descriptionForm = this.formBuilder.group({
    language: ['', Validators.required],
    message: ['', Validators.required]
  });

  languageAvailable = ['en-US', 'fr-FR', 'es-ES', 'nl-NL', 'nl-BE', 'de-DE', 'it-IT', 'en-GB'];

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.oldDescription = { ...this.description };
    this.placeholder = this.translate.instant(`banner.${this.componentPlaceholder}`);
    this.msgError = this.translate.instant('banner.error.Is required.', {
      componentName: this.componentName
    });
    if (!this.description) {
      this.description = {};
    }

    this.updateDataSource();
  }

  updateDataSource(): void {
    this.descriptionDataSource = [];
    Object.entries(this.description).forEach(([key, value]) =>
      this.descriptionDataSource.push({ language: key, message: value.toString() })
    );
  }

  addDescription(): void {
    this.description[this.language] = this.value;
    this.updateDataSource();
    this.resetForm();
    this.emitChanges();
  }

  updateDescription(description: Description): void {
    this.displayDescriptionForm = true;
    this.descriptionForm.get('language').setValue(description.language);
    this.descriptionForm.get('message').setValue(description.message);
  }

  resetForm(): void {
    this.descriptionForm.reset();
    this.displayDescriptionForm = false;
  }

  emitChanges(): void {
    this.descriptionChange.emit({
      newDescription: this.description,
      isDescriptionUpdated: this.isDescriptionUpdated()
    });
  }

  openDialog(language: string): void {
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: new DialogConfirmationData(
        'common.Deletion confirmation',
        'common.Delete',
        'common.Cancel'
      )
    });
    dialogRef.afterClosed().subscribe(deleteConfirmation => {
      if (deleteConfirmation) {
        this.deleteTemplate(language);
      }
    });
  }

  deleteTemplate(language: string): void {
    delete this.description[language];
    this.descriptionDataSource = this.descriptionDataSource.filter(
      template => template.language !== language
    );
    this.changeDetector.markForCheck();
    this.emitChanges();
  }

  isDescriptionUpdated(): boolean {
    if (this.oldDescription) {
      const keys1 = Object.keys(this.oldDescription);
      const keys2 = Object.keys(this.description);

      if (keys1.length !== keys2.length) {
        return true;
      }

      for (const key of keys1) {
        if (this.oldDescription[key] !== this.description[key]) {
          return true;
        }
      }
    }
    return false;
  }

  get language(): string {
    return this.descriptionForm.get('language').value;
  }

  get value(): string {
    return this.descriptionForm.get('message').value;
  }
}
