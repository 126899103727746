import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { TxTimeline } from '@app/core';
import { ChartLabel } from '@app/shared';
import { ChartColorScheme } from '@app/shared/interface/shared-type';

@Component({
  selector: 'app-widget-tx-timeline',
  templateUrl: './tx-timeline-chart.component.html'
})
export class WidgetTxTimelineComponent implements OnChanges {
  @HostBinding('class.app-widget-tx-timeline') chartClass = true;
  @Input() timeline: TxTimeline;
  @Input() stacked = true;
  @Input() view: any = [600, 400];

  leftSeries: any[] = [];
  rightSeries: any[] = [];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = false;
  showYAxisLabel = true;
  xAxisLabel = 'transaction.chart.Time';
  yAxisLabel = 'transaction.chart.Current (A)';
  yAxisLabelRight = 'transaction.chart.Energy (kWh)';

  @Input() colorScheme: ChartColorScheme = { domain: ['#5AA454', '#A10A28', '#C7B42C'] };

  legendDomain: any[];

  private currentLabel: ChartLabel;
  private energyLabel: ChartLabel;

  // line, area
  autoScale = true;

  constructor(public translate: TranslateService) {
    this.currentLabel = new ChartLabel('Current', () =>
      translate.instant('transaction.chart.Current')
    );
    this.energyLabel = new ChartLabel('Energy', () =>
      translate.instant('transaction.chart.Energy')
    );
    this.legendDomain = [this.currentLabel, this.energyLabel];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.timeline) {
      this.updateData();
    }
  }

  xTickFormat(time: Date): string {
    return moment(time).format('LT');
  }

  yLeftAxisScale(_min, max) {
    let scaleMax = 20;
    if (scaleMax < max && max < 32) {
      scaleMax = 32;
    } else {
      scaleMax = max / 0.8;
    }
    return { min: 0, max: scaleMax };
  }

  yRightAxisScale(min, max) {
    const a = { min, max: max / 0.8 };
    return a;
  }

  yLeftTickFormat(data) {
    return data / 1000;
  }

  yRightTickFormat(data) {
    return data / 1000;
  }

  onSelect(_event) {}

  private updateData() {
    this.leftSeries = [];
    this.rightSeries = [];
    if (!this.timeline) {
      return;
    }

    const txEnergy = { name: this.energyLabel, series: [] };
    const txCurrent = { name: this.currentLabel, series: [] };
    let iTime;
    this.timeline.timeline.forEach(plot => {
      let time = moment(plot.time);
      const start = !iTime || !time.isSameOrBefore(iTime);
      if (!start) {
        time = iTime.clone().add(1, 'ms');
      }
      iTime = time;
      txEnergy.series.push({ name: time.toDate(), value: plot.energy });
      txCurrent.series.push({ name: time.toDate(), value: plot.currentIntensity });
    });
    this.leftSeries.push(txCurrent);
    this.rightSeries.push(txEnergy);
  }
}
