import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AppNavigationService } from '@app/navigation';
import { NetworkDisplayApi, NetworkService } from '../../network/network.service';
import { Network } from '../../core/infrastructure/network';
import { HttpParams } from '@angular/common/http';
import { showChart } from '@app/network/base/network-display-content/network-chart-animations';
import { fadeInOut } from '@app/shared';
import { ChartColorScheme } from '@app/shared/interface/shared-type';

@Component({
  selector: 'app-infra-network-info',
  templateUrl: 'network-info.component.html',
  styleUrls: ['network-info.component.scss'],
  animations: [fadeInOut, showChart]
})
export class InfraNetworkInfoComponent implements OnDestroy, OnInit, AfterViewInit {
  chartWidth = 0;
  colorScheme: ChartColorScheme = { domain: [''] };
  error: any;
  network: Network;
  locationId: string;
  curveType = 1;
  iccid: string;
  forfaitLimit: number;
  display: NetworkDisplayApi;
  private _destroyed = new Subject();
  private keepBackUrlWhenDestroy: boolean;

  constructor(
    public translate: TranslateService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private navService: AppNavigationService,
    private netService: NetworkService
  ) {}

  ngOnInit() {
    this.navService.setBackUrlFallback('/infrastructure');

    this.route.params.subscribe(params => {
      this.locationId = params.id;
      this.iccid = params.iccid;
      this.fetchNetworkDetails(this.locationId, this.iccid);
    });
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();

    if (!this.keepBackUrlWhenDestroy) {
      this.navService.setBackUrlFallback(undefined);
    }
  }

  ngAfterViewInit() {
    this.onWindowResize();
    this.colorScheme.domain = ['#ec7547', '#3bd8f2'];
  }

  @HostListener('window:resize')
  onWindowResize() {
    const net = window.innerWidth < 600;
    const panelWidth = net ? window.innerWidth - 16 : (window.innerWidth * 2) / 3;
    this.chartWidth = panelWidth - 100;
    this.changeDetectorRef.markForCheck();
  }

  private fetchNetworkDetails(locationId: string, iccid: string) {
    this.navService.setAppBarProgress(true);
    this.error = undefined;
    this.changeDetectorRef.markForCheck();
    this.ngZone.runOutsideAngular(() =>
      forkJoin([
        this.netService.getNetwork(
          new HttpParams({
            fromObject: {
              location: locationId,
              iccid
            }
          })
        )
      ])
        .pipe(takeUntil(this._destroyed))
        .subscribe(
          result => this.ngZone.run(() => this.onNetworkDetailsLoaded(result[0][0])),
          err =>
            this.ngZone.run(() => {
              this.navService.setAppBarProgress(false);
              this.error = err;
              this.changeDetectorRef.markForCheck();
            })
        )
    );
  }

  onLinkClick(e: MouseEvent) {
    if ((e.button !== 0 && e.button !== 1) || e.metaKey || e.ctrlKey) {
      return;
    }
    this.keepBackUrlWhenDestroy = true;
    this.navService.setBackUrlFallback(
      `/infrastructure/location/${this.locationId}/network/${this.iccid}`
    );
  }

  private onNetworkDetailsLoaded(network: Network) {
    this.navService.setAppBarProgress(false);
    if (network) {
      this.display = this.netService.getNetworkDisplay(network);
      this.forfaitLimit = this.display.forfaitLimit;
      this.changeDetectorRef.markForCheck();
    }
  }
}
