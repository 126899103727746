import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ScopeGuard, TranslationGuard } from './navigation';
import { NavigationMainContainerComponent } from './navigation/main-container/main-container.component';

import { AppComponent } from './app.component';
import { ForbiddenGuard } from './core/guards/forbidden.guard';
import { COMMISSIONING_INSTALLER_PREFIX_URL } from './core/utils/route.utils';

const ROUTES: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [TranslationGuard],
    children: [
      {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
      },
      {
        path: COMMISSIONING_INSTALLER_PREFIX_URL,
        component: NavigationMainContainerComponent,
        data: { shouldHideMenu: true },
        loadChildren: () =>
          import('./commissioning/commissioning-installer.module').then(
            m => m.CommissioningInstallerModule
          )
      },
      {
        path: 'forbidden',
        component: NavigationMainContainerComponent
      },
      {
        path: '',
        component: NavigationMainContainerComponent,
        canActivate: [ForbiddenGuard],
        children: [
          {
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full'
          },
          {
            path: 'dashboard',
            loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'infrastructure',
            loadChildren: () =>
              import('./infrastructure/infrastructure.module').then(m => m.InfrastructureModule),
            canLoad: [ScopeGuard],
            data: { shouldDisplaySearchEngineSwitch: true }
          },
          {
            path: 'alert',
            loadChildren: () => import('./alert/alert.module').then(m => m.AlertModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'accor-hotels',
            loadChildren: () =>
              import('./accor-hotels/accor-hotels.module').then(m => m.AccorHotelsModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'emsp/user',
            loadChildren: () => import('./emsp-user/emsp-user.module').then(m => m.EmspUserModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'emsp/tx',
            loadChildren: () => import('./emsp-tx/emsp-tx.module').then(m => m.EmspTxModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'emsp/reporting',
            loadChildren: () =>
              import('./emsp-reporting/emsp-reporting.module').then(m => m.EmspReportingModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'emsp/order',
            loadChildren: () =>
              import('./purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'emsp/badges',
            loadChildren: () =>
              import('./badge-deliveries/badge-deliveries.module').then(
                m => m.BadgeDeliveriesModule
              ),
            canLoad: [ScopeGuard]
          },
          {
            path: 'transaction',
            loadChildren: () =>
              import('./transaction/transaction.module').then(m => m.TransactionModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'task',
            loadChildren: () => import('./task/task.module').then(m => m.TaskModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'reporting',
            loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'dataviz',
            loadChildren: () => import('./dataviz/dataviz.module').then(m => m.DatavizModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'smart-charging',
            loadChildren: () =>
              import('./smart-charging/smart-charging.module').then(m => m.SmartChargingModule)
          },
          {
            path: 'user',
            loadChildren: () => import('./user/user.module').then(m => m.UserModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'dedicated-fleet/view',
            loadChildren: () =>
              import('./dedicated-fleet/fleet-view/dedicated-fleet-view.module').then(
                m => m.DedicatedFleetViewModule
              ),
            canLoad: [ScopeGuard]
          },
          {
            path: 'dedicated-fleet/admin',
            loadChildren: () =>
              import('./dedicated-fleet/fleet-admin/dedicated-fleet-admin.module').then(
                m => m.DedicatedFleetAdminModule
              ),
            canLoad: [ScopeGuard]
          },
          {
            path: 'dedicated-fleet/management',
            loadChildren: () =>
              import('./dedicated-fleet/fleet-management/dedicated-fleet-management.module').then(
                m => m.DedicatedFleetManagementModule
              ),
            canLoad: [ScopeGuard]
          },
          {
            path: 'dedicated-fleet/activity-management',
            loadChildren: () =>
              import('./dedicated-fleet/activity-management/activity-management.module').then(
                m => m.ActivityManagementModule
              ),
            canLoad: [ScopeGuard]
          },
          {
            path: 'admin',
            loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'network-mobility',
            loadChildren: () =>
              import('./network-mobility/network-mobility.module').then(
                m => m.NetworkMobilityModule
              ),
            canLoad: [ScopeGuard]
          },
          {
            path: 'marketplace',
            loadChildren: () =>
              import('./marketplace/marketplace.module').then(m => m.MarketplaceModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'vendor',
            loadChildren: () =>
              import('./marketplace/vendor/vendor.module').then(m => m.VendorModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'price-management',
            loadChildren: () =>
              import('./emsp-price-management/emsp-price-management.module').then(
                m => m.EmspPriceManagementModule
              ),
            canLoad: [ScopeGuard]
          },
          {
            path: 'park',
            loadChildren: () => import('./referencial/park/park.module').then(m => m.ParkModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'roaming',
            loadChildren: () =>
              import('./referencial/roaming/roaming.module').then(m => m.RoamingModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'token-fleet',
            loadChildren: () =>
              import('./fleet-token/fleet-token.module').then(m => m.FleetTokenModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'commissioning',
            loadChildren: () =>
              import('./commissioning/commissioning.module').then(m => m.CommissioningModule)
          },
          {
            path: 'settings',
            loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
          }
        ]
      }
    ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      enableTracing: false,
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
