import { AnimationEvent } from '@angular/animations';
import { CdkPortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  EmbeddedViewRef,
  EventEmitter,
  HostBinding,
  HostListener,
  ViewChild
} from '@angular/core';

import { filterTipsContainer } from './filter-tips-animations';

@Component({
  selector: 'app-filter-tips-container',
  templateUrl: 'filter-tips-container.component.html',
  animations: [filterTipsContainer]
})
export class FilterTipsContainerComponent {
  @HostBinding('@filterTipsContainer') _state: string;
  @ViewChild(CdkPortalOutlet, { static: true }) _portalOutlet: CdkPortalOutlet;

  _animationStateChanged = new EventEmitter<AnimationEvent>();

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  /**
   * Attach a TemplatePortal as content to this dialog container.
   * @param portal Portal to be attached as the dialog content.
   */
  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    return this._portalOutlet.attachTemplatePortal(portal);
  }

  @HostListener('@filterTipsContainer.done', ['$event'])
  _onAnimationDone(event: AnimationEvent) {
    this._animationStateChanged.emit(event);
  }

  @HostListener('@filterTipsContainer.start', ['$event'])
  _onAnimationStart(event: AnimationEvent) {
    this._animationStateChanged.emit(event);
  }

  /** Starts the dialog exit animation. */
  _startExitAnimation(): void {
    this._state = 'exit';

    // Mark the container for check so it can react if the
    // view container is using OnPush change detection.
    this._changeDetectorRef.markForCheck();
  }
}
