import {
  animate,
  animateChild,
  query,
  stagger,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

export const filterTips = trigger('filterTips', [
  transition('* => *', [query('@*', stagger(50, animateChild()), { optional: true })])
]);

export const filterTipsContainer = trigger('filterTipsContainer', [
  state('exit', style({ opacity: 0 })),

  transition('* => exit', [
    query(
      '.filter-tip',
      stagger(
        -50,
        animate(
          `${AnimationDurations.COMPLEX} ${AnimationCurves.STANDARD_CURVE}`,
          style({ opacity: 0 })
        )
      ),
      { optional: true }
    )
  ])
]);
