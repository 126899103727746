import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pie-chart-placeholder',
  template: `
    <svg height="100%" width="100%">
      <circle cx="50%" cy="50%" [attr.r]="radius" fill="rgba(0, 0, 0, .12)"></circle>
    </svg>
  `,
  styleUrls: ['pie-chart-placeholder.component.scss']
})
export class PieChartPlaceholderComponent {
  @Input() radius: number;
}
