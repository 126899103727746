import { Icon, MarkerCluster } from 'leaflet';

import { ChargingLocation } from '@app/core';
import { getMarkerSvg } from './marker';

const AVAILABLE_COLOR = '#64dd17';
const ALERT_COLOR = '#f44336';
const DISCONNECTED_COLOR = '#A9A9A9';

export interface LocationClusterOptons {
  defaultBackgroundColor: string;
  cluster: boolean;
  markerColor: boolean;
  showAlert: boolean;
  showDisconnected: boolean;
}

export class LocationCluster extends Icon {
  constructor(private cluster: MarkerCluster, private clusterOptions: LocationClusterOptons) {
    super({
      iconUrl: '',
      iconSize: [96, 96],
      iconAnchor: [48, 72],
      className: 'location-cluster mat-body-1 app-typo-mono'
    });
  }

  createIcon(): HTMLElement {
    let totalCount = 0;
    let availableCount = 0;
    let warnCount = 0;
    let disconnectedCount = 0;
    this.cluster.getAllChildMarkers().forEach(marker => {
      const location: ChargingLocation = (marker.options as any).location;
      if (location.chargingStations) {
        location.chargingStations.forEach(cs => {
          totalCount += cs.evses.length;
          cs.evses.forEach(e => {
            if (e) {
              if (e.status === 'Available' || e.status === 'Occupied') {
                availableCount++;
              }
              if (e.status === 'Faulted' || e.status === 'Unavailable') {
                warnCount++;
              }
              if (e.comStatus === 'DOWN') {
                disconnectedCount++;
              }
            }
          });
        });
      }
    });

    const div = document.createElement('div');

    if (this.clusterOptions.cluster) {
      this.options.iconSize = [96, 96];
      this.options.iconAnchor = [48, 72];
      (this as any)._setIconStyles(div, 'icon');
      div.style.backgroundImage = `url('${getMarkerSvg(
        this.clusterOptions.defaultBackgroundColor
      )}')`;
      div.innerHTML = `
        <span>${totalCount}</span>
      `;
      if (this.clusterOptions.showAlert && warnCount > 0) {
        div.innerHTML += `
        <div class="alert-badge">
          <span class="mdi mdi-alert-outline"></span>
          <span class="mat-caption alert-number">${warnCount}</span>
        </div>`;
      }
    } else {
      this.options.iconSize = [12, 12];
      this.options.iconAnchor = [6, 6];
      div.classList.add('leaflet-marker-icon');
      div.classList.add('cluster-disabled');
      const alertOn = totalCount > 0 && warnCount > 0 && this.clusterOptions.showAlert;
      const availableOn = availableCount > 0 && this.clusterOptions.markerColor;
      const disconnectedOn =
        totalCount > 0 && disconnectedCount > 0 && this.clusterOptions.showDisconnected;
      if (alertOn && availableOn) {
        div.style.backgroundImage = `linear-gradient(135deg, ${AVAILABLE_COLOR} 50%, ${ALERT_COLOR} 50%)`;
      }
      if (disconnectedOn && availableOn) {
        div.style.backgroundImage = `linear-gradient(135deg, ${AVAILABLE_COLOR} 50%, ${DISCONNECTED_COLOR} 50%)`;
      }
      if (disconnectedOn && alertOn) {
        div.style.backgroundImage = `linear-gradient(135deg, ${ALERT_COLOR} 50%, ${DISCONNECTED_COLOR} 50%)`;
      } else if (alertOn) {
        div.style.backgroundColor = ALERT_COLOR;
      } else if (availableOn) {
        div.style.backgroundColor = AVAILABLE_COLOR;
      } else if (disconnectedOn) {
        div.style.backgroundColor = DISCONNECTED_COLOR;
      } else {
        div.style.backgroundColor = this.clusterOptions.defaultBackgroundColor;
      }
    }
    return div;
  }
}
