import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AllocationStrategy } from '@app/core/smart-charging/allocation-strategy';

@Component({
  selector: 'app-basic-card',
  templateUrl: './basic-card.component.html',
  styleUrls: ['./basic-card.component.scss']
})
export class BasicCardComponent implements OnChanges {
  /**
   * Represents the mat-card title - use the translateFieldName boolean if you want to translate this value
   */
  @Input() fieldName: string;

  /**
   * If the value is true, the string will be translated
   * ex: 'smartCharging.CHARGING_SERVICE' in the fieldName will be correctly translated
   * If the value is false, undefined or null then the fieldName will be displayed
   */
  @Input() translateFieldName: boolean;
  @Input() fieldValue: string;

  /**
   * Define the input type
   *
   * If not set the default value 'text' is used
   */
  @Input() inputType: 'text' | 'number' = 'text';
  @Input() icon?: string;
  @Input() isSvgIcon = false;
  @Output() updateValue = new EventEmitter<AllocationStrategy>();

  /**
   * Use editing = true to modify the field.
   * Mat-label becomes an input.
   * Use parentFormGroup and parentFormControlName to link the field to an existing form (from a parent component for example)
   */
  @Input() editing: boolean;

  /**
   * This field can be used to link the fieldValue input to an existing form from a parent component
   *
   */
  @Input() parentFormGroup: FormGroup;

  /**
   * This field is used for the formControlName directive.
   * Use a form control from a parent component
   *
   */
  @Input() parentFormControlName: string;

  /**
   * Allow you to reverse the name and value fields position.
   *
   * False by default. The position will be reversed if value is true
   */
  @Input() reverseFieldsPosition = false;

  /**
   * Manage the matCard width in pixel (px).
   *
   * If undefined or null the component will use its parent width
   */
  @Input() customComponentWidth: number;

  /**
   * Manage the matCard min-width in pixel (px).
   *
   * If undefined or null the component will use the content height
   */
  @Input() minComponentHeight: number;

  /**
   * Defines if the icon should be centered vertically with name and value fields
   *
   * If false, the icon is aligned with one of the field (value or name) depending on the edition mode
   */
  @Input() isIconCenteredVertically: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editing) {
      if (this.editing) {
        this.parentFormGroup.get(this.parentFormControlName).enable();
      }
    }
  }
}
