export interface EventRecordDTO {
  body: EventRecord[];
  status: number;
  count: number;
}

export interface EventRecord {
  cpoId: string;
  locationId: string;
  evseId: string;
  chargingUnitId: string;
  timestamp: string;
  transactionLink?: string;
  alertLink?: string;
  type?: EventRecordType;
  additionalInfo: {
    severity?: string;
    alertType?: string;
    errorCode?: string;
    currentStatus?: string;
  };
}

export enum EventRecordType {
  BOOT_NOTIFICATION = 'BOOT_NOTIFICATION',
  CHARGINGUNIT_COMMUNICATION_PROBLEM = 'CHARGINGUNIT_COMMUNICATION_PROBLEM',
  CHARGINGUNIT_COMMUNICATION_PROBLEM_FINISH = 'CHARGINGUNIT_COMMUNICATION_PROBLEM_FINISH',
  AUTHORIZATION = 'AUTHORIZATION',
  TRANSACTION_STARTED = 'TRANSACTION_STARTED',
  TRANSACTION_STOPPED = 'TRANSACTION_STOPPED',
  STATUS_NOTIFICATION = 'STATUS_NOTIFICATION',
  UPDATE_FIRMWARE = 'UPDATE_FIRMWARE',
  UPDATE_FIRMWARE_TASK_REQUESTED = 'UPDATE_FIRMWARE_TASK_REQUESTED',
  UPDATE_FIRMWARE_TASK_STATUS = 'UPDATE_FIRMWARE_TASK_STATUS',
  EVSE_UNEXPECTED_BEHAVIOR = 'EVSE_UNEXPECTED_BEHAVIOR',
  USER_EXCEPTIONAL_FAILURE = 'USER_EXCEPTIONAL_FAILURE',
  USER_RECURRING_FAILURE = 'USER_RECURRING_FAILURE'
}

export const EVENT_RECORD_TYPE = [
  {
    display: 'infra.eventRecordType.BOOT_NOTIFICATION',
    value: EventRecordType.BOOT_NOTIFICATION,
    translate: true
  },
  {
    display: 'infra.eventRecordType.CHARGINGUNIT_COMMUNICATION_PROBLEM',
    value: EventRecordType.CHARGINGUNIT_COMMUNICATION_PROBLEM,
    translate: true
  },
  {
    display: 'infra.eventRecordType.CHARGINGUNIT_COMMUNICATION_PROBLEM_FINISH',
    value: EventRecordType.CHARGINGUNIT_COMMUNICATION_PROBLEM_FINISH,
    translate: true
  },
  {
    display: 'infra.eventRecordType.AUTHORIZATION',
    value: EventRecordType.AUTHORIZATION,
    translate: true
  },
  {
    display: 'infra.eventRecordType.TRANSACTION_STARTED',
    value: EventRecordType.TRANSACTION_STARTED,
    translate: true
  },
  {
    display: 'infra.eventRecordType.TRANSACTION_STOPPED',
    value: EventRecordType.TRANSACTION_STOPPED,
    translate: true
  }
];
