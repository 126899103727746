import { User } from '@app/core';

export interface Notification {
  _key: string;
  _id: string;
  infra_type_id: string;
  subscriptions: Subscription[];
  updateDate: string;
}

export interface NotificationSubscription {
  id: string;
  infraTypeId: string;
  subscriptions: SubscriptionNotification[];
}

export interface DataDialog {
  model: NotificationSubscription;
  canceled: boolean;
  user: User;
  unsubscribeList: string[];
  updateSubscriptionList: string[];
}

export interface NotificationDTO {
  body: NotificationSubscription;
  status: number;
  message: string;
}

export interface Emails {
  adresse: string;
  language: string;
}

export interface Sms {
  phone: string;
  language: string;
}

export interface Channel {
  emails?: Emails[];
  sms?: Sms[];
}

export interface Trigger {
  severity: Severity;
  event: Event;
}

export interface Subscription {
  _key: string;
  type: AlertType;
  trigger: Trigger;
  channel: Channel;
}
export interface SubscriptionNotification {
  id: string;
  type: AlertType;
  trigger: Trigger;
  channel: Channel;
}

export enum AlertType {
  COMM_ALERT = 'COMM_ALERT',
  STATUS_ALERT = 'STATUS_ALERT',
  ILLEGAL_OCCUPANCY_ALERT = 'ILLEGAL_OCCUPANCY_ALERT'
}

export enum Severity {
  Signal = 'Signal',
  Info = 'Info',
  Warning = 'Warning',
  Critical = 'Critical'
}

export enum Event {
  Open = 'Open',
  Close = 'Close',
  Update = 'Update'
}

export const SEVERITY_LEVEL = [
  { display: 'alert.severity.Signal', value: Severity.Signal, translate: true },
  { display: 'alert.severity.Info', value: Severity.Info, translate: true },
  { display: 'alert.severity.Warning', value: Severity.Warning, translate: true },
  { display: 'alert.severity.Critical', value: Severity.Critical, translate: true }
];

export const EVENT_TRIGGER = [
  { display: 'alert.event.Open', value: Event.Open, translate: true },
  { display: 'alert.event.Close', value: Event.Close, translate: true },
  { display: 'alert.event.Update', value: Event.Update, translate: true }
];

export const ALERT_TYPE = [
  {
    display: 'alert.type.COMM_ALERT',
    value: AlertType.COMM_ALERT,
    translate: true,
    severitiesAvailable: [Severity.Signal, Severity.Info, Severity.Warning, Severity.Critical],
    triggersAvailable: [Event.Open, Event.Close]
  },
  {
    display: 'alert.type.STATUS_ALERT',
    value: AlertType.STATUS_ALERT,
    translate: true,
    severitiesAvailable: [Severity.Signal, Severity.Info, Severity.Warning, Severity.Critical],
    triggersAvailable: [Event.Open, Event.Close]
  },
  {
    display: 'alert.type.ILLEGAL_OCCUPANCY_ALERT',
    value: AlertType.ILLEGAL_OCCUPANCY_ALERT,
    translate: true,
    severitiesAvailable: [Severity.Signal, Severity.Info, Severity.Warning, Severity.Critical],
    triggersAvailable: [Event.Open, Event.Close, Event.Update]
  }
  // Note: the park sensor type was is more restrictive in terms of severity (Critical) and triggers available (Close/Update)
];
