export enum EmspChannel {
  KIWHI = 'Kiwhi',
  G2M = 'G2M',
  MOBILECITY = 'Mobilecity',
  ORANGE = 'Orange',
  GIREVE = 'Gireve',
  PLUGSURFING = 'Plugsurfing',
  CLEM = 'Clem',
  PARKNPLUG = 'ParknPlug',
  KIWHIOCPI = 'KiwhiOCPI',
  EMVCO = 'EMVCO',
  TE61 = 'TE61',
  AUTOPILOT = 'AutoPilot',
  LIFETREE = 'LifeTree',
  G2EMSP = 'G2EMSP',
  CHARGEMAP = 'ChargeMap',
  EVBRIDGES = 'EvBridges',
  TEMS = 'Tems',
  YESEMSP = 'YesEMSP',
  CSRENAULT = 'CsRenault',
  OCE = 'Oce',
  LOCALEMSP = 'LocalEmsp',
  BEMO = 'BEMO'
}
