import { Component } from '@angular/core';

@Component({
  selector: 'app-bar-chart-placeholder',
  template: `
    <div fxFill fxLayoutAlign="space-evenly end">
      <div class="chart-placeholder-1"></div>
      <div class="chart-placeholder-2"></div>
      <div class="chart-placeholder-3"></div>
    </div>
  `,
  styleUrls: ['bar-chart-placeholder.component.scss']
})
export class BarChartPlaceholderComponent {}
