import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface Detail {
  title: string;
  button: string;
  variable?: string;
}

/**
 * This component is used to display a confirm dialog
 * it is meant to be used with a matDialog
 *
 * To use : matDialog.open(ConfirmDialogComponent, {data : {details}}) where details is of type Detail
 *
 */
@Component({
  selector: 'app-admin-confirm-delete-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Detail, public dialogRef: MatDialogRef<any>) {}
}
