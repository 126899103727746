import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AppNavigationService } from '@app/navigation';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fabShowHide } from './scroll-top-fab-animations';

@Component({
  selector: 'app-navigation-scroll-top-fab',
  templateUrl: 'scroll-top-fab.component.html',
  styleUrls: ['scroll-top-fab.component.scss'],
  animations: [fabShowHide],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationScrollTopFabComponent implements OnDestroy, OnInit {
  @HostBinding('class.shift-up') hasFab: boolean;

  show: boolean;

  private _destroyed = new Subject();
  private scrollable: CdkScrollable;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private scrollDispatcher: ScrollDispatcher,
    private scrollToService: ScrollToService,
    private navService: AppNavigationService
  ) {}

  ngOnInit() {
    this.scrollDispatcher
      .scrolled(500)
      .pipe(takeUntil(this._destroyed))
      .subscribe((scrollable: CdkScrollable) => {
        if (scrollable) {
          if (scrollable.getElementRef().nativeElement.id === 'sidenavContent') {
            this.scrollable = scrollable;
            this.onScrolled(scrollable.getElementRef().nativeElement.scrollTop);
          }
        }
      });

    this.navService.fabAction
      .pipe(takeUntil(this._destroyed))
      .subscribe(fab => (this.hasFab = fab !== undefined));
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  scrollToTop() {
    if (this.scrollable) {
      const distance = this.scrollable.getElementRef().nativeElement.scrollTop;
      let duration = distance / 20;
      if (duration < 150) {
        duration = 150;
      }
      if (duration > 375) {
        duration = 375;
      }
      const config: ScrollToConfigOptions = {
        container: this.scrollable.getElementRef(),
        duration,
        easing: 'easeOutCubic',
        offset: -distance
      };
      this.scrollToService.scrollTo(config);
    }
  }

  private onScrolled(scrollTop: number) {
    const show = scrollTop > 0;
    if (show !== this.show) {
      this.show = show;
      this.changeDetectorRef.detectChanges();
    }
  }
}
