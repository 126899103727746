import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HubSettingsAbstractComponent } from '@app/smart-charging/base/hub-settings.component';
import { TranslateService } from '@ngx-translate/core';
import { SmartChargingService } from '@app/smart-charging/smart-charging.service';
import { PeriodType } from '@app/core/smart-charging/profile/charging-period/period-type';
import { SmartChargingPeriod } from '@app/core/smart-charging/profile/charging-period/charging-period';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-hub-eco-periods',
  templateUrl: './hub-eco-periods.component.html',
  styleUrls: ['./hub-eco-periods.scss']
})
export class HubEcoPeriodsComponent extends HubSettingsAbstractComponent implements OnChanges {
  @Input() hubId: string;
  @Input() ecoPeriods: SmartChargingPeriod[] = [];

  isEditing = false;
  isLoadingContent = false;
  type = PeriodType.eco;
  form: FormGroup;
  communicationError = false;
  errorMessage = '';

  constructor(
    public translateService: TranslateService,
    private smartChargingService: SmartChargingService,
    private ref: ChangeDetectorRef,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initForm();
  }

  canValidateEcoPeriod(): boolean {
    if (this.isLoadingContent) {
      return false;
    }
    return this.isEcoPeriodsValid();
  }

  isEcoPeriodsValid(): boolean {
    const form = this.form.get('hubStrategy').get('periods');
    return form.valid && (form.dirty || form.touched);
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      hubStrategy: this.formBuilder.group({
        periods: this.formBuilder.array([])
      })
    });

    if (this.ecoPeriods && this.ecoPeriods.length > 0) {
      this.ecoPeriods.forEach(period => {
        this.periods.push(this.addGroup(period));
      });
    }
  }

  get periods(): FormArray {
    return this.form.get('hubStrategy').get('periods') as FormArray;
  }

  editCurrentTimeRangeStrategy(): void {
    this.isEditing = true;
  }

  cancelTimeStrategyHub(): void {
    this.initForm();
    this.isEditing = false;
  }

  private updateEcoPeriods(): void {
    this.ecoPeriods = this.periods.value;
  }

  onSendEcoPeriods(): void {
    this.isLoadingContent = true;
    this.updateEcoPeriods();

    this.smartChargingService
      .setEcoPeriods(
        this.hubId,
        this.ecoPeriods.map(period => {
          const copyPeriod = JSON.parse(JSON.stringify(period)); // this is an ugly copy
          copyPeriod.startTime = super.formatToUTC(period.startTime.toString());
          copyPeriod.endTime = super.formatToUTC(period.endTime.toString());
          return copyPeriod;
        })
      )
      .subscribe(
        () => {
          this.isLoadingContent = false;
          this.isEditing = false;
          this.communicationError = false;
          this.ref.markForCheck();
        },
        error => {
          this.isLoadingContent = false;
          this.communicationError = true;
          this.updateErrorMessage(error);
          this.ref.markForCheck();
        }
      );
  }

  addNewPeriod(): void {
    const period: SmartChargingPeriod = {
      endTime: null,
      type: this.type,
      startTime:
        this.periods.controls.length > 0
          ? this.periods.controls[this.periods.controls?.length - 1]?.value.endTime
          : null
    };
    const newGroup = this.addGroup(period);
    this.periods.push(newGroup);
  }

  private addGroup(period: SmartChargingPeriod): FormGroup {
    return this.formBuilder.group({
      type: new FormControl(period.type, [Validators.required]),
      startTime: new FormControl(period.startTime, [Validators.required]),
      endTime: new FormControl(period.endTime, [Validators.required])
    });
  }

  private updateErrorMessage(error: any): void {
    switch (error.status) {
      case 400:
        if (error.error === 'Mismatch') {
          this.errorMessage = 'smartCharging.getConf.MISMATCH';
          break;
        }
        this.errorMessage = 'smartCharging.getConf.BAD_REQUEST';
        break;
      case 404:
        this.errorMessage = 'smartCharging.getConf.NOT_FOUND';
        break;
      case 408:
        this.errorMessage = 'smartCharging.getConf.TIMEOUT';
        break;
    }
  }

  onRemovePeriod(index: number) {
    this.periods.removeAt(index);
  }
}
