import { Component, NgZone, OnInit } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { FilterAutocompleteOption, FilterOption, SearchFilter } from '@app/shared';
import { AppNavigationService } from '@app/navigation';
import { OPERATIONS } from '@app/task/create-new/operation-options';
import { FeatureCode } from '../../../environments/feature-code';
import { ConfigService } from '../../../environments/config.service';

export const INPUT_FILTER_OPTIONS: FilterOption[] = [
  { name: 'task.Operation', param: 'operation', type: 'option' },
  { name: 'task.Target', param: 'target', type: 'option' },
  { name: 'task.Task ID', param: 'id' },
  { name: 'task.Cpo', param: 'cpo', type: 'option' }
];

export const FEATURE_OPTIONS: FilterOption[] = [
  { name: 'task.filter.Ongoing', param: 'pending' },
  { name: 'task.filter.Finished', param: 'finished' },
  { name: 'task.filter.MY_TASKS', param: 'my_tasks' }
];

export class TaskFilter extends SearchFilter {
  inputFilters: FilterOption[] = INPUT_FILTER_OPTIONS.map(f => Object.assign({}, f));
  features: FilterOption[] = FEATURE_OPTIONS.map(f => Object.assign({}, f));

  private evseOptions: FilterAutocompleteOption[];
  private cpoOptions: FilterAutocompleteOption[];
  private filterAutocompleteOptions: FilterAutocompleteOption[];

  constructor(
    private ngZone: NgZone,
    private navService: AppNavigationService,
    private configService: ConfigService
  ) {
    super();
    this.filterAutocompleteOptions = this._generateTaskOperationsOptions();
  }

  private _generateTaskOperationsOptions(): FilterAutocompleteOption[] {
    const autocompleteOptions: FilterAutocompleteOption[] = OPERATIONS.map(operation => {
      return {
        display: 'task.operation.' + operation.operation,
        value: operation.operation,
        translate: true
      };
    });
    return this._applyFeatureFlagClearLocalBadges(autocompleteOptions);
  }

  private _applyFeatureFlagClearLocalBadges(
    autocompleteOptions: FilterAutocompleteOption[]
  ): FilterAutocompleteOption[] {
    const featureEnabled = this.configService.isFeatureEnabledNew(FeatureCode.CLEAR_LOCAL_BADGES);
    if (!featureEnabled) {
      return autocompleteOptions.filter(opOption => {
        return opOption.value !== 'CLEAR_LOCAL_BADGES';
      });
    }
    return autocompleteOptions;
  }

  getInputAutoCompleteOptions(selected: FilterOption): Observable<FilterAutocompleteOption[]> {
    if (selected) {
      switch (selected.param) {
        case 'target':
          if (!this.evseOptions) {
            return this.loadEvseAutoCompleteOptions();
          } else {
            return of(this.evseOptions);
          }
        case 'operation':
          return of(this.filterAutocompleteOptions);
        case 'cpo':
          if (!this.cpoOptions) {
            return this.loadCpoAutoCompleteOptions();
          } else {
            return of(this.cpoOptions);
          }
        default:
          return undefined;
      }
    }
    return undefined;
  }

  onFeatureChipClick(filter: FilterOption) {
    filter.value = !filter.value;
    if (!filter.value) {
      switch (filter.param) {
        case 'pending':
        case 'finished':
        case 'my_tasks':
          this.removeFeatureFilterByParam(filter.param);
      }
    }
  }

  applyFeatureFilter(feature: FilterOption, params: any) {
    switch (feature.param) {
      case 'pending':
        params.pending = 'true';
        break;
      case 'finished':
        params.finished = 'true';
        break;
      case 'my_tasks':
        params.my_tasks = 'true';
        break;
      default:
        params[feature.param] = feature.value;
    }
  }

  parseOptionFilterParam(
    filter: FilterOption,
    value: any
  ): FilterAutocompleteOption | Observable<FilterAutocompleteOption> {
    let $options: Observable<FilterAutocompleteOption[]>;
    if (filter.param === 'target') {
      $options = this.evseOptions ? of(this.evseOptions) : this.loadEvseAutoCompleteOptions();
    }
    if (filter.param === 'operation') {
      return this.filterAutocompleteOptions.find(o => o.value === value);
    }
    if (filter.param === 'cpo') {
      $options = this.cpoOptions ? of(this.cpoOptions) : this.loadCpoAutoCompleteOptions();
    }
    if ($options) {
      return $options.pipe(map(options => options.find(o => o.value === value)));
    }
    return undefined;
  }

  parseFeatureParam(key: string, value: any): string {
    if (value === true) {
      switch (key) {
        case 'pending':
        case 'finished':
        case 'my_tasks':
          return key;
      }
    }
    return undefined;
  }

  private loadEvseAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return new Observable(observer => {
      if (this.evseOptions) {
        observer.next(this.evseOptions);
        observer.complete();
      } else {
        this.ngZone.runOutsideAngular(() => {
          this.navService.getEvseHints().subscribe(evses => {
            this.evseOptions = evses.map(evse => {
              return { display: evse._key, value: evse._key };
            });
            this.ngZone.run(() => {
              observer.next(this.evseOptions);
              observer.complete();
            });
          });
        });
      }
    });
  }

  private loadCpoAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return new Observable(observer => {
      if (this.cpoOptions) {
        observer.next(this.cpoOptions);
        observer.complete();
      } else {
        this.ngZone.runOutsideAngular(() => {
          this.navService.getCpoHints().subscribe(cpos => {
            this.cpoOptions = cpos.map(cpo => {
              return { display: cpo.name, value: cpo._key };
            });
            this.ngZone.run(() => {
              observer.next(this.cpoOptions);
              observer.complete();
            });
          });
        });
      }
    });
  }
}
