import {
  animateChild,
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
  sequence,
  state
} from '@angular/animations';
import { AnimationCurves } from '@angular/material/core';

export const chevron = trigger('chevron', [
  state('up', style({ transform: 'rotate(0)' })),
  state('down', style({ transform: 'rotate(-180deg)' })),
  transition('* => *', animate(`500ms ${AnimationCurves.STANDARD_CURVE}`))
]);

export const showHideAddress = trigger('showHideAddress', [
  transition(':enter', [
    style({ height: 0 }),
    animate(`500ms ${AnimationCurves.STANDARD_CURVE}`, style({ height: '*' }))
  ]),

  transition(':leave', [
    style({ height: '!' }),
    animate(`375ms ${AnimationCurves.STANDARD_CURVE}`, style({ height: 0 }))
  ])
]);
