import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { fadeInOut } from '@app/shared';
import {
  CurveResponse,
  CurveResponseValue,
  ExceededCapResponse,
  LevelB,
  Point,
  SmartChargingService
} from '@app/smart-charging/smart-charging.service';
import { TranslateService } from '@ngx-translate/core';
import { TimeRange } from '@app/core/smart-charging/chart/time-range';
import { showChart } from './chart-animation';
import { AppNavigationService } from '@app/navigation';
import { ChartFilter } from '@app/smart-charging/base/chart/filter-chart/filter-chart.component';
import { Hub } from '@app/core/smart-charging/hub';
import {
  AVAILABLE_UNITS,
  isSelectedField,
  Unit,
  UnitDatabaseField
} from '@app/smart-charging/base/chart/filter-chart/available-units';
import { MatDialog } from '@angular/material/dialog';
import {
  ChartDialogComponent,
  DialogData
} from '@app/smart-charging/base/chart/chart-dialog/chart-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarMessageComponent } from '@app/shared/valid-or-error-confirmation/snack-bar-message.component';
import { ChartColorScheme } from '@app/shared/interface/shared-type';
import { concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as moment from 'moment';

export interface ChartData {
  name: string;
  series: Point[];
}

@Component({
  selector: 'app-smart-charging-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  animations: [fadeInOut, showChart]
})
export class SmartChargingChartComponent {
  @Input() hub: Hub;
  @Input() staticCeiling: number;

  data: ChartData[];
  dataByPhase = new Map<number, ChartData[]>();
  exceededCeilingValue: ExceededCapResponse;
  filterValue: ChartFilter;
  timeRange: TimeRange;
  customColors: any[] = [];
  refLines: Point[];
  levelBData: ChartData;
  unitDb = UnitDatabaseField;
  readonly colorScheme: ChartColorScheme = {
    domain: [
      '#4004A3',
      '#B70088',
      '#FF7148',
      '#F9F871',
      '#B1A8B9',
      '#900000',
      '#FFB843',
      '#007BF5',
      '#F20E65',
      '#CEBDE0',
      '#4B4453',
      '#008165',
      '#412828',
      '#C4FCF0',
      '#EDA5FF'
    ]
  };
  private chartDates: (string | number | Date)[];
  private readonly DYNAMIC_CEILING_COLOR = 'rgba(0, 0, 0)';
  private readonly STATIC_CEILING_COLOR = 'rgba(0, 0, 0, 0)';
  private readonly STATIC_CEILING_EXCEEDED_COLOR = '#FF0000';
  private readonly DEFAULT_VOLTAGE = 230;

  constructor(
    private smartChargingService: SmartChargingService,
    public translate: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private navService: AppNavigationService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  onFilterChange(filter: ChartFilter): void {
    this.filterValue = filter;
    this.initCharts();
    if (!this.atLeastOneDeviceSelected()) {
      return;
    }
    if (isSelectedField(UnitDatabaseField.POWER, filter.field)) {
      this.getExceededCap(filter);
    }
    this.updateCurve(filter);
  }

  private atLeastOneDeviceSelected() {
    return this.filterValue.deviceId && this.filterValue.deviceId?.length > 0;
  }

  private initCharts(): void {
    this.initialiseCurveData();
    this.exceededCeilingValue = null;
  }

  private updateCurve(filter: ChartFilter): void {
    this.navService.setAppBarProgress(true);
    this.smartChargingService
      .getCurve(filter)
      .pipe(
        concatMap(response => {
          this.data = this.mapDataFromResponse(
            response,
            this.getUnitByField(this.filterValue.field).dbField
          );
          this.dataByPhase.set(1, this.mapDataFromResponse(response, UnitDatabaseField.CURRENT1));
          this.dataByPhase.set(2, this.mapDataFromResponse(response, UnitDatabaseField.CURRENT2));
          this.dataByPhase.set(3, this.mapDataFromResponse(response, UnitDatabaseField.CURRENT3));
          if (
            isSelectedField(UnitDatabaseField.POWER, this.filterValue.field) &&
            this.filterValue.displayPowerCaps
          ) {
            return this.smartChargingService.getProfilesLevelB(this.hub._key, filter);
          }
          return of(null);
        })
      )
      .subscribe(
        response => {
          if (response) {
            this.addReferencesCaps(response);
          }
          this.changeDetector.markForCheck();
          this.navService.setAppBarProgress(false);
        },
        _err => {
          this.navService.setAppBarProgress(false);
        }
      );
  }

  getUnitByField(field: string): Unit {
    return AVAILABLE_UNITS.find(u => u.dbField === field);
  }

  mapUnitByField(field: string): string {
    const unit = this.getUnitByField(field);
    const name = this.translate.instant(unit.translation);
    return `${name} (${unit.abbreviation})`;
  }

  private getExceededCap(filter: ChartFilter): void {
    this.navService.setAppBarProgress(true);
    this.smartChargingService.getExceededCeilingCap(filter, this.staticCeiling).subscribe(
      response => {
        this.exceededCeilingValue = response;
        this.navService.setAppBarProgress(false);
      },
      _err => {
        this.navService.setAppBarProgress(false);
      }
    );
  }

  private initialiseCurveData(): void {
    this.data = [];
    this.dataByPhase = new Map<number, ChartData[]>();
    this.levelBData = null;
    this.refLines = [];
    this.customColors = [];
  }

  private mapDataFromResponse(
    response: CurveResponse,
    neededField: UnitDatabaseField
  ): ChartData[] {
    return Object.entries(response).map(([key, value]) => ({
      name: key,
      series: this.mapSeriesFromResponse(value, neededField)
    }));
  }

  private mapSeriesFromResponse(
    values: { name: string; value: CurveResponseValue }[],
    field: UnitDatabaseField
  ): Point[] {
    return values.map(p => {
      let value;
      if (field === UnitDatabaseField.POWER) {
        value = this.getActivePowerValue(p);
      } else {
        value = p.value[field];
      }
      return {
        name: new Date(p.name),
        value
      };
    });
  }

  private mapResponseLevelB(response: LevelB, name: string): ChartData {
    return {
      name,
      series: this.chartDates.map(date => {
        return { name: date, value: this.getValueForDate(response, date) };
      })
    };
  }

  private getValueForDate(response: LevelB, date) {
    const matchingResponses = response.filter(({ applicationDate, startTime, endTime }) => {
      const responseApplicationDate = new Date(applicationDate);
      if (date >= responseApplicationDate) {
        const [startHour, startMin] = startTime.split(':').map(Number);
        const [endHour, endMin] = endTime.split(':').map(Number);
        const hour = date.getHours();
        const minutes = date.getMinutes();
        const currentPoint = this.formatHoursAndMinutesInMinutes(hour, minutes);
        const start = this.formatHoursAndMinutesInMinutes(startHour, startMin);
        const end = this.formatHoursAndMinutesInMinutes(endHour, endMin);
        return currentPoint >= start && currentPoint <= end;
      }
      return false;
    });
    if (!matchingResponses.length) return null;
    // Sort the matching responses by application date in descending order so that the most recent date comes first
    matchingResponses.sort(
      (a, b) => new Date(b.applicationDate).getTime() - new Date(a.applicationDate).getTime()
    );
    // Return the power from the first (most recent) matching response
    return matchingResponses[0].power;
  }

  private formatHoursAndMinutesInMinutes(hours: number, minutes: number): number {
    const MINUTES_IN_ONE_HOUR = 60;
    return hours * MINUTES_IN_ONE_HOUR + minutes;
  }

  private getActivePowerValue(responseValues: { name: string; value: CurveResponseValue }): number {
    let activePowerValue = responseValues.value.activePower;
    if (!activePowerValue) {
      activePowerValue =
        (responseValues.value.currentPhase1 +
          responseValues.value.currentPhase2 +
          responseValues.value.currentPhase3) *
        this.DEFAULT_VOLTAGE;
    }
    return activePowerValue || 0;
  }

  private createDatesIntervalBetweenTwoDates(start: Date, stop: Date): Date[] {
    const DEFAULT_NB_POINT = 100;
    const startMoment = moment(start);
    const stopMoment = moment(stop);
    const diff = stopMoment.diff(startMoment);
    const interval = diff / DEFAULT_NB_POINT;
    const dates = [start];
    for (let i = 0; i < DEFAULT_NB_POINT; i++) {
      const newDate = startMoment.add(interval, 'ms');
      dates.push(newDate.toDate());
    }
    return dates;
  }

  private addReferencesCaps(responseLevelB: LevelB): void {
    const datesFromData = this.data.map(chartData => chartData.series.map(point => point.name))[0];
    const dateList = [];
    if (!datesFromData) {
      const dateListCopy = this.createDatesIntervalBetweenTwoDates(
        this.filterValue.timeRange.startDate,
        this.filterValue.timeRange.stopDate
      );
      dateList.push(...dateListCopy);
    }
    this.chartDates = datesFromData || dateList;
    this.addLevelA();
    this.addLevelB(responseLevelB);
  }

  private addLevelA(): void {
    const staticCeiling = this.translate.instant('smartCharging.CEILING_FIELD');
    const staticCeilingPoints = this.chartDates.reduce(
      (acc, val) => [
        ...acc,
        {
          name: val,
          value: this.staticCeiling
        }
      ],
      []
    );

    const levelA = { name: staticCeiling, series: staticCeilingPoints };
    const customColor = { name: staticCeiling, value: this.getStaticCeilingCurveColor() };
    const refLine = { name: staticCeiling, value: this.staticCeiling };
    this.data = [...this.data, levelA];
    this.customColors = [...this.customColors, customColor];
    this.refLines = [...this.refLines, refLine];
  }

  private addLevelB(response: LevelB): void {
    const dynamicCapName = this.translate.instant('smartCharging.DYNAMIC_CEILING');
    this.levelBData = this.mapResponseLevelB(response, dynamicCapName);
    const customColor = { name: dynamicCapName, value: this.DYNAMIC_CEILING_COLOR };
    this.data = [...this.data, this.levelBData];
    this.customColors = [...this.customColors, customColor];
  }

  private getStaticCeilingCurveColor(): string {
    if (this.isCeilingExceeded()) {
      return this.STATIC_CEILING_EXCEEDED_COLOR;
    }
    return this.STATIC_CEILING_COLOR;
  }

  exportCsvCurve(): void {
    this.navService.setAppBarProgress(true);
    this.smartChargingService.getCurve(this.filterValue).subscribe(
      curveResponse => {
        const csvData = this.mapResponseToCsvFormat(curveResponse);
        if (csvData && csvData.length > 0) {
          this.smartChargingService.downloadFile(csvData, this.hub._key);
        } else {
          this.openSnackBar(false, 'smartCharging.NO_DATA');
        }
      },
      error => {
        this.openSnackBar(false, error);
      },
      () => {
        this.navService.setAppBarProgress(false);
      }
    );
  }

  private openSnackBar(isSuccess: boolean, errorMessage?: string): void {
    const message = isSuccess ? 'common.DATA_SAVED' : errorMessage;
    this._snackBar.openFromComponent(SnackBarMessageComponent, {
      data: {
        message,
        type: isSuccess ? 'success' : 'error',
        closeSnackBar: () => this._snackBar.dismiss()
      },
      duration: 5000
    });
  }

  private mapResponseToCsvFormat(response: CurveResponse): any {
    const csvData = [];
    Object.entries(response).forEach(([key, points]) => {
      points.forEach(point => {
        const csvRow = {
          evseId: key,
          dateTime: point.name
        };
        Object.entries(point.value).forEach(([field, value]) => {
          csvRow[field] = value;
        });
        csvData.push(csvRow);
      });
    });
    return csvData;
  }

  isCeilingExceeded(): boolean {
    return (
      this.exceededCeilingValue &&
      !!this.exceededCeilingValue.timestamp &&
      !!this.exceededCeilingValue.evse
    );
  }

  openFullScreen(phase?: number): void {
    const dialogData: DialogData = {
      data: phase ? this.dataByPhase.get(phase) : this.data,
      colorScheme: this.colorScheme,
      customColors: phase ? null : this.customColors,
      refLines: phase ? null : this.refLines,
      staticCeiling: phase ? null : this.staticCeiling,
      selectedUnit: phase
        ? this.getUnitByField(this.unitDb.CURRENT1)
        : this.getUnitByField(this.filterValue.field),
      yAxisLabel: phase
        ? this.mapUnitByField(UnitDatabaseField.INTENSITY)
        : this.mapUnitByField(this.filterValue.field),
      xMin: this.filterValue.timeRange.startDate,
      xMax: this.filterValue.timeRange.stopDate
    };
    this.dialog.open(ChartDialogComponent, {
      data: dialogData,
      disableClose: false,
      hasBackdrop: true,
      height: '80%',
      width: '80%'
    });
  }
}
