import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, mergeAll, takeUntil, tap } from 'rxjs/operators';

import { Country } from '@app/shared';
import { AlertCount } from './alert/alert-count/alert-count';
import { EmspUserCount } from './emsp/emsp-user-creation-trend/emsp-user-count';
import { EvseOccupancyCount } from './infrastructure/evse-occupancy-trend/evse-occupancy-count';
import { TxComplianceCount } from './transaction/tx-compliance-trend/tx-compliance-count';
import { TxConsumptionCount } from './transaction/tx-consumption-trend/tx-consumption-count';
import { TxDurationCount } from './transaction/tx-duration-trend/tx-duration-count';
import { TxEmspDistribution } from './transaction/tx-emsp-distribution/tx-emsp-distribution';
import { TxEmspCount } from './transaction/tx-emsp-trend/tx-emsp-count';

const MAX_PARRALLEL_REQUESTS = 10;

@Injectable({ providedIn: 'root' })
export class WidgetService {
  private apiUrl = 'api/dataviz';

  private requests = new Subject<Observable<any>>();

  constructor(private http: HttpClient) {
    this.requests.pipe(mergeAll(MAX_PARRALLEL_REQUESTS)).subscribe();
  }

  getDashboard(): Observable<any> {
    return this.http.get<any>('api/user/dashboard');
  }

  saveDashboard(dashboard: any): Observable<any> {
    return this.http.put<any>('api/user/dashboard', dashboard);
  }

  getTxComplianceTrend(params: any): Observable<TxComplianceCount[]> {
    return this.doRequest(
      this.http.get<TxComplianceCount[]>(`${this.apiUrl}/txComplianceTrend`, { params })
    );
  }

  getTxEmspTrend(params: any): Observable<TxEmspCount[]> {
    return this.doRequest(
      this.http.get<TxEmspCount[]>(`${this.apiUrl}/txEmspTrend`, { params })
    );
  }

  getTxEmspAggregation(params: any): Observable<TxEmspDistribution[]> {
    return this.doRequest(
      this.http.get<TxEmspDistribution[]>(`${this.apiUrl}/txEmspAggregation`, { params })
    );
  }

  getTxConsumptionTrend(params: any): Observable<TxConsumptionCount[]> {
    return this.doRequest(
      this.http.get<TxConsumptionCount[]>(`${this.apiUrl}/txConsumptionTrend`, { params })
    );
  }

  getTxDurationTrend(params: any): Observable<TxDurationCount[]> {
    return this.doRequest(
      this.http.get<TxDurationCount[]>(`${this.apiUrl}/txDurationTrend`, { params })
    );
  }

  getEvseOccupancyTrend(params: any): Observable<EvseOccupancyCount[]> {
    return this.doRequest(
      this.http.get<EvseOccupancyCount[]>(`${this.apiUrl}/evseOccupancyTrend`, { params })
    );
  }

  getAlertAggregation(params?: any): Observable<AlertCount[]> {
    return this.doRequest(
      this.http.get<AlertCount[]>(`${this.apiUrl}/alertAggregation`, { params })
    );
  }

  getEmspUserCreationTrend(params?: any): Observable<EmspUserCount[]> {
    return this.doRequest(
      this.http.get<EmspUserCount[]>(`${this.apiUrl}/emspUserCreationTrend`, { params })
    );
  }

  getCountries(): Observable<Country[]> {
    return this.doRequest(this.http.get<Country[]>('assets/countries/countries.json'));
  }

  getTopoJson(url): Observable<any> {
    return this.doRequest(this.http.get(url));
  }

  doRequest<T>(request: Observable<T>): Observable<T> {
    return new Observable(observer => {
      const unsubscribe = new Subject();
      this.requests.next(
        request.pipe(
          takeUntil(unsubscribe),
          tap(val => {
            observer.next(val);
            observer.complete();
          }),
          catchError(err => {
            observer.error(err);
            return of(undefined);
          })
        )
      );
      return () => {
        unsubscribe.next();
        unsubscribe.complete();
        observer.complete();
      };
    });
  }
}
