import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { concatMap, filter, takeUntil } from 'rxjs/operators';
import { Evse } from '@app/core';
import { FlyingTaskBadgeDirective } from '@app/shared';
import { CreateTaskResult, TaskService } from '@app/task';
import { EvseStartTxDialogComponent } from '../evse-start-tx-dialog/evse-start-tx-dialog.component';
import { EvseActionConfirmDialogComponent } from '../evse-action-confirm-dialog/evse-action-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EvseUpdateFirmwareDialogComponent } from '../evse-update-firmware-dialog/evse-update-firmware-dialog.component';
import { EvseGetDiagnosticsDialogComponent } from '../evse-get-diagnostics-dialog/evse-get-diagnostics-dialog.component';
import { AppNavigationService } from '@app/navigation';
import { EvseSetChargingProfileDialogComponent } from '@app/infrastructure/base/evse-set-charging-profile-dialog/evse-set-charging-profile-dialog.component';
import { SetChargingProfileRequest } from '@app/infrastructure/base/set-charging-profile-request.model';
import { ConfigService } from '../../../../environments/config.service';

@Component({
  selector: 'app-infra-evse-actions',
  templateUrl: './evse-actions.component.html',
  styleUrls: ['./evse-actions.component.scss']
})
export class EvseActionsComponent implements OnDestroy {
  @Input() evse: Evse;
  @Input() icon = false;
  userIsEnergyOperator = false;
  userId: string;

  private _destroyed = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private taskService: TaskService,
    navigationService: AppNavigationService,
    configService: ConfigService
  ) {
    if (configService.getUserGrantedScopes().includes('energy-operator')) {
      this.userIsEnergyOperator = true;
    }
    this.userId = navigationService.userId.getValue();
    console.log();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  startTransaction(taskBadge: FlyingTaskBadgeDirective): void {
    this.dialog
      .open(EvseStartTxDialogComponent, {
        data: { evse: this.evse },
        hasBackdrop: false
      })
      .afterClosed()
      .pipe(takeUntil(this._destroyed))
      .subscribe(params => {
        if (params) {
          this.taskService
            .createTask$({
              targets: [this.evse._id],
              operation: 'REQUEST_START_TRANSACTION',
              params: {
                connectorId: params.connectorId,
                idTag: params.idTag
              }
            })
            .pipe(takeUntil(this._destroyed))
            .subscribe(result => {
              taskBadge.playAnimation(result.pendingTasks);
            });
        }
      });
  }

  stopTransaction(taskBadge: FlyingTaskBadgeDirective): void {
    this.dialog
      .open(EvseActionConfirmDialogComponent, {
        data: { title: 'infra.evse.action.Stop current transaction?' },
        hasBackdrop: false
      })
      .afterClosed()
      .pipe(takeUntil(this._destroyed))
      .subscribe(confirm => {
        if (confirm) {
          this.taskService
            .createTask$({
              targets: [this.evse._id],
              operation: 'REQUEST_STOP_TRANSACTION'
            })
            .pipe(takeUntil(this._destroyed))
            .subscribe(result => {
              taskBadge.playAnimation(result.pendingTasks);
            });
        }
      });
  }

  enableMaintenance(taskBadge: FlyingTaskBadgeDirective, connectorId: number): void {
    this.taskService
      .createTask$({
        targets: [this.evse._id],
        operation: 'ENABLE_MAINTENANCE',
        params: {
          connectorId
        }
      })
      .pipe(takeUntil(this._destroyed))
      .subscribe(result => {
        taskBadge.playAnimation(result.pendingTasks);
      });
  }

  disableMaintenance(taskBadge: FlyingTaskBadgeDirective, connectorId: number): void {
    this.taskService
      .createTask$({
        targets: [this.evse._id],
        operation: 'DISABLE_MAINTENANCE',
        params: {
          connectorId
        }
      })
      .pipe(takeUntil(this._destroyed))
      .subscribe(result => {
        taskBadge.playAnimation(result.pendingTasks);
      });
  }

  unlockConnector(taskBadge: FlyingTaskBadgeDirective, connectorId: number): void {
    this.taskService
      .createTask$({
        targets: [this.evse._id],
        operation: 'UNLOCK_CONNECTOR',
        params: {
          connectorId
        }
      })
      .pipe(takeUntil(this._destroyed))
      .subscribe(result => {
        taskBadge.playAnimation(result.pendingTasks);
      });
  }

  hardReset(taskBadge: FlyingTaskBadgeDirective): void {
    this.dialog
      .open(EvseActionConfirmDialogComponent, {
        data: { title: 'infra.evse.action.Hard reset the device?' },
        hasBackdrop: false
      })
      .afterClosed()
      .pipe(takeUntil(this._destroyed))
      .subscribe(confirm => {
        if (confirm) {
          this.taskService
            .createTask$({
              targets: [this.evse._id],
              operation: 'HARD_RESET'
            })
            .pipe(takeUntil(this._destroyed))
            .subscribe(result => {
              taskBadge.playAnimation(result.pendingTasks);
            });
        }
      });
  }
  updateFirmware(taskBadge: FlyingTaskBadgeDirective): void {
    this.dialog
      .open(EvseUpdateFirmwareDialogComponent, {
        data: { evse: this.evse },
        hasBackdrop: false
      })
      .afterClosed()
      .pipe(takeUntil(this._destroyed))
      .subscribe(params => {
        if (params) {
          this.taskService
            .createTask$({
              targets: [this.evse._id],
              operation: 'UPDATE_FIRMWARE',
              params: {
                location: params.location,
                retrieveDate: params.retrieveDate,
                retries: params.retries,
                retryInterval: params.retryInterval
              }
            })
            .pipe(takeUntil(this._destroyed))
            .subscribe(result => {
              taskBadge.playAnimation(result.pendingTasks);
            });
        }
      });
  }

  getDiagnostics(taskBadge: FlyingTaskBadgeDirective): void {
    this.dialog
      .open(EvseGetDiagnosticsDialogComponent, {
        data: { evse: this.evse },
        hasBackdrop: false
      })
      .afterClosed()
      .pipe(takeUntil(this._destroyed))
      .subscribe(params => {
        if (params) {
          this.taskService
            .createTask$({
              targets: [this.evse._id],
              operation: 'GET_DIAGNOSTICS',
              params: {
                location: params.location,
                retries: params.retries,
                retryInterval: params.retryInterval
              }
            })
            .pipe(takeUntil(this._destroyed))
            .subscribe(result => {
              taskBadge.playAnimation(result.pendingTasks);
            });
        }
      });
  }

  setChargingProfile(taskBadge: FlyingTaskBadgeDirective): void {
    this.dialog
      .open(EvseSetChargingProfileDialogComponent, {
        data: { evse: this.evse },
        hasBackdrop: false
      })
      .afterClosed()
      .pipe(
        takeUntil(this._destroyed),
        filter(setChargingProfileRequest => !!setChargingProfileRequest),
        concatMap((setChargingProfileRequest: SetChargingProfileRequest) =>
          this.taskService.createTask$({
            targets: [this.evse._id],
            operation: 'SET_CHARGING_PROFILE',
            params: setChargingProfileRequest
          })
        )
      )
      .subscribe((createTaskResult: CreateTaskResult) => {
        taskBadge.playAnimation(createTaskResult.pendingTasks);
      });
  }
}
