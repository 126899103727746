import { Connectors } from './connector';
import { Ocpp } from './ocpp';
import { Emsp } from './emsp';
import { ServiceStatus } from '@app/core/infrastructure/evse-service-status';

export class InfraEvse {
  id: string;
  name: string;
  sviName: string;
  serviceStatus: ServiceStatus;
  blocked: boolean;
  emi3: string;
  maxPower: number;
  emsps: Emsp[];
  connectors: Connectors[];
  creationDate: string;
  staticUpdateDate: string;
  inServiceDate: string;
  retiredDate: string;
  ocpp: Ocpp;
  extraInfo: object;
  hostServiceGroup: string;
  serviceGroup: string;
  physicalReference: string;
  floorLevel: string;
  parkingRestrictions: string[];
  capabilities: string[];
  qrCodeIdForRedirectUrl?: string;

  static determineServiceStatus(evse?: InfraEvse): ServiceStatus {
    return evse?.serviceStatus === ServiceStatus.SUSPENDED && evse?.blocked === true
      ? ServiceStatus.BLOCKED
      : evse?.serviceStatus;
  }

  static toDisplayedEvseArray(evseResponseArray: InfraEvse[]): InfraEvse[] {
    return evseResponseArray.map(evseRes => this.toDisplayedEvse(evseRes));
  }

  static toDisplayedEvse(evseResponse?: InfraEvse): InfraEvse {
    const displayedStatus =
      evseResponse?.serviceStatus === ServiceStatus.SUSPENDED && evseResponse?.blocked === true
        ? ServiceStatus.BLOCKED
        : evseResponse?.serviceStatus;
    return { ...evseResponse, serviceStatus: displayedStatus };
  }

  static toEvseRequest(evse?: InfraEvse): InfraEvse {
    const actualEvse = { ...evse };
    delete actualEvse.blocked;
    if (evse?.serviceStatus === ServiceStatus.BLOCKED) {
      actualEvse.serviceStatus = ServiceStatus.SUSPENDED;
      actualEvse.blocked = true;
    } else if (evse.serviceStatus === ServiceStatus.SUSPENDED) {
      actualEvse.blocked = false;
    }
    return actualEvse;
  }
}
