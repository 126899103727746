import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { CodeScannerComponent } from '@app/shared/code-scanner/code-scanner.component';
import { LanguageSelectorComponent } from '@app/shared/language-selector/language-selector.component';
import { SharedRulesComponent } from '@app/shared/mass-import/shared-rules/shared-rules.component';
import { StepperButtonsComponent } from '@app/shared/stepper-buttons/stepper-buttons.component';
import { TableHeaderComponent } from '@app/shared/table-header/table-header.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { GridsterModule } from 'angular2gridster';
import { MomentModule } from 'ngx-moment';
import { AccordionExpandCollapseAllComponent } from './accordion-expand-collapse-all/accordion-expand-collapse-all.component';
import { AnimatedMenuComponent } from './animated-menu/animated-menu.component';
import { AppMaterialModule } from './app-material.module';
import { BannerDescriptionEditorComponent } from './banner/description-editor.component';
import { BasicCardComponent } from './basic-card/basic-card.component';
import { ChargeStatusPipe } from './charge-status/charge-status.pipe';
import { ItemListWithCheckBoxComponent } from './checkbox-list/item-list-with-check-box.component';
import { AddElementDialogComponent } from './chip-list/add-element-dialog/add-element-dialog.component';
import { ChipListComponent } from './chip-list/chip-list.component';
import { CloseTxDialogComponent } from './close-tx-dialog/close-tx-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ContentContainerComponent } from './content-container/content-container.component';
import { DialogConfirmationComponent } from './dialog-confirmation/dialog-confirmation.component';
import { DurationFloorPipe } from './duration-floor-pipe/duration-floor-pipe';
import { EditableFormFieldComponent } from './editable-form-field';
import { BaseFilterComponent } from './filter/base-filter.component';
import { FilterTipsContainerComponent } from './filter/filter-tips-container.component';
import { FlyingTaskBadgeDirective } from './flying-task-badge/flying-task-badge.directive';
import { ImportButtonMapCsvToListComponent } from './import-button-map-csv-to-list/import-button-map-csv-to-list.component';
import { IncrementDurationPipe } from './increment-duration-pipe/increment-duration.pipe';
import { InputChipListComponent } from './input-chip-list/input-chip-list.component';
import { OverlayService } from './load-spinner/overlay.service';
import { LoadingService } from './loading-manager/loading-service.service';
import { LocalePaginatorIntl } from './locale-paginator-intl/locale-paginator-intl';
import { LocaleSelectorComponent } from './locale-selector/locale-selector.component';
import { ReplacePipe } from './replace-pipe/replace.pipe';
import { ResultCountComponent } from './result-count/result-count.component';
import { SameTaskDialogComponent } from './same-task-dialog/same-task-dialog.component';
import { SectionTitleComponent } from './section-title/section-title.component';
import { SpeedDialFabComponent } from './speed-dial-fab/speed-dial-fab.component';
import { SnackBarMessageComponent } from './valid-or-error-confirmation/snack-bar-message.component';
import { ValidationBtnComponent } from './validation-btn/validation-btn.component';
import { BinaryChoiceSelectorComponent } from '@app/shared/binary-choice-selector/binary-choice-selector.component';

export const DATE_FORMATS = MAT_MOMENT_DATE_FORMATS;
DATE_FORMATS.display.dateInput = 'L';

export const LOCALE_PROVIDER = (translateService: TranslateService): string => {
  return translateService.currentLang;
};

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // Material & CDK
    AppMaterialModule,
    MatMomentDateModule,
    // 3rd Party
    TranslateModule,
    MomentModule,
    ScrollToModule.forRoot(),

    // DeviceDetectorModule.forRoot(),
    GridsterModule.forRoot(),
    MatChipsModule,

    // barcode scanner library module
    ZXingScannerModule
  ],
  declarations: [
    AnimatedMenuComponent,
    DialogConfirmationComponent,
    BaseFilterComponent,
    ContentContainerComponent,
    FilterTipsContainerComponent,
    EditableFormFieldComponent,
    LocaleSelectorComponent,
    ResultCountComponent,
    ConfirmDialogComponent,
    SpeedDialFabComponent,
    AccordionExpandCollapseAllComponent,
    InputChipListComponent,
    AddElementDialogComponent,
    ChipListComponent,
    CloseTxDialogComponent,
    BannerDescriptionEditorComponent,
    BasicCardComponent,
    SectionTitleComponent,
    ItemListWithCheckBoxComponent,
    SameTaskDialogComponent,
    ImportButtonMapCsvToListComponent,
    ValidationBtnComponent,
    SnackBarMessageComponent,
    CodeScannerComponent,
    LanguageSelectorComponent,
    BinaryChoiceSelectorComponent,
    // Directive
    FlyingTaskBadgeDirective,
    // pipe
    IncrementDurationPipe,
    DurationFloorPipe,
    ReplacePipe,
    ChargeStatusPipe,
    SharedRulesComponent,
    StepperButtonsComponent,
    TableHeaderComponent
  ],
  exports: [
    DialogConfirmationComponent,
    AnimatedMenuComponent,
    BaseFilterComponent,
    ContentContainerComponent,
    EditableFormFieldComponent,
    InputChipListComponent,
    ChipListComponent,
    LocaleSelectorComponent,
    ResultCountComponent,
    SpeedDialFabComponent,
    AccordionExpandCollapseAllComponent,
    BannerDescriptionEditorComponent,
    BasicCardComponent,
    ItemListWithCheckBoxComponent,
    ImportButtonMapCsvToListComponent,
    SectionTitleComponent,
    ValidationBtnComponent,
    SnackBarMessageComponent,
    LanguageSelectorComponent,
    BinaryChoiceSelectorComponent,
    // Directive,
    FlyingTaskBadgeDirective,
    // Framework
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // Material & CDK
    AppMaterialModule,
    MatMomentDateModule,
    // 3rd Party
    TranslateModule,
    MomentModule,
    ScrollToModule,
    GridsterModule,
    // pipe
    IncrementDurationPipe,
    DurationFloorPipe,
    ReplacePipe,
    ChargeStatusPipe,
    FilterTipsContainerComponent,
    AddElementDialogComponent,
    ConfirmDialogComponent,
    CloseTxDialogComponent,
    SameTaskDialogComponent,
    StepperButtonsComponent,
    SharedRulesComponent,
    TableHeaderComponent,
    CodeScannerComponent
  ],
  providers: [
    LoadingService,
    OverlayService,
    { provide: MatPaginatorIntl, useClass: LocalePaginatorIntl },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'standard', floatLabel: 'auto' }
    }
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useFactory: LOCALE_PROVIDER, deps: [TranslateService] },
        { provide: DateAdapter, useClass: MomentDateAdapter }
      ]
    };
  }
}
