import { animate, style, transition, trigger } from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

export const resultCountAnim = trigger('resultCountAnim', [
  transition(':enter', [
    style({ padding: '0', width: '0' }),
    animate(
      `${AnimationDurations.COMPLEX} 500ms ${AnimationCurves.DECELERATION_CURVE}`,
      style({ padding: '*', width: '*' })
    )
  ]),

  transition(':leave', [
    style({ opacity: '*' }),
    animate(
      `${AnimationDurations.COMPLEX} ${AnimationCurves.ACCELERATION_CURVE}`,
      style({ opacity: '0' })
    )
  ])
]);
