import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as jspdf from 'jspdf';

import { EmvcoReceipt } from '@app/core';

@Component({
  selector: 'app-credit-card-receipt-dialog',
  templateUrl: './credit-card-receipt-dialog.component.html',
  styleUrls: ['./credit-card-receipt-dialog.component.scss']
})
export class CreditCardReceiptDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CreditCardReceiptDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { txId: string; receipt: EmvcoReceipt }
  ) {}

  download(): void {
    const doc = new jspdf();
    doc.text(this.data.receipt.receipt, 20, 20);
    doc.save(`emvco_receipt_${this.data.txId}.pdf`);
  }
}
