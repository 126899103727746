import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Event,
  AlertType,
  Severity,
  NotificationDTO,
  NotificationSubscription,
  Notification,
  Subscription,
  SubscriptionNotification
} from './notification';
import { NotificationDialogComponent } from './notification-dialog.component';
import { NotificationService } from './notification.service';
import { User } from '@app/core';
import { ADMIN, UserService } from '@app/user';
import { concatMap, mergeMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppNavigationService } from '@app/navigation';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-infrastructure-notification-component',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  constructor(
    public dialog: MatDialog,
    public notificationService: NotificationService,
    private navService: AppNavigationService,
    private userService: UserService
  ) {
    this.canceled = false;
    this.alreadyExists = false;
    this.infraTypeId = this.type + '/' + this.id;
  }

  model: NotificationSubscription;
  canceled: boolean;
  alreadyExists: boolean;
  currentUser: User;
  @Input()
  public id: string;
  @Input()
  public type: string;
  infraTypeId: string;
  receivedId: string;
  toUnsubscribe: string[];

  private unsubscriber = new Subject();

  ngOnInit(): void {
    this.infraTypeId = this.type + '/' + this.id;
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  emptyNotificationSubscription(
    notifId: string,
    typeId: string,
    user: User
  ): NotificationSubscription {
    return {
      id: notifId,
      infraTypeId: typeId,
      subscriptions: [
        {
          id: uuidv4(),
          type: AlertType.COMM_ALERT,
          trigger: {
            severity: Severity.Critical,
            event: Event.Open
          },
          channel: {
            emails: [
              {
                adresse: user ? user.email : '',
                language: user ? user.preferences.language : ''
              }
            ]
          }
        }
      ]
    };
  }

  private refresh(result: NotificationDTO) {
    if (result.body) {
      this.model = {
        id: result.body.id,
        infraTypeId: result.body.infraTypeId,
        subscriptions: result.body.subscriptions
      };
      this.model = this.cleanObject(this.model);
      this.receivedId = this.model.id;
      this.alreadyExists = true;
    }
  }

  private refreshSubscription(result: Notification) {
    if (result) {
      this.model = {
        id: result._key,
        subscriptions: this.mapSubscriptions(result.subscriptions),
        infraTypeId: result.infra_type_id
      };
      this.receivedId = this.model.id;
      this.alreadyExists = true;
    }
  }

  private mapSubscriptions(subscriptions: Subscription[]) {
    const subscriptionsNot: SubscriptionNotification[] = [];
    subscriptions.forEach(sub => {
      subscriptionsNot.push({
        id: sub._key,
        channel: sub.channel,
        trigger: sub.trigger,
        type: sub.type
      });
    });

    return subscriptionsNot;
  }

  private cleanObject = function(object) {
    Object.entries(object).forEach(([k, v]) => {
      if (v && typeof v === 'object') this.cleanObject(v);
      if ((v && typeof v === 'object' && !Object.keys(v).length) || v === null || v === undefined) {
        if (Array.isArray(object)) object.splice(1);
        else if (!(v instanceof Date)) delete object[k];
      }
    });
    return object;
  };

  private unsubscribe(subscriptionId: string) {
    this.notificationService
      .updateSubscriptionEmail(this.model, this.infraTypeId, subscriptionId)
      .subscribe();
  }

  private addSubscription(subscription: SubscriptionNotification) {
    this.notificationService.updateWithNewSubscription(subscription, this.infraTypeId).subscribe();
  }

  private subIsModified(newSub, oldSub) {
    return (
      newSub.type !== oldSub.type ||
      newSub.trigger.event !== oldSub.trigger.event ||
      newSub.trigger.severity !== oldSub.trigger.severity
    );
  }

  onSubmit() {
    if (!this.alreadyExists) {
      this.notificationService
        .createNotification(this.model)
        .pipe(
          concatMap(result => {
            return this.notificationService.getNotification(result.body.infraTypeId);
          })
        )
        .subscribe(notif => {
          this.refreshSubscription(notif);
        });
    } else {
      this.notificationService.updateNotification(this.model).subscribe(result => {
        this.refresh(result);
      });
    }
  }

  openDialog(): void {
    this.notificationService
      .getNotification(this.infraTypeId)
      .pipe(
        mergeMap(result => {
          if (result) {
            this.refreshSubscription(result);
            this.alreadyExists = true;
          }
          return this.navService.userId;
        }),
        mergeMap(userId => {
          const key = userId.replace('user/', '');
          return this.userService.getUserByKey(key);
        }),
        mergeMap(currentUser => {
          if (!this.canceled && this.model) {
            this.model = this.cleanObject(this.model);
          }
          if (!this.model) {
            this.currentUser = currentUser;
            this.model = this.emptyNotificationSubscription(
              this.id,
              this.infraTypeId,
              this.currentUser
            );
          }
          this.toUnsubscribe = [];
          const dialogRef = this.dialog.open(NotificationDialogComponent, {
            width: '50%',
            data: {
              model: this.model,
              canceled: this.canceled,
              user: currentUser,
              unsubscribeList: this.toUnsubscribe
            },
            disableClose: true
          });
          return dialogRef.afterClosed();
        }),
        takeUntil(this.unsubscriber)
      )
      .subscribe(result => {
        this.canceled = result.data.canceled;
        if (!this.canceled) {
          const oldSubscriptions = this.model.subscriptions;
          this.currentUser = result.data.user;
          this.model = result.data.model;
          this.model.infraTypeId = this.infraTypeId;

          if (
            this.currentUser.groups.includes(ADMIN) ||
            this.currentUser.profiles.includes(ADMIN)
          ) {
            // this.onSubmit on peut tout écraser comme avant
            if (this.alreadyExists) {
              this.model.id = this.receivedId;
            }
            this.onSubmit();
          } else {
            // this.onSubmit on fait abonnement par abonnement :
            result.data?.unsubscribeList.forEach(subscriptionId => {
              this.unsubscribe(subscriptionId);
            });

            this.model.subscriptions.forEach(sub => {
              const found = oldSubscriptions.find(oldSub => oldSub.id === sub.id);
              if (found && this.subIsModified(found, sub)) {
                this.unsubscribe(sub.id);
                sub.id = uuidv4();
                this.addSubscription(sub);
              } else if (!found) {
                this.addSubscription(sub);
              }
            });
          }
        }
      });
  }
}
