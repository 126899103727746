export abstract class HubSettingsAbstractComponent {
  protected constructor() {}

  formatPeriod(periods: any[]): void {
    periods.filter(period => period.power).forEach(period => (period.power = period.power / 1000));
    periods.forEach(period => {
      period.startTime = new Date(period.startTime).toLocaleTimeString(navigator.language, {
        hour: 'numeric',
        minute: 'numeric'
      });
      period.endTime = new Date(period.endTime).toLocaleTimeString(navigator.language, {
        hour: 'numeric',
        minute: 'numeric'
      });
      delete period._class;
    });
  }

  formatToUTC(timeAsString: string): string {
    const hourMinute = timeAsString.split(':');
    const actualTime = new Date();
    actualTime.setHours(parseInt(hourMinute[0], 10));
    actualTime.setMinutes(parseInt(hourMinute[1], 10));
    actualTime.setSeconds(0, 0);
    return actualTime.toISOString();
  }
}
