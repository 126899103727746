import {
  animate,
  animateChild,
  group,
  query,
  stagger,
  style,
  transition,
  trigger
} from '@angular/animations';
import { AnimationCurves } from '@angular/material/core';

export const txPanel = trigger('txPanel', [
  transition(':enter', [
    style({ maxHeight: 0, opacity: 0, width: 0 }),
    group([
      animate(
        `250ms ${AnimationCurves.DECELERATION_CURVE}`,
        style({
          maxHeight: '88px'
        })
      ),
      animate(`200ms 300ms`, style({ opacity: 1, width: '*' }))
    ])
  ]),
  transition(':leave', [
    style({ opacity: 1, height: '!' }),
    group([
      animate(`500ms ${AnimationCurves.STANDARD_CURVE}`, style({ opacity: 0 })),
      animate(`250ms 250ms ${AnimationCurves.STANDARD_CURVE}`, style({ height: 0 }))
    ])
  ])
]);

export const footerAnim = trigger('footerFadeIn', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(`300ms ${AnimationCurves.STANDARD_CURVE}`, style({ opacity: 1 }))
  ])
]);

export const noMoreFadeIn = trigger('noMoreFadeIn', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(`500ms 300ms ${AnimationCurves.STANDARD_CURVE}`, style({ opacity: 1 }))
  ])
]);

export const accordionAnim = trigger('accordionAnim', [
  transition('* => *', [
    query(':enter', stagger(50, animateChild()), { optional: true }),
    query(':leave', animateChild(), { optional: true })
  ])
]);
