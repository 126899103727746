import { animate, style, transition, trigger } from '@angular/animations';
import { AnimationDurations, AnimationCurves } from '@angular/material/core';

export const summary = trigger('summary', [
  transition(':enter', [
    style({ height: '29px', opacity: '0', width: '29px' }),
    animate(
      `${AnimationDurations.ENTERING} ${AnimationCurves.SHARP_CURVE}`,
      style({ height: '*', opacity: '1', width: '*' })
    )
  ]),

  transition(':leave', [
    style({ height: '!', opacity: '1', width: '!' }),
    animate(
      `${AnimationDurations.EXITING} ${AnimationCurves.SHARP_CURVE}`,
      style({ height: '29px', opacity: '0', width: '29px' })
    )
  ])
]);
