import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppNavigationService } from '@app/navigation';
import { parseQueryParams } from '@app/shared/filter/filter-util';
import { Router } from '@angular/router';

export interface Subscriptions {
  name: string;
  reportTypes: ReportTypes[];
  fileType: string;
}

export interface ReportTypes {
  name: string;
  label: string;
}
@Injectable({ providedIn: 'root' })
export class ReportingService {
  private reportingUrl = 'api/reporting';

  constructor(
    private http: HttpClient,
    private navService: AppNavigationService,
    private router: Router
  ) {}

  getTxReport(params: any): Observable<HttpResponse<ArrayBuffer>> {
    const url = `${this.reportingUrl}/tx`;
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'arraybuffer',
        params
      })
      .pipe(tap(response => this.navService.downLoadFile(response)));
  }

  getUsersReport(params: any): Observable<HttpResponse<ArrayBuffer>> {
    const url = `${this.reportingUrl}/emsp-user`;
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'arraybuffer',
        params
      })
      .pipe(tap(response => this.navService.downLoadFile(response)));
  }

  getEmspsReport(params: any): Observable<HttpResponse<ArrayBuffer>> {
    const url = `${this.reportingUrl}/emsp-report`;
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'arraybuffer',
        params
      })
      .pipe(tap(response => this.navService.downLoadFile(response)));
  }

  getReferentielReport(params: any): Observable<HttpResponse<ArrayBuffer>> {
    const url = `${this.reportingUrl}/referentiel-static`;
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'arraybuffer',
        params
      })
      .pipe(tap(response => this.navService.downLoadFile(response)));
  }

  getSessionReport(): Observable<Subscriptions> {
    const url = `${this.reportingUrl}/session-report`;
    return this.http.get<Subscriptions>(url);
  }

  getEmspReportTypes(): Observable<Subscriptions> {
    const url = `${this.reportingUrl}/emsp-report-types`;
    return this.http.get<Subscriptions>(url);
  }

  getAlertReport(params: any): Observable<HttpResponse<ArrayBuffer>> {
    const url = `${this.reportingUrl}/alert-report`;
    params = parseQueryParams(params);
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'arraybuffer',
        params
      })
      .pipe(tap(response => this.navService.downLoadFile(response)));
  }

  getTimelineReport(params: any): Observable<HttpResponse<ArrayBuffer>> {
    const url = `${this.reportingUrl}/timeline-report`;
    let type = '';
    let id = '';
    if (this.router.url) {
      const afterInfra = this.router.url.substring(this.router.url.indexOf('infrastructure/'));
      type = afterInfra.split('/')[1];
      id = afterInfra.split('/')[2];
    }
    params = parseQueryParams(params);
    if (!params.evseId && !params.locationId) {
      if (type === 'location') {
        params.locationId = id;
      } else {
        params.evseId = id;
      }
    }
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'arraybuffer',
        params
      })
      .pipe(tap(response => this.navService.downLoadFile(response)));
  }
  getTaskReport(params: any): Observable<HttpResponse<ArrayBuffer>> {
    const url = `${this.reportingUrl}/task-report`;
    params = parseQueryParams(params);
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'arraybuffer',
        params
      })
      .pipe(tap(response => this.navService.downLoadFile(response)));
  }
}
