import { AlertHistoryDisplay } from './../../alert.service';
import { Component, OnInit, Input } from '@angular/core';
import { Alert } from '@app/core/infrastructure/alert';
import { AlertEvent } from '@app/core/infrastructure/alert-event';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alert-history',
  templateUrl: './alert-history.component.html',
  styleUrls: ['./alert-history.component.scss']
})
export class AlertHistoryComponent implements OnInit {
  @Input() displayAlertHistory: AlertHistoryDisplay[];
  @Input() alertHistoryEvents: AlertEvent[];
  @Input() alert: Alert;
  @Input() historySingleSide: boolean;

  listDate: string[];
  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.displayAlertHistory.sort(
      (a, b) => new Date(a.history.date).getTime() - new Date(b.history.date).getTime()
    );
    this.alertHistoryEvents.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    this.listDate = this.displayAlertHistory.map(display => display.history.date);
  }

  trackByFn(_index: number, item: AlertHistoryDisplay) {
    return item.history.eventId;
  }
}
