import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const fabMovement = trigger('fabMovement', [
  state(
    'attachAppbar',
    style({
      transform: 'translate(-64px, {{top}})'
    }),
    {
      params: { top: '228px' }
    }
  ),
  state(
    'static',
    style({
      transform: 'translate({{right}}, calc(100vh - 100% - {{bottom}}))'
    }),
    {
      params: { bottom: '32px', right: '-16px' }
    }
  ),

  transition(
    'void => static',
    [
      style({
        transform: 'translate({{right}}, calc(100vh + 100%))'
      }),
      group([
        animate(
          '500ms 800ms cubic-bezier(.4, 0, .2, 1)',
          style({
            transform: 'translate({{right}}, calc(100vh - 100% - {{bottom}}))'
          })
        ),
        query('@*', animateChild(), { optional: true })
      ])
    ],
    { params: { bottom: '32px', right: '-16px' } }
  ),

  transition(
    'static => void',
    [
      style({
        transform: 'translate({{right}}, calc(100vh - 100% - 128px - {{bottom}}))'
      }),
      group([
        animate(
          '300ms cubic-bezier(.4, 0, .2, 1)',
          style({
            transform: 'translate({{right}}, calc(100vh - 128px + 100%))'
          })
        ),
        query('@*', animateChild(), { optional: true })
      ])
    ],
    { params: { bottom: '32px', right: '-16px' } }
  ),

  transition(
    'void => attachAppbar',
    [
      style({
        transform: 'translate(-64px, 228px) scale(0) rotate(-180deg)'
      }),
      group([
        animate(
          '300ms 800ms cubic-bezier(.4, 0, .2, 1)',
          style({
            transform: 'translate(-64px, {{top}}) scale(1) rotate(0)'
          })
        ),
        query('@*', animateChild(), { optional: true })
      ])
    ],
    { params: { bottom: '32px', right: '-16px' } }
  ),

  transition(
    'attachAppbar => void',
    [
      style({
        transform: 'translate(-64px, calc({{top}} - 128px)) scale(1) rotate(0)'
      }),
      group([
        animate(
          '300ms cubic-bezier(.4, 0, .2, 1)',
          style({
            transform: 'translate(-64px, calc({{top}} - 128px)) scale(0) rotate(180deg)'
          })
        ),
        query('@*', animateChild(), { optional: true })
      ])
    ],
    { params: { top: '228px' } }
  ),

  transition('attachAppbar <=> static', [
    group([
      animate('400ms cubic-bezier(.4, 0, .2, 1)'),
      query('@*', animateChild(), { optional: true })
    ])
  ]),

  transition(
    'shrink => static',
    [
      style({
        transform: 'translate({{right}}, calc(100vh + 100%))'
      }),
      group([
        animate(
          '500ms cubic-bezier(.4, 0, .2, 1)',
          style({
            transform: 'translate({{right}}, calc(100vh - 100% - {{bottom}}))'
          })
        ),
        query('@*', animateChild(), { optional: true })
      ])
    ],
    { params: { bottom: '32px', right: '-16px' } }
  )
]);

export const fabShrink = trigger('fabShrink', [
  state('show', style({ transform: 'scale(1) rotate(0)' })),
  state('shrink', style({ transform: 'scale(0) rotate(180deg)' })),
  transition('* => *', [
    group([
      animate('300ms cubic-bezier(.4, 0, .2, 1)'),
      query('@*', animateChild(), { optional: true })
    ])
  ])
]);
