import { Component, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EVENT_TRIGGER, SEVERITY_LEVEL, Subscription, ALERT_TYPE, AlertType } from './notification';

@Component({
  selector: 'app-infra-notification-trigger-component',
  templateUrl: 'trigger.component.html',
  styleUrls: ['trigger.component.scss']
})
export class TriggerComponent implements OnChanges {
  public severities = Object.values(SEVERITY_LEVEL);
  public events = Object.values(EVENT_TRIGGER);
  public alertType = Object.values(ALERT_TYPE);

  @Input()
  public triggerForm: FormGroup;
  @Input()
  public subscription: Subscription;
  @Input()
  public alertListener: AlertType;

  ngOnChanges() {
    this.updateAlertType();
    this.updateTriggersAvailable();
    this.updateSeveritiesAvailable();
  }

  updateAlertType() {
    this.alertType = Object.values(ALERT_TYPE).filter(
      type => this.triggerForm.parent.value.type === type.value
    );
  }

  updateTriggersAvailable() {
    if (this.alertType[0]) {
      this.events = Object.values(EVENT_TRIGGER).filter(event =>
        this.alertType[0].triggersAvailable.some(eAvailable => eAvailable === event.value)
      );
    } else {
      this.events = Object.values(EVENT_TRIGGER);
    }

    if (!this.events.some(event => event.value === this.subscription.trigger.event)) {
      this.triggerForm.controls.event.setValue(this.events[0].value);
    }
  }

  updateSeveritiesAvailable() {
    if (this.alertType[0]) {
      this.severities = Object.values(SEVERITY_LEVEL).filter(severity =>
        this.alertType[0].severitiesAvailable.some(sAvailable => sAvailable === severity.value)
      );
    } else {
      this.severities = Object.values(SEVERITY_LEVEL);
    }

    if (!this.severities.some(severity => severity.value === this.subscription.trigger.severity)) {
      this.triggerForm.controls.severity.setValue(this.severities[0].value);
    }
  }
}
