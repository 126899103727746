import { Component, Input } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-accordion-expand-collapse-all',
  templateUrl: './accordion-expand-collapse-all.component.html'
})
export class AccordionExpandCollapseAllComponent {
  @Input() accordion: MatAccordion;
}
