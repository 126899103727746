import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { Subject, timer } from 'rxjs';
import { bufferTime, debounceTime, takeUntil } from 'rxjs/operators';

import { createTimeline, Transaction } from '@app/core';
import { AppNavigationService } from '@app/navigation';
import { parseQueryParams } from '@app/shared';
import { TxDisplay, TxService } from '../tx.service';

import { accordionAnim, footerAnim, noMoreFadeIn, txPanel } from './accordion-animations';
import { ChartColorScheme } from '@app/shared/interface/shared-type';

const LOADING_SIZE = 20;

@Component({
  selector: 'app-tx-accordion',
  templateUrl: 'accordion.component.html',
  styleUrls: ['accordion.component.scss'],
  animations: [accordionAnim, footerAnim, noMoreFadeIn, txPanel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TxAccordionComponent implements AfterViewInit, OnDestroy, OnInit {
  chartWidth = 0;
  displays: TxDisplay[] = [];

  colorScheme: ChartColorScheme = { domain: [''] };
  currentScheme: any = { domain: [''] };
  energyScheme: any = { domain: [''] };

  viewInit: boolean;
  querying: boolean;
  totalCount = -1;

  @ViewChild('primaryColorGetter', { static: true }) primaryColorGetter: ElementRef;
  @ViewChild('accentColorGetter', { static: true }) accentColorGetter: ElementRef;
  infraAccess: boolean;

  private _destroyed = new Subject();
  private queryParams: any;
  private notifications = new Subject<string>();
  private stopTxUpdateSub = new Subject<string>();
  private stopQuery = new Subject();

  constructor(
    public translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private scrollDispatcher: ScrollDispatcher,
    private scrollToService: ScrollToService,
    private navService: AppNavigationService,
    private txService: TxService
  ) {}

  ngOnInit() {
    this.scrollDispatcher
      .scrolled()
      .pipe(takeUntil(this._destroyed))
      .subscribe(scrollable => {
        if (scrollable) {
          const nativeEl = scrollable.getElementRef().nativeElement;
          if (nativeEl.scrollTop + nativeEl.offsetHeight > nativeEl.scrollHeight - 48) {
            this.onScrolled();
          }
        }
      });

    this.notifications.pipe(takeUntil(this._destroyed), bufferTime(1000)).subscribe(txIds => {
      if (txIds.length > 0) {
        this.fetchTransactions(txIds);
      }
    });

    this.navService.navItems.pipe(takeUntil(this._destroyed)).subscribe(items => {
      const infraItem = items.find(i => i.path === 'infrastructure');
      this.infraAccess = infraItem !== undefined;
    });

    this.navService.setAppBarProgress(true);

    this.route.queryParams
      .pipe(takeUntil(this._destroyed), debounceTime(500))
      .subscribe(params => this.onSearchParamsChanges(params));
  }

  ngAfterViewInit() {
    this.viewInit = true;
    this.onWindowResize();

    const primaryStyle = getComputedStyle(this.primaryColorGetter.nativeElement);
    const accentStyle = getComputedStyle(this.accentColorGetter.nativeElement);
    this.currentScheme = { domain: [primaryStyle.backgroundColor, accentStyle.backgroundColor] };
    this.energyScheme = {
      selectable: true,
      group: 'Ordinal',
      domain: [primaryStyle.backgroundColor, accentStyle.backgroundColor]
    };
    this.colorScheme.domain = [primaryStyle.backgroundColor, accentStyle.backgroundColor];
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  @HostListener('window:resize')
  onWindowResize() {
    const xs = window.innerWidth < 600;
    const panelWidth = xs ? window.innerWidth - 16 : (window.innerWidth * 2) / 3;
    this.chartWidth = panelWidth - 24;

    this.changeDetectorRef.markForCheck();
  }

  trackByFn(_index: number, item: TxDisplay) {
    return item.tx.id;
  }

  onPanelOpened(expansionPanel: MatExpansionPanel, display: TxDisplay): void {
    this.scrollToPanel(expansionPanel);

    if (display.timeline) {
      return;
    }
    this.txService
      .getAggregateData(display.tx.id)
      .pipe(takeUntil(this.stopQuery))
      .subscribe(data => {
        if (data) {
          display.maxPower = data.maxPower !== undefined ? data.maxPower / 1000 : undefined;
          display.timeline = createTimeline(display.tx, data);
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  onScrolled() {
    if (this.querying || this.displays.length === this.totalCount) {
      return;
    }
    this.fetchMoreTransactions();
  }

  private onSearchParamsChanges(params: any) {
    // Unsubscribe query ongoing and tx update subscriptions
    this.stopQuery.next();
    this.querying = false;
    this.displays = [];
    this.totalCount = -1;
    this.queryParams = params;
    this.fetchMoreTransactions();
    this.getNbDocuments();
  }

  private fetchMoreTransactions() {
    if (this.querying) {
      return;
    }
    this.querying = true;
    this.navService.setAppBarProgress(this.displays.length === 0);
    this.changeDetectorRef.markForCheck();

    const queryParams = {
      ...parseQueryParams(this.queryParams),
      limit: LOADING_SIZE + '',
      skip: this.displays.length + ''
    };
    this.txService
      .getTransactions(new HttpParams({ fromObject: queryParams }))
      .pipe(takeUntil(this.stopQuery))
      .subscribe({
        next: txDto => this.onTransactionsLoaded(txDto.items),
        error: () => this.stopLoading(),
        complete: () => this.stopLoading()
      });
  }

  private getNbDocuments(): void {
    const queryParams = parseQueryParams(this.queryParams);
    this.txService
      .getNbDocuments(new HttpParams({ fromObject: queryParams }))
      .pipe(takeUntil(this.stopQuery))
      .subscribe({
        next: response => {
          this.totalCount = response;
          this.changeDetectorRef.markForCheck();
        },
        error: () => this.stopLoading(),
        complete: () => this.stopLoading()
      });
  }

  private stopLoading() {
    this.querying = false;
    this.navService.setAppBarProgress(false);
    this.changeDetectorRef.markForCheck();
  }

  private fetchTransactions(txIds: string[]) {
    const chunks = [];
    const chunkLength = 3;
    for (let i = 0; i < txIds.length; i += chunkLength) {
      chunks.push(txIds.slice(i, i + chunkLength));
    }
    chunks.forEach(idList => {
      const queryParams = { ...parseQueryParams(this.queryParams), id: idList.join(',') };
      this.txService
        .getTransactions(new HttpParams({ fromObject: queryParams }))
        .pipe(takeUntil(this.stopQuery))
        .subscribe(res => {
          const txs = res.items;
          txs
            .filter(tx => !this.displays.some(d => d.tx.id === tx.id))
            .forEach(_ => {
              this.totalCount++;
              txIds
                .filter(id => !txs.some(t => t.id === id))
                .forEach(id => this.onTransactionRequestedMissing(id));
            });
          this.onTransactionsLoaded(txs);
        });
    });
  }

  private onTransactionsLoaded(transactions: Transaction[]) {
    transactions.forEach(tx => {
      const i = this.displays.findIndex(d => d.tx.id === tx.id);
      const display = this.txService.getTransactionDisplay(tx);
      if (i > -1) {
        if (display.finished && !this.displays[i].finished) {
          // Notify tx stop to unsubscribe
          this.stopTxUpdateSub.next(tx.id);
        }
        this.displays[i] = display;
      } else {
        // Subscribe if ongoing
        this.displays.push(display);
      }
    });
    this.sortTransactions();
  }

  private onTransactionRequestedMissing(txId: string) {
    const i = this.displays.findIndex(d => d.tx.id === txId);
    if (i > 0) {
      this.stopTxUpdateSub.next(txId);
      this.displays.splice(i, 1);
      this.totalCount--;
    }
  }

  private sortTransactions() {
    this.displays.sort((a, b) => {
      if (a.tx.creationDate < b.tx.creationDate) {
        return 1;
      }
      if (a.tx.creationDate > b.tx.creationDate) {
        return -1;
      }
      return 0;
    });
    this.querying = false;
    this.navService.setAppBarProgress(false);
    this.changeDetectorRef.markForCheck();
  }

  private scrollToPanel(panel: MatExpansionPanel) {
    timer(0).subscribe(() => {
      const scrollables = this.scrollDispatcher.getAncestorScrollContainers(panel._body);
      if (scrollables && scrollables.length > 0) {
        const distance =
          (panel._body.nativeElement.offsetParent as any).offsetTop -
          scrollables[0].getElementRef().nativeElement.scrollTop;
        const config: ScrollToConfigOptions = {
          container: scrollables[0].getElementRef(),
          duration: 225,
          easing: 'easeOutCubic',
          offset: distance
        };
        this.scrollToService.scrollTo(config);
      }
    });
  }
}
