import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

export class ItemListResponse {
  selectedItemList: any[];
  isAllSelected: boolean;
}

export class ItemListResponseWithParent extends ItemListResponse {
  parent: any;
}

export class ItemCompleted {
  item: any;
  isSelected: boolean;
  isSelectable?: boolean;
}

export class ItemSelectable {
  item: any;
  isNotSelectable: boolean;
}

@Component({
  selector: 'app-item-list-check-box',
  templateUrl: './item-list-with-check-box.component.html',
  styleUrls: ['./item-list-with-check-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ItemListWithCheckBoxComponent implements OnInit, OnChanges {
  /**
   * The information that displays on the select all checkBox (example for the contract: emspCriteriaServiceGroup)
   */
  @Input() parent: any;
  /**
   * The list of checkbox to display
   */
  @Input() itemList: any[];
  /**
   * Display the checkbox parent if it is true
   */
  @Input() displayParent: boolean;
  /**
   * Select all the items of the list if is true. False per default
   */
  @Input() selectAll = false;
  /**
   * To display an attribute of the parent or an item of itemList (only useful if parent and itemList are objects AND if there are preSelectedItem). Null per default
   */
  @Input() displayAttributeName: string = null;
  /**
   * To differentiate the items of itemList between them (only useful if parent and itemList are objects)
   * Prefer using a unique attribute like id/key
   * Null per default
   * Example : this attribute is used to compare each element for preSelectedItems.
   */
  @Input() idAttributeName: string = null;
  /**
   * Hide items when parent isn't checked if it is true. False per default
   */
  @Input() hideChildrenWhenParentNotSelected = false;
  /**
   * To be able to hide the children even if there are selected ones, if it is true. False per default
   */
  @Input() possibleToHideChildrenWhenSelected = false;
  /**
   * Adds the parent to the output (selectedItemChange). False per default
   */
  @Input() outputWithParent = false;
  /**
   * Display items in grid if it is true, in list otherwise. True per default
   */
  @Input() displayItemInGrid = true;
  /**
   * The max width of the items. 40 per default
   */
  @Input() maxItemWidth = 40;
  /**
   * Items already selected. Empty list per default
   */
  @Input() preSelectedItem: ItemCompleted[] = [];
  /**
   * Display component even itemList is empty
   */
  @Input() displayEvenIfNoItems = false;
  /**
   * Disable parent checkbox
   */
  @Input() disableCheckboxs = false;

  @Output() selectedItemChange = new EventEmitter<ItemListResponse | ItemListResponseWithParent>();

  selectedItem: ItemCompleted[] = [];
  allSelected = false;
  displayChildren = false;

  ngOnInit(): void {
    this.selectedItem = [...this.itemList].map(item => ({
      item,
      isSelected: false,
      isSelectable: !item.isNotSelectable
    }));
    this.preSelectedItem.forEach(value => {
      if (this.idAttributeName) {
        const item = this.selectedItem.find(
          value1 => value1.item[this.idAttributeName] === value.item[this.idAttributeName]
        );
        if (item) {
          item.isSelected = true;
        }
      } else {
        this.selectedItem.find(value1 => value1.item === value.item).isSelected = true;
      }
    });
    this.updateAllComplete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectAll && !changes?.selectAll.firstChange) {
      this.setAll(changes.selectAll.currentValue);
    }
  }

  updateAllComplete(): void {
    if (this.disableCheckboxs) {
      this.allSelected = true;
    } else {
      this.allSelected =
        this.selectedItem.length > 0 ? this.selectedItem.every(item => item.isSelected) : false;
    }
    this.emitUpdateForSelectedItemList();
  }

  someComplete(): boolean {
    return this.selectedItem.filter(item => item.isSelected).length > 0 && !this.allSelected;
  }

  setAll(completed: boolean): void {
    if (!this.disableCheckboxs) {
      this.allSelected = completed;
      this.selectedItem.forEach(item => {
        if (item.isSelectable) {
          item.isSelected = completed;
        }
      });
      this.emitUpdateForSelectedItemList();
    }
  }

  private emitUpdateForSelectedItemList(): void {
    const selectedItem = [...this.itemList].filter(item => this.isItemCompleted(item));
    if (this.outputWithParent) {
      this.selectedItemChange.emit({
        parent: this.parent,
        selectedItemList: selectedItem,
        isAllSelected: this.allSelected
      });
    } else {
      this.selectedItemChange.emit({
        selectedItemList: selectedItem,
        isAllSelected: this.allSelected
      });
    }
  }

  private isItemCompleted(id: string): boolean {
    return this.selectedItem.find(item => item.item === id).isSelected;
  }

  displaySelectedItem(item: string): string {
    if (!this.displayAttributeName) {
      return item;
    }
    return item[this.displayAttributeName].length > this.maxItemWidth
      ? `${item[this.displayAttributeName].slice(0, this.maxItemWidth)}...`
      : item[this.displayAttributeName];
  }

  displayChildrenItems(e: any): void {
    e.preventDefault();
    this.displayChildren = !this.displayChildren;
  }

  displayChildrens(): boolean {
    if (this.possibleToHideChildrenWhenSelected) {
      return !this.hideChildrenWhenParentNotSelected || this.displayChildren;
    }
    return (
      !this.hideChildrenWhenParentNotSelected ||
      this.displayChildren ||
      this.allSelected ||
      this.someComplete()
    );
  }
}
