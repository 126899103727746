import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';

import * as bezier from 'bezier-easing';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthenticationService } from '@app/auth/auth.service';
import { AppNavigationService, ToolbarAction } from '@app/navigation';
import { UserService } from '@app/user';
import { TranslateService } from '@ngx-translate/core';
import { COMMISSIONING_LOCALE_OPTIONS } from '@app/shared/language-selector/evp-locale';
import { actionInOut, menuButtonSlide, searchBarAnim, toolbarFadeIn } from './toolbar-animations';

export const MIN_APPBAR_HEIGHT = 56;
export const MAX_APPBAR_HEIGHT = 256;
export const MAX_SCROLL_RANGE = 88;
export const MAX_FONT_SIZE = 34;
export const MIN_FONT_SIZE = 20;
export const MAX_ICON_SIZE = 72;
export const MIN_ICON_SIZE = 40;

export const EASING: bezier.EasingFunction = bezier(0.075, 0.82, 0.165, 1);

@Component({
  selector: 'app-navigation-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['toolbar.component.scss'],
  animations: [actionInOut, toolbarFadeIn, menuButtonSlide, searchBarAnim],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationToolbarComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
  @HostBinding('class.app-navigation-toolbar') toolbarClass = true;
  @HostBinding('style.zIndex') zIndex = 999;

  @Input() displayLocaleOption: boolean;
  @Input() isMenuEnabled: boolean;
  @Input() shouldDisplaySearchEngineSwitch: boolean;

  toolbarTranslate = `translateY(0)`;

  afterInit: boolean;
  searchBoxTranslate = `translateY(136px)`;
  searchBoxWidth = `50%`;

  searchBoxEnabled: boolean;
  titleTranslate = 'translate(16px, 72px)';
  titleSize = `${MAX_FONT_SIZE}px`;
  iconSize = `${MAX_ICON_SIZE}px`;
  sidenavLockedOpened: boolean;

  menuButtonShowState = 'show';
  menuButtonState = 'hamburger';

  iconActions: ToolbarAction[] = [];
  overflowActions: ToolbarAction[] = [];
  overflowActionBadge: boolean;
  isFleetTokenTab = false;
  isEvUserTab = false;
  isUserReader = false;
  isMarketplaceTab = false;
  isServiceGroup = false;
  isInStockZone = false;

  commissioningLocaleOptions = COMMISSIONING_LOCALE_OPTIONS;

  @Input() sidenavLocked: boolean;
  @Input() title: string;
  @Input() logo: string;
  @Input() canBack: boolean;

  @Output() back = new EventEmitter();
  @Output() openSidenav = new EventEmitter();
  @Output() closeSidenav = new EventEmitter();
  @ViewChild('menuButton', { static: true }) menuButton: MatButton;

  private _destroyed = new Subject();
  private scrollEase = 1;

  private actions: ToolbarAction[] = [];

  private logOutAction: ToolbarAction = {
    fontIcon: 'mdi-logout',
    name: 'appBar.Log out',
    onAction: () => this.logOut()
  };

  constructor(
    public navService: AppNavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaObserver,
    private authService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (this.navService.isUserConnected) {
      this.userService
        .getUserByKey(this.navService.userId.value.replace('user/', ''))
        .subscribe(user => {
          this.isUserReader = user.profiles.indexOf('EMSP_FUNC_SUPPORT') > -1;
        });
    }

    this.navService.sidenavLockedOpened.pipe(takeUntil(this._destroyed)).subscribe(lockedOpened => {
      this.sidenavLockedOpened = lockedOpened;
      this.updateMenuButtonState();
    });

    this.navService.appBarSearchEnabled.pipe(takeUntil(this._destroyed)).subscribe(enabled => {
      this.searchBoxEnabled = enabled;
      this.updateAppBarHeight();
    });

    this.navService.appBarAction.pipe(takeUntil(this._destroyed)).subscribe(actions => {
      this.actions = actions || [];
      this.updateToolbarActions();
    });

    this.isFleetTokenTab = this.router.url.indexOf('/token-fleet') > -1;
    this.isEvUserTab = this.router.url.indexOf('/emsp/user') > -1;
    this.isMarketplaceTab = this.router.url.indexOf('/marketplace') > -1;
    this.isServiceGroup = this.router.url.indexOf('/price-management') > -1;

    this.media
      .asObservable()
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => this.updateToolbarActions());
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isFleetTokenTab = this.router.url.indexOf('/token-fleet') > -1;
    this.isEvUserTab = this.router.url.indexOf('/emsp/user') > -1;
    this.isMarketplaceTab = this.router.url.indexOf('/marketplace') > -1;
    this.isServiceGroup = this.router.url.indexOf('/price-management') > -1;
    this.isInStockZone = this.router.url.includes('/commissioning/stock-zone');
    if (changes.canBack) {
      this.updateMenuButtonState();
    }
  }

  ngAfterViewInit() {
    this.afterInit = true;
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  onMenuButtonClicked() {
    if (this.canBack) {
      this.back.emit();
    } else if (this.isMenuEnabled) {
      this.openSidenav.emit();
    }
    if (this.menuButton) {
      this.menuButton._elementRef.nativeElement.blur();
    }
  }

  logOut() {
    this.authService.logOut();
  }

  scrolled(scrollTop: number, mediaChange?: boolean) {
    const x = scrollTop / MAX_SCROLL_RANGE;
    const y = 1 - EASING(x > 1 ? 1 : x);
    if (mediaChange || this.scrollEase !== y) {
      this.scrollEase = y;
      this.updateAppBarHeight();
    }
  }

  private updateAppBarHeight() {
    const smallScreen = this.media.isActive('lt-lg');
    const min = smallScreen && this.searchBoxEnabled ? MIN_APPBAR_HEIGHT * 2 : MIN_APPBAR_HEIGHT;
    const translateY = (min - MAX_APPBAR_HEIGHT) * (1 - this.scrollEase);
    this.zIndex = this.scrollEase < 0.4 ? 999 : 0;
    this.toolbarTranslate = `translateY(${translateY}px)`;

    const fontSize = MIN_FONT_SIZE + (MAX_FONT_SIZE - MIN_FONT_SIZE) * this.scrollEase;
    const iSize = MIN_ICON_SIZE + (MAX_ICON_SIZE - MIN_ICON_SIZE) * this.scrollEase;

    let titleTransX: number;
    if (this.menuButtonShowState === 'show') {
      titleTransX = 56 + (smallScreen ? -32 : 16) * this.scrollEase;
    } else {
      titleTransX = 16 + 48 * this.scrollEase;
    }
    const titleTransY = 8 + 64 * this.scrollEase;
    const searchBoxTop = smallScreen ? MIN_APPBAR_HEIGHT : 0;
    const searchBoxTransY = searchBoxTop + (136 - searchBoxTop) * this.scrollEase;

    this.titleSize = `${fontSize}px`;
    this.iconSize = `${iSize}px`;
    this.titleTranslate = `translate(${titleTransX}px, ${titleTransY}px)`;
    this.searchBoxTranslate = `translateY(${searchBoxTransY}px)`;
    this.changeDetectorRef.markForCheck();
  }

  private updateMenuButtonState() {
    const hidden =
      (!this.isMenuEnabled && !this.canBack) || (this.sidenavLockedOpened && !this.canBack);
    if (!hidden) {
      this.menuButtonShowState = 'show';
      this.menuButtonState = this.canBack ? 'arrow' : 'hamburger';
    } else {
      this.menuButtonShowState = 'hide';
    }
    this.updateAppBarHeight();
  }

  private updateToolbarActions() {
    this.iconActions = [];
    this.overflowActions = [];

    let iconActionSize = 0;
    if (this.media.isActive('gt-xs')) {
      iconActionSize = 1;
    } else {
      iconActionSize = 0;
    }
    for (const action of this.actions) {
      if (iconActionSize > 0) {
        iconActionSize--;
        this.iconActions.push(action);
      } else {
        this.overflowActions.push(action);
      }
    }
    this.overflowActions.push(this.logOutAction);
    this.overflowActionBadge = this.overflowActions.findIndex(a => a.badgeText !== undefined) > -1;
    this.changeDetectorRef.markForCheck();
  }
}
