import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { AuthenticationService, AuthEvent } from '../auth.service';
import { CognitoAuthenticationService } from './cognito-authentication.service';
import { AccessToken } from './models/access-token';

@Injectable()
export class CognitoAuthenticationAdapter implements AuthenticationService {
  _events = new Subject<AuthEvent>();

  tokenEndpoint = '/apigw/cognito/token';
  waitForTokenInMsec: number;

  constructor(private authService: CognitoAuthenticationService, private router: Router) {}

  authenticateUsingAuthorizationCodeFlow(
    authorizationCode: string,
    authenticationId: string
  ): Observable<void> {
    return this.authService.authenticate(authorizationCode, authenticationId).pipe(
      tap(() => this._events.next({ type: 'token_received' })),
      mapTo(undefined)
    );
  }

  fetchTokenUsingPasswordFlow(username: string, password: string): Promise<object> {
    return of(undefined).toPromise();
  }

  getAccessToken(): string {
    return this.authService.accessTokenStr;
  }

  /**
   * For some reason, every call to this function considers the result to be an array.
   * This array contains a single string that holds all the scopes separated with spaces...
   *
   * Ex: ['scope1 scope2 scope3']
   *
   * @returns scopes under the described format.
   */
  getGrantedScopes(): object {
    if (!this.authService.userScopes) {
      return [];
    }
    return [this.authService.userScopes.join(' ')];
  }

  getRefreshToken(): string {
    return '';
  }

  hasValidAccessToken(): boolean {
    return (
      this.authService.accessToken &&
      parseInt(this.authService.accessToken?.exp.toString().padEnd(13, '0'), 10) >=
        new Date().getTime()
    );
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  logOut(): void {
    this.logOutObservable().subscribe();
  }

  logOutObservable(): Observable<void> {
    return this.authService.revokeToken().pipe(
      tap(() => this.router.navigate(['login'])),
      catchError(err => throwError(err))
    );
  }

  refreshToken(): Promise<object> {
    return this.authService.refreshToken().toPromise();
  }

  refreshTokenObservable(): Observable<AccessToken> {
    return this.authService.refreshToken();
  }

  get events(): Observable<AuthEvent> {
    return this._events.asObservable();
  }
}
