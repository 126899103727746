import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { AppTranslateModule, SharedModule } from '@app/shared';
import { AlertAccordionComponent } from './accordion/accordion.component';
import { AlertRoutingModule } from './alert-routing.module';
import { AlertMainListComponent } from './main-list/main-list.component';
import { AlertBaseModule } from './base/alert-base.module';

@NgModule({
  imports: [
    SharedModule,
    AppTranslateModule.forChild(['alert']),
    AlertBaseModule,
    AlertRoutingModule
  ],
  declarations: [AlertAccordionComponent, AlertMainListComponent]
})
export class AlertModule {
  constructor(sanitizer: DomSanitizer, iconRegistry: MatIconRegistry) {
    iconRegistry.addSvgIcon(
      'bank-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_bank_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'cloud-alert-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_cloud_alert_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'electricity-alert-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_electricity_alert_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'ev-station-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_ev_station_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'ev-station-alert',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_ev_station_alert.svg')
    );
    iconRegistry.addSvgIcon(
      'power-off-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_power_off_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'freshservice-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_freshservice.svg')
    );
    iconRegistry.addSvgIcon(
      'jira-bug-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_jira_bug.svg')
    );
    iconRegistry.addSvgIcon(
      'acknowledged-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_acknowledged.svg')
    );
    iconRegistry.addSvgIcon(
      'acknowledge-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_acknowledge.svg')
    );
    iconRegistry.addSvgIcon(
      'delete-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_delete_icon.svg')
    );
  }
}
