import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { AppTranslateModule, SharedModule } from '@app/shared';
import { WidgetModule } from '@app/widget';
import { CreditCardReceiptDialogComponent } from './credit-card-receipt/credit-card-receipt-dialog.component';
import { TxAccordionComponent } from './accordion/accordion.component';
import { TxMainListComponent } from './main-list/main-list.component';
import { TxDisplayContentComponent } from './tx-display-content/tx-display-content.component';
import { TxDisplayHeaderComponent } from './tx-display-header/tx-display-header.component';
import { TxRoutingModule } from './tx-routing.module';
import { TxActionsComponent } from './tx-actions/tx-actions.component';

@NgModule({
  imports: [
    SharedModule,
    AppTranslateModule.forChild(['transaction']),
    WidgetModule,
    TxRoutingModule
  ],
  declarations: [
    TxAccordionComponent,
    TxDisplayContentComponent,
    TxDisplayHeaderComponent,
    TxMainListComponent,
    CreditCardReceiptDialogComponent,
    TxActionsComponent
  ],
  exports: [TxAccordionComponent, TxDisplayHeaderComponent],
  entryComponents: [CreditCardReceiptDialogComponent]
})
export class TransactionModule {
  constructor(sanitizer: DomSanitizer, iconRegistry: MatIconRegistry) {
    iconRegistry.addSvgIcon(
      'battery-charging-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_battery_charging_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'clipboard-alert-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_clipboard_alert_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'emi3',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_emi3.svg')
    );
    iconRegistry.addSvgIcon(
      'ev-station-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_ev_station_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'plug-chademo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_plug_chademo.svg')
    );
    iconRegistry.addSvgIcon(
      'plug-combo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_plug_combo.svg')
    );
    iconRegistry.addSvgIcon(
      'plug-ef',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_plug_ef.svg')
    );
    iconRegistry.addSvgIcon(
      'plug-t2-t3',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_plug_t2_t3.svg')
    );
    iconRegistry.addSvgIcon(
      'plug-t2-ef',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_plug_t2_ef.svg')
    );
    iconRegistry.addSvgIcon(
      'plug-t3-ef',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_plug_t3_ef.svg')
    );
    iconRegistry.addSvgIcon(
      'plug-type-1',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_plug_type_1.svg')
    );
    iconRegistry.addSvgIcon(
      'plug-type-2',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_plug_type_2.svg')
    );
    iconRegistry.addSvgIcon(
      'plug-type-3',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_plug_type_3.svg')
    );
    iconRegistry.addSvgIcon(
      'receipt-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic-receipt-outline.svg')
    );
    iconRegistry.addSvgIcon(
      'receipt-long',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_receipt_long.svg')
    );
    iconRegistry.addSvgIcon(
      'remote-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_remote_outline.svg')
    );
  }
}
