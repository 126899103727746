import { Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { resultCountAnim } from './result-count-animations';

@Component({
  selector: 'app-result-count',
  templateUrl: 'result-count.component.html',
  styleUrls: ['result-count.component.scss'],
  animations: [resultCountAnim]
})
export class ResultCountComponent {
  @Input() resultCount: number;

  constructor(public translate: TranslateService) {}
}
