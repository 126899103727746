import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppNavigationService } from '../navigation.service';
import { fab } from './fab-animations';

export interface FabAction {
  fontIcon?: string;
  svgIcon?: string;
  name: string;
  floatText?: string;
  onAction: () => void;
}

@Component({
  selector: 'app-navigation-fab',
  templateUrl: 'fab.component.html',
  styleUrls: ['fab.component.scss'],
  animations: [fab],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationFabComponent implements OnDestroy, OnInit {
  @HostBinding('class.drawer-opened') drawerOpened = false;

  @Input() fabAction: FabAction;

  fabState = 'appBar';
  translateY = 'translateY(0)';

  private _destroyed = new Subject();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaObserver,
    private navService: AppNavigationService
  ) {}

  ngOnInit() {
    this.media
      .asObservable()
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => this.updatePosition());

    this.navService.sidenavLockedOpened.pipe(takeUntil(this._destroyed)).subscribe(opened => {
      this.drawerOpened = opened;
      this.updatePosition();
    });

    this.updatePosition();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  setTranslateY(translateY: string) {
    this.translateY = translateY;
    this.updatePosition();
    this.changeDetectorRef.detectChanges();
  }

  private updatePosition() {
    if (this.media.isActive('gt-md')) {
      this.fabState = 'appBar';
    } else {
      this.fabState = 'float';
    }
    this.changeDetectorRef.markForCheck();
  }
}
