import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataDialog, ALERT_TYPE, AlertType } from './notification';
import { allFormValidator, customValidatorChannel } from './notification-validators';
import { User } from '@app/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-infra-notification-dialog-component',
  templateUrl: 'notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {
  public types = ALERT_TYPE;
  public currentType: AlertType;
  public myForm: FormGroup;
  public currentUser: User;
  private number: number;

  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DataDialog,
    private fb: FormBuilder
  ) {
    this.number = 0;
  }

  ngOnInit() {
    this.currentUser = this.data.user;
    this.myForm = this.fb.group({
      subscriptions: this.fb.array([])
    });
    this.myForm.setValidators(allFormValidator(this.myForm));
    if (this.data && this.data.model) {
      for (const i of this.data.model.subscriptions) {
        const tmp = this.myForm.get('subscriptions') as FormArray;
        tmp.push(this.initSubscription(this.number++));
      }
      this.myForm.patchValue(this.data.model);
    }
  }

  triggerAlertListener(newTypeValue: AlertType) {
    this.currentType = newTypeValue;
  }

  initEmails(indexSubscriptions: number) {
    const controls = new FormArray([]);
    if (
      !this.data.model.subscriptions[indexSubscriptions] ||
      !this.data.model.subscriptions[indexSubscriptions].channel
    ) {
      this.data.model.subscriptions[indexSubscriptions] = {
        id: null,
        type: null,
        trigger: { event: null, severity: null },
        channel: {
          emails: [
            {
              adresse: this.currentUser.email,
              language: this.currentUser.preferences.language
            }
          ],
          sms: []
        }
      };
    }
    if (this.data.model.subscriptions[indexSubscriptions].channel.emails) {
      if (this.data.model.subscriptions[indexSubscriptions].channel.emails.length > 0) {
        for (const [i, v] of this.data.model.subscriptions[
          indexSubscriptions
        ].channel.emails.entries()) {
          controls.push(
            this.fb.group({
              adresse: new FormControl(
                this.data.model.subscriptions[indexSubscriptions].channel.emails[i].adresse,
                [Validators.required, Validators.email]
              ),
              language: new FormControl(
                this.data.model.subscriptions[indexSubscriptions].channel.emails[i].language,
                [Validators.required, Validators.minLength(1)]
              )
            })
          );
        }
      } else {
        controls.push(
          this.fb.group({
            adresse: new FormControl(this.currentUser.email, [
              Validators.required,
              Validators.email
            ]),
            language: new FormControl(this.currentUser.preferences.language, [
              Validators.required,
              Validators.minLength(1)
            ])
          })
        );
      }
    }
    return controls;
  }

  initSms(indexSubscriptions: number) {
    const controls = new FormArray([]);
    if (
      !this.data.model.subscriptions[indexSubscriptions] ||
      !this.data.model.subscriptions[indexSubscriptions].channel
    ) {
      this.data.model.subscriptions[indexSubscriptions] = {
        id: null,
        type: null,
        trigger: { event: null, severity: null },
        channel: { emails: [], sms: [] }
      };
    }
    if (this.data.model.subscriptions[indexSubscriptions].channel.sms) {
      if (this.data.model.subscriptions[indexSubscriptions].channel.sms.length > 0) {
        for (const [i, v] of this.data.model.subscriptions[
          indexSubscriptions
        ].channel.sms.entries()) {
          controls.push(
            this.fb.group({
              phone: new FormControl(
                this.data.model.subscriptions[indexSubscriptions].channel.sms[i].phone,
                [Validators.required, Validators.minLength(1)]
              ),
              language: new FormControl(
                this.data.model.subscriptions[indexSubscriptions].channel.sms[i].language,
                [Validators.required, Validators.minLength(1)]
              )
            })
          );
        }
      }
    }
    return controls;
  }

  initSubscription(indexSubscriptions: number) {
    const subscription = this.data.model?.subscriptions[indexSubscriptions];
    return this.fb.group(
      {
        id: subscription?.id ? subscription.id : uuidv4(),
        type: new FormControl(subscription?.type ? subscription.type : null, {
          validators: [Validators.required]
        }),
        trigger: this.fb.group({
          event: new FormControl(subscription?.trigger?.event ? subscription.trigger.event : null, {
            validators: [Validators.required]
          }),
          severity: new FormControl(
            subscription?.trigger?.severity ? subscription.trigger.severity : null,
            { validators: [Validators.required] }
          )
        }),
        channel: this.fb.group(
          {
            emails: this.initEmails(indexSubscriptions),
            sms: this.initSms(indexSubscriptions)
          },
          { validators: customValidatorChannel() }
        )
      },
      Validators.required
    );
  }

  getEmailsControl(indexSubscriptions: number) {
    return ((this.myForm.get('subscriptions') as FormArray).controls[indexSubscriptions].get(
      'channel.emails'
    ) as FormArray).controls;
  }

  getSmsControl(indexSubscriptions: number) {
    return ((this.myForm.get('subscriptions') as FormArray).controls[indexSubscriptions].get(
      'channel.sms'
    ) as FormArray).controls;
  }

  getChannelControl(indexSubscriptions: number) {
    return ((this.myForm.get('subscriptions') as FormArray).controls[indexSubscriptions].get(
      'channel'
    ) as FormArray).controls;
  }

  getSubscriptions() {
    return this.myForm.get('subscriptions') as FormArray;
  }

  getType(indexSubscriptions: number) {
    return (this.myForm.get('subscriptions') as FormArray).controls[indexSubscriptions].get(
      'type'
    ) as FormControl;
  }

  getTrigger(indexSubscriptions: number) {
    return (this.myForm.get('subscriptions') as FormArray).controls[indexSubscriptions].get(
      'trigger'
    ) as FormGroup;
  }

  getChannel(indexSubscriptions: number) {
    return (this.myForm.get('subscriptions') as FormArray).controls[indexSubscriptions].get(
      'channel'
    ) as FormGroup;
  }

  getEmails(indexSubscriptions: number) {
    return (this.myForm.get('subscriptions') as FormArray).controls[indexSubscriptions].get(
      'channel.emails'
    ) as FormArray;
  }

  getSms(indexSubscriptions: number) {
    return (this.myForm.get('subscriptions') as FormArray).controls[indexSubscriptions].get(
      'channel.sms'
    ) as FormArray;
  }

  addEmails(index: number) {
    let bAddEmails = true;
    const controls = this.getEmailsControl(index);
    if (
      controls.length !== 0 &&
      (controls[controls.length - 1].get('adresse').invalid ||
        controls[controls.length - 1].get('language').invalid)
    ) {
      bAddEmails = false;
    }
    if (bAddEmails) {
      if (this.data.model.subscriptions[index].channel.emails) {
        this.data.model.subscriptions[index].channel.emails[
          this.data.model.subscriptions[index].channel.emails.length
        ] = {
          adresse: this.currentUser.email,
          language: this.currentUser.preferences.language
        };
      } else {
        this.data.model.subscriptions[index].channel.emails = [
          {
            adresse: this.currentUser.email,
            language: this.currentUser.preferences.language
          }
        ];
      }
      controls.push(
        this.fb.group({
          adresse: new FormControl(this.currentUser.email, [Validators.required, Validators.email]),
          language: new FormControl(this.currentUser.preferences.language, [
            Validators.required,
            Validators.minLength(1)
          ])
        })
      );
      controls[controls.length - 1].setParent(this.getEmails(index));
      this.getChannel(index).updateValueAndValidity();
    }
  }

  addSms(index: number) {
    let bAddSms = true;
    const controls = this.getSmsControl(index);
    if (
      controls.length !== 0 &&
      (controls[controls.length - 1].get('phone').invalid ||
        controls[controls.length - 1].get('language').invalid)
    ) {
      bAddSms = false;
    }
    if (bAddSms) {
      if (this.data.model.subscriptions[index].channel.sms) {
        this.data.model.subscriptions[index].channel.sms[
          this.data.model.subscriptions[index].channel.sms.length
        ] = {
          phone: null,
          language: this.currentUser.preferences.language
        };
      } else {
        this.data.model.subscriptions[index].channel.sms = [
          {
            phone: null,
            language: this.currentUser.preferences.language
          }
        ];
      }
      controls.push(
        this.fb.group({
          phone: new FormControl(null, [Validators.required, Validators.minLength(1)]),
          language: new FormControl(null, [Validators.required, Validators.minLength(1)])
        })
      );
      controls[controls.length - 1].setParent(this.getSms(index));
      this.getChannel(index).updateValueAndValidity();
    }
  }

  addSubscriptions() {
    const controls = this.getSubscriptions();
    controls.push(this.initSubscription(this.number++));
  }

  removeEmails(indexSubscriptions: number, indexEmails: number) {
    const controls = this.getEmails(indexSubscriptions);
    controls.removeAt(indexEmails);
    this.data.model.subscriptions[indexSubscriptions].channel.emails.splice(indexEmails, 1);
    this.getChannel(indexSubscriptions).updateValueAndValidity();
  }

  removeSms(indexSubscriptions: number, indexSms: number) {
    const controls = this.getSms(indexSubscriptions);
    controls.removeAt(indexSms);
    this.data.model.subscriptions[indexSubscriptions].channel.sms.splice(indexSms, 1);
    this.getChannel(indexSubscriptions).updateValueAndValidity();
  }

  removeSubscription(indexSubscriptions: number) {
    this.data.unsubscribeList.push(this.data.model.subscriptions[indexSubscriptions].id);
    const controls = this.getSubscriptions();
    this.number--;
    controls.removeAt(indexSubscriptions);
    this.data.model.subscriptions.splice(indexSubscriptions, 1);
  }

  onCancel() {
    this.data.canceled = true;
    this.dialogRef.close({ data: this.data });
  }

  onSubmit() {
    if (!this.myForm.invalid) {
      this.data.canceled = false;
      this.data.model = this.myForm.getRawValue();
      this.dialogRef.close({ data: this.data });
    }
  }
}
