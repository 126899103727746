import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  SimpleChange,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment-timezone';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FilterAutocompleteOption, FilterOption } from '@app/shared';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetCpoLocationFilterComponent } from '../cpo-location-filter/cpo-location-filter.component';
import { DatavizChartTimeRange } from './chart-time-range';
import { Moment } from 'moment-timezone';

@Component({
  selector: 'app-base-chart',
  template: ''
})
export class BaseChartComponent extends BaseWidgetComponent implements AfterViewInit {
  @Input() cpoGroupOptions: FilterAutocompleteOption[];
  @Input() cpoOptions: FilterAutocompleteOption[];
  @Input() locationOptions: FilterAutocompleteOption[];

  timeRangeOptions = ['Previous month', 'The month before last', 'Previous trimester'];
  sampleRateOptions = ['days', 'weeks', 'months'];

  view = [0, 0];
  pieRadius = 0;
  chartMarginTop = 20;
  chartMarginBottom = 0;
  largeEnoughForStat: boolean;
  dense: boolean;
  customDateRangeInput = this.getLastWeekRange();

  protected stopQuerying = new Subject();
  @ViewChild('chartContainer', { static: false }) protected chartContainer: ElementRef;

  constructor(
    protected elementRef: ElementRef,
    protected changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    super(changeDetectorRef);
  }

  ngAfterViewInit() {
    timer(0).subscribe(() => this.updateChartDimension());
  }

  updateChartDimension() {
    if (this.chartContainer) {
      const width = this.chartContainer.nativeElement.offsetWidth;
      const height = this.elementRef.nativeElement.offsetHeight;
      this.dense = width < 350;
      const largeEnoughForStat = width >= 240 && height > 120;
      if (largeEnoughForStat !== this.largeEnoughForStat) {
        this.largeEnoughForStat = largeEnoughForStat;
        this.changeDetectorRef.markForCheck();
        timer(500).subscribe(() => this.updateChartDimension());
      } else {
        this.view = [
          this.chartContainer.nativeElement.offsetWidth,
          this.chartContainer.nativeElement.offsetHeight -
            this.chartMarginTop -
            this.chartMarginBottom
        ];
        this.pieRadius = Math.min(this.view[0], this.view[1]) / 4;
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.onParentResize();
  }

  onParentResize() {
    timer(500).subscribe(() => this.updateChartDimension());
  }

  setTimeRange(timeRangeOption: string | DatavizChartTimeRange | undefined): void {
    const option = Object.assign({}, this.option || {}, { timeRange: timeRangeOption });
    const change = new SimpleChange(this.option, option, false);
    this.option = option;
    this.ngOnChanges({ option: change });
  }

  setCustomTimeRange(endDate: Moment): void {
    if (!endDate) {
      return;
    }
    this.customDateRangeInput.end = endDate;
    this.setTimeRange({
      start: this.customDateRangeInput.start.startOf('days'),
      end: this.customDateRangeInput.end.endOf('days')
    });
  }

  openFilter(displayServiceStatus?: boolean, disableForCpoTab?: boolean) {
    this.dialog
      .open(WidgetCpoLocationFilterComponent, {
        data: {
          appliedFilters: this.option ? this.option.filter : undefined,
          cpoOptions: this.cpoOptions,
          cpoGroupOptions: this.cpoGroupOptions,
          locationOptions: this.locationOptions,
          disableForCpoTab,
          displayServiceStatus
        },
        disableClose: true,
        width: '40%'
      })
      .afterClosed()
      .pipe(takeUntil(this._destroyed))
      .subscribe(filters => {
        if (filters) {
          this.setFilter(filters);
        }
      });
  }

  setFilter(filters: FilterOption[]) {
    const option = Object.assign({}, this.option || {}, { filter: filters });
    const change = new SimpleChange(this.option, option, false);
    this.option = option;
    this.ngOnChanges({ option: change });
  }

  setSampleRate(sampleOption: moment.unitOfTime.Base) {
    const option = Object.assign({}, this.option || {}, { sampleRate: sampleOption });
    const change = new SimpleChange(this.option, option, false);
    this.option = option;
    this.ngOnChanges({ option: change });
  }

  parseSampleRateOption(): moment.unitOfTime.Base {
    if (!this.option || !this.option.sampleRate) {
      return 'days';
    }
    return this.option.sampleRate;
  }

  parseTimeRangeOption(): DatavizChartTimeRange {
    if (this.option && this.option.timeRange) {
      if (this.option.timeRange.start) {
        if (!moment.isMoment(this.option.timeRange.start)) {
          this.option.timeRange.start = moment(this.option.timeRange.start);
          this.option.timeRange.end = moment(this.option.timeRange.end);
        }
        return this.option.timeRange;
      }
      switch (this.option.timeRange) {
        case 'Previous month':
          return {
            start: moment()
              .subtract(1, 'months')
              .startOf('months'),
            end: moment()
              .subtract(1, 'months')
              .endOf('months')
          };
        case 'The month before last':
          return {
            start: moment()
              .subtract(2, 'months')
              .startOf('months'),
            end: moment()
              .subtract(2, 'months')
              .endOf('months')
          };
        case 'Previous trimester':
          return {
            start: moment()
              .subtract(1, 'quarter')
              .startOf('quarter'),
            end: moment()
              .subtract(1, 'quarter')
              .endOf('quarter')
          };
      }
    }
    switch (this.parseSampleRateOption()) {
      case 'weeks':
        return {
          start: moment()
            .subtract(12, 'weeks')
            .startOf('weeks'),
          end: moment().endOf('weeks')
        };
      case 'months':
        return {
          start: moment()
            .subtract(6, 'months')
            .startOf('months'),
          end: moment().endOf('months')
        };
      default:
        return {
          start: moment()
            .subtract(1, 'weeks')
            .startOf('days'),
          end: moment().endOf('days')
        };
    }
  }

  parseFilters(param: string): string[] {
    if (this.option && this.option.filter) {
      return (this.option.filter as FilterOption[])
        .filter(f => f.param === param)
        .map(f => f.value.value);
    }
    return [];
  }

  private getLastWeekRange(): DatavizChartTimeRange {
    return {
      start: moment()
        .subtract(1, 'weeks')
        .startOf('days'),
      end: moment().endOf('days')
    };
  }
}
