import { Observable } from 'rxjs';
import { AccessToken } from './cognito-authentication/models/access-token';

export class AuthEvent {
  type: 'token_received' = 'token_received';
}

/**
 * Adapter interface to work with both angular-oauth2-oidc and cognito
 */
export abstract class AuthenticationService {
  abstract events: Observable<AuthEvent>;
  abstract tokenEndpoint: string;
  /**
   * Used in DefaultOAuthInterceptor
   */
  abstract waitForTokenInMsec: number;
  abstract authenticateUsingAuthorizationCodeFlow(
    authorizationCode: string,
    authenticationId: string
  ): Observable<void>;
  abstract fetchTokenUsingPasswordFlow(username: string, password: string): Promise<object>;
  abstract getAccessToken(): string;
  abstract getGrantedScopes(): object;
  abstract getRefreshToken(): string;
  abstract hasValidAccessToken(): boolean;
  abstract isLoggedIn(): boolean;
  abstract logOut(): void;
  abstract logOutObservable(): Observable<void>;
  abstract refreshToken(): Promise<object>;
  abstract refreshTokenObservable(): Observable<AccessToken>;
}
