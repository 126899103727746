import * as moment from 'moment';

import { Transaction } from './transaction';
import { TxAggregateData } from './tx-aggregate-data';

export interface TxTimelinePlot {
  time: string;
  currentIntensity: number;
  energy: number;
}

export interface TxTimeline {
  evseId: string;
  transactionId: string;
  timeline: TxTimelinePlot[];
}

export function createTimeline(tx: Transaction, data: TxAggregateData): TxTimeline {
  if (!data) {
    return { evseId: tx.evseId, transactionId: tx.id, timeline: [] };
  }
  const start = data.energyData.length > 0 ? data.energyData[0].index : 0;
  const timeline: TxTimelinePlot[] = data.energyData.map(d => {
    return {
      time: moment(d.date).toISOString(),
      currentIntensity: d.current,
      energy: d.index - start
    };
  });
  return { evseId: tx.evseId, transactionId: tx.id, timeline };
}
