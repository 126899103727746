import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppNavigationService } from '@app/navigation/navigation.service';
import { EventRecord, EventRecordType } from '../event-record';
import { EventRecordService } from '../event-record.service';
import { EventRecordHistoryFilter } from './filter/filter';
import { parseQueryParams } from '@app/shared';
import { Subject, timer } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { line } from 'd3-shape';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const SEARCH_BOX_PLACE_HOLDER = 'infra.eventRecord.Search by ...';

@Component({
  selector: 'app-infra-event-record-history-details-component',
  templateUrl: 'history-details.component.html',
  styleUrls: ['./history-details.component.scss']
})
export class HistoryDetailsComponent implements OnDestroy, OnInit {
  private _destroyed = new Subject();

  private id: string;
  private type: string;
  private filter: EventRecordHistoryFilter;
  private resultSize: number;
  private DEFAULT_LIMIT_SIZE = 50;

  private queryParams: any;
  hasData: boolean;
  historyDisplay: EventRecord[];
  @ViewChild(MatTable, { static: false }) private historyTable: MatTable<EventRecord>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventRecordService: EventRecordService,
    private changeDetector: ChangeDetectorRef,
    private ngZone: NgZone,
    private navService: AppNavigationService,
    public translate: TranslateService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry
  ) {
    this.router.url.startsWith('/infrastructure/location')
      ? (this.type = 'location')
      : (this.type = 'evse');
    this.filter = new EventRecordHistoryFilter(ngZone, navService, this.type);
    iconRegistry.addSvgIcon(
      'authorize',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/timeline/authorize.svg')
    );
    iconRegistry.addSvgIcon(
      'boot-notification',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/timeline/boot-notification.svg')
    );
    iconRegistry.addSvgIcon(
      'transaction-start',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/timeline/transaction-start.svg')
    );
    iconRegistry.addSvgIcon(
      'transaction-stop',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/timeline/transaction-stop.svg')
    );
    iconRegistry.addSvgIcon(
      'unexpected-behavior',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/timeline/unexpected-behavior.svg')
    );
    iconRegistry.addSvgIcon(
      'user-failure',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/timeline/user-failure.svg')
    );
    iconRegistry.addSvgIcon(
      'update-firmware-task-request',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/timeline/update-firmware-task-request.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'update-firmware-task-status',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/timeline/update-firmware-task-status.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'update-firmware',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/timeline/update-firmware.svg')
    );
    iconRegistry.addSvgIcon(
      'bank-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_bank_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'cloud-alert-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_cloud_alert_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'electricity-alert-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_electricity_alert_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'ev-station-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_ev_station_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'ev-station-alert',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_ev_station_alert.svg')
    );
    iconRegistry.addSvgIcon(
      'power-off-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_power_off_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'freshservice-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_freshservice.svg')
    );
    iconRegistry.addSvgIcon(
      'jira-bug-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_jira_bug.svg')
    );
    iconRegistry.addSvgIcon(
      'acknowledged-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_acknowledged.svg')
    );
    iconRegistry.addSvgIcon(
      'acknowledge-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_acknowledge.svg')
    );
    iconRegistry.addSvgIcon(
      'delete-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_delete_icon.svg')
    );
  }

  ngOnDestroy() {
    this.navService.setAppBarSearchEnabled(false);
    this.navService.setAppBarSearchFilter(undefined);
    this._destroyed.next();
    this._destroyed.complete();
  }

  ngOnInit() {
    this.navService.setAppBarSearchEnabled(true);
    this.navService.setAppBarSearchPlaceHolder(SEARCH_BOX_PLACE_HOLDER);
    this.navService.setAppBarSearchFilter(this.filter);
    if (this.route.queryParams)
      this.route.queryParams.pipe().subscribe(params => {
        this.queryParams = params;
      });
    if (this.route.url) {
      this.route.url.subscribe(value => {
        if (Array.isArray(value)) {
          if (value.length > 2) {
            this.type = value[0].path;
            this.id = value[1].path;
          }
        }
      });
    }
    timer(1000)
      .pipe(takeUntil(this._destroyed))
      .subscribe(() =>
        this.route.queryParams
          .pipe(takeUntil(this._destroyed), debounceTime(500))
          .subscribe(params => this.fetchEventRecords(params, true))
      );
  }

  fetchEventRecords(queryParams: Params, updateResultSize: boolean) {
    this.navService.setAppBarProgress(true);
    let params = Object.assign({}, parseQueryParams(queryParams));
    params = Object.assign(
      params,
      this.type === 'location' ? { locationId: this.id } : { evseId: this.id }
    );
    if (!params.limit) {
      if (this.paginator) {
        params.limit = this.paginator.pageSize;
      } else {
        params.limit = this.DEFAULT_LIMIT_SIZE;
      }
    }
    this.eventRecordService.getEventRecords(params).subscribe(response => {
      if (response) {
        this.historyDisplay = response.body;
        console.log(this.historyDisplay);
        this.changeDetector.markForCheck();
        if (this.historyDisplay && this.historyDisplay.length > 0) {
          this.hasData = true;
          if (updateResultSize) {
            this.resultSize = response.count;
          }
        } else {
          this.hasData = false;
        }
      }
      this.navService.setAppBarProgress(false);
    });
  }

  handlePaginatorEvent(event: PageEvent) {
    const params = {
      skip: event.pageIndex * event.pageSize,
      limit: event.pageSize
    };
    this.fetchEventRecords(params, false);
  }

  openAlertLink(alertId: string) {
    this.router.navigateByUrl(`/alert?id=${alertId}`);
  }

  openTransactionLink(transactionId: string) {
    this.router.navigateByUrl(`/transaction?uuid=${transactionId}`);
  }

  openTaskLink(taskId: string) {
    this.router.navigateByUrl(`/task?id=${taskId}`);
  }

  currentRowIsTheSubtaskTarget(substaskTarget: any, evseId: string) {
    return substaskTarget === 'evse/'.concat(evseId);
  }

  private getAvatarColor(event: EventRecord): string {
    let color: string;
    if (event.additionalInfo?.severity) {
      switch (event.additionalInfo.severity) {
        case 'Signal':
          color = 'signal-severity';
          break;
        case 'Info':
          color = 'info-severity';
          break;
        case 'Warning':
          color = 'warning-severity';
          break;
        case 'Critical':
          color = 'critical-severity';
          break;
      }
    } else if (event.additionalInfo?.currentStatus) {
      switch (event.additionalInfo.currentStatus) {
        case 'Downloading':
        case 'Installing':
        case 'Downloaded':
          color = 'installing-severity';
          break;
        case 'Installed':
          color = 'installed-severity';
          break;
        case 'DownloadFailed':
        case 'InstallationFailed':
          color = 'critical-severity';
          break;
      }
    } else {
      color = 'signal-severity';
    }
    return color;
  }

  getAvatarFontIcon(event: EventRecord): string {
    let fontIcon: string;
    switch (event.type) {
      case EventRecordType.CHARGINGUNIT_COMMUNICATION_PROBLEM:
      case EventRecordType.CHARGINGUNIT_COMMUNICATION_PROBLEM_FINISH:
        fontIcon = 'mdi-link-off';
        break;
      case EventRecordType.STATUS_NOTIFICATION:
        if (event.additionalInfo?.errorCode) {
          fontIcon = this.getFontIconFromErrorCode(event.additionalInfo.errorCode);
        } else {
          fontIcon = null;
          break;
        }
        break;
      default:
        fontIcon = null;
    }
    return fontIcon;
  }

  private getFontIconFromErrorCode(errorCode: string): string {
    let fontIcon: string;
    switch (errorCode) {
      case 'IllegalParking':
      case 'SensorParking':
        fontIcon = 'mdi-alpha-p-circle-outline';
        break;
      case 'ConnectorLockFailure':
        fontIcon = 'mdi-lock-outline';
        break;
      case 'DoorNotClosed':
        fontIcon = 'mdi-door-open';
        break;
      case 'ReaderFailure':
        fontIcon = 'mdi-nfc-variant';
        break;
      default:
        fontIcon = null;
        break;
    }
    return fontIcon;
  }

  private getSvgIconFromErrorCode(errorCode: string): string {
    let svgIcon: string;
    switch (errorCode) {
      case 'PowerOutage':
        svgIcon = 'power-off-outline';
        break;
      case 'GroundFailure':
      case 'OverCurrentFailure':
      case 'PowerSwitchFailure':
      case 'UnderVoltage':
        svgIcon = 'electricity-alert-outline';
        break;
      default:
        svgIcon = 'ev-station-alert';
        break;
    }
    return svgIcon;
  }

  public getAvatarSvgIcon(event: EventRecord): string {
    let svgIcon: string;
    switch (event.type) {
      case EventRecordType.STATUS_NOTIFICATION:
        if (event.additionalInfo?.errorCode) {
          svgIcon = this.getSvgIconFromErrorCode(event.additionalInfo.errorCode);
        } else {
          svgIcon = 'ev-station-alert';
          break;
        }
        break;
      case EventRecordType.EVSE_UNEXPECTED_BEHAVIOR:
        svgIcon = 'unexpected-behavior';
        break;
      case EventRecordType.USER_EXCEPTIONAL_FAILURE:
      case EventRecordType.USER_RECURRING_FAILURE:
        svgIcon = 'user-failure';
        break;
      case EventRecordType.AUTHORIZATION:
        svgIcon = 'authorize';
        break;
      case EventRecordType.TRANSACTION_STARTED:
        svgIcon = 'transaction-start';
        break;
      case EventRecordType.TRANSACTION_STOPPED:
        svgIcon = 'transaction-stop';
        break;
      case EventRecordType.BOOT_NOTIFICATION:
        svgIcon = 'boot-notification';
        break;
      case EventRecordType.UPDATE_FIRMWARE_TASK_REQUESTED:
        svgIcon = 'update-firmware-task-request';
        break;
      case EventRecordType.UPDATE_FIRMWARE_TASK_STATUS:
        svgIcon = 'update-firmware-task-status';
        break;
      case EventRecordType.UPDATE_FIRMWARE:
        svgIcon = 'update-firmware';
        break;
      default:
        svgIcon = 'ev-station-alert';
        break;
    }
    return svgIcon;
  }

  getLineColor(event: EventRecord, index: number): string {
    const defaultColor = 'signal';
    let lineColor = defaultColor;
    if (event?.additionalInfo?.currentStatus) {
      lineColor = event.additionalInfo.currentStatus.toLowerCase();
    } else if (event?.additionalInfo?.severity) {
      lineColor = event.additionalInfo.severity.toLowerCase();
    }
    if (this.historyDisplay && this.historyDisplay.length > index) {
      if (this.historyDisplay[index + 1]?.additionalInfo?.currentStatus) {
        lineColor = this.historyDisplay[index + 1].additionalInfo.currentStatus
          .toLowerCase()
          .concat('-')
          .concat(lineColor);
      } else if (this.historyDisplay[index + 1]?.additionalInfo?.severity) {
        lineColor = this.historyDisplay[index + 1].additionalInfo.severity
          .toLowerCase()
          .concat('-')
          .concat(lineColor);
      } else {
        lineColor = defaultColor.concat('-').concat(lineColor);
      }
    }
    return lineColor;
  }
}
