import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NetworkDisplayApi } from '../../network.service';
@Component({
  selector: 'app-network-display-content',
  templateUrl: 'network-display-content.component.html',
  styleUrls: ['network-display-content.component.scss']
})
export class NetworkDisplayContentComponent {
  @Input() display: NetworkDisplayApi;

  constructor(public translate: TranslateService) {}
}
