import { AppNavigationService } from '@app/navigation';
import { FilterOption } from '@app/shared';
import { UserService } from '@app/user';
import { UserGroupFilter } from '@app/user/filter/user-group-filter';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../environments/config.service';

export const FEATURE_OPTIONS: FilterOption[] = [
  { name: 'user.filter.User without group', param: 'noGroup' },
  { name: 'user.filter.User without profile', param: 'noProfile' },
  { name: 'user.filter.Active', param: 'active' },
  { name: 'user.filter.Inactive', param: 'inactive' }
];

export class UserFilter extends UserGroupFilter {
  features: FilterOption[] = FEATURE_OPTIONS.map(f => Object.assign({}, f));

  constructor(
    protected navService: AppNavigationService,
    protected userService: UserService,
    protected translate: TranslateService,
    protected configService: ConfigService
  ) {
    super(navService, userService, translate, configService);
  }

  onFeatureChipClick(filter: FilterOption): void {
    filter.value = filter.value ? undefined : true;
    if (filter.param === 'noGroup' && filter.value) {
      this.removeInputFilterByParam('group');
    }
    if (filter.param === 'noProfile' && filter.value) {
      this.removeInputFilterByParam('profile');
    }
    if (filter.param === 'active' && filter.value) {
      this.removeFeatureFilterByParam('inactive');
    }
    if (filter.param === 'inactive' && filter.value) {
      this.removeFeatureFilterByParam('active');
    }
  }

  applyFeatureFilter(feature: FilterOption, params: any): void {
    switch (feature.param) {
      case 'active':
        params.accountStatus = 'active';
        break;
      case 'inactive':
        params.accountStatus = 'inactive';
        break;
      default:
        params[feature.param] = feature.value;
    }
  }

  parseFeatureParam(key: string, value: any): string {
    if (key === 'noGroup' && value === 'true') {
      return 'noGroup';
    }
    if (key === 'noProfile' && value === 'true') {
      return 'noProfile';
    }
    if (key === 'accountStatus') {
      if (value === 'active') {
        return 'active';
      } else {
        return 'inactive';
      }
    }
    return undefined;
  }
}
