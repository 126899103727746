import { PaymentType } from '../purchase-order';

export interface MangopayUser {
  userId: string;
  walletId: string;
  paymentMeans: PaymentMean[];
}

export interface FiservUser {
  pspProvider: string;
  paymentMeans: PaymentMean[];
}

export interface PaymentEvUser {
  mangopay: MangopayUser;
  fiserv: FiservUser;
}

export interface PaymentMean {
  defaultMean: boolean;
  type: PaymentType;
  cardId: string;
  mandateId: string;
  expirationDate?: string;
  recurrence?: PaymentRecurrence;
  validity?: string;
  alias?: string;
  creationDate?: string;
}
export interface PaymentRecurrence {
  recurringId?: string;
  recurringStatus?: RecurringStatus;
  lastUpdateDate?: string;
}

export enum RecurringStatus {
  CREATED = 'CREATED',
  AUTHENTICATION_NEEDED = 'AUTHENTICATION_NEEDED',
  ENDED = 'ENDED',
  IN_PROGRESS = 'IN_PROGRESS'
}
