import { animate, state, style, transition, trigger } from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

export const fab = trigger('fab', [
  state('appBar', style({ transform: '{{transform}}' }), { params: { transform: 'none' } }),

  transition(':enter', [
    style({ transform: 'rotate(-90deg) scale(0)' }),
    animate(
      `${AnimationDurations.ENTERING} ${AnimationCurves.DECELERATION_CURVE}`,
      style({ transform: 'rotate(0) scale(1)' })
    )
  ]),

  transition(':leave', [
    style({ transform: 'rotate(0) scale(1)' }),
    animate(
      `${AnimationDurations.EXITING} ${AnimationCurves.ACCELERATION_CURVE}`,
      style({ transform: 'rotate(-90deg) scale(0)' })
    )
  ]),

  transition(
    'appBar => float',
    [
      style({ transform: '{{transform}}' }),
      animate(`375ms ${AnimationCurves.STANDARD_CURVE}`, style({ transform: 'none' }))
    ],
    { params: { transform: 'none' } }
  ),

  transition(
    'float => appBar',
    [
      style({ transform: '!' }),
      animate(`375ms ${AnimationCurves.STANDARD_CURVE}`, style({ transform: '{{transform}}' }))
    ],
    { params: { transform: 'none' } }
  )
]);
