import { animate, state, style, trigger, transition, query } from '@angular/animations';
import { AnimationDurations, AnimationCurves } from '@angular/material/core';

export const targetTable = trigger('targetTable', [
  state('0', style({ height: 0, overflow: 'hidden' })),

  transition('0 => *', [
    style({ height: 0, overflow: 'hidden' }),
    animate(
      `${AnimationDurations.COMPLEX} ${AnimationCurves.STANDARD_CURVE}`,
      style({ height: '*' })
    )
  ]),
  transition('* => 0', [
    query(':leave', style({ height: '!', minHeight: '48px', overflow: 'hidden' }), {
      optional: true
    }),
    style({ height: '!', overflow: 'hidden' }),
    animate(`${AnimationDurations.COMPLEX} ${AnimationCurves.STANDARD_CURVE}`, style({ height: 0 }))
  ]),
  transition('* => *', [
    query(
      ':leave',
      [
        style({ height: '!', minHeight: '48px', overflow: 'hidden' }),
        animate(
          `${AnimationDurations.COMPLEX} ${AnimationCurves.STANDARD_CURVE}`,
          style({ height: 0, minHeight: 0 })
        )
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
        style({ height: '0', minHeight: 0, overflow: 'hidden' }),
        animate(
          `${AnimationDurations.COMPLEX} ${AnimationCurves.STANDARD_CURVE}`,
          style({ height: '*', minHeight: '48px' })
        )
      ],
      { optional: true }
    )
  ])
]);

export const params = trigger('params', [
  transition(':enter', [
    style({ height: 0, overflow: 'hidden' }),
    animate(
      `${AnimationDurations.COMPLEX} ${AnimationCurves.STANDARD_CURVE}`,
      style({ height: '*' })
    )
  ]),
  transition(':leave', [
    style({ height: '!', overflow: 'hidden' }),
    animate(`${AnimationDurations.COMPLEX} ${AnimationCurves.STANDARD_CURVE}`, style({ height: 0 }))
  ])
]);
