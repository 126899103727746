import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Notification,
  NotificationDTO,
  NotificationSubscription,
  SubscriptionNotification
} from '@app/infrastructure/notification/notification';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private notificationApiUrl = 'api/notification';

  constructor(private http: HttpClient) {}

  createNotification(notificationDTO: NotificationSubscription): Observable<NotificationDTO> {
    return this.http.post<NotificationDTO>(this.notificationApiUrl, notificationDTO);
  }

  getNotification(id: string): Observable<Notification> {
    return this.http.get<Notification>(`${this.notificationApiUrl}?infraTypeId=${id}`);
  }

  updateNotification(notificationDTO: NotificationSubscription): Observable<NotificationDTO> {
    return this.http.put<NotificationDTO>(
      `${this.notificationApiUrl}?infraTypeId=${notificationDTO.infraTypeId}`,
      notificationDTO
    );
  }

  updateSubscriptionEmail(
    notificationDTO: NotificationSubscription,
    infraTypeId: string,
    subscriptionToUpdateId: string
  ): Observable<NotificationDTO> {
    return this.http.put<NotificationDTO>(
      `${this.notificationApiUrl}?infraTypeId=${infraTypeId}&subscriptionToUpdateId=${subscriptionToUpdateId}`,
      notificationDTO
    );
  }

  updateWithNewSubscription(
    subscriptionDTO: SubscriptionNotification,
    infraTypeId: string
  ): Observable<NotificationDTO> {
    return this.http.put<NotificationDTO>(
      `${this.notificationApiUrl}?infraTypeId=${infraTypeId}`,
      subscriptionDTO
    );
  }

  deleteNotification(id: string): Observable<NotificationDTO> {
    return this.http.delete<NotificationDTO>(`${this.notificationApiUrl}?infraTypeId=${id}`);
  }
}
