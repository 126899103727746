import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { TaskDisplayContentComponent } from './task-display-content/task-display-content.component';
import { TaskDisplayHeaderComponent } from './task-display-header/task-display-header.component';

@NgModule({
  imports: [SharedModule],
  declarations: [TaskDisplayContentComponent, TaskDisplayHeaderComponent],
  exports: [TaskDisplayContentComponent, TaskDisplayHeaderComponent]
})
export class TaskBaseModule {}
