import { InfraChargingStation } from '@app/core/park/charging-station/infraChargingStation';
import { InfraEvse } from '@app/core/park/evse/infraEvse';
import { Emsp } from '@app/core/park/evse/emsp';
import { Connectors } from '@app/core/park/evse/connector';
import { LocalToken } from '@app/infrastructure';

export interface MassImportMapper<T> {
  fromJson(dto: any): T;
  toJson(model: T, parentId?: string): any;
}

export class ChargingStationMassImportMapper implements MassImportMapper<InfraChargingStation> {
  fromJson(dto: any): InfraChargingStation {
    return undefined;
  }

  toJson(chargingStation: InfraChargingStation, locationId: string): any {
    return {
      locationId,
      id: chargingStation.id,
      name: chargingStation.name,
      emi3: chargingStation.emi3,
      model: chargingStation.model?.id,
      retributorId: chargingStation.retributorId,
      monthlyRentAmount: chargingStation.monthlyRentAmount,
      rentUnit: chargingStation.rentUnit,
      rentQuantity: chargingStation.rentQuantity,
      realEvseNumber: chargingStation.realEvseNumber
    };
  }
}

export class EvseMassImportMapper implements MassImportMapper<InfraEvse> {
  private static mapEmsp(emsps: Emsp[]): string[] {
    return emsps.map(emsp => emsp.name);
  }

  private static getGireveOperatorId(emsps: Emsp[]): string {
    return emsps.find(emsp => emsp.name === 'GIREVE')?.operatorId;
  }

  private static getKiwhiCode(emsps: Emsp[]): string {
    return emsps.find(emsp => emsp.name === 'KIWHI')?.operatorId;
  }

  fromJson(dto: any): InfraEvse {
    return undefined;
  }

  toJson(evse: InfraEvse, chargingStationId: string): any {
    return {
      chargingStationId,
      id: evse.id,
      name: evse.name,
      emi3: evse.emi3,
      maxPower: evse.maxPower,
      serviceStatus: evse.serviceStatus,
      'ocpp.model': evse.ocpp?.model,
      'ocpp.inetAddress': evse.ocpp?.inetAddress,
      'ocpp.portName': evse.ocpp?.portName,
      'ocpp.ocppId': evse.ocpp?.ocppId,
      'ocpp.allocatedCurrent': evse.ocpp?.allocatedCurrent,
      hostServiceGroup: evse.hostServiceGroup,
      serviceGroup: evse.serviceGroup,
      emsps: EvseMassImportMapper.mapEmsp(evse.emsps),
      gireveOperatorId: EvseMassImportMapper.getGireveOperatorId(evse.emsps),
      kiwhiCode: EvseMassImportMapper.getKiwhiCode(evse.emsps),
      extraInfo: evse.extraInfo
    };
  }
}

export class ConnectorMassImportMapper implements MassImportMapper<Connectors> {
  fromJson(dto: any): Connectors {
    return undefined;
  }

  toJson(connector: Connectors): any {
    return {
      evseId: connector.evseId,
      type: connector.type,
      value: connector.value,
      connectorId: connector.connectorId,
      amperage: connector.amperage,
      voltage: connector.voltage,
      'ocpp.ocppId': connector.ocpp?.ocppId
    };
  }
}

export class TokenMassImportMapper implements MassImportMapper<LocalToken> {
  fromJson(dto: any): LocalToken {
    return undefined;
  }

  toJson(token: LocalToken): any {
    return {
      internalId: token.internalId,
      tokenId: token.tokenId,
      label: token.label ? token.label : token.name + ' ' + token.surname,
      active: token.status === 'ACCEPTED' ? 'true' : 'false'
    };
  }
}
