import { DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { forkJoin, merge, Subject, timer } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { AppNavigationService } from '@app/navigation';
import { ChartLabel, fadeInOut } from '@app/shared';
import { BaseChartComponent } from '../../base-chart/base-chart.component';
import { DatavizChartTimeRange } from '../../base-chart/chart-time-range';
import { WidgetService } from '../../widget.service';
import { TxEmspCount } from '../tx-emsp-trend/tx-emsp-count';
import { NON_ROAMING } from '../tx-roaming-distribution/tx-roaming-distribution.component';

const MIN_LOADING_TIME = 1000;

const DEFAULT_TITLE_PER_DAY = 'widget.roamingTrend.Daily roaming trend';
const DEFAULT_TITLE_PER_WEEK = 'widget.roamingTrend.Weekly roaming trend';
const DEFAULT_TITLE_PER_MONTH = 'widget.roamingTrend.Monthly roaming trend';

@Component({
  selector: 'app-widget-tx-roaming-trend',
  templateUrl: 'tx-roaming-trend.component.html',
  styleUrls: ['tx-roaming-trend.component.scss'],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetTxRoamingTrendComponent extends BaseChartComponent
  implements AfterViewInit, OnChanges {
  results: any[] = [];
  roamingPercent: number;
  nonRoamingPercent: number;
  querying: boolean;

  // options
  showXAxis = false;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel: string;
  showYAxisLabel = false;
  yAxisLabel: string;
  barPadding = 1;

  colorScheme: any = 'vivid';
  colorSchemeType = 'ordinal';

  // data
  sampleRate: moment.unitOfTime.Base = 'days';
  timeRange: DatavizChartTimeRange;
  timezone: string;

  @ViewChild('primaryColorGetter', { static: true }) private primaryColorGetter: ElementRef;
  @ViewChild('accentColorGetter', { static: true }) private accentColorGetter: ElementRef;

  private data: TxEmspCount[];
  private nonRoamingLabel: ChartLabel;
  private roamingLabel: ChartLabel;
  private decimalPipe = new DecimalPipe('en-US');
  private defaultTitle$ = new Subject<string>();

  constructor(
    public translate: TranslateService,
    private ngZone: NgZone,
    private navService: AppNavigationService,
    private service: WidgetService,
    elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef,
    dialog: MatDialog
  ) {
    super(elementRef, changeDetectorRef, dialog);

    this.nonRoamingLabel = new ChartLabel('Non-roaming', () =>
      translate.instant('widget.roamingTrend.Non-roaming')
    );

    this.roamingLabel = new ChartLabel('Roaming', () =>
      translate.instant('widget.roamingTrend.Roaming')
    );

    this.defaultTitle$
      .pipe(
        takeUntil(this._destroyed),
        switchMap(key =>
          merge(
            this.translate.stream(key),
            this.translate.onTranslationChange.pipe(switchMap(() => this.translate.get(key)))
          )
        )
      )
      .subscribe(val => {
        this._defaultTitle = val;
        if (!this.title) {
          this.title = undefined;
        }
      });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    timer(0).subscribe(() => {
      const primaryStyle = getComputedStyle(this.primaryColorGetter.nativeElement);
      const accentStyle = getComputedStyle(this.accentColorGetter.nativeElement);
      this.colorScheme = { domain: [primaryStyle.backgroundColor, accentStyle.backgroundColor] };
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.option) {
      const sampleRate = this.parseSampleRateOption();
      const rateChanged = sampleRate !== this.sampleRate;
      this.sampleRate = sampleRate;
      if (changes.option.isFirstChange() || rateChanged) {
        this.updateDefaultTitle();
      }

      this.timeRange = this.parseTimeRangeOption();

      if (this.timezone) {
        this.fetchData(changes.option.isFirstChange());
      } else {
        this.navService.userPreferences.pipe(takeUntil(this._destroyed)).subscribe(prefs => {
          const tz = prefs.timeZone || moment.tz.guess();
          if (!this.timezone || this.timezone !== tz) {
            this.timezone = tz;
            this.fetchData(changes.option.isFirstChange());
          }
        });
      }
    }
  }

  yAxisTickFormattingFn(tick: string) {
    return this.decimalPipe.transform(tick, '1.0-2', this.translate.currentLang);
  }

  toggleNormalized() {
    if (!this.option) {
      this.option = { normalized: true };
    } else {
      this.option.normalized = !this.option.normalized;
    }
    this.optionChanged.emit(this.option);
    this.changeDetectorRef.markForCheck();
  }

  private updateDefaultTitle() {
    let title;
    switch (this.sampleRate) {
      case 'weeks':
        title = DEFAULT_TITLE_PER_WEEK;
        break;
      case 'months':
        title = DEFAULT_TITLE_PER_MONTH;
        break;
      default:
        title = DEFAULT_TITLE_PER_DAY;
    }
    this.defaultTitle$.next(title);
  }

  private fetchData(firstTime?: boolean) {
    if (firstTime && this.cacheKey && BaseChartComponent.DATA_CACHE.has(this.cacheKey)) {
      this.data = BaseChartComponent.DATA_CACHE.get(this.cacheKey);
      this.displayData();
      return;
    }

    this.data = [];
    this.results = [];
    this.roamingPercent = 0;
    this.nonRoamingPercent = 0;

    this.stopQuerying.next();
    this.changeDetectorRef.markForCheck();

    this.ngZone.runOutsideAngular(() => {
      const params: any = {};
      const cpoGroupFilter = this.parseFilters('cpoGroup');
      if (cpoGroupFilter.length > 0) {
        params.cpoGroup = cpoGroupFilter;
      }
      const cpoFilter = this.parseFilters('cpo');
      if (cpoFilter.length > 0) {
        params.cpo = cpoFilter;
      }
      const locationFilter = this.parseFilters('location');
      if (locationFilter.length > 0) {
        params.location = locationFilter;
      }

      this.querying = true;
      forkJoin([
        timer(MIN_LOADING_TIME),
        this.service.getTxEmspTrend(
          Object.assign(params, {
            after: this.timeRange.start.toISOString(),
            before: this.timeRange.end.toISOString(),
            rate: this.sampleRate,
            timezone: this.timezone
          })
        )
      ])
        .pipe(
          takeUntil(this._destroyed),
          takeUntil(this.stopQuerying),
          tap(result => this.onDataLoaded(result[1]))
        )
        .subscribe({
          next: () => {
            if (this.cacheKey) {
              BaseChartComponent.DATA_CACHE.set(this.cacheKey, this.data);
            }
          },
          complete: () => {
            this.querying = false;
            this.changeDetectorRef.markForCheck();
          }
        });
    });
  }

  private onDataLoaded(trend: TxEmspCount[]) {
    trend.forEach(count => {
      const index = this.data.findIndex(i => i.name === count.name);
      if (index > -1) {
        for (const emsp in count.emsp) {
          if (count.emsp.hasOwnProperty(emsp)) {
            if (this.data[index].hasOwnProperty(emsp)) {
              this.data[index][emsp] = this.data[index][emsp] + count.emsp[emsp];
            } else {
              this.data[index][emsp] = count.emsp[emsp];
            }
          }
        }
      } else {
        this.data.push(count);
      }
    });
    this.displayData();
  }

  private displayData() {
    let totalRoaming = 0;
    let totalNonRoaming = 0;
    const results = this.data.map(count => {
      const result: any = { name: count.name, series: [] };
      let barRoaming = 0;
      let barTotal = 0;
      for (const e in count.emsp) {
        if (count.emsp.hasOwnProperty(e)) {
          barTotal += count.emsp[e];
          if (NON_ROAMING === e) {
            totalNonRoaming += count.emsp[e];
          } else {
            barRoaming += count.emsp[e];
            totalRoaming += count.emsp[e];
          }
        }
      }
      result.series.push({
        name: this.nonRoamingLabel,
        value: barTotal - barRoaming,
        total: barTotal
      });
      result.series.push({ name: this.roamingLabel, value: barRoaming, total: barTotal });
      return result;
    });
    if (totalRoaming + totalNonRoaming > 0) {
      this.roamingPercent = (totalRoaming * 100) / (totalRoaming + totalNonRoaming);
      this.nonRoamingPercent = (totalNonRoaming * 100) / (totalRoaming + totalNonRoaming);
    }

    this.fillMissingSeriesAndSort(results);
    this.displayResults(results);
  }

  private fillMissingSeriesAndSort(results: any[]) {
    let dateFormat;
    switch (this.sampleRate) {
      case 'weeks':
        dateFormat = 'YYYY-ww';
        break;
      case 'months':
        dateFormat = 'YYYY-MM';
        break;
      default:
        dateFormat = 'YYYY-MM-DD';
    }

    const dateIterator = this.timeRange.start.clone();
    while (dateIterator.isBefore(this.timeRange.end)) {
      const date = dateIterator.format(dateFormat);
      if (results.findIndex(i => i.name === date) < 0) {
        results.push({ name: date, series: [] });
      }
      dateIterator.add(1, this.sampleRate);
    }
    results.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  }

  private displayResults(results: any[]) {
    this.ngZone.run(() => {
      this.results = results;
      this.changeDetectorRef.markForCheck();
    });
  }
}
