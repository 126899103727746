import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccessTokenDto } from './models/fetch-tokens.dto';
import { SKIP_AUTH_HEADER } from '../interceptor/skip-auth-header';
import { UserType } from '@app/core/user-data/user-type';

@Injectable()
export class CognitoHttpService {
  private endpoint = `/apigw/auth`;

  constructor(private http: HttpClient) {}

  authenticate(
    authorizationCode: string,
    authenticationId: string,
    userType: UserType
  ): Observable<AccessTokenDto> {
    const headers: HttpHeaders = new HttpHeaders().append(SKIP_AUTH_HEADER, 'true');
    const params: HttpParams = new HttpParams().appendAll({
      code: authorizationCode,
      authenticationId,
      'user-type': userType
    });
    return this.http.get<AccessTokenDto>(`${this.endpoint}/token`, {
      headers,
      params
    });
  }

  refreshToken(userType: UserType): Observable<AccessTokenDto> {
    const headers = new HttpHeaders().append(SKIP_AUTH_HEADER, 'true');
    const params = new HttpParams().append('user-type', userType);
    return this.http.get<AccessTokenDto>(`${this.endpoint}/refresh`, { headers, params });
  }

  revokeToken(userType: UserType): Observable<void> {
    const params = new HttpParams().append('user-type', userType);
    return this.http.post<void>(`${this.endpoint}/revoke`, undefined, { params });
  }
}
