import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Sms } from './notification';
import { AVAILABLE_LOCALES } from '../../shared/locale-selector/locale-selector.component';

@Component({
  selector: 'app-infra-notification-sms-component',
  templateUrl: 'sms.component.html',
  styleUrls: ['sms.component.scss']
})
export class SmsComponent {
  public locales = AVAILABLE_LOCALES;
  @Input()
  public smsForm: FormGroup;
  @Input()
  public sms: Sms;
}
