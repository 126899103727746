import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {
  SnackbarConfig,
  SnackbarData,
  SnackBarMessageComponent
} from '@app/shared/valid-or-error-confirmation/snack-bar-message.component';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  private DEFAULT_DURATION = 8000;

  constructor(private snackBar: MatSnackBar) {}

  displaySnackBar(
    data: SnackbarData,
    configOrDuration: MatSnackBarConfig | number = this.DEFAULT_DURATION
  ): void {
    let config: SnackbarConfig;

    if (typeof configOrDuration === 'number') {
      config = { duration: configOrDuration };
    } else {
      config = {
        ...configOrDuration,
        duration: configOrDuration?.duration ?? this.DEFAULT_DURATION
      };
    }

    this.displaySnackBarImpl(data, config);
  }

  private displaySnackBarImpl(data: SnackbarData, config: SnackbarConfig): void {
    if (!data.closeSnackBar) {
      data.closeSnackBar = () => this.dismissSnackBar();
    }
    this.snackBar.openFromComponent(SnackBarMessageComponent, { ...config, data });
  }

  displayError(message: string): void {
    this.displaySnackBar({ type: 'error', message });
  }

  displayHttpError(error: HttpErrorResponse): void {
    this.displayError(error.message);
  }

  displaySuccess(message: string): void {
    this.displaySnackBar({ type: 'success', message });
  }

  displayWarning(message: string, config?: SnackbarConfig): void {
    this.displaySnackBar({ type: 'warning', message }, config);
  }

  private dismissSnackBar(): void {
    this.snackBar.dismiss();
  }
}
