import { Component, OnInit } from '@angular/core';
import { BannerService } from './navigation/banner.service';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  static isBannerDisplayed = false;

  constructor(private bannerService: BannerService, private translateService: TranslateService) {}

  ngOnInit(): void {
    if (!AppComponent.isBannerDisplayed) {
      setTimeout(() => this.bannerService.display(), 500);
      AppComponent.isBannerDisplayed = true;
    }
  }
}
