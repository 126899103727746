import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { merge, of, timer } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AppNavigationService } from '@app/navigation';
import { ChartLabel, fadeInOut } from '@app/shared';
import { BaseChartComponent } from '../../base-chart/base-chart.component';
import { DatavizChartTimeRange } from '../../base-chart/chart-time-range';
import { WidgetService } from '../../widget.service';
import { TxEmspDistribution } from '../tx-emsp-distribution/tx-emsp-distribution';

export const NON_ROAMING = 'NoRoaming';

const DEFAULT_TITLE = 'widget.roamingDistribution.Roaming distribution';

@Component({
  selector: 'app-widget-tx-roaming-distribution',
  templateUrl: 'tx-roaming-distribution.component.html',
  styleUrls: ['tx-roaming-distribution.component.scss'],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetTxRoamingDistributionComponent extends BaseChartComponent
  implements AfterViewInit, OnChanges, OnInit {
  results: any[];

  // options
  gradient = false;
  showLegend = false;
  showLabels = true;
  arcWidth = 0.25;

  colorScheme: any = 'vivid';
  colorSchemeType = 'ordinal';

  // data
  sampleRate: moment.unitOfTime.Base = 'days';
  timeRange: DatavizChartTimeRange;
  timezone: string;

  @ViewChild('primaryColorGetter', { static: true }) private primaryColorGetter: ElementRef;
  @ViewChild('accentColorGetter', { static: true }) private accentColorGetter: ElementRef;

  private data: TxEmspDistribution[];
  private nonRoamingLabel: ChartLabel;
  private roamingLabel: ChartLabel;

  constructor(
    public translate: TranslateService,
    private ngZone: NgZone,
    private navService: AppNavigationService,
    private service: WidgetService,
    elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef,
    dialog: MatDialog
  ) {
    super(elementRef, changeDetectorRef, dialog);

    this.nonRoamingLabel = new ChartLabel('Non-roaming', () =>
      translate.instant('widget.roamingDistribution.Non-roaming')
    );
    this.roamingLabel = new ChartLabel('Roaming', () =>
      translate.instant('widget.roamingDistribution.Roaming')
    );

    of(DEFAULT_TITLE)
      .pipe(
        takeUntil(this._destroyed),
        switchMap(key =>
          merge(
            this.translate.stream(key),
            this.translate.onTranslationChange.pipe(switchMap(() => this.translate.get(key)))
          )
        )
      )
      .subscribe(val => {
        this._defaultTitle = val;
        if (!this.title) {
          this.title = undefined;
        }
      });
  }

  ngOnInit() {
    this.translate.onLangChange
      .asObservable()
      .pipe(takeUntil(this._destroyed), debounceTime(500))
      .subscribe(() => {
        if (this.data) {
          this.displayData();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.option) {
      this.sampleRate = this.parseSampleRateOption();
      this.timeRange = this.parseTimeRangeOption();

      if (this.timezone) {
        this.fetchData(changes.option.isFirstChange());
      } else {
        this.navService.userPreferences.pipe(takeUntil(this._destroyed)).subscribe(prefs => {
          const tz = prefs.timeZone || moment.tz.guess();
          if (!this.timezone || this.timezone !== tz) {
            this.timezone = tz;
            this.fetchData(changes.option.isFirstChange());
          }
        });
      }
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    timer(0).subscribe(() => {
      const primaryStyle = getComputedStyle(this.primaryColorGetter.nativeElement);
      const accentStyle = getComputedStyle(this.accentColorGetter.nativeElement);
      this.colorScheme = { domain: [primaryStyle.backgroundColor, accentStyle.backgroundColor] };
    });
  }

  private fetchData(firstTime?: boolean) {
    if (firstTime && this.cacheKey && BaseChartComponent.DATA_CACHE.has(this.cacheKey)) {
      this.data = BaseChartComponent.DATA_CACHE.get(this.cacheKey);
      this.displayData();
      return;
    }

    this.results = undefined;
    this.data = [];
    this.stopQuerying.next();
    this.changeDetectorRef.markForCheck();

    this.ngZone.runOutsideAngular(() => {
      const params: any = {};
      const cpoGroupFilter = this.parseFilters('cpoGroup');
      if (cpoGroupFilter.length > 0) {
        params.cpoGroup = cpoGroupFilter;
      }
      const cpoFilter = this.parseFilters('cpo');
      if (cpoFilter.length > 0) {
        params.cpo = cpoFilter;
      }
      const locationFilter = this.parseFilters('location');
      if (locationFilter.length > 0) {
        params.location = locationFilter;
      }

      this.service
        .getTxEmspAggregation(
          Object.assign(params, {
            after: this.timeRange.start.toISOString(),
            before: this.timeRange.end.toISOString(),
            timezone: this.timezone
          })
        )
        .pipe(
          takeUntil(this._destroyed),
          takeUntil(this.stopQuerying),
          tap(trend => this.onDataLoaded(trend))
        )
        .subscribe(() => {
          if (this.cacheKey) {
            BaseChartComponent.DATA_CACHE.set(this.cacheKey, this.data);
          }
        });
    });
  }

  private onDataLoaded(data: TxEmspDistribution[]) {
    this.data = data;
    this.displayData();
  }

  private displayData() {
    let nonRoaming = 0;
    let total = 0;
    this.data.forEach(v => {
      if (NON_ROAMING === v.name) {
        nonRoaming += v.count;
      }
      total += v.count;
    });
    const results = [
      { name: this.nonRoamingLabel, value: total ? (nonRoaming * 100) / total : 0 },
      { name: this.roamingLabel, value: total ? ((total - nonRoaming) * 100) / total : 0 }
    ];
    this.displayResults(results);
  }

  private displayResults(results: any[]) {
    this.ngZone.run(() => {
      this.results = results;
      this.changeDetectorRef.markForCheck();
    });
  }
}
