import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Subtask, Task } from '@app/core';
import { FlyingTaskBadgeDirective } from '@app/shared';
import { TaskDisplay, TaskService } from '../../task.service';

@Component({
  selector: 'app-task-display-content',
  templateUrl: './task-display-content.component.html',
  styleUrls: ['./task-display-content.component.scss']
})
export class TaskDisplayContentComponent {
  @Input() display: TaskDisplay;
  @Input() taskIdLink: boolean;
  @Output() taskChange = new EventEmitter();

  private prodUrl =
    'https://totalworkplace.sharepoint.com/sites/TECS_EVPGetDiagnostic-Prod/Documents%20partages/GetDiag/';
  private preprodUrl =
    'https://totalworkplace.sharepoint.com/sites/TECS_EVPGetDiagnostic-Preprod/Documents%20partages/GetDiag/';
  private testUrl =
    'https://totalworkplace.sharepoint.com/sites/TECS_EVPGetDiagnostic-Test/Documents%20partages/GetDiag/';

  constructor(public translate: TranslateService, private taskService: TaskService) {}

  getKeyFromId(id: string) {
    return id.substring(id.indexOf('/') + 1);
  }

  retrySubtask(subtask: Subtask, flyingBadge: FlyingTaskBadgeDirective) {
    this.taskService.retrySubtask(this.display, [subtask.target], flyingBadge);
  }

  taskContainsSharepointOption(index: number) {
    return (
      this.display.task.params?.SHAREPOINT && this.display.task.subtasks[index]?.status === 'DONE'
    );
  }

  taskCanBePlanned(task: Task) {
    return task.operation === 'ENABLE_MAINTENANCE';
  }

  openLink(index: number) {
    const filename = this.extractFilenameFromReply(this.display.task.subtasks[index].reply);
    window.open(
      this.selectEnvironnement(window.location.host.split('.')[0]) + filename + '?web=1',
      '_blank'
    );
  }

  selectEnvironnement(env: string): string {
    switch (env) {
      case 'prod':
        return this.prodUrl;
      case 'pp':
        return this.preprodUrl;
      case 'test':
      default:
        return this.testUrl;
    }
  }

  extractFilenameFromReply(reply: string): string {
    return JSON.parse(reply)?.data?.filename;
  }
}
