import { IdentityProvider } from '@app/auth/identity-provider';

export class Environment {
  /**
   * List of values separated by commas.
   */
  administrationProfileNames: string;
  /**
   * List of values separated by commas.
   */
  administrationProfileValues: string;
  administrationProfileEnabled: 'True' | 'False';
  authenticationRedirectUri: string;
  authenticationAuthorizeEndpoint: string;
  banner: {
    enabled: 'True' | 'False';
    endDate: string;
    startDate: string;
  };
  identityProviders: Array<IdentityProvider>;
  production: boolean;
  useLegacyAuthentication: 'True' | 'False';

  constructor(environment: Environment) {
    Object.keys(environment).forEach(key => (this[key] = environment[key]));
  }
}
