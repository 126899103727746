import {
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  FormArray,
  FormGroup,
  FormControl
} from '@angular/forms';

export function customValidatorChannel(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const emails = control.get('emails') as FormArray;
    const sms = control.get('sms') as FormArray;
    return (emails && emails.length > 0) || (sms && sms.length > 0)
      ? null
      : { customValidatorChannel: true };
  };
}

export function allFormValidator(myForm: FormGroup): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (myForm && myForm.get('subscriptions')) {
      if ((myForm.get('subscriptions') as FormArray).length > 0) {
        const subscriptionsArray = myForm.get('subscriptions') as FormArray;
        for (
          let indexSubscriptions = 0;
          indexSubscriptions < subscriptionsArray.length;
          ++indexSubscriptions
        ) {
          if (
            ((myForm.get('subscriptions') as FormArray).controls[indexSubscriptions].get(
              'type'
            ) as FormControl).invalid
          ) {
            return { allFormValidator: true };
          }
          if (
            ((myForm.get('subscriptions') as FormArray).controls[indexSubscriptions].get(
              'trigger'
            ) as FormGroup).get('event').invalid
          ) {
            return { allFormValidator: true };
          }
          if (
            ((myForm.get('subscriptions') as FormArray).controls[indexSubscriptions].get(
              'trigger'
            ) as FormGroup).get('severity').invalid
          ) {
            return { allFormValidator: true };
          }
          const emails = ((myForm.get('subscriptions') as FormArray).controls[
            indexSubscriptions
          ].get('channel.emails') as FormArray).controls;
          for (const [k, v] of emails.entries()) {
            if (emails[k].get('adresse').invalid || emails[k].get('language').invalid) {
              return { allFormValidator: true };
            }
          }
          const sms = ((myForm.get('subscriptions') as FormArray).controls[indexSubscriptions].get(
            'channel.sms'
          ) as FormArray).controls;
          for (const [k, v] of sms.entries()) {
            if (sms[k].get('phone').invalid || sms[k].get('language').invalid) {
              return { allFormValidator: true };
            }
          }
        }
        return null;
      }
    }
    return { allFormValidator: true };
  };
}
