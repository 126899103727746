import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { SharedModule } from '@app/shared';
import { EvseDisplayHeaderComponent } from './evse-display-header/evse-display-header.component';
import { EvseActionsComponent } from './evse-actions/evse-actions.component';
import { EvseActionConfirmDialogComponent } from './evse-action-confirm-dialog/evse-action-confirm-dialog.component';
import { EvseStartTxDialogComponent } from './evse-start-tx-dialog/evse-start-tx-dialog.component';
import { MatIconRegistry } from '@angular/material/icon';
import { EvseUpdateFirmwareDialogComponent } from './evse-update-firmware-dialog/evse-update-firmware-dialog.component';
import { EvseGetDiagnosticsDialogComponent } from './evse-get-diagnostics-dialog/evse-get-diagnostics-dialog.component';
import { EvseSetChargingProfileDialogComponent } from '@app/infrastructure/base/evse-set-charging-profile-dialog/evse-set-charging-profile-dialog.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    EvseActionsComponent,
    EvseDisplayHeaderComponent,
    EvseActionConfirmDialogComponent,
    EvseStartTxDialogComponent,
    EvseUpdateFirmwareDialogComponent,
    EvseGetDiagnosticsDialogComponent,
    EvseSetChargingProfileDialogComponent
  ],
  entryComponents: [
    EvseActionConfirmDialogComponent,
    EvseStartTxDialogComponent,
    EvseUpdateFirmwareDialogComponent,
    EvseGetDiagnosticsDialogComponent,
    EvseSetChargingProfileDialogComponent
  ],
  exports: [EvseActionsComponent, EvseDisplayHeaderComponent]
})
export class InfrastructureBaseModule {
  constructor(sanitizer: DomSanitizer, iconRegistry: MatIconRegistry) {
    iconRegistry.addSvgIcon(
      'ev-station-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_ev_station_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'wrench-off-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_wrench_off_outline.svg')
    );
  }
}
