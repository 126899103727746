export interface Unit {
  translation: string;
  abbreviation: string;
  dbField: UnitDatabaseField;
}

export enum UnitDatabaseField {
  ENERGY = 'energyRegister',
  INTENSITY = 'currentOffered',
  POWER = 'activePower',
  VOLTAGE1 = 'voltage1',
  VOLTAGE2 = 'voltage2',
  VOLTAGE3 = 'voltage3',
  CURRENT1 = 'currentPhase1',
  CURRENT2 = 'currentPhase2',
  CURRENT3 = 'currentPhase3',
  TEMPERATURE = 'temperature'
}

const { ENERGY, INTENSITY, POWER, VOLTAGE1 } = UnitDatabaseField;

export const AVAILABLE_UNITS: Unit[] = [
  { translation: 'smartCharging.ENERGY', abbreviation: 'Wh', dbField: ENERGY },
  {
    translation: 'smartCharging.INTENSITY',
    abbreviation: 'A',
    dbField: INTENSITY
  },
  { translation: 'smartCharging.POWER', abbreviation: 'W', dbField: POWER },
  { translation: 'smartCharging.VOLTAGE', abbreviation: 'V', dbField: VOLTAGE1 }
];

export function isSelectedField(field: UnitDatabaseField, selectedField: any): boolean {
  return selectedField === field;
}
