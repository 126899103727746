import { Component, Inject } from '@angular/core';

import { Evse } from '@app/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-infra-evse-start-tx-dialog',
  templateUrl: './evse-start-tx-dialog.component.html',
  styleUrls: ['./evse-start-tx-dialog.component.scss']
})
export class EvseStartTxDialogComponent {
  request = { idTag: '', connectorId: undefined };
  zeroAllowed: false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { evse: Evse },
    public dialogRef: MatDialogRef<any>
  ) {}
}
