import { Component } from '@angular/core';
import { AppNavigationService } from '@app/navigation';
import { ActivatedRoute } from '@angular/router';
import { EvController } from '@app/core/ev-controller/ev-controller';

@Component({
  templateUrl: './infra-ev-controller.component.html',
  styleUrls: ['./infra-ev-controller.component.scss']
})
export class InfraEvControllerComponent {
  evController: EvController = this.route.snapshot.data['evController'];

  constructor(navService: AppNavigationService, private route: ActivatedRoute) {
    navService.setAppBarTitle('infra.EV_CONTROLLER.TITLE');
  }

  getLocationNameFromEvController(): string {
    return !!this.evController.locationName
      ? this.evController.locationName
      : this.evController.locationId;
  }
}
