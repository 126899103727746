import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertSummary } from '../../alert.service';

const COMM_ALERT = 'COMM_ALERT';
const IT_SERVICE_STATUS = 'IT_SERVICE_STATUS';
const STATUS_ALERT = 'STATUS_ALERT';
const ILLEGAL_OCCUPANCY_ALERT = 'ILLEGAL_OCCUPANCY_ALERT';

@Component({
  selector: 'app-alert-summary',
  templateUrl: './alert-summary.component.html',
  styleUrls: ['./alert-summary.component.scss']
})
export class AlertSummaryComponent implements OnInit {
  @Input() alertsSummary: AlertSummary;

  alertSummaryStatus: Map<string, number>;
  acknowledge: number;
  notAcknowledge: number;
  acknowledgeRate: number;
  keys: string[];

  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.prepareAlertsSummaryDisplay();
  }

  private prepareAlertsSummaryDisplay() {
    if (this.alertsSummary) {
      this.alertSummaryStatus = this.alertsSummary.alertsTypeSummary;
      this.acknowledge = this.alertsSummary.acknowledgeSummary.acknowledge;
      this.notAcknowledge = this.alertsSummary.acknowledgeSummary.notAcknowledge;
      this.acknowledgeRate = this.alertsSummary.acknowledgeSummary.acknowledgeRate;
      this.alertSummaryStatus = new Map(
        [...this.alertSummaryStatus.entries()].sort((a, b) => b[1] - a[1])
      );
      this.keys = Array.from(this.alertSummaryStatus.keys());
    }
  }
}
