import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvService {
  private nbLines: number;

  private isArray = [
    'parkingRestrictions',
    'defaultParkingRestrictions',
    'facilities',
    'emsps',
    'capabilities'
  ];

  private static createArray(items: string): Array<string> {
    items = items.trim();
    return items.length !== 0
      ? items
          .replace(/\r/g, '')
          .split('|')
          .map(emsp => emsp.trim())
      : [];
  }

  async readCsv(files: FileList): Promise<any[]> {
    const itemsList = [];

    const file: File = files.item(0);

    const fileContents = await this.readFile(file);

    let csv: string = fileContents as string;

    // To avoid server error about decimal number
    const regexForDecimalNumber = /(?![0-9]+)[,]{1}(?=[0-9]+)/g;
    csv = csv.replace(regexForDecimalNumber, '.');

    const data = csv.replace(/\r/g, '').split('\n');
    const csvColumns = data[0].trim().split(';');
    const csvContent = data.slice(1);
    this.nbLines = csvContent.length;

    csvContent.forEach((itemString, index) => {
      const temp: any = {};
      temp.line = index + 2;
      temp.currentStatus = undefined;

      let j = 0;
      const itemValues = itemString.split(';');

      if (itemValues.length === csvColumns.length) {
        csvColumns.forEach(col => {
          if (col.split('.').length >= 2) {
            const parentObj = col.split('.')[0];
            const childObj = col.split('.')[1];
            temp[parentObj] = temp[parentObj] ? temp[parentObj] : {};
            temp[parentObj][childObj] = itemValues[j] === '' ? undefined : itemValues[j];
          } else {
            temp[col] = itemValues[j] === '' ? undefined : itemValues[j];
            if (col === 'extraInfo' && itemValues[j]) {
              temp[col] = this.createExtraInfo(itemValues[j]);
            }
            if (this.isArray.includes(col)) {
              temp[col] = CsvService.createArray(itemValues[j]);
            }
          }
          j++;
        });
        itemsList.push(temp);
      }
    });
    return itemsList;
  }

  private createExtraInfo(items: any): {} {
    const temp = {};
    const extraInfos = items.replace(/\r/g, '').split('|');
    extraInfos.forEach(info => {
      info = info.split(':');
      temp[info[0]] = info[1];
    });
    return temp;
  }

  private readFile(file: File) {
    const reader: FileReader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.readAsText(file, 'ascii');
    });
  }
}
