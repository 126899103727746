import { style, transition, trigger, animate } from '@angular/animations';
import { AnimationDurations, AnimationCurves } from '@angular/material/core';

export const actions = trigger('actions', [
  transition(':enter', [
    style({ height: 0 }),
    animate(
      `${AnimationDurations.ENTERING} ${AnimationCurves.STANDARD_CURVE}`,
      style({ height: '*' })
    )
  ]),

  transition(':leave', [
    style({ height: '!' }),
    animate(`${AnimationDurations.EXITING} ${AnimationCurves.STANDARD_CURVE}`, style({ height: 0 }))
  ])
]);
