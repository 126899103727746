export type UserProfile =
  | 'ACCOR_USER'
  | 'USER_ADMIN'
  | 'EMSP_FUNC_ADMIN'
  | 'FLEET'
  | 'SUPPORT'
  | 'SPONSOR'
  | 'INFRASTRUCTURE_MANAGER'
  | 'INFRASTRUCTURE_ADMIN'
  | 'INFRASTRUCTURE_IMPORT'
  | 'LOCAL_ELECTRIC_MANAGER'
  | 'ACTIVITY_MANAGER'
  | 'FLEET_MANAGER'
  | 'EMSP_FUNC_SUPPORT'
  | 'ROAMING_ADMIN'
  | 'NETWORK_MOBILITY'
  | 'COMMISSIONING'
  | 'PRICE_MANAGER'
  | 'MARKETPLACE_MANAGER'
  | 'ENERGY_OPERATOR'
  | 'ADMIN'
  | 'SETPOINT_PROVIDER'
  | 'ENERGY_TREE_ADMINISTRATOR'
  | 'REGULATION_ADMINISTRATOR'
  | 'METADATA_SUBMITTER'
  | 'METADATA_ADMIN';

export type LegacyUserProfile =
  | 'ROLE_MANAGER_INFRASTRUCTURE'
  | 'ROLE_USER'
  | 'ROLE_SUPERVISOR'
  | 'ROLE_OPERATOR'
  | 'ROLE_SPONSOR'
  | 'ROLE_MANAGER_ENERGY'
  | 'ROLE_MANAGER_DRIVER'
  | 'ROLE_PARTNER'
  | 'ROLE_USER_PARTNER';

export const LEGACY_PROFILES: LegacyUserProfile[] = [
  'ROLE_MANAGER_INFRASTRUCTURE',
  'ROLE_USER',
  'ROLE_SUPERVISOR',
  'ROLE_OPERATOR',
  'ROLE_SPONSOR',
  'ROLE_MANAGER_ENERGY',
  'ROLE_MANAGER_DRIVER',
  'ROLE_PARTNER',
  'ROLE_USER_PARTNER'
];

export class UserProfileWrapper<T extends string> {
  value: T;
  /**
   * Can be either a translate key or the actual displayed name.
   */
  name?: string;

  constructor(profileDisplay: UserProfileWrapper<T>) {
    this.value = profileDisplay.value;
    this.name = profileDisplay.name || `user.profile.${profileDisplay.value}`;
  }
}
