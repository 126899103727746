import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  NgZone,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ElementRef,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { forkJoin, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
  DivIcon,
  featureGroup,
  Icon,
  IconOptions,
  latLng,
  MapOptions,
  Marker,
  marker,
  tileLayer,
  MarkerCluster,
  MarkerClusterGroup,
  MarkerClusterGroupOptions,
  LatLngBounds,
  popup,
  point
} from 'leaflet';
import { LeafletDirective } from '@asymmetrik/ngx-leaflet';

import { ChargingLocation } from '@app/core';
import { InfrastructureService } from '@app/infrastructure/infrastructure.service';
import { BaseChartComponent } from '../../base-chart/base-chart.component';
import { LocationCluster } from './location-cluster';
import { WidgetService } from '../../widget.service';
import { WidgetLocationListComponent } from '../location-list/location-list.component';
import { summary } from './location-map-animations';
import { TranslateService } from '@ngx-translate/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';

const FETCH_LIMIT = Infinity;
const MIN_LOADING_TIME = 300;

@Component({
  selector: 'app-widget-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss'],
  animations: [summary],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetLocationMapComponent extends BaseChartComponent implements OnChanges, OnInit {
  @HostBinding('class.app-widget-location-map') mapClass = true;
  @Input() openList: boolean;
  @Input() disableForCpoTab: boolean;
  @Output() infraListOpened = new EventEmitter<boolean>();

  options: MapOptions = {
    layers: [
      tileLayer('https://a.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      })
    ],
    zoomControl: false
  };
  center = latLng(48.8566, 2.3522);
  zoom = 5;

  markerClusterGroup: MarkerClusterGroup;
  markerClusterData: Marker[] = [];
  markerClusterOptions: MarkerClusterGroupOptions = {
    animate: true,
    chunkedLoading: true,
    iconCreateFunction: this.iconCreateFunction.bind(this),
    maxClusterRadius: () => (!this.option || this.option.cluster !== false ? 96 : 0),
    showCoverageOnHover: false,
    singleMarkerMode: true,
    zoomToBoundsOnClick: true
  };
  fitBounds: LatLngBounds;

  rawLocations: ChargingLocation[] = [];
  totalCount = -1;
  focusLocations: ChargingLocation[] = [];
  hideListActions: boolean;

  summary = {
    available: 0,
    occupied: 0,
    unavailable: 0,
    lost: 0
  };

  displayStatus = true;
  optionShowHideChanged: boolean;

  filters: string;
  activeFilter = [];
  tiptool = '';
  @ViewChild(LeafletDirective, { static: true }) private leaflet: LeafletDirective;
  @ViewChild('drawer', { static: true }) private drawer: MatDrawer;
  @ViewChild(WidgetLocationListComponent, { static: true })
  private locationList: WidgetLocationListComponent;
  @ViewChild(CdkScrollable, { static: true }) private scrollable: CdkScrollable;
  @ViewChild('backgroundColorGetter', { static: true }) private backgroundColorGetter: ElementRef;

  private interacted: boolean;
  private defaultMarkerColor: string;
  private focusMarker: Marker;

  constructor(
    private ngZone: NgZone,
    private infraService: InfrastructureService,
    private widgetService: WidgetService,
    elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef,
    dialog: MatDialog,
    private translate: TranslateService
  ) {
    super(elementRef, changeDetectorRef, dialog);
  }

  ngOnInit() {
    this.scrollable
      .elementScrolled()
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {
        const nativeEl = this.scrollable.getElementRef().nativeElement;
        if (nativeEl.scrollTop + nativeEl.offsetHeight >= nativeEl.scrollHeight) {
          this.locationList.onScrolled();
        }
      });

    this.defaultMarkerColor = getComputedStyle(
      this.backgroundColorGetter.nativeElement
    ).backgroundColor;
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.option) {
      let filterChanged = true;
      if (this.option && this.option.filter) {
        const filterJson = JSON.stringify(this.option.filter);
        if (!changes.option.isFirstChange()) {
          filterChanged = filterJson !== this.filters;
        }
        this.filters = filterJson;
      }
      if (filterChanged) {
        this.markerClusterData = [];
        this.rawLocations = [];
        this.summary.available = 0;
        this.summary.occupied = 0;
        this.summary.unavailable = 0;
        this.summary.lost = 0;
        this.fetchMoreLocations();
      }
    }
  }

  onParentResize() {
    this.leaflet.onResize();
  }

  zoomIn() {
    this.zoom++;
  }

  zoomOut() {
    this.zoom--;
  }

  toggleSummary() {
    if (!this.option) {
      this.option = { summary: true };
    } else {
      this.option.summary = !this.option.summary;
    }
    this.optionChanged.emit(this.option);
    this.changeDetectorRef.markForCheck();
  }

  toggleClusterDisplay() {
    if (!this.option || this.option.cluster === undefined) {
      this.option = { cluster: false };
    } else {
      this.option.cluster = !this.option.cluster;
    }
    this.markerClusterGroup.addTo(this.leaflet.map);
    this.markerClusterGroup.clearLayers();
    this.markerClusterGroup.addLayers(this.markerClusterData);
    this.optionChanged.emit(this.option);
    this.changeDetectorRef.markForCheck();
  }

  toggleAlertDisplay() {
    this.optionShowHideChanged = !this.optionShowHideChanged;
    if (!this.option) {
      this.option = { showAlert: true };
    } else {
      this.option.showAlert = !this.option.showAlert;
    }
    this.markerClusterGroup.clearLayers();
    this.markerClusterGroup.addLayers(this.markerClusterData);
    this.optionChanged.emit(this.option);
    this.changeDetectorRef.markForCheck();
  }

  toggleMarkerColor() {
    this.optionShowHideChanged = !this.optionShowHideChanged;
    if (!this.option) {
      this.option = { markerColor: true };
    } else {
      this.option.markerColor = !this.option.markerColor;
    }
    this.markerClusterGroup.clearLayers();
    this.markerClusterGroup.addLayers(this.markerClusterData);
    this.optionChanged.emit(this.option);
    this.changeDetectorRef.markForCheck();
  }
  toggleDisconnectedDisplay() {
    this.optionShowHideChanged = !this.optionShowHideChanged;
    if (!this.option) {
      this.option = { showDisconnected: true };
    } else {
      this.option.showDisconnected = !this.option.showDisconnected;
    }
    this.markerClusterGroup.clearLayers();
    this.markerClusterGroup.addLayers(this.markerClusterData);
    this.optionChanged.emit(this.option);
    this.changeDetectorRef.markForCheck();
  }

  onClusterReady(group: MarkerClusterGroup) {
    this.markerClusterGroup = group;
    (this.markerClusterGroup as any)._getExpandedVisibleBounds = () => {
      return this.leaflet.map.getBounds();
    };

    this.markerClusterGroup.on('clusterclick', cluster => {
      this.interacted = true;
    });
  }

  onMapClick() {
    this.removeFocusMarker();
    if (this.openList) {
      this.focusLocations = [].concat(this.rawLocations);
      this.hideListActions = false;
    } else {
      this.closeDrawer();
    }
    this.changeDetectorRef.markForCheck();
  }

  openDrawer() {
    this.focusLocations = [].concat(this.rawLocations);
    this.hideListActions = false;
    this.drawer.open();
    this.infraListOpened.next(true);
    this.changeDetectorRef.markForCheck();
  }

  closeDrawer() {
    this.drawer.close();
    this.infraListOpened.next(false);
  }

  onListOptionChanged(e: any) {
    if (!this.option) {
      this.option = { listOption: e };
    } else {
      this.option.listOption = e;
    }
    if (e.alertAtTop !== this.option.alertAtTop) {
      this.option.alertAtTop = e.alertAtTop;
      this.markerClusterData = [];
      this.rawLocations = [];
      this.summary.available = 0;
      this.summary.occupied = 0;
      this.summary.unavailable = 0;
      this.summary.lost = 0;
      this.fetchMoreLocations();
    }
    this.optionChanged.emit(this.option);
    this.changeDetectorRef.markForCheck();
  }

  private fetchMoreLocations() {
    if (this.totalCount > 0 && this.rawLocations.length >= this.totalCount) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      const params: any = {
        fetch: true,
        map: true,
        limit: FETCH_LIMIT,
        skip: this.rawLocations.length,
        alertAtTop: this.option && this.option.alertAtTop
      };

      const cpoFilter = this.parseFilters('cpo');
      const cpoGroupFilter = this.parseFilters('cpoGroup');
      const locationFilter = this.parseFilters('location');
      const statusFilter = this.parseFilters('status');

      if (cpoFilter.length > 0) {
        params.cpo = cpoFilter;
      }
      if (cpoGroupFilter.length > 0) {
        params.cpoGroup = cpoGroupFilter;
      }
      if (locationFilter.length > 0) {
        params.location = locationFilter;
      }
      if (statusFilter.length > 0) {
        params.status = statusFilter;
      }

      forkJoin([
        this.widgetService.doRequest(this.infraService.getLocations(params)).pipe(
          tap(result => {
            this.onLocationsLoaded(result.items);
            this.ngZone.run(() => {
              this.totalCount = result.totalCount;
              if (this.openList) {
                this.openDrawer();
              }
              this.changeDetectorRef.markForCheck();
            });
          })
        ),
        timer(MIN_LOADING_TIME)
      ])
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => this.fetchMoreLocations());
    });
    this.parseOptionFilter();
  }

  private onLocationsLoaded(locations: ChargingLocation[]) {
    this.rawLocations = this.rawLocations.concat(locations);
    const cluster = !this.option || this.option.cluster !== false;

    this.markerClusterData = this.markerClusterData.concat(
      locations
        .filter(
          l =>
            !!l.coordinates &&
            typeof l.coordinates.latitude === 'number' &&
            typeof l.coordinates.longitude === 'number' &&
            l.coordinates.latitude <= 90 &&
            l.coordinates.latitude >= -90 &&
            l.coordinates.longitude <= 180 &&
            l.coordinates.longitude >= -180
        )
        .map(location => {
          for (const cs of location.chargingStations) {
            for (const e of cs.evses) {
              if (e) {
                if (e.comStatus === 'DOWN') {
                  this.summary.lost++;
                } else {
                  switch (e.status) {
                    case 'Available':
                      this.summary.available++;
                      break;
                    case 'Unavailable':
                      this.summary.unavailable++;
                      break;
                    default:
                      this.summary.occupied++;
                  }
                }
              }
            }
          }

          const options: any = {
            location
          };
          const m = marker(
            latLng(location.coordinates.latitude, location.coordinates.longitude),
            options
          );
          m.bindPopup(
            popup({ closeButton: false, offset: point(0, cluster ? -48 : -4) }).setContent(
              `${location.name} (${location.cpo.name || location.cpo._key})`
            )
          );
          m.on('mouseover', () => {
            m.openPopup();
          });
          m.on('mouseout', () => {
            m.closePopup();
          });
          m.on('click', () => this.ngZone.run(() => this.onLocationMarkerClick(m, location)));
          m.on('dblclick', () =>
            this.ngZone.run(() => {
              this.interacted = true;
              this.leaflet.map.flyTo(m.getLatLng(), 15, {
                animate: false
              });
              this.changeDetectorRef.detectChanges();
            })
          );
          return m;
        })
    );
    if (!this.interacted) {
      this.leaflet.map.fitBounds(featureGroup(this.markerClusterData).getBounds(), {
        padding: [16, 16]
      });
    }
  }

  private onLocationMarkerClick(m: Marker, location: ChargingLocation) {
    if (this.focusMarker) {
      this.removeFocusMarker();
    }
    this.focusMarker = m;
    ((this.focusMarker as any)._icon as HTMLElement).classList.add('focused-marker');
    this.interacted = true;

    this.focusLocations = [location];
    this.hideListActions = true;
    this.drawer.open();
    this.infraListOpened.next(true);
    this.changeDetectorRef.detectChanges();
  }

  private iconCreateFunction(cluster: MarkerCluster): Icon<IconOptions> | DivIcon {
    return new LocationCluster(cluster, {
      defaultBackgroundColor: this.defaultMarkerColor,
      cluster: !this.option || this.option.cluster !== false,
      markerColor: this.option && this.option.markerColor,
      showAlert: this.option && this.option.showAlert,
      showDisconnected: true
    });
  }

  private removeFocusMarker() {
    if (this.focusMarker) {
      ((this.focusMarker as any)._icon as HTMLElement).classList.remove('focused-marker');
      this.focusMarker = undefined;
    }
  }
  parseOptionFilter() {
    this.activeFilter = [];
    this.tiptool = '';
    if (this.option && this.option.filter && this.option.filter.length > 0) {
      this.activeFilter = this.option.filter
        .filter(
          el =>
            el.param === 'cpo' ||
            el.param === 'cpoGroup' ||
            el.param === 'location' ||
            el.param === 'status'
        )
        .forEach(elem => {
          this.tiptool += `${this.translate.instant(elem.param)}:${this.translate.instant(
            elem.value.display
          )} `;
        });
    }
  }
}
