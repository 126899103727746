import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { EvController } from '@app/core/ev-controller/ev-controller';
import { Observable } from 'rxjs';
import { EvControllerService } from '@app/infrastructure/service/ev-controller.service';

@Injectable({ providedIn: 'root' })
export class InfraEvControllerResolver implements Resolve<EvController> {
  constructor(private evControllerService: EvControllerService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<EvController> {
    return this.evControllerService.findById(route.params.id);
  }
}
