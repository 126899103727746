import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { Transaction, TxAggregateData, TxTimeline } from '@app/core';
import { StartStop } from '@app/shared';

export interface TxApi {
  items: Transaction[];
}

export interface TxAvatar {
  color?: string;
  fontIcon?: string;
  svgIcon?: string;
  tip?: string;
}

export interface TxDisplay {
  tx: Transaction;
  finished: boolean;
  avatar: TxAvatar;
  timeLabel: Observable<string>;
  consumption?: number;
  duration?: StartStop;
  chargeDuration?: StartStop;
  tokenIcon?: string;
  plugIcon?: string;
  chargeService?: string;
  maxPower?: number;
  timeline?: TxTimeline;
}

export interface CloseTx {
  transactionId?: string;
  evseId?: string;
  timestamp?: string;
  manualClosed?: boolean;
}

export interface CloseTxResponse {
  status?: string;
}

@Injectable({ providedIn: 'root' })
export class TxService {
  private txApiUrl = 'api/tx';

  constructor(private http: HttpClient, private translate: TranslateService) {}

  getTransactions(params?: HttpParams): Observable<TxApi> {
    return this.http.get<TxApi>(this.txApiUrl, { params });
  }

  getAggregateData(txId): Observable<TxAggregateData> {
    return this.http.get<TxAggregateData>(`${this.txApiUrl}/${txId}/aggregateData`);
  }

  closeTransaction(closeTx: CloseTx): Observable<CloseTxResponse> {
    return this.http.post<CloseTxResponse>(this.txApiUrl, closeTx);
  }

  getTransactionDisplay(tx: Transaction): TxDisplay {
    const finished = this.isTransactionFinished(tx);
    return {
      tx,
      finished,
      avatar: this.getAvatar(tx, finished),
      timeLabel: this.getTimeLabel(tx),
      consumption: this.getConsumption(tx),
      duration: this.getDuration(tx, finished),
      chargeDuration: this.getChargeDuration(tx, finished),
      tokenIcon: this.getTokenIcon(tx),
      plugIcon: this.getPlugIcon(tx),
      chargeService: this.getChargeService(tx)
    };
  }

  public getTimeLabel(tx: Transaction): Observable<string> {
    return merge(of(tx), this.translate.onLangChange).pipe(
      map(() => {
        const startDate = moment(tx.startDate || tx.authorizeDate);
        let label = startDate.format('l LT');
        if (tx.stopDate) {
          const stopDate = moment(tx.stopDate);
          if (stopDate.format('l') === startDate.format('l')) {
            label += ' - ' + stopDate.format('LT');
          } else {
            label += ' - ' + stopDate.format('l LT');
          }
        }
        return label;
      })
    );
  }

  public isTransactionFinished(tx: Transaction): boolean {
    if (
      (tx.compliance !== undefined && tx.compliance.complianceType !== 'INTERMEDIATE') ||
      tx.authorizeResponse !== 'Accepted'
    ) {
      return true;
    }
    if (
      tx.stopDate !== undefined &&
      moment()
        .subtract(1, 'days')
        .isAfter(moment(tx.stopDate))
    ) {
      return true;
    }
    return false;
  }

  public getAvatar(tx: Transaction, finished: boolean): TxAvatar {
    switch (tx.status) {
      case 'AuthorizeTerminated':
        return {
          color:
            tx.authorizeResponse === 'Accepted'
              ? 'avatar-default-bgc'
              : 'app-background-warn-default',
          fontIcon:
            tx.authorizeResponse === 'Accepted' ? 'mdi-lock-open-outline' : 'mdi-lock-outline'
        };
      case 'TransactionStartTerminated':
        return { color: 'app-background-primary-default', svgIcon: 'battery-charging-outline' };
      case 'Remote':
        return { svgIcon: 'remote-outline' };
      case 'RemoteFailed':
        return { color: 'app-background-warn-default', fontIcon: 'mdi-sync-alert' };
      case 'RemoteStartNoShow':
      case 'RemoteStartExpired':
        return { color: 'app-background-accent-default', fontIcon: 'mdi-clock-alert-outline' };
      case 'TransactionStopRecived':
      case 'TransactionStopReceived':
      case 'TransactionStopTerminated':
        if (
          finished &&
          tx.compliance &&
          (!tx.compliance.functional || !tx.compliance.functional.isValid)
        ) {
          return {
            color: 'app-background-warn-default',
            svgIcon: 'clipboard-alert-outline',
            tip: 'Incompliant'
          };
        }
        return { color: 'app-background-lightgreen-default', fontIcon: 'mdi-check' };
      case 'TransactionStoppedWithError':
        return {
          color: 'app-background-warn-default',
          svgIcon: 'clipboard-alert-outline',
          tip: 'Stopped with error'
        };
      default:
        return { color: 'app-background-lightgreen-default', fontIcon: 'mdi-check' };
    }
  }

  public getConsumption(tx: Transaction): number {
    if (tx.meterStart !== undefined) {
      if (tx.meterStop !== undefined) {
        return (tx.meterStop - tx.meterStart) / 1000;
      } else if (tx.lastMeterIndex !== undefined) {
        return (tx.lastMeterIndex - tx.meterStart) / 1000;
      }
    }
    return undefined;
  }

  public getDuration(tx: Transaction, finished: boolean): StartStop {
    if (tx.startDate && (tx.stopDate || !finished)) {
      return {
        start: moment(tx.startDate),
        stop: tx.stopDate ? moment(tx.stopDate) : undefined
      };
    }
    return undefined;
  }

  public getChargeDuration(tx: Transaction, finished: boolean): StartStop {
    if (tx.startCharge && (tx.stopCharge || !finished)) {
      return {
        start: moment(tx.startCharge),
        stop: tx.stopCharge ? moment(tx.stopCharge) : undefined
      };
    }
    return undefined;
  }

  public getTokenIcon(tx: Transaction): string {
    if (tx.identification) {
      switch (tx.identification.tokenType) {
        case 'BOOT':
          return 'mdi-power';
        case 'AUTO':
          return 'mdi-autorenew';
        case 'ISO14443':
          return 'mdi-nfc-variant';
        case 'EMVCO':
          return 'mdi-credit-card';
        case 'EMAID':
          return 'mdi-bookmark-outline';
      }
    }
    return '';
  }

  public getPlugIcon(tx: Transaction): string {
    if (tx.connectorType) {
      switch (tx.connectorType.toUpperCase()) {
        case 'T1':
          return 'plug-type-1';
        case 'T2':
        case 'T2S':
          return 'plug-type-2';
        case 'T3':
          return 'plug-type-3';
        case 'EF':
          return 'plug-ef';
        case 'T2/T3':
        case 'T3/T2':
        case 'T2-T3':
        case 'T3-T2':
        case 'T2 T3':
          return 'plug-t2-t3';
        case 'T2/EF':
        case 'EF/T2':
          return 'plug-t2-ef';
        case 'T3/EF':
        case 'EF/T3':
          return 'plug-t3-ef';
        case 'CHADEMO':
          return 'plug-chademo';
        case 'COMBO':
        case 'COMBO T1':
        case 'COMBO T2':
          return 'plug-combo';
      }
    }
  }

  public getChargeService(tx: Transaction): string {
    if (tx.connectorType) {
      switch (tx.connectorType) {
        case 'COMBO':
        case 'COMBO T1':
        case 'COMBO T2':
        case 'CHADEMO':
          return `DC (${tx.connectorType})`;
        default:
          return `AC (${tx.connectorType})`;
      }
    }
    return 'AC';
  }

  public getCurrentTransactionDisplay(tx: Transaction): TxDisplay {
    const finished = false;
    return {
      tx,
      finished,
      avatar: null,
      timeLabel: null,
      consumption: this.getConsumption(tx),
      duration: this.getDuration(tx, finished),
      chargeDuration: this.getChargeDuration(tx, finished),
      tokenIcon: null,
      plugIcon: null,
      chargeService: null
    };
  }

  getNbDocuments(httpParams: HttpParams): Observable<number> {
    return this.http.get<number>(`${this.txApiUrl}/count`, { params: httpParams });
  }
}
