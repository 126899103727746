import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TxMainListComponent } from './main-list/main-list.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: TxMainListComponent
      },
      // {
      //   path: 'detail/:id',
      //   component: EmspTxDetailComponent,
      //   resolve: {
      //     emspTx: EmspTxResolver
      //   }
      // },
      { path: '**', redirectTo: '', pathMatch: 'full' }
    ])
  ],
  exports: [RouterModule]
})
export class TxRoutingModule {}
