import { Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Evse } from '@app/core';
import { actions } from './evse-actions-animations';

export function getEvseAvatarClass(evse: Evse): string {
  switch (evse.ocppStatus) {
    case 'AVAILABLE':
      return 'available';
    case 'OCCUPIED':
    case 'PREPARING':
    case 'SUSPENDED_EV':
    case 'SUSPENDED_EV_DISCONNECTED':
    case 'SUSPENDED_EVSE':
    case 'SUSPENDED_EVSE_DISCONNECTED':
    case 'PRECHARGING':
    case 'CHARGING':
    case 'FINISHING':
    case 'RESERVED':
      return 'occupied';
    case 'UNAVAILABLE':
      return 'unavailable';
    case 'FAULTED':
      return 'faulted';
    default:
      return 'unknown';
  }
}

export function getEvseAvatarIcon(evse: Evse): string {
  switch (evse.ocppStatus) {
    case 'AVAILABLE':
      return 'ev-station-ok';
    case 'PREPARING':
      return 'prepare-session';
    case 'OCCUPIED':
    case 'SUSPENDED_EV':
    case 'SUSPENDED_EV_DISCONNECTED':
    case 'SUSPENDED_EVSE':
    case 'SUSPENDED_EVSE_DISCONNECTED':
    case 'FINISHING':
    case 'RESERVED':
      return 'ev-station-outline';
    case 'PRECHARGING':
    case 'CHARGING':
      return 'battery-charging-outline';
    case 'UNAVAILABLE':
      return 'wrench-outline';
    case 'FAULTED':
      return 'ev-station-alert';
    default:
      return 'ev-station-unknown';
  }
}

export function getPlugIcon(connectorType: string): string {
  if (connectorType) {
    switch (connectorType) {
      case 'IEC_62196_T1':
        return 'plug-type-1';
      case 'SOCKET_IEC_62196_T2_THREE':
      case 'CABLE_IEC_62196_T2_THREE':
      case 'CABLE_IEC_62196_T2_SINGLE':
      case 'SOCKET_IEC_62196_T2_SINGLE':
        return 'plug-type-2';
      case 'IEC_62196_T3AC':
      case 'IEC_62196_T3A':
        return 'plug-type-3';
      case 'DOMESTIC_E':
        return 'plug-ef';
      case 'CHADEMO':
        return 'plug-chademo';
      case 'COMBO':
      case 'COMBO T1':
      case 'COMBO T2':
      case 'IEC_62196_T2_COMBO':
        return 'plug-combo';
    }
  }
}

@Component({
  selector: 'app-infra-evse-display-header',
  templateUrl: './evse-display-header.component.html',
  styleUrls: ['./evse-display-header.component.scss'],
  animations: [actions]
})
export class EvseDisplayHeaderComponent {
  @Input() evse: Evse;
  @Input() actionsExpanded: boolean;
  @Input() iconActions: boolean;
  showActions: boolean;

  constructor(public translate: TranslateService) {}

  toggleActions(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.showActions = !this.showActions;
  }

  getEvseAvatarClass(evse: Evse) {
    return getEvseAvatarClass(evse);
  }

  getEvseAvatarIcon(evse: Evse) {
    return getEvseAvatarIcon(evse);
  }

  getPlugIcon(connectorType: string) {
    return getPlugIcon(connectorType);
  }
}
