import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-input-chip-list',
  templateUrl: './input-chip-list.component.html',
  styleUrls: ['./input-chip-list.component.scss']
})
/**
 * This component is used to display an editable chipList with an autocomplete
 *
 * To use : use the <app-input-chip-list> selector
 *
 * Inputs : - entryList : the list containing the autocomplete options
 *          - selectedList : the list where the selected elements should be stored
 *          - displayAttributeName : the name of the object attribute that should be displayed (usually name or label)
 *          - inputLabel : the label of the formField that will be displayed above the input (it will be translated)
 *
 */
export class InputChipListComponent implements OnInit, OnChanges {
  @Input() entryList: any[];
  @Input() displayAttributeName: string;
  @Input() selectedList: any[];
  @Input() inputLabel: string;
  @Input() disabled = false;

  filteredList: Observable<any[]>;
  chipForm: FormGroup;
  @ViewChild('addInput', { static: true }) addInput: ElementRef;
  @ViewChild('addInput', { static: true, read: MatAutocompleteTrigger })
  addInputTrigger: MatAutocompleteTrigger;

  @Output() selectedListOutput = new EventEmitter<any[]>();

  constructor(private fb: FormBuilder) {
    this.chipForm = fb.group({
      chipEntry: ['']
    });
  }

  get chipEntry(): AbstractControl {
    return this.chipForm.get('chipEntry');
  }

  ngOnInit(): void {
    this.setDisablingOfChipEntry();
    this.getFilterList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getFilterList();
    if (changes.disabled) {
      this.disabled = changes.disabled.currentValue;
      this.setDisablingOfChipEntry();
    }
  }

  private setDisablingOfChipEntry(): void {
    this.disabled ? this.chipEntry.disable() : this.chipEntry.enable();
  }

  removeElementFromList(index: number) {
    this.selectedList.splice(index, 1);
    this.selectedListOutput.emit(this.selectedList);
  }

  addElementToSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedList.push(event.option.value);
    this.addInput.nativeElement.value = '';
    this.chipEntry.patchValue('');
    this.selectedListOutput.emit(this.selectedList);
  }

  openAutoCompletePanel(): void {
    this.addInputTrigger.openPanel();
  }

  private getFilterList() {
    this.filteredList = this.chipEntry.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value[this.displayAttributeName])),
      map(name => (name ? this._filter(name) : this._filter('')))
    );
  }

  private _filter(name: string) {
    return (
      this.entryList
        .filter(element =>
          element[this.displayAttributeName].toLowerCase().includes(name.toLowerCase())
        )
        // next line filters already selected options
        .filter(
          element =>
            this.selectedList.findIndex(
              selected => selected[this.displayAttributeName] === element[this.displayAttributeName]
            ) < 0
        )
    );
  }
}
