import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { AlertDisplayHeaderComponent } from './alert-display-header/alert-display-header.component';
import { AlertDisplayContentComponent } from './alert-display-content/alert-display-content.component';
import { AlertAcknowledgeDialogComponent } from './acknowledge-dialog/acknowledge-dialog.component';
import { AlertHistoryComponent } from './alert-history/alert-history.component';
import { AlertDeleteTicketDialogComponent } from './delete-ticket-dialog/delete-ticket-dialog.component';
import { ConfirmDialogComponent } from '@app/shared/confirm-dialog/confirm-dialog.component';
import { AlertSummaryComponent } from './alert-summary/alert-summary.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    AlertAcknowledgeDialogComponent,
    AlertDisplayHeaderComponent,
    AlertDisplayContentComponent,
    AlertHistoryComponent,
    AlertDeleteTicketDialogComponent,
    AlertSummaryComponent
  ],
  exports: [
    AlertAcknowledgeDialogComponent,
    AlertDisplayHeaderComponent,
    AlertDisplayContentComponent,
    AlertDeleteTicketDialogComponent,
    AlertSummaryComponent
  ]
})
export class AlertBaseModule {}
