import { Component, Input } from '@angular/core';
import { SmartChargingService } from '@app/smart-charging/smart-charging.service';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-smart-charging-static-ceiling',
  templateUrl: './static-ceiling.component.html',
  styleUrls: ['./static-ceiling.component.scss']
})
export class StaticCeilingComponent {
  @Input() isEditMode = false;
  @Input() parentForm: FormGroup;

  constructor(
    private smartChargingService: SmartChargingService,
    private translateService: TranslateService
  ) {}

  get staticCeiling(): AbstractControl {
    return this.parentForm.get('staticCeiling');
  }

  getStaticCeilingValue(): string {
    return this.staticCeiling?.value
      ? `${this.staticCeiling.value} kVA`
      : this.translateService.instant('smartCharging.TO_COMPLETE');
  }

  getFieldName(): string {
    return this.isEditMode ? 'smartCharging.CEILING_FIELD_KVA' : 'smartCharging.CEILING_FIELD';
  }
}
