import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { InfrastructureService, LocalToken } from '../../infrastructure.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { AddRfidCardCredentielDialogComponent } from '../add-rfid-card-credentiel-dialog/add-rfid-card-credentiel-dialog.component';
import { MassImportRfidCardCredentielDialogComponent } from '../mass-import-rfid-card-credentiel-dialog/mass-import-rfid-card-credentiel-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { XlsxService } from '@app/referencial/park/core/download/xlsx-service';

const DEFAULT_COLUMNS = ['select', 'tokenId', 'label', 'active', 'actions'];

export interface LocalTokenDisplay {
  localToken: LocalToken;
  labelModel: string;
  active: boolean;
  editing: boolean;
  editingActive: boolean;
}

@Component({
  selector: 'app-rfid-card-credentiel-dialog',
  templateUrl: './rfid-card-credentiel-dialog.component.html',
  styleUrls: ['./rfid-card-credentiel-dialog.component.scss']
})
export class RfidCardCredentielDialogComponent implements OnInit {
  displayedColumns = DEFAULT_COLUMNS;

  groupForm = this.fb.group({
    activeCheck: ''
  });

  dataSource = new MatTableDataSource<LocalTokenDisplay>();
  selection = new SelectionModel<LocalTokenDisplay>(true, []);
  resultsLength: number;
  displays: LocalTokenDisplay[] = [];
  valid = true;
  loading = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private addDialogRef: MatDialogRef<AddRfidCardCredentielDialogComponent>;
  private massImportDialogRef: MatDialogRef<MassImportRfidCardCredentielDialogComponent>;
  private _destroyed = new Subject();

  constructor(
    public dialogRef: MatDialogRef<RfidCardCredentielDialogComponent>,
    private fb: FormBuilder,
    private infraService: InfrastructureService,
    public dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      cpoId: string;
      locationId: string;
      cpoKey: string;
      tokens: LocalTokenDisplay[];
    },
    private xlsxService: XlsxService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.loadLocalTokenData();
  }

  valuechange(newValue: any): void {
    this.valid = newValue !== '';
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter(filterInput: any): void {
    filterInput.value = '';
    this.applyFilter('');
  }

  onAdd(): void {
    if (this.addDialogRef) {
      return;
    }

    this.addDialogRef = this.dialog.open(AddRfidCardCredentielDialogComponent, {
      disableClose: true,
      width: '50%',
      data: { cpoId: this.data.cpoId, locationId: this.data.locationId }
    });
    this.addDialogRef
      .afterClosed()
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {
        this.addDialogRef = undefined;
        this.loadLocalTokenData();
      });
  }

  onMassImport(): void {
    if (this.massImportDialogRef) {
      return;
    }

    this.massImportDialogRef = this.dialog.open(MassImportRfidCardCredentielDialogComponent, {
      disableClose: true,
      width: '75%',
      data: {
        cpoId: this.data.cpoId,
        cpoKey: this.data.cpoKey,
        locationId: this.data.locationId,
        tokens: this.displays
      }
    });
    this.massImportDialogRef
      .afterClosed()
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {
        this.massImportDialogRef = undefined;
        this.loadLocalTokenData();
      });
  }

  onEdit(display: LocalTokenDisplay): void {
    this.valid = true;
    display.editing = true;
  }

  onConfirmEdit(display: LocalTokenDisplay): void {
    this.infraService
      .updateLocalToken(
        display.localToken.internalId,
        display.labelModel,
        display.localToken.tokenId,
        display.active,
        this.data.cpoId,
        this.data.locationId
      )
      .pipe(takeUntil(this._destroyed))
      .subscribe(
        () => {
          display.editing = false;
          display.editingActive = false;
          this.loadLocalTokenData();
        },
        () => {
          display.editing = true;
          display.editingActive = true;
        }
      );
  }

  onCancelEdit(display: LocalTokenDisplay): void {
    display.editing = false;
    display.editingActive = false;
    display.labelModel = display.localToken.label
      ? display.localToken.label
      : display.localToken.name + ' ' + display.localToken.surname;
    display.active = display.localToken.status.indexOf('ACCEPTED') > -1;
  }

  getTransactions(tokenId: string): void {
    this.router.navigate(['transaction'], {
      queryParams: { tokenId }
    });
    this.dialogRef.close();
  }

  private loadLocalTokenData(): void {
    let localTokensById: Observable<LocalToken[]>;
    if (this.data.cpoId) {
      localTokensById = this.infraService.getLocalTokensByCpoId(this.data.cpoId);
    } else if (this.data.locationId) {
      localTokensById = this.infraService.getLocalTokensByLocationId(this.data.locationId);
    }

    localTokensById
      .pipe(
        map(localTokens => {
          this.displays = localTokens.map(loc => {
            return {
              localToken: loc,
              labelModel: loc.label ? loc.label : loc.name + ' ' + loc.surname,
              tokenIdModel: loc.tokenId,
              active: loc.status.indexOf('ACCEPTED') > -1,
              editing: false,
              editingActive: false
            };
          });
          return this.displays;
        })
      )
      .subscribe(() => {
        this.loading = false;
        this.dataSource.data = this.displays;
        this.resultsLength = this.displays.length;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  onCheckboxChange(event: MatCheckboxChange, localDisplay: LocalTokenDisplay): void {
    localDisplay.editingActive = true;
    this.groupForm.get('activeCheck').setValue(event.checked);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  exportData2Csv() {
    const token2Export = [];
    this.selection.selected.forEach(selected => token2Export.push(selected.localToken));
    this.xlsxService.exportTokens(token2Export);
  }
}
