import { Hub } from '@app/core/smart-charging/hub';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SmartChargingProfile } from '@app/core/smart-charging/profile/profile';
import { ChargingServicePlan } from '@app/core/smart-charging/charging-service-plan';
import { SmartChargingPeriod } from '@app/core/smart-charging/profile/charging-period/charging-period';
import { AllocationStrategy } from '@app/core/smart-charging/allocation-strategy';
import { ChartFilter } from '@app/smart-charging/base/chart/filter-chart/filter-chart.component';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

export interface SmartChargingApi {
  items: Hub[];
  totalCount: number;
}

export interface Point {
  name: string | number | Date;
  value: number;
}

export interface CurveResponseValue {
  activePower: number;
  currentPhase1: number;
  currentPhase2: number;
  currentPhase3: number;
}

export type CurveResponse = { [key: string]: { name: string; value: CurveResponseValue }[] };
export type ExceededCapResponse = { timestamp: string; evse: string };
export type LevelB = {
  applicationDate: string;
  startTime: string;
  endTime: string;
  power: number;
}[];

@Injectable({ providedIn: 'root' })
export class SmartChargingService {
  private smartChargingApiUrl = 'api/smart-charging';
  private timeseriesApiUrl = 'api/timeseries';

  constructor(private http: HttpClient) {}

  private static formatDateToString(date: Date): string {
    return moment(date)
      .utc()
      .format();
  }

  private mapParamsFromFilterValue(filter: ChartFilter, params: HttpParams): HttpParams {
    Object.keys(filter).forEach(key => {
      if (key === 'timeRange') {
        params = this.setStartAndStopDateFromFilterInParams(filter, params);
      } else if (filter[key] instanceof Array && filter[key]?.length > 0) {
        params = params.set(key, filter[key].join(','));
      } else {
        params = params.set(key, filter[key]);
      }
    });
    return params;
  }

  private setStartAndStopDateFromFilterInParams(
    filter: ChartFilter,
    params: HttpParams
  ): HttpParams {
    params = params.set(
      'startDate',
      SmartChargingService.formatDateToString(filter.timeRange.startDate)
    );
    params = params.set(
      'stopDate',
      SmartChargingService.formatDateToString(filter.timeRange.stopDate)
    );
    return params;
  }

  getCurve(filter: ChartFilter): Observable<CurveResponse> {
    let params = new HttpParams();
    params = this.mapParamsFromFilterValue(filter, params);

    return this.http.get<any>(`${this.timeseriesApiUrl}`, { params });
  }

  getExceededCeilingCap(filter: ChartFilter, ceilingCap: number): Observable<ExceededCapResponse> {
    let params = new HttpParams();
    params = this.setStartAndStopDateFromFilterInParams(filter, params);
    params = params.set('deviceIdList', filter.deviceId.join(','));
    params = params.set('staticCeiling', ceilingCap.toString());

    return this.http.get<ExceededCapResponse>(`${this.timeseriesApiUrl}/staticCeilingCap`, {
      params
    });
  }

  getProfilesLevelB(hubId: string, filter: ChartFilter): Observable<LevelB> {
    let params = new HttpParams();
    params = this.setStartAndStopDateFromFilterInParams(filter, params);
    return this.http.get<LevelB>(`${this.smartChargingApiUrl}/hubs/${hubId}/level-b`, {
      params
    });
  }

  getHub(hubId: string): Observable<Hub> {
    return this.http.get<Hub>(`${this.smartChargingApiUrl}/hubs/${hubId}`);
  }

  getHubsLight(locationId: string): Observable<SmartChargingApi> {
    return this.http.get<SmartChargingApi>(
      `${this.smartChargingApiUrl}/locations/${locationId}/hubs`
    );
  }

  getLastComDate(hubId: string): Observable<string> {
    return this.http.get<string>(this.smartChargingApiUrl + '/getLastComDate', {
      params: { id: hubId }
    });
  }

  requestGetConf(hubId: string): Observable<Hub> {
    return this.http.get<Hub>(`${this.smartChargingApiUrl}/hubs/${hubId}/conf`);
  }

  setProfiles(
    hubId: string,
    ceiling: number,
    profiles: SmartChargingProfile[]
  ): Observable<string> {
    return this.http.post<string>(`${this.smartChargingApiUrl}/setProfiles`, {
      hubId,
      ceiling,
      profiles
    });
  }

  getProfilesByHubId(hubId: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.smartChargingApiUrl}/getProfiles`, {
      params: { hubId }
    });
  }

  setCeiling(hubId: string, ceiling: number): Observable<string> {
    return this.http.post<string>(`${this.smartChargingApiUrl}/setCeiling`, {
      hubId,
      ceiling
    });
  }

  setEcoPeriods(hubId: string, ecoPeriods: SmartChargingPeriod[]): Observable<string> {
    return this.http.post<string>(`${this.smartChargingApiUrl}/setEcoPeriods`, {
      hubId,
      ecoPeriods
    });
  }

  saveChargingServicePlanOnCpo(
    chargingServicePlan: ChargingServicePlan,
    cpoId: string
  ): Observable<string> {
    return this.http.post<string>(`${this.smartChargingApiUrl}/cpo/${cpoId}/chargingServicePlans`, {
      chargingServicePlan
    });
  }

  getServiceByIdAndVersion(versioningId: string, version: string): Observable<ChargingServicePlan> {
    return this.http.get<ChargingServicePlan>(`${this.smartChargingApiUrl}/chargingServicePlan`, {
      params: { versioningId, version }
    });
  }

  getServices(): Observable<ChargingServicePlan[]> {
    return this.http.get<ChargingServicePlan[]>(`${this.smartChargingApiUrl}/chargingServicePlans`);
  }

  getServicesByCpo(cpoId: string): Observable<ChargingServicePlan[]> {
    return this.http.get<ChargingServicePlan[]>(
      `${this.smartChargingApiUrl}/cpo/${cpoId}/chargingServicePlans`
    );
  }

  getServicesByFleet(fleetId: string): Observable<ChargingServicePlan[]> {
    return this.http.get<ChargingServicePlan[]>(
      `${this.smartChargingApiUrl}/fleets/${fleetId}/chargingServicePlans`
    );
  }

  downloadFile(data: any, hubId: string) {
    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(data[0]);
    const csv = data.map(row =>
      header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';')
    );
    csv.unshift(header.join(';'));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });
    const fileName = hubId + '_' + new Date().getTime() + '.csv';
    saveAs(blob, fileName);
  }

  applyServices(hubId: string, nodeServiceMap: Map<string, string>): Observable<string> {
    const chargeServiceApplications = [];
    nodeServiceMap.forEach((service, nodeId) => {
      chargeServiceApplications.push({
        serviceKey: service,
        targetKey: nodeId,
        targetType: 'EVSE'
      });
    });
    return this.http.post<string>(
      `${this.smartChargingApiUrl}/hubs/${hubId}/chargingServicePlans`,
      { chargeServiceApplications }
    );
  }

  getServicesByHubId(hubId: string): Observable<ChargingServicePlan[]> {
    return this.http.get<ChargingServicePlan[]>(
      `${this.smartChargingApiUrl}/hubs/${hubId}/chargingServicePlans`
    );
  }

  setCurrentAllocationStrategy(hubId: string, currentAllocationStrategy: AllocationStrategy) {
    return this.http.put(`${this.smartChargingApiUrl}/hubs/${hubId}/currentAllocationStrategy`, {
      currentAllocationStrategy
    });
  }

  saveChargingServicePlanOnFleet(chargingServicePlan: ChargingServicePlan, ownerId: string) {
    return this.http.post<string>(
      `${this.smartChargingApiUrl}/fleets/${ownerId}/chargingServicePlans`,
      {
        chargingServicePlan
      }
    );
  }

  getChargingServicePlansByActivity(activityId: string): Observable<ChargingServicePlan[]> {
    return this.http.get<ChargingServicePlan[]>(
      `${this.smartChargingApiUrl}/activities/${activityId}/services`
    );
  }
}
