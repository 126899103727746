import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { InfraMainViewComponent } from './main-view/main-view.component';
import { InfraLocationInfoComponent } from './location-info/location-info.component';
import { InfraEvseInfoComponent } from './evse-info/evse-info.component';
import { InfraCpoInfoComponent } from './cpo-info/cpo-info.component';
import { InfraNetworkInfoComponent } from './network-info/network-info.component';
import { InfraEvseConfigComponent } from './evse-config/evse-config.component';
import { HistoryDetailsComponent } from './event-record/history-details/history-details.component';
import { InfraEvControllerComponent } from '@app/infrastructure/ev-controller/infra-ev-controller.component';
import { InfraEvControllerResolver } from '@app/infrastructure/resolver/infra-ev-controller-resolver.service';
import { LocationEvControllerFeatureFlagGuard } from '@app/infrastructure/ev-controller/location-ev-controller-feature-flag.guard';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: InfraMainViewComponent
      },
      {
        path: 'location/:id/history',
        component: HistoryDetailsComponent
      },
      {
        path: 'location/:id/network/:iccid',
        component: InfraNetworkInfoComponent
      },
      {
        path: 'location/:id',
        component: InfraLocationInfoComponent
      },
      {
        path: 'evse/:id/history',
        component: HistoryDetailsComponent
      },
      {
        path: 'evse/:id/config',
        component: InfraEvseConfigComponent
      },
      {
        path: 'evse/:id',
        component: InfraEvseInfoComponent
      },
      {
        path: 'cpo/:id',
        component: InfraCpoInfoComponent
      },
      {
        path: 'ev-controller/:id',
        component: InfraEvControllerComponent,
        resolve: {
          evController: InfraEvControllerResolver
        },
        canActivate: [LocationEvControllerFeatureFlagGuard]
      },
      { path: '**', redirectTo: '', pathMatch: 'full' }
    ])
  ],
  exports: [RouterModule]
})
export class InfraRoutingModule {}
