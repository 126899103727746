import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Alert } from '@app/core';
import { Observable } from 'rxjs';
import { AlertHistory } from '@app/core/infrastructure/alert-history';
import { AlertEvent } from '@app/core/infrastructure/alert-event';

export interface AlertApi {
  items: Alert[];
  totalCount: number;
}

export interface AlertSummary {
  alertsTypeSummary: Map<string, number>;
  acknowledgeSummary: AcknowledgeSummary;
}

export interface AcknowledgeSummary {
  acknowledge: number;
  notAcknowledge: number;
  acknowledgeRate: number;
}

export interface AlertAvatar {
  color?: string;
  fontIcon?: string;
  svgIcon?: string;
  tip?: string;
}

export interface AlertDisplay {
  alert: Alert;
  avatar: AlertAvatar;
  errorCode?: string;
  closed: boolean;
}

export interface AlertHistoryDisplay {
  history: AlertHistory;
  avatar: AlertAvatar;
  background: string;
}

const KNOWN_ILLEGAL_OCCUPANCY_ERROR_CODES = ['IllegalParking', 'SensorParking'];

const KNOWN_VENDOR_ERROR_CODES = [
  'PowerOutage',
  'DoorNotClosed',
  'CommunicationError',
  ...KNOWN_ILLEGAL_OCCUPANCY_ERROR_CODES
];

const EXTERNAL_FS_URL = 'http://www.support.total-ev-charge.com/helpdesk/tickets/';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private alertApiUrl = 'api/alert';

  constructor(private http: HttpClient) {}

  getAlerts(params?: HttpParams): Observable<AlertApi> {
    return this.http.get<AlertApi>(this.alertApiUrl, { params });
  }

  getAlertsSummary(params?: HttpParams): Observable<AlertSummary> {
    const url = `${this.alertApiUrl}/summary`;
    return this.http.get<AlertSummary>(url, { params });
  }

  saveComment(alert: Alert, comment: string): Observable<Alert> {
    const url = `${this.alertApiUrl}/${alert._id}/comment`;
    return this.http.put<Alert>(url, { comment });
  }

  acknowledgeAlert(alert: Alert): Observable<Alert> {
    const url = `${this.alertApiUrl}/${alert._id}/acknowledge`;
    return this.http.post<Alert>(url, null);
  }

  unacknowledgeAlert(alert: Alert): Observable<Alert> {
    const url = `${this.alertApiUrl}/${alert._id}/unacknowledge`;
    return this.http.post<Alert>(url, null);
  }

  deleteAlertTicketLink(alertId: string, ticket: string, equipmentId: string): Observable<Alert> {
    const url = `${this.alertApiUrl}/${alertId}/deleteTicketLink`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: { ticket, equipmentId }
    };
    return this.http.delete<Alert>(url, options);
  }

  checkFreshServiceOrgSync(orgId: string): Observable<boolean> {
    const url = `${this.alertApiUrl}/${orgId}/freshServiceSync`;
    return this.http.get<boolean>(url);
  }

  addFsTicketToAlert(alert: Alert, ticketId: string, alreadyChecked = false): Observable<boolean> {
    const url = `${this.alertApiUrl}/addFsTicketToAlert`;
    const ticketDto = { ...alert, alertId: alert._id, ticketId, alreadyChecked };
    return this.http.post<boolean>(url, ticketDto);
  }

  getAlertDisplay(alert: Alert): AlertDisplay {
    const errorCode = this.getStatusErrorCode(alert);
    const closed = this.isAlertClosed(alert);
    return { alert, closed, avatar: this.getAvatar(alert, errorCode), errorCode };
  }

  goToFsTicket(item: string) {
    const link = EXTERNAL_FS_URL + item;
    window.open(link, '_blank');
  }

  getSummary(result: any) {
    return {
      acknowledgeSummary: result.acknowledgeSummary,
      alertsTypeSummary: new Map<string, number>(Object.entries(result.alertsTypeSummary))
    };
  }

  private isAlertClosed(alert: Alert): boolean {
    return alert.status === 'Closed';
  }

  private getAvatar(alert: Alert, errorCode: string): AlertAvatar {
    let color: string;
    let fontIcon: string;
    let svgIcon: string;
    switch (alert.severity) {
      case 'Signal':
        color = 'signal-severity';
        break;
      case 'Info':
        color = 'info-severity';
        break;
      case 'Warning':
        color = 'warning-severity';
        break;
      case 'Critical':
        color = 'critical-severity';
        break;
    }
    switch (alert.alertType) {
      case 'COMM_ALERT':
        fontIcon = 'mdi-link-off';
        break;
      case 'ILLEGAL_OCCUPANCY_ALERT':
        fontIcon = 'mdi-alpha-p-circle-outline';
        break;
      case 'STATUS_ALERT':
        if (errorCode) {
          switch (errorCode) {
            case 'IllegalParking':
            case 'SensorParking':
              fontIcon = 'mdi-alpha-p-circle-outline';
              break;
            case 'CommunicationError':
              svgIcon = 'ev-station-alert';
              break;
            case 'ConnectorLockFailure':
              fontIcon = 'mdi-lock-outline';
              break;
            case 'DoorNotClosed':
              fontIcon = 'mdi-door-open';
              break;
            case 'ReaderFailure':
              fontIcon = 'mdi-nfc-variant';
              break;
            case 'PowerOutage':
              svgIcon = 'power-off-outline';
              break;
            case 'GroundFailure':
            case 'OverCurrentFailure':
            case 'PowerSwitchFailure':
            case 'UnderVoltage':
              svgIcon = 'electricity-alert-outline';
              break;
            default:
              svgIcon = 'ev-station-alert';
              break;
          }
        } else {
          svgIcon = 'ev-station-alert';
        }
        break;
      case 'IT_SERVICE_STATUS':
        svgIcon = 'cloud-alert-outline';
        break;
      case 'BANK_CONNECTION_ALERT':
        svgIcon = 'bank-outline';
        break;
      case 'TERMINAL_CONNECTION_ALERT':
        fontIcon = 'mdi-contactless-payment';
        break;
      case 'INCONSISTENT_REFERENTIAL':
        fontIcon = 'mdi-file-document';
        break;
    }
    return { color, fontIcon, svgIcon };
  }

  getStatusErrorCode(alert: Alert): string {
    if (alert.initiatorEvent && alert.initiatorEvent.details) {
      const details = alert.initiatorEvent.details;
      if (
        details.vendorErrorCode &&
        KNOWN_VENDOR_ERROR_CODES.indexOf(details.vendorErrorCode) > -1
      ) {
        return details.vendorErrorCode;
      } else if (
        details.errorCode &&
        details.errorCode !== 'NoError' &&
        details.errorCode !== 'InternalError' &&
        details.errorCode !== 'OtherError'
      ) {
        return details.errorCode;
      }
    }
    return undefined;
  }

  getAlertEvents(eventsId: string[]) {
    const url = `${this.alertApiUrl}/events`;
    return this.http.get<AlertEvent[]>(url, { params: { eventsId } });
  }

  getAlertHistoryDisplay(alertHistory: AlertHistory): AlertHistoryDisplay {
    const errorCodeEvent = this.getStatusErrorCodeEvent(alertHistory.errorCodeEvent);
    return {
      history: alertHistory,
      avatar: this.getAlertHistoryAvatar(alertHistory, errorCodeEvent),
      background: (
        alertHistory.inputSeverity +
        '-' +
        (alertHistory.outupSeverity ? alertHistory.outupSeverity : 'closed')
      ).toLowerCase()
    };
  }

  getStatusErrorCodeEvent(errorCodeEvent: string): string {
    if (
      errorCodeEvent &&
      errorCodeEvent !== 'NoError' &&
      errorCodeEvent !== 'InternalError' &&
      errorCodeEvent !== 'OtherError'
    ) {
      return errorCodeEvent;
    }
    return undefined;
  }

  private getAlertHistoryAvatar(alertHistory: AlertHistory, errorCodeEvent: string): AlertAvatar {
    let color: string;
    let fontIcon: string;
    let svgIcon: string;
    switch (alertHistory.outupSeverity) {
      case 'Signal':
        color = 'signal-severity';
        break;
      case 'Info':
        color = 'info-severity';
        break;
      case 'Warning':
        color = 'warning-severity';
        break;
      case 'Critical':
        color = 'critical-severity';
        break;
      default:
        color = 'signal-severity';
        break;
    }

    switch (alertHistory.typeEvent) {
      case 'NO_COMM':
        fontIcon = 'mdi-link-off';
        break;
      case 'COMM':
        fontIcon = 'mdi-link';
        break;
      case 'STATUS_NOTIFICATION':
        if (errorCodeEvent) {
          switch (errorCodeEvent) {
            case 'CommunicationError':
              svgIcon = 'ev-station-alert';
              break;
            case 'ConnectorLockFailure':
              fontIcon = 'mdi-lock-outline';
              break;
            case 'DoorNotClosed':
              fontIcon = 'mdi-door-open';
              break;
            case 'ReaderFailure':
              fontIcon = 'mdi-nfc-variant';
              break;
            case 'PowerOutage':
              svgIcon = 'power-off-outline';
              break;
            case 'GroundFailure':
            case 'OverCurrentFailure':
            case 'PowerSwitchFailure':
            case 'UnderVoltage':
              svgIcon = 'electricity-alert-outline';
              break;
            default:
              svgIcon = 'ev-station-alert';
              break;
          }
        } else {
          svgIcon = 'ev-station-alert';
        }
        break;
      case 'CLOSE_ALERT':
        svgIcon = 'power-off-outline';
        break;
      case 'FLAPPING':
        fontIcon = 'mdi-sync-alert';
        break;
      case 'STATUS_DEVICE':
        fontIcon = 'mdi-alert-outline';
        break;
      case 'ILLEGAL_PARKING':
      case 'SENSOR_PARKING':
        fontIcon = 'mdi-alpha-p-circle-outline';
        break;
      case 'CONNECTOR_ID_NOT_FOUND':
        fontIcon = 'mdi-file-document';
        break;
      default:
        svgIcon = 'ev-station-alert';
        break;
    }
    return { color, fontIcon, svgIcon };
  }

  getNbDocuments(httpParams: HttpParams): Observable<number> {
    const url = `${this.alertApiUrl}/count`;
    return this.http.get<number>(url, { params: httpParams });
  }
}
