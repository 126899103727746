import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EvController } from '@app/core/ev-controller/ev-controller';

@Injectable({
  providedIn: 'root'
})
export class EvControllerService {
  private evcEndpoint = '/apigw/evc-manager/api/ev-controllers';

  constructor(private http: HttpClient) {}

  findById(id: string): Observable<EvController> {
    return this.http.get<EvController>(`${this.evcEndpoint}/${id}`);
  }
}
