import { Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { EvControllerSummary } from '@app/core/ev-controller/ev-controller';

@Component({
  selector: 'app-ev-controller-summary-header',
  templateUrl: 'ev-controller-summary-header.component.html',
  styleUrls: ['ev-controller-summary-header.component.scss']
})
export class EvControllerSummaryHeaderComponent {
  @Input() evController: EvControllerSummary;

  constructor(public translate: TranslateService) {}
}
