import { Injectable } from '@angular/core';

/**
 * This service was created to read a csv file.
 *
 * Input : FileList : une liste de fichier, mais seulement le premier sera lu.
 *
 * Output : Promise<any[]> : une liste d'object sera retourné une fois la lecture
 * et la transformation terminée.
 *
 */

@Injectable({
  providedIn: 'root'
})
export class CsvReaderService {
  async readCsv(files: FileList): Promise<any[]> {
    const itemsList = [];
    const file: File = files.item(0);
    const fileContents = await this.readFile(file);
    let nbLines = 0;

    let csv: string = fileContents as string;

    // To avoid server error about decimal number
    const regexForDecimalNumber = /(?![0-9]+)[,]{1}(?=[0-9]+)/g;
    csv = csv.replace(regexForDecimalNumber, '.');

    const data = csv.replace(/\r/g, '').split('\n');
    const csvColumns = data[0].split(';');
    const csvContent = data.slice(1);
    nbLines = csvContent.length;

    csvContent.forEach((itemString, index) => {
      const temp: any = {};
      temp.line = index + 2;
      temp.currentStatus = undefined;

      let j = 0;
      const itemValues = itemString.split(';');

      if (itemValues.length === csvColumns.length) {
        csvColumns.forEach(col => {
          if (col.split('.').length >= 2) {
            const parentObj = col.split('.')[0];
            const childObj = col.split('.')[1];
            temp[parentObj] = temp[parentObj] ? temp[parentObj] : {};
            temp[parentObj][childObj] = itemValues[j] === '' ? undefined : itemValues[j];
            j++;
          } else {
            temp[col] = itemValues[j] === '' ? undefined : itemValues[j];
            if (col === 'extraInfo' && itemValues[j]) {
              temp[col] = this.createExtraInfo(itemValues[j]);
            }
            j++;
          }
        });

        itemsList.push(temp);
      }
    });
    return itemsList;
  }

  private readFile(file: File) {
    const reader: FileReader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.readAsText(file, 'ascii');
    });
  }

  private createExtraInfo(items: any): {} {
    const temp = {};
    const extraInfos = items.replace(/\r/g, '').split('|');
    extraInfos.forEach(info => {
      info = info.split(':');
      temp[info[0]] = info[1];
    });
    return temp;
  }
}
