import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { chipInOut, chipListInOut, fadeInOut, swipeInOut } from '@app/shared';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { iif, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { Cpo, THEMES, UserGroup, UserProfile } from '@app/core';
import { CpoGroup } from '@app/core/infrastructure/cpo_group';
import { Emsp } from '@app/core/infrastructure/emsp';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@app/core/infrastructure/location';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Fleet } from '@app/core/dedicated-fleet/fleet';
import { ADMIN, UserService } from '@app/user';
import { AccessTarget } from '@app/user/user-group-list/access-target.model';
import { InfrastructureService } from '@app/infrastructure';
import { ConfigService } from 'src/environments/config.service';
import { AppNavigationService } from '@app/navigation';
import { LOG } from '@zxing/library/es2015/core/datamatrix/encoder/constants';

export interface AutoCompleteOption {
  display: string;
  value: string;
}

export const PROFILE_OPTIONS: UserProfile[] = [
  'ACCOR_USER',
  'USER_ADMIN',
  'EMSP_FUNC_ADMIN',
  'FLEET',
  'SUPPORT',
  'SPONSOR',
  'INFRASTRUCTURE_MANAGER',
  'INFRASTRUCTURE_ADMIN',
  'INFRASTRUCTURE_IMPORT',
  'LOCAL_ELECTRIC_MANAGER',
  'ENERGY_OPERATOR',
  'ADMIN',
  'SETPOINT_PROVIDER',
  'ENERGY_TREE_ADMINISTRATOR',
  'REGULATION_ADMINISTRATOR',
  'ACTIVITY_MANAGER',
  'FLEET_MANAGER',
  'EMSP_FUNC_SUPPORT',
  'COMMISSIONING',
  'PRICE_MANAGER',
  'MARKETPLACE_MANAGER',
  'METADATA_SUBMITTER',
  'METADATA_ADMIN'
];

enum FormControlName {
  key = 'key',
  profile = 'profile',
  theme = 'theme',
  subUserGroup = 'subUserGroup',
  cpoGroup = 'cpoGroup',
  cpo = 'cpo',
  emsp = 'emsp',
  fleet = 'fleet',
  location = 'location',
  administrationProfile = 'administrationProfile'
}

@Component({
  selector: 'app-user-group-dialog',
  templateUrl: './user-group-dialog.component.html',
  styleUrls: ['./user-group-dialog.component.scss'],
  animations: [chipListInOut, chipInOut, fadeInOut, swipeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserGroupDialogComponent implements OnInit {
  formGroup: FormGroup;
  private editMode = false;
  readonly FormControlName: typeof FormControlName = FormControlName;

  disableOtherWhenLocationIsSet = false;
  disableLocation = true;

  profiles: AutoCompleteOption[] = [];
  filteredProfiles: Observable<AutoCompleteOption[]>;
  private profileOptions: AutoCompleteOption[];

  themes: AutoCompleteOption[] = [];
  filteredThemes: Observable<AutoCompleteOption[]>;
  private themeOptions: AutoCompleteOption[] = THEMES.map(theme => {
    return { display: theme.name || theme.value, value: theme.value };
  });

  subUserGroups: AutoCompleteOption[] = [];
  filteredSubUserGroups: Observable<AutoCompleteOption[]>;
  private subUserGroupOptions: AutoCompleteOption[];

  cpoGroups: AutoCompleteOption[] = [];
  filteredCpoGroups: Observable<AutoCompleteOption[]>;
  private cpoGroupOptions: AutoCompleteOption[];

  cpos: AutoCompleteOption[] = [];
  filteredCpos: Observable<AutoCompleteOption[]>;
  private cpoOptions: AutoCompleteOption[];

  private locationChipList: Location[] = [];

  emsps: AutoCompleteOption[] = [];
  filteredEmsps: Observable<AutoCompleteOption[]>;
  private emspOptions: AutoCompleteOption[];

  locations: AutoCompleteOption[] = [];
  filteredLocation: Observable<AutoCompleteOption[]>;
  private locationsOptions: AutoCompleteOption[];

  fleets: AutoCompleteOption[] = [];
  filteredFleets: Observable<AutoCompleteOption[]>;
  private fleetOptions: AutoCompleteOption[];

  private _destroyed = new Subject<void>();

  private accessibleSubUserGroups: UserGroup[];
  private accessibleCpoGroups: CpoGroup[];
  private accessibleCpos: Cpo[];
  private accessibleEmsps: Emsp[];
  private accessibleFleets: Fleet[];

  administrationProfiles: AutoCompleteOption[] = [];
  filteredAdministrationProfiles: Observable<AutoCompleteOption[]>;
  private administrationProfilesOptions: AutoCompleteOption[];
  private isConnectedUserAdmin: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { userKey: string; userGroup?: UserGroup },
    private translate: TranslateService,
    private dialogRef: MatDialogRef<UserGroupDialogComponent>,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private infrastructureService: InfrastructureService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _configService: ConfigService,
    private navService: AppNavigationService
  ) {
    if (data.userGroup?._key) {
      this.editMode = true;
    }

    this.administrationProfilesOptions = this.loadAllAdministrationProfiles();

    if (this.editMode) {
      this.setChipListValuesInEditMode();
    } else {
      this.profiles = [
        { display: this.translate.instant('user.profile.USER_ADMIN'), value: 'USER_ADMIN' },
        { display: this.translate.instant('user.profile.SUPPORT'), value: 'SUPPORT' },
        { display: this.translate.instant('user.profile.SPONSOR'), value: 'SPONSOR' }
      ];
    }
  }

  private setChipListValuesInEditMode() {
    if (this.editMode) {
      this.themes = this.themeOptions.filter(themeOption =>
        this.data.userGroup.themes.some(theme => theme === themeOption.value)
      );
      this.profiles = this.data.userGroup.profiles.map(profile => ({
        display: this.translate.instant(`user.profile.${profile}`),
        value: profile
      }));
      this.subUserGroups = this.data.userGroup.userGroups.map(subUserGroup => {
        return { display: subUserGroup._key, value: subUserGroup._key };
      });
      this.cpoGroups = this.data.userGroup.cpoGroups.map(cpoGroup => {
        return { display: cpoGroup._key, value: cpoGroup._key };
      });
      this.cpos = this.data.userGroup.cpos.map(cpo => {
        return { display: cpo.name || cpo._key, value: cpo._key };
      });
      this.emsps = this.data.userGroup.emsps.map(emsp => {
        return { display: emsp.name || emsp._key, value: emsp._key };
      });
      this.fleets = this.data.userGroup.fleets.map(fleet => {
        return { display: fleet.label || fleet._key, value: fleet._key };
      });
      this.locations = this.data.userGroup.locations.map(location => {
        this.locationChipList.push(location);
        return { display: location.name || location._key, value: location._key };
      });

      this.administrationProfiles = this.administrationProfilesOptions.filter(admProfileOption =>
        this.data.userGroup.administrationProfiles?.some(
          admProfile => admProfile === admProfileOption.value
        )
      );
    }
  }

  ngOnInit(): void {
    this.createFormGroup();
    this.loadProfileOptions();
    this.onLoadAdministrationProfileAutoCompleteOptions();
    this.onLoadThemeAutoCompleteOptions();
    this.loadAccessTargetList();

    if (this.editMode && this.data.userGroup?.locations?.length) {
      this.disableOtherWhenLocationIsSet = true;
      this.disableLocation = false;
    } else {
      this.getFormControl(FormControlName.location).disable();
    }

    this.filteredAdministrationProfiles = this.setFilteredControlArray(
      FormControlName.administrationProfile,
      this.administrationProfilesOptions,
      this.administrationProfiles
    );

    this.filteredThemes = this.setFilteredControlArray(
      FormControlName.theme,
      this.themeOptions,
      this.themes
    );

    this.dialogRef
      .keydownEvents()
      .pipe(takeUntil(this._destroyed))
      .subscribe(event => {
        if (event.key === 'Escape') {
          this.dialogRef.close();
        }
      });
  }

  private loadProfileOptions(): void {
    this.navService
      .isConnectedUserAdmin()
      .pipe(
        tap(value => {
          this.isConnectedUserAdmin = value;
        }),
        takeUntil(this._destroyed)
      )
      .subscribe(() => {
        if (this.isConnectedUserAdmin) {
          this.profileOptions = PROFILE_OPTIONS.map(profile => this.mapProfileOptions(profile));
        } else {
          this.profileOptions = PROFILE_OPTIONS.filter(
            availableProfile => availableProfile !== ADMIN
          ).map(profile => this.mapProfileOptions(profile));
        }
        this.onLoadProfileAutoCompleteOptions();
        this.filteredProfiles = this.setFilteredControlArray(
          FormControlName.profile,
          this.profileOptions,
          this.profiles
        );
      });
  }
  private mapProfileOptions(profile: UserProfile): { display: string; value: UserProfile } {
    return { display: this.translate.instant(`user.profile.${profile}`), value: profile };
  }

  private createFormGroup() {
    this.formGroup = this.formBuilder.group({
      key: [
        { value: this.data.userGroup?._key || '', disabled: this.editMode },
        Validators.required
      ],
      profile: '',
      theme: '',
      subUserGroup: '',
      cpoGroup: '',
      cpo: '',
      emsp: '',
      fleet: '',
      location: '',
      administrationProfile: ''
    });
  }

  private loadAccessTargetList(): void {
    this.userService
      .getAccessTarget(this.data.userKey)
      .pipe(takeUntil(this._destroyed))
      .subscribe((access: AccessTarget) => {
        this.accessibleSubUserGroups = access.subUserGroups;
        this.onLoadSubUserGroupAutoCompleteOptions();
        this.accessibleCpoGroups = access.cpoGroups;
        this.onLoadCpoGroupAutoCompleteOptions();
        this.accessibleCpos = access.cpos;
        this.onLoadCpoAutoCompleteOptions();
        this.accessibleEmsps = access.emsps;
        this.onLoadEmspAutoCompleteOptions();
        this.accessibleFleets = access.fleets;
        this.onLoadFleetAutoCompleteOptions();

        this.filteredSubUserGroups = this.setFilteredControlArray(
          FormControlName.subUserGroup,
          this.subUserGroupOptions,
          this.subUserGroups
        );

        this.filteredCpoGroups = this.setFilteredControlArray(
          FormControlName.cpoGroup,
          this.cpoGroupOptions,
          this.cpoGroups
        );

        this.filteredCpos = this.setFilteredControlArray(
          FormControlName.cpo,
          this.cpoOptions,
          this.cpos
        );

        this.filteredEmsps = this.setFilteredControlArray(
          FormControlName.emsp,
          this.emspOptions,
          this.emsps
        );

        this.filteredFleets = this.setFilteredControlArray(
          FormControlName.fleet,
          this.fleetOptions,
          this.fleets
        );
      });
  }

  private setFilteredControlArray(
    formControlname: FormControlName,
    controlArrayOptions: AutoCompleteOption[],
    controlArray: AutoCompleteOption[]
  ): Observable<AutoCompleteOption[]> {
    return this.getFormControl(formControlname).valueChanges.pipe(
      startWith(''),
      map(formControlValue =>
        this.filterAutocompleteOptions(controlArrayOptions, controlArray, formControlValue)
      )
    );
  }

  private loadLocationListByCpo(cpoSelected: string): void {
    this.infrastructureService
      .getLocationCpo(cpoSelected)
      .pipe(takeUntil(this._destroyed))
      .subscribe(locationlist => {
        this.toDoAfterLoadLocations(locationlist);
      });
  }

  private toDoAfterLoadLocations(locationlist: Location[]) {
    locationlist.forEach(element => this.locationChipList.push(element));
    this.onLoadLocationAutoCompleteOptions();
    this.filteredLocation = this.setFilteredControlArray(
      FormControlName.location,
      this.locationsOptions,
      this.locations
    );
  }

  private loadLocationListByCpoGroup(cpoSelected: string): void {
    this.infrastructureService
      .getLocationCpoGroup(cpoSelected)
      .pipe(takeUntil(this._destroyed))
      .subscribe(locationlist => {
        this.toDoAfterLoadLocations(locationlist);
      });
  }

  private loadAllAdministrationProfiles(): AutoCompleteOption[] {
    return this._configService
      .getFeature('administrationProfiles')
      .profiles.map(admProfileWrapper => {
        return { value: admProfileWrapper.value, display: admProfileWrapper.name };
      });
  }

  private filterAutocompleteOptions(
    allOptions: AutoCompleteOption[],
    selectedOptions: AutoCompleteOption[],
    value: string | AutoCompleteOption
  ): AutoCompleteOption[] {
    if (!allOptions) {
      return [];
    }
    let options = allOptions.filter(
      option => !selectedOptions.some(selectedOption => selectedOption.value === option.value)
    );
    if (typeof value === 'string') {
      value = value.toLowerCase();
      options = options.filter(
        option => (option.display || option.value).toLowerCase().indexOf(value as string) > -1
      );
    }
    return options;
  }

  save(): void {
    const profilesUpdated = this.profiles.map(theme => theme.value as UserProfile);
    const themesUpdated = this.themes.map(theme => theme.value);
    const cpoGroupUpdated = this.accessibleCpoGroups.filter(accessibleCpoGroup =>
      this.cpoGroups.some(cpoGroup => cpoGroup.value === accessibleCpoGroup._key)
    );
    const subUserGroupUpdated = this.accessibleSubUserGroups.filter(accessibleSubUserGroup =>
      this.subUserGroups.some(subUserGroup => subUserGroup.value === accessibleSubUserGroup._key)
    );
    const cpoUpdated = this.accessibleCpos.filter(accessibleCpo =>
      this.cpos.some(cpo => cpo.value === accessibleCpo._key)
    );
    const emspUpdated = this.accessibleEmsps.filter(accessibleEmsp =>
      this.emsps.some(emsp => emsp.value === accessibleEmsp._key)
    );
    const fleetUpdated = this.accessibleFleets.filter(accessibleFleet =>
      this.fleets.some(fleet => fleet.value === accessibleFleet._key)
    );
    const locationUpdated = this.locationChipList.filter(
      accessibleLocation =>
        this.locations.findIndex(location => location.value === accessibleLocation._key) > -1
    );
    const administrationProfilesUpdated = this.administrationProfiles.map(
      admProfile => admProfile.value
    );

    const userGroupToSave: UserGroup = {
      _key: this.editMode
        ? this.data.userGroup._key
        : this.getFormControl(FormControlName.key).value,
      profiles: profilesUpdated,
      themes: themesUpdated,
      userGroups: subUserGroupUpdated,
      cpoGroups: cpoGroupUpdated,
      cpos: cpoUpdated,
      emsps: emspUpdated,
      fleets: fleetUpdated,
      locations: locationUpdated,
      administrationProfiles: administrationProfilesUpdated
    };

    iif(
      () => this.editMode,
      this.userService.updateUserGroup(userGroupToSave),
      this.userService.createUserGroup(userGroupToSave)
    )
      .pipe(takeUntil(this._destroyed))
      .subscribe(
        () => this.dialogRef.close(this.editMode),
        () =>
          this.snackBar.open(
            this.editMode
              ? this.translate.instant('user.userGroup.error.Failed to edit user group.')
              : this.translate.instant('user.userGroup.error.Failed to add user group.'),
            null,
            {
              duration: 5000
            }
          )
      );
  }

  getFormControl(name: FormControlName): FormControl {
    return this.formGroup.get(name) as FormControl;
  }

  //////////////////////////////////////
  /////////// REMOVE FILTER ////////////
  //////////////////////////////////////

  removeFilter(
    index: number,
    optionSelectedArray: AutoCompleteOption[],
    formControlName: FormControlName
  ): void {
    optionSelectedArray.splice(index, 1);
    this.getFormControl(formControlName).setValue('');
    this._changeDetectorRef.markForCheck();
  }

  removeCpoGroupFilter(index: number): void {
    this.removeFilter(index, this.cpoGroups, FormControlName.cpoGroup);

    if (!this.cpoGroups.length && !this.disableLocation) {
      this.disableLocation = true;
      this.locationChipList = [];
    }
    this._changeDetectorRef.markForCheck();
  }

  removeCpoFilter(index: number): void {
    this.removeFilter(index, this.cpos, FormControlName.cpo);

    if (!this.cpos.length && !this.disableLocation) {
      this.disableLocation = true;
      this.locationChipList = [];
    }
    this._changeDetectorRef.markForCheck();
  }

  removeLocationFilter(index: number): void {
    this.removeFilter(index, this.locations, FormControlName.location);

    if (!this.locations.length && this.disableOtherWhenLocationIsSet) {
      this.disableOtherWhenLocationIsSet = false;
    }
    this._changeDetectorRef.markForCheck();
  }

  //////////////////////////////////////
  ///////// ON OPTION SELECTED /////////
  //////////////////////////////////////

  onOptionSelected(
    event: MatAutocompleteSelectedEvent,
    input: HTMLInputElement,
    optionSelectedArray: AutoCompleteOption[],
    formControlName: FormControlName,
    trigger: MatAutocompleteTrigger
  ): void {
    this.openPanel(trigger);
    const selected: AutoCompleteOption = event.option.value;

    if (!optionSelectedArray.includes(selected)) {
      optionSelectedArray.push(selected);
    }

    this.resetFormControl(input, formControlName);
  }

  private openPanel(trigger: MatAutocompleteTrigger) {
    setTimeout(() => {
      trigger.openPanel();
    }, 0);
  }

  onCpoGroupOptionSelected(
    event: MatAutocompleteSelectedEvent,
    input: HTMLInputElement,
    trigger: MatAutocompleteTrigger
  ): void {
    this.openPanel(trigger);
    const selected = event.option.value;

    if (!this.cpoGroups.includes(selected)) {
      this.cpoGroups.push(selected);
      if (this.cpoGroups.length && this.disableLocation) {
        this.disableLocation = false;
      }
    }

    this.loadLocationListByCpoGroup(selected.value);

    this.resetFormControl(input, FormControlName.cpoGroup);
  }

  onCpoOptionSelected(
    event: MatAutocompleteSelectedEvent,
    input: HTMLInputElement,
    trigger: MatAutocompleteTrigger
  ): void {
    this.openPanel(trigger);
    const selected = event.option.value;

    if (!this.cpos.includes(selected)) {
      this.cpos.push(selected);
      if (this.cpos.length && this.disableLocation) {
        this.disableLocation = false;
      }
    }

    this.loadLocationListByCpo(selected.value);

    this.resetFormControl(input, FormControlName.cpo);
  }

  onLocationOptionSelected(
    event: MatAutocompleteSelectedEvent,
    input: HTMLInputElement,
    trigger: MatAutocompleteTrigger
  ): void {
    this.openPanel(trigger);
    const selected = event.option.value;

    if (!this.locations.includes(selected)) {
      this.locations.push(selected);
      if (this.locations.length && !this.disableOtherWhenLocationIsSet) {
        this.disableOtherWhenLocationIsSet = true;
      }
    }

    this.resetFormControl(input, FormControlName.location);
  }

  private resetFormControl(input: HTMLInputElement, formControlName: FormControlName): void {
    input.value = '';
    this.getFormControl(formControlName).setValue('');
  }

  //////////////////////////////////////
  ////////////// ON LOAD ///////////////
  //////////////////////////////////////

  private onLoadProfileAutoCompleteOptions(): void {
    this.profileOptions.sort((a: AutoCompleteOption, b: AutoCompleteOption) =>
      this.sortAutoCompleteOptions(a, b)
    );
  }

  private onLoadAdministrationProfileAutoCompleteOptions(): void {
    this.administrationProfiles.sort((a: AutoCompleteOption, b: AutoCompleteOption) =>
      this.sortAutoCompleteOptions(a, b)
    );
  }

  private onLoadThemeAutoCompleteOptions(): void {
    this.themeOptions.sort((a: AutoCompleteOption, b: AutoCompleteOption) =>
      this.sortAutoCompleteOptions(a, b)
    );
  }

  private onLoadLocationAutoCompleteOptions(): void {
    this.locationsOptions = this.locationChipList
      .map(location => {
        return { display: location.name || location._key, value: location._key };
      })
      .sort((a: AutoCompleteOption, b: AutoCompleteOption) => this.sortAutoCompleteOptions(a, b));
  }

  private onLoadSubUserGroupAutoCompleteOptions(): void {
    this.subUserGroupOptions = this.accessibleSubUserGroups
      .map(subUserGroup => {
        return { display: subUserGroup._key, value: subUserGroup._key };
      })
      .sort((a: AutoCompleteOption, b: AutoCompleteOption) => this.sortAutoCompleteOptions(a, b));
  }

  private onLoadCpoGroupAutoCompleteOptions(): void {
    this.cpoGroupOptions = this.accessibleCpoGroups
      .map(cpoGroup => {
        return { display: cpoGroup._key, value: cpoGroup._key };
      })
      .sort((a: AutoCompleteOption, b: AutoCompleteOption) => this.sortAutoCompleteOptions(a, b));
  }

  private onLoadCpoAutoCompleteOptions(): void {
    this.cpoOptions = this.accessibleCpos
      .map(cpo => {
        return { display: cpo.name || cpo._key, value: cpo._key };
      })
      .sort((a: AutoCompleteOption, b: AutoCompleteOption) => this.sortAutoCompleteOptions(a, b));
  }

  private onLoadEmspAutoCompleteOptions(): void {
    this.emspOptions = this.accessibleEmsps
      .map(emsp => {
        return { display: emsp.name || emsp._key, value: emsp._key };
      })
      .sort((a: AutoCompleteOption, b: AutoCompleteOption) => this.sortAutoCompleteOptions(a, b));
  }

  private onLoadFleetAutoCompleteOptions(): void {
    this.fleetOptions = this.accessibleFleets
      .map(fleet => {
        return { display: fleet.label || fleet._key, value: fleet._key };
      })
      .sort((a: AutoCompleteOption, b: AutoCompleteOption) => this.sortAutoCompleteOptions(a, b));
  }

  private sortAutoCompleteOptions(a: AutoCompleteOption, b: AutoCompleteOption): number {
    const aStr = (a.display || a.value).toLowerCase();
    const bStr = (b.display || b.value).toLowerCase();

    return aStr.localeCompare(bStr);
  }

  get isAdministrationProfileEnabled(): boolean {
    return this._configService.isFeatureEnabled('administrationProfiles');
  }
}
