export enum EnergySourceType {
  NUCLEAR = 'NUCLEAR',
  GENERAL_FOSSIL = 'GENERAL_FOSSIL',
  COAL = 'COAL',
  GAS = 'GAS',
  GENERAL_GREEN = 'GENERAL_GREEN',
  SOLAR = 'SOLAR',
  WIND = 'WIND',
  WATER = 'WATER'
}
