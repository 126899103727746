import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';

import { SKIP_AUTH_HEADER } from '@app/auth/interceptor/skip-auth-header';
import {
  LangChangeEvent,
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateCompiler,
  TranslateDefaultParser,
  TranslateLoader,
  TranslateParser,
  TranslateService,
  TranslateStore,
  USE_DEFAULT_LANG,
  USE_STORE
} from '@ngx-translate/core';
import * as moment from 'moment';
import {
  MESSAGE_FORMAT_CONFIG,
  TranslateMessageFormatCompiler
} from 'ngx-translate-messageformat-compiler';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

export const TRANSALATION_MODULE_NAMES = new InjectionToken<string[]>('TranslationModuleName');

const REGEX = /^[a-zA-Z0-9\.]*\.(.*)$/;
// Show last part of key if missing
@Injectable()
export class AppMissingTranslationHandler extends MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    const key = params.key;
    const group = key.match(REGEX);
    if (group && group.length > 0) {
      return group[1];
    }
    return key;
  }
}

export class AppTranslateLoader extends TranslateLoader {
  constructor(
    private http: HttpClient,
    @Inject(TRANSALATION_MODULE_NAMES) private moduleNames: string[]
  ) {
    super();
  }

  public getTranslation(lang: string): Observable<any> {
    if (lang && lang.startsWith('fr-')) {
      lang = 'fr';
    }
    if (lang) {
      return forkJoin(
        this.moduleNames.map(moduleName =>
          this.http.get(`assets/i18n/${moduleName}/${lang}.json`, {
            headers: { [SKIP_AUTH_HEADER]: '' }
          })
        )
      ).pipe(
        map(results => {
          let result = {};
          results.forEach(i => (result = Object.assign(result, i)));
          return result;
        })
      );
    } else {
      return of(null);
    }
  }
}

export const LOCALES = ['en-US', 'fr-FR', 'es-ES', 'nl-NL', 'de-DE', 'it-IT', 'en-GB', 'nl-BE'];

@NgModule()
export class AppTranslateModule {
  static forRoot(): ModuleWithProviders<AppTranslateModule> {
    return {
      ngModule: AppTranslateModule,
      providers: [
        AppMissingTranslationHandler,
        { provide: TRANSALATION_MODULE_NAMES, useValue: ['common'] },
        { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: LOCALES } },
        {
          provide: TranslateLoader,
          useClass: AppTranslateLoader,
          deps: [HttpClient, TRANSALATION_MODULE_NAMES]
        },
        { provide: TranslateCompiler, useClass: TranslateMessageFormatCompiler },
        { provide: TranslateParser, useClass: TranslateDefaultParser },
        { provide: MissingTranslationHandler, useClass: AppMissingTranslationHandler },
        TranslateStore,
        { provide: USE_STORE, useValue: false },
        { provide: USE_DEFAULT_LANG, useValue: false },
        TranslateService
      ]
    };
  }

  static forChild(moduleNames: string[]): ModuleWithProviders<any> {
    return {
      ngModule: AppTranslateModule,
      providers: [
        { provide: TRANSALATION_MODULE_NAMES, useValue: moduleNames },
        {
          provide: TranslateLoader,
          useClass: AppTranslateLoader,
          deps: [HttpClient, TRANSALATION_MODULE_NAMES]
        }
      ]
    };
  }

  constructor(
    @Inject(TRANSALATION_MODULE_NAMES) private moduleNames: string[],
    private loader: TranslateLoader,
    private translate: TranslateService
  ) {
    if (translate.currentLang) {
      this.loadTranslations(translate.currentLang);
    }
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      moment.locale(event.lang);
      this.loadTranslations(event.lang);
    });
  }

  private loadTranslations(lang: string) {
    let load;
    for (const moduleName of this.moduleNames) {
      if (this.translate.instant(moduleName) === moduleName) {
        load = true;
        break;
      }
    }
    if (load) {
      this.loader.getTranslation(lang).subscribe(translations => {
        this.translate.setTranslation(lang, translations, true);
      });
    }
  }
}
