import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subtask, Task } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { EvseService } from '@app/referencial/park/core/http-services/evse.service';
import { InfraEvse } from '@app/core/park/evse/infraEvse';

export interface SameTask {
  task: Task;
  subtask: Subtask;
  target: InfraEvse;
}

@Component({
  selector: 'app-same-task-dialog',
  templateUrl: './same-task-dialog.component.html',
  styleUrls: ['../../referencial/park/core/shared/form.scss', './same-task-dialog.component.scss']
})
export class SameTaskDialogComponent {
  datasource: MatTableDataSource<SameTask>;
  displayedColumns: string[] = ['target', 'creation_date', 'link'];
  evses: InfraEvse[] = [];
  actionsDisabled = true;

  constructor(
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data,
    private evseService: EvseService
  ) {
    const evseIds = this.data.sameTasks.map(sameTask => sameTask.subtask.target.split('/')[1]);

    this.evseService.getEvsesDetails(evseIds).subscribe((evses: InfraEvse[]) => {
      const sameTasks = data.sameTasks;
      sameTasks.forEach(sameTask => {
        sameTask.target = evses.find(
          target => target?.id === sameTask.subtask.target.split('/')[1]
        );
      });

      this.datasource = new MatTableDataSource<SameTask>(sameTasks);
      this.actionsDisabled = false;
    });
  }

  openTaskLink(sameTask: SameTask) {
    const fullCurrentUrl = window.location.href.split('/');
    const url = `${fullCurrentUrl.slice(0, fullCurrentUrl.length - 2).join('/')}/task?id=${
      sameTask.task._id
    }`;
    window.open(url, '_blank');
  }
}
