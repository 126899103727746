import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-delete-ticket-dialog',
  templateUrl: 'delete-ticket-dialog.component.html',
  styleUrls: ['delete-ticket-dialog.component.scss']
})
export class AlertDeleteTicketDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) {}
}
