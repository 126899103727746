import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss']
})
export class SectionTitleComponent {
  @Input() title: string;
  @Input() actionBtn = false;
  @Input() fontIconBtn: string;
  @Input() colorBtn: 'primary' | 'warn' | 'accent' = 'primary';
  @Output() action: EventEmitter<any> = new EventEmitter();

  constructor() {}

  onBtnClick(): void {
    if (this.actionBtn) this.action.emit();
  }
}
