import { Component, Inject } from '@angular/core';

import { Transaction } from '@app/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-close-tx-dialog',
  templateUrl: './close-tx-dialog.component.html',
  styleUrls: ['./close-tx-dialog.component.scss']
})
export class CloseTxDialogComponent {
  request = { stopDate: new Date() };
  zeroAllowed: false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { transaction: Transaction },
    public dialogRef: MatDialogRef<any>
  ) {}
}
