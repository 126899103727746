export enum ChargingProgress {
  START_TRANSACTION = 'START_TRANSACTION',
  CHARGE = 'CHARGE',
  CHARGE_UP = 'CHARGE_UP',
  CHARGE_END = 'CHARGE_END',
  CHARGE_FULL = 'CHARGE_FULL',
  CHARGE_DOWN = 'CHARGE_DOWN',
  STOP_TRANSACTION = 'STOP_TRANSACTION',
  PAUSE = 'PAUSE',
  NO_CHARGE = 'NO_CHARGE'
}
