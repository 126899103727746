import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SmartChargingGuard implements CanActivate {
  constructor(private authService: AuthenticationService, protected router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.authService
      .getGrantedScopes()[0]
      .split(' ')
      .includes('local-elec-management');
  }
}
