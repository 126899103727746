// Huge hack of String class for chart label displaying.
// tslint:disable:ban-types
export class ChartLabel {
  get length() {
    return this.value.length;
  }

  constructor(private value: string, public toLocaleString: () => string) {}

  valueOf() {
    return this.value;
  }

  toString() {
    return this.value;
  }
}

Object.getOwnPropertyNames(String.prototype).forEach(key => {
  const func = String.prototype[key];
  if (key !== 'length' && key !== 'valueOf' && key !== 'toString') {
    ChartLabel.prototype[key] = func;
  }
});
