import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InfrastructureService } from '../../infrastructure.service';
import { Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

function formatField(field: string) {
  field = field || '';
  return field.trim().replace(/[^0-9a-z]/gi, '');
}

@Component({
  selector: 'app-add-rfid-card-credentiel-dialog',
  templateUrl: './add-rfid-card-credentiel-dialog.component.html',
  styleUrls: ['./add-rfid-card-credentiel-dialog.component.scss']
})
export class AddRfidCardCredentielDialogComponent {
  private readonly TOKEN_ID_PATTERN = '^[a-zA-Z0-9]*$';

  groupForm = this.fb.group({
    label: ['', [Validators.required, Validators.pattern(this.TOKEN_ID_PATTERN)]],
    tokenId: ['', [Validators.required, Validators.pattern(this.TOKEN_ID_PATTERN)]],
    active: false
  });

  private _destroyed = new Subject();
  private labelInput = this.groupForm.get('label');
  private tokenIdInput = this.groupForm.get('tokenId');
  private activeInput = this.groupForm.get('active');

  constructor(
    public dialogRef: MatDialogRef<AddRfidCardCredentielDialogComponent>,
    private fb: FormBuilder,
    private infraService: InfrastructureService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { cpoId: string; locationId: string; cpoKey: string }
  ) {}

  save(): void {
    const id = this.data.locationId ? this.data.locationId : this.data.cpoId.replace('cpo/', '');
    this.infraService
      .saveLocalToken(
        this.data.cpoId,
        this.data.locationId,
        this.labelInput.value,
        formatField(this.tokenIdInput.value),
        this.activeInput.value,
        id + '_' + formatField(this.tokenIdInput.value)
      )
      .pipe(takeUntil(this._destroyed))
      .subscribe(
        newAndOldLocalToken => {
          this.dialogRef.close();

          if (newAndOldLocalToken.oldLocationIds) {
            const message = `${this.translateService.instant(
              'infra.cpo.rfid.error.The RFID Cards, which already existed in the following locations, have been removed',
              { locations: newAndOldLocalToken.oldLocationIds.join(', ') }
            )}`;
            this.snackBar.open(message, null, { duration: 5000 });
          }
        },
        err => {
          let message = `${this.translateService.instant(
            'infra.cpo.rfid.error.Failed to add RFID card.'
          )}`;
          if (this.data.locationId && err.error === 'cpo') {
            message += `${this.translateService.instant(
              'infra.cpo.rfid.error.The RFID Card already exist in the CPO to whom belongs location'
            )}`;
          } else {
            message += `${this.translateService.instant(
              'infra.cpo.rfid.error.A RFID card with this id already exist in'
            )} ${this.translateService.instant('infra.cpo.rfid.error.' + err.error)}`;
          }
          this.snackBar.open(message, null, { duration: 5000 });
        }
      );
  }
}
