import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { AuthenticationService } from '../auth.service';

@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isLoggedIn() || req.url.includes('oauth2/token')) {
      return next.handle(req);
    }

    const apiKey = new URLSearchParams(location.search).get('api-key');

    if (apiKey) {
      const headers = new HttpHeaders({ 'api-key': apiKey });
      return next.handle(req.clone({ headers })).pipe(
        filter(event => event.type === HttpEventType.Response),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            this.router.navigate(['/commissioning', 'installer', 'invalid-token']);
          }
          return throwError(err);
        })
      );
    }

    return next.handle(req);
  }
}
