import {
  animateChild,
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
  sequence,
  state
} from '@angular/animations';
import { AnimationCurves } from '@angular/material/core';

export const footerAnim = trigger('footerFadeIn', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(`300ms ${AnimationCurves.STANDARD_CURVE}`, style({ opacity: 1 }))
  ])
]);

export const chevron = trigger('chevron', [
  state('up', style({ transform: 'rotate(0)' })),
  state('down', style({ transform: 'rotate(-180deg)' })),
  transition('* => *', animate(`500ms ${AnimationCurves.STANDARD_CURVE}`))
]);

export const showHideEvse = trigger('showHideEvse', [
  transition(':enter', [
    style({ height: 0 }),
    animate(`500ms ${AnimationCurves.STANDARD_CURVE}`, style({ height: '*' }))
  ]),

  transition(':leave', [
    style({ height: '!' }),
    animate(`375ms ${AnimationCurves.STANDARD_CURVE}`, style({ height: 0 }))
  ])
]);

export const listAnim = trigger('listAnim', [
  transition('* => *', [
    query(':leave', style({ display: 'none' }), { optional: true }),
    query(
      ':enter',
      [
        style({ opacity: 0 }),
        stagger(50, animate(`300ms ${AnimationCurves.STANDARD_CURVE}`, style({ opacity: 1 })))
      ],
      { optional: true }
    )
  ])
]);
