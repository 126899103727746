import { Component, Inject, ViewChild } from '@angular/core';
import { CsvService } from '@app/infrastructure/common/csv.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { InfrastructureService, NewAndOldLocalToken } from '../../infrastructure.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import processItemsInBatch from '@app/referencial/park/core/http-services/old_mass-import/batch.service';
import { CompareService } from '@app/infrastructure/common/compare.service';
import { BatchRequestResponse } from '@app/referencial/shared/model/batch-request-response';

enum Status {
  CREATED = 'created',
  KO = 'error',
  DUPLICATE = 'duplicate',
  UPDATED = 'updated',
  READY_TO_SEND = 'valid',
  READY_TO_INSERT = 'to be inserted',
  READY_TO_UPDATE = 'to be updated',
  NOTHING_TO_UPDATE = 'nothing to update'
}

const DEFAULT_COLUMNS = ['line', 'internalId', 'tokenId', 'label', 'active', 'status', 'message'];
const CONCURRENCY_NUMBER = 5;

class LocalTokenDisplay {
  constructor(
    public internalId: string,
    public line: number,
    public tokenId: string,
    public label: string,
    public active: boolean,
    public status: string,
    public message: string,
    public errors: Map<string, boolean>
  ) {}

  hasErrors() {
    return (
      this.errors.size !== 0 ||
      (this.status !== 'Valid' &&
        this.status !== 'Saved' &&
        this.status !== Status.NOTHING_TO_UPDATE &&
        this.status !== Status.READY_TO_UPDATE &&
        this.status !== Status.READY_TO_INSERT &&
        this.status !== 'Updated')
    );
  }
}

@Component({
  selector: 'app-mass-import-rfid-card-credentiel-dialog',
  templateUrl: './mass-import-rfid-card-credentiel-dialog.component.html',
  styleUrls: ['./mass-import-rfid-card-credentiel-dialog.component.scss']
})
export class MassImportRfidCardCredentielDialogComponent {
  displayedColumns = DEFAULT_COLUMNS;

  private readonly TOKEN_ID_PATTERN: RegExp = new RegExp('^[a-zA-Z0-9]*$');
  @ViewChild('fileInput') fileInput;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  private mandatoryFields = ['internalId', 'tokenId', 'label', 'active'];

  dataSource = new MatTableDataSource<LocalTokenDisplay>();
  itemList: LocalTokenDisplay[] = [];
  fileUploaded = false;
  uploadedTime;
  file: File;
  canSend = false;
  hasError = false;
  hideUnchanged = false;
  sendingData = false;
  canUploadCsvFile = true;
  oldList = [];
  newList = [];
  toUpdateList: Map<number, Map<string, any>>;
  isDataSent: boolean;

  constructor(
    public dialogRef: MatDialogRef<MassImportRfidCardCredentielDialogComponent>,
    public csvService: CsvService,
    private infraService: InfrastructureService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private compareService: CompareService,
    @Inject(MAT_DIALOG_DATA)
    public data: { cpoId: string; locationId: string; tokens: LocalTokenDisplay[] }
  ) {
    this.toUpdateList = new Map<number, Map<string, any>>();
    this.isDataSent = false;
  }

  getMandatoryFields() {
    return this.mandatoryFields;
  }

  getDisplayedColumns() {
    this.displayedColumns = DEFAULT_COLUMNS;
    return this.displayedColumns;
  }

  public async changeListener(files: FileList) {
    this.file = files[0];
    this.uploadedTime = Date.now();
    this.csvService.readCsv(files).then(async value => {
      if (value.length === 0) {
        this.updateLocalTokenList([]);
        this.hasError = false;
        this.fileUploaded = true;
        this.canSend = false;

        const message = 'CSV is empty';
        this.snackBar.open(message, null, { duration: 5000 });
      } else if (
        this.mandatoryFields.every(mandatoryField => Object.keys(value[0]).includes(mandatoryField))
      ) {
        const tmpList = [];
        const tokenKeys = [];
        this.newList = value;

        for (const item of value) {
          tokenKeys.push(item._key);
        }

        this.oldList = this.data.tokens;

        for (const item of value) {
          tmpList.push(this.csvItemToLocalToken(item));
        }

        this.updateLocalTokenList(tmpList);
        if (this.itemList.some(item => item.hasErrors())) {
          this.canSend = false;
          this.hasError = true;
          this.displayOnlyError(true);

          // all rows have nothing to change
        } else if (
          this.itemList.filter(item => item.status === Status.NOTHING_TO_UPDATE) &&
          this.itemList.length ===
            this.itemList.filter(item => item.status === Status.NOTHING_TO_UPDATE).length
        ) {
          this.canSend = false;
        } else {
          this.canSend = true;
          this.hasError = false;
        }

        this.fileUploaded = true;
        this.fileInput.nativeElement.value = '';
      } else {
        this.updateLocalTokenList([]);
        this.hasError = false;
        this.fileUploaded = false;
        this.canSend = false;

        const message = 'Format du csv invalide';
        this.snackBar.open(message, null, { duration: 5000 });
      }
    });
  }

  getCurrentCpoId(list: any[]): string {
    if (list && list.length > 0) {
      return list[0].cpoId;
    }
    return null;
  }

  saveOrUpdate(): void {
    this.sendingData = true;
    this.canSend = false;
    this.canUploadCsvFile = false;
    processItemsInBatch(
      this.itemList.filter(
        item => item.status === Status.READY_TO_INSERT || item.status === Status.READY_TO_UPDATE
      ),
      localToken =>
        localToken.status === Status.READY_TO_INSERT
          ? this.infraService.saveLocalToken(
              this.data.cpoId,
              this.data.locationId,
              localToken.label,
              this.formatField(localToken.tokenId),
              localToken.active,
              localToken.internalId
            )
          : this.infraService.updateLocalToken(
              localToken.internalId,
              localToken.label,
              this.formatField(localToken.tokenId),
              localToken.active,
              this.data.cpoId,
              this.data.locationId
            ),
      CONCURRENCY_NUMBER
    ).subscribe((batchResult: BatchRequestResponse<NewAndOldLocalToken>) => {
      this.isDataSent = true;
      for (const [line, value] of batchResult.results) {
        if (value.result) {
          this.manageSuccessResponse(line, value.result);
        } else if (value.error) {
          this.manageErrorResponse(line, value.error);
        }
      }

      if (batchResult.itemsRemainingCount === 0) {
        this.manageSendComplete();
      }
    });
  }

  // Display only invalid items
  public displayOnlyError(onlyErrors: boolean) {
    this.dataSource.data = onlyErrors ? this.itemList.filter(it => it.hasErrors()) : this.itemList;
    this.dataSource.paginator = this.paginator;
  }

  // Display only invalid items
  public hideUnChanged(hideUnchanged: boolean) {
    this.dataSource.data = hideUnchanged
      ? this.itemList.filter(it => it.status !== Status.NOTHING_TO_UPDATE)
      : this.itemList;
    this.dataSource.paginator = this.paginator;
  }

  public getInvalidItemCount(): number {
    return this.itemList.filter(item => item.errors.size !== 0).length;
  }

  private manageSuccessResponse(line: number, response: NewAndOldLocalToken) {
    let newStatus: string;
    let message: string;
    if (response.oldLocationIds) {
      message = `${this.translateService.instant(
        'infra.cpo.rfid.error.The RFID Cards, which already existed in the following locations, have been removed',
        { locations: response.oldLocationIds.join(', ') }
      )}`;
      newStatus = 'Saved';
    } else {
      newStatus = 'Saved';
    }

    const item = this.itemList.find(it => it.line === line);
    item.status = newStatus;
    item.message = message;
  }

  private manageErrorResponse(line: number, error: any) {
    let message = `${this.translateService.instant(
      'infra.cpo.rfid.error.Failed to add RFID card.'
    )}`;
    if (this.data.locationId && error.error === 'cpo') {
      message += `${this.translateService.instant(
        'infra.cpo.rfid.error.The RFID Card already exist in the CPO to whom belongs location'
      )}`;
    } else {
      message += `${this.translateService.instant(
        'infra.cpo.rfid.error.A RFID card with this id already exist in'
      )} ${this.translateService.instant('infra.cpo.rfid.error.' + error.error)}`;
    }

    const item = this.itemList.find(it => it.line === line);
    item.status = 'Failure';
    item.message = message;
  }

  // Get the status of the item
  displayStatus(line: number): string {
    const item = this.itemList.find(it => it.line === line);
    switch (item.status) {
      case 'Failure':
        return Status.KO;
      case 'Valid':
      case Status.READY_TO_INSERT:
      case Status.READY_TO_UPDATE:
        return Status.READY_TO_SEND;
      case 'Invalid':
        return Status.KO;
      case 'Saved':
      case 'Updated':
        return Status.CREATED;
    }
  }

  private manageSendComplete() {
    this.updateLocalTokenList(this.itemList);
    this.sendingData = false;
    this.canUploadCsvFile = true;
    let message;
    if (this.itemList.some(op => op.hasErrors())) {
      message = 'Finished all operations with errors.';
      this.hasError = true;
      this.displayOnlyError(true);
    } else {
      message = 'Successfully finished all operations.';
    }
    this.snackBar.open(message, null, { duration: 5000 });
  }

  private updateLocalTokenList(localTokens: LocalTokenDisplay[]) {
    this.itemList = localTokens;
    this.dataSource.data = this.itemList;
    this.dataSource.paginator = this.paginator;
  }

  private formatField(field: string) {
    field = field || '';
    return field.trim().replace(/[^0-9a-z]/gi, '');
  }

  private csvItemToLocalToken(item: any): LocalTokenDisplay {
    const errors = new Map<string, boolean>();
    const messages = [];

    if (!item.internalId) {
      errors.set('internalId', true);
      messages.push('Missing internalId');
    }

    if (!item.tokenId || !this.TOKEN_ID_PATTERN.test(item.tokenId)) {
      errors.set('tokenId', true);
      messages.push('Missing tokenId or invalid format.');
    }
    if (!item.label) {
      errors.set('label', true);
      messages.push('Missing label');
    }
    if (
      !item.active ||
      (item.active.toLowerCase() !== 'true' && item.active.toLowerCase() !== 'false')
    ) {
      errors.set('active', true);
      messages.push("Field 'active' must be 'true' or 'false'.");
    }

    let changes = [];
    // check if filed changed
    const oldToken = this.oldList.filter(
      token => token && token.localToken && token.localToken.internalId === item.internalId
    );
    if (oldToken && oldToken.length > 0) {
      const copy = JSON.parse(JSON.stringify(oldToken[0].localToken));
      copy.active = copy.status === 'ACCEPTED';
      const compareItems = this.compareService.compareObjects(item, copy);
      if (compareItems) {
        this.toUpdateList.set(item.line, compareItems);
        changes = [...compareItems.keys()];
      }
    }
    const hasErrors = errors.size !== 0;

    return new LocalTokenDisplay(
      item.internalId,
      item.line,
      item.tokenId,
      item.label,
      this.getTokenStatus(item),
      hasErrors ? 'Invalid' : this.getStatus(changes, oldToken, item, errors, messages),
      messages.join('\n'),
      errors
    );
  }

  getTokenStatus(item): boolean {
    try {
      return Boolean(JSON.parse(item.active));
    } catch (error) {
      // parse error
      return false;
    }
  }

  getStatus(
    changes: string[],
    oldToken: any[],
    item: any,
    errors: Map<string, boolean>,
    messages
  ): string {
    const count = this.newList.filter(
      token => token.internalId === item.internalId || token.tokenId === item.tokenId
    ).length;
    if (count > 1) {
      errors.set('internalId', true);
      messages.push('internalId or tokenId is present more than once in the file.');
      return Status.DUPLICATE;
    } else if (oldToken && oldToken.length > 0) {
      if (changes && changes.length > 0) {
        return Status.READY_TO_UPDATE;
      } else {
        return Status.NOTHING_TO_UPDATE;
      }
    } else {
      return Status.READY_TO_INSERT;
    }
  }
}
