import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { fromEvent, Subject, Subscription, timer } from 'rxjs';
import { bufferTime, debounceTime, takeUntil } from 'rxjs/operators';

import { Alert } from '@app/core';
import { AppNavigationService } from '@app/navigation';
import { parseQueryParams } from '@app/shared';
import { AlertDisplay, AlertService } from '../alert.service';
import { accordionAnim, alertPanel, footerAnim, noMoreFadeIn } from './accordion-animations';
import { AlertAcknowledgeDialogComponent } from '../base/acknowledge-dialog/acknowledge-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@app/user';

const LOADING_SIZE = 20;

@Component({
  selector: 'app-alert-accordion',
  templateUrl: 'accordion.component.html',
  styleUrls: ['accordion.component.scss'],
  animations: [accordionAnim, alertPanel, footerAnim, noMoreFadeIn],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertAccordionComponent implements AfterViewInit, OnDestroy, OnInit {
  displays: AlertDisplay[] = [];
  viewInit: boolean;
  querying: boolean;
  totalCount = -1;
  acknowledgeQuinzeSecondsTimeout: any;
  acknowledgeFiveSecondsTimeout;
  hasUserAdminProfile: boolean;
  username: string;

  private stopAlertUpdateSub = new Subject<string>();
  private _destroyed = new Subject();
  private queryParams: any;
  private notifications = new Subject<string>();
  private stopQuery = new Subject();
  subscription: Subscription;

  clickoutHandler: any;

  dialogRef: MatDialogRef<AlertAcknowledgeDialogComponent>;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.clickoutHandler) {
      this.clickoutHandler(event);
    }
  }

  constructor(
    public translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private scrollDispatcher: ScrollDispatcher,
    private scrollToService: ScrollToService,
    private navService: AppNavigationService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.scrollDispatcher
      .scrolled()
      .pipe(takeUntil(this._destroyed))
      .subscribe(scrollable => {
        if (scrollable) {
          const nativeEl = scrollable.getElementRef().nativeElement;
          if (nativeEl.scrollTop + nativeEl.offsetHeight > nativeEl.scrollHeight - 48) {
            this.onScrolled();
          }
        }
      });

    this.userService.getUserByArangoId(this.navService.userId.value).subscribe(user => {
      this.hasUserAdminProfile = user.profiles?.includes('USER_ADMIN');
      this.username = user.username;
    });

    this.notifications.pipe(takeUntil(this._destroyed), bufferTime(1000)).subscribe(alertIds => {
      if (this.queryParams.id) {
        alertIds = alertIds.filter(id => id === this.queryParams.id);
      }
      if (alertIds.length > 0) {
        this.fetchAlerts(alertIds);
      }
    });

    this.navService.setAppBarProgress(true);
    this.querying = true;

    this.route.queryParams
      .pipe(takeUntil(this._destroyed), debounceTime(500))
      .subscribe(params => this.onSearchParamsChanges(params));
  }

  ngAfterViewInit() {
    this.viewInit = true;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this._destroyed.next();
    this._destroyed.complete();
  }

  trackByFn(_index: number, item: AlertDisplay) {
    return item.alert._id;
  }

  onPanelOpened(expansionPanel: MatExpansionPanel, alert: Alert) {
    if (!alert.acknowledge && alert.status === 'Opened') {
      this.launchTimeout(alert);
      this.subscription = fromEvent(document, 'mousemove').subscribe(() => {
        if (!this.dialogRef && !this.acknowledgeFiveSecondsTimeout) {
          this.launchTimeout(alert);
        }
      });
    }
    this.scrollToPanel(expansionPanel);
  }

  private launchTimeout(alert: Alert) {
    this.acknowledgeFiveSecondsTimeout = setTimeout(() => {
      clearTimeout(this.acknowledgeQuinzeSecondsTimeout);
      this.showAcknowledgeDialogAfterFiveSecond(alert);
      this.acknowledgeQuinzeSecondsTimeout = setTimeout(() => {
        this.dialog.closeAll();
      }, 15000);
    }, 5000);
  }

  onPanelClosed() {
    this.dialog.closeAll();
    clearTimeout(this.acknowledgeQuinzeSecondsTimeout);
    clearTimeout(this.acknowledgeFiveSecondsTimeout);
    this.subscription?.unsubscribe();
  }

  showAcknowledgeDialogAfterFiveSecond(currentAlert: Alert) {
    this.dialogRef = this.dialog.open(AlertAcknowledgeDialogComponent, {
      data: { acknowledged: false },
      position: { bottom: '15px', right: '30px' },
      hasBackdrop: false
    });

    this.dialogRef.afterOpened().subscribe(() => {
      this.clickoutHandler = this.closeDialogFromClickout;
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.alertService
          .acknowledgeAlert(currentAlert)
          .pipe(takeUntil(this._destroyed))
          .subscribe(alert => this.onAlertUpdated(alert));
      } else if (result !== false) {
        this.dialogRef = null;
      }
      this.acknowledgeFiveSecondsTimeout = null;
      this.clickoutHandler = null;
    });
    this.acknowledgeFiveSecondsTimeout = null;
  }

  onAlertUpdated(alert: Alert) {
    this.onAlertsLoaded([alert]);
  }

  onScrolled() {
    if (this.querying || this.displays.length === this.totalCount) {
      return;
    }
    this.fetchMoreAlerts();
  }

  closeDialogFromClickout(event: MouseEvent) {
    const matDialogContainerEl = this.dialogRef.componentInstance.hostElement.nativeElement
      .parentElement;
    const rect = matDialogContainerEl.getBoundingClientRect();
    if (
      event.clientX <= rect.left ||
      event.clientX >= rect.right ||
      event.clientY <= rect.top ||
      event.clientY >= rect.bottom
    ) {
      this.dialogRef.close();
    }
  }

  private onSearchParamsChanges(params: any) {
    // Unsubscribe query ongoing and tx update subscriptions
    this.stopQuery.next();
    this.querying = false;
    this.displays = [];
    this.totalCount = -1;
    this.queryParams = params;
    this.fetchMoreAlerts();
    this.getNbDocuments();
  }

  private fetchMoreAlerts() {
    if (this.querying) {
      return;
    }
    this.querying = true;
    this.navService.setAppBarProgress(this.displays.length === 0);
    this.changeDetectorRef.markForCheck();

    const page = Math.floor(this.displays.length / LOADING_SIZE) + 1;
    const queryParams = {
      ...parseQueryParams(this.queryParams),
      limit: LOADING_SIZE + '',
      page: page + ''
    };

    this.alertService.getAlerts(new HttpParams({ fromObject: queryParams })).subscribe({
      next: response => this.onAlertsLoaded(response.items),
      error: () => this.stopLoading(),
      complete: () => this.stopLoading()
    });
  }

  private getNbDocuments(): void {
    const queryParams = parseQueryParams(this.queryParams);
    this.alertService
      .getNbDocuments(new HttpParams({ fromObject: queryParams }))
      .pipe(takeUntil(this.stopQuery))
      .subscribe({
        next: response => {
          this.totalCount = response;
          this.changeDetectorRef.markForCheck();
        },
        error: () => this.stopLoading(),
        complete: () => this.stopLoading()
      });
  }

  private stopLoading() {
    this.querying = false;
    this.navService.setAppBarProgress(false);
    this.changeDetectorRef.markForCheck();
  }

  private onAlertsLoaded(alerts: Alert[]) {
    alerts.forEach(alert => {
      const i = this.displays.findIndex(d => d.alert._id === alert._id);
      const display = this.alertService.getAlertDisplay(alert);
      if (i > -1) {
        if (display.closed && !this.displays[i].closed) {
          // Notify alert stop to unsubscribe
          this.stopAlertUpdateSub.next(alert._id);
        }
        this.displays[i] = display;
      } else {
        // Subscribe if ongoing
        this.displays.push(display);
      }
    });
    this.sortAlerts();
  }

  private onAlertRequestedMissing(alertId: string) {
    const i = this.displays.findIndex(d => d.alert._id === alertId);
    if (i > 0) {
      this.stopAlertUpdateSub.next(alertId);
      this.displays.splice(i, 1);
      this.totalCount--;
    }
  }

  private sortAlerts() {
    this.displays.sort((a, b) => {
      if (a.alert.openDate < b.alert.openDate) {
        return 1;
      }
      if (a.alert.openDate > b.alert.openDate) {
        return -1;
      }
      return 0;
    });
    this.querying = false;
    this.navService.setAppBarProgress(false);
    this.changeDetectorRef.markForCheck();
  }

  private scrollToPanel(panel: MatExpansionPanel) {
    timer(0).subscribe(() => {
      const scrollables = this.scrollDispatcher.getAncestorScrollContainers(panel._body);
      if (scrollables && scrollables.length > 0) {
        const distance =
          (panel._body.nativeElement.offsetParent as any).offsetTop -
          scrollables[0].getElementRef().nativeElement.scrollTop;
        const config: ScrollToConfigOptions = {
          container: scrollables[0].getElementRef(),
          duration: 225,
          easing: 'easeOutCubic',
          offset: distance
        };
        this.scrollToService.scrollTo(config);
      }
    });
  }

  private fetchAlerts(alertIds: string[]) {
    const chunks = [];
    const chunkLength = 3;
    for (let i = 0; i < alertIds.length; i += chunkLength) {
      chunks.push(alertIds.slice(i, i + 1));
    }
    chunks.forEach(idList => {
      const queryParams = { ...parseQueryParams(this.queryParams), id: idList.join(',') };
      this.alertService
        .getAlerts(new HttpParams({ fromObject: queryParams }))
        .pipe(takeUntil(this.stopQuery))
        .subscribe(res => {
          const alerts = res.items;
          alerts.forEach(alert => {
            if (this.displays.findIndex(d => d.alert._id === alert._id) < 0) {
              this.totalCount++;
            }
          });
          alertIds.forEach(id => {
            if (alerts.findIndex(a => a._id === id) < 0) {
              this.onAlertRequestedMissing(id);
            }
          });
          this.onAlertsLoaded(alerts);
        });
    });
  }
}
