import { fadeInOut } from '@app/shared';
import {
  chevron,
  showHideAddress
} from '@app/infrastructure/location-info/location-info-animations';
import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SmartChargingService } from '@app/smart-charging/smart-charging.service';
import { Hub } from '@app/core/smart-charging/hub';
import { MatDialog } from '@angular/material/dialog';
import { ChargingServicePlan } from '@app/core/smart-charging/charging-service-plan';
import { EnergyNode } from '@app/core/smart-charging/energy-node';
import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppNavigationService } from '@app/navigation';

const basicVersioningId = '0be9e818-d4cf-11eb-b8bc-0242ac130003';

@Component({
  selector: 'app-hub-details',
  templateUrl: './hub-details.component.html',
  styleUrls: ['./hub-details.component.scss'],
  animations: [fadeInOut, chevron, showHideAddress]
})
export class HubDetailsComponent implements OnChanges {
  @Input() hub: Hub;

  errorMessage = '';
  isCommunicationError = false;
  chargingServicePlans: ChargingServicePlan[];
  subNodes: any[];
  displayedColumns: string[] = ['Name', 'SyncStatus', 'Power', 'Service', 'L1', 'L2', 'L3'];
  nodeServiceMap: Map<string, string> = new Map<string, string>();
  isLoadingContent = false;
  editServiceMode = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private smartChargingService: SmartChargingService,
    private navService: AppNavigationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hub && this.hub && this.hub._key) {
      this.refreshSubNodes(this.hub);
      this.getServicesForHub();
      this.changeDetectorRef.markForCheck();
    }
  }

  refreshSubNodes(hub: Hub): void {
    if (!!hub && !!hub.subNodes) {
      const subNodes = hub.subNodes.map(node =>
        Object.assign(node, {
          serviceKey: this.getServiceKey(node),
          syncStatus: this.getIcon(node)
        })
      );
      if (!!hub.errors) {
        hub.errors
          .filter(error => error.type === 'UNKNOWN_EVSE')
          .forEach(error =>
            subNodes.push({
              chargePointId: error.chargePointId,
              evseName: error.chargePointId,
              evseEmi3: '',
              isThreePhase: false,
              maxPower: 0,
              connectorId: '',
              phase: 1,
              errors: [error],
              evseId: error.chargePointId,
              syncStatus: 'warning-evse',
              serviceKey: 'NONE',
              serviceVersion: -1
            })
          );
      }
      subNodes.sort((a, b) => a.chargePointId.localeCompare(b.chargePointId));
      this.subNodes = subNodes;
      this.changeDetectorRef.markForCheck();
    }
  }

  private getServicesForHub(): void {
    this.smartChargingService.getServicesByHubId(this.hub._key).subscribe(servicePlans => {
      servicePlans.sort((a, b) => a.label.localeCompare(b.label));
      this.chargingServicePlans = servicePlans;
      this.changeDetectorRef.markForCheck();
    });
  }

  isPhaseActive(node: EnergyNode, phaseNumber: number): boolean {
    return node.phase === phaseNumber;
  }

  isPhaseDisplayed(node: EnergyNode, phaseNumber: number): boolean {
    return node.phase === phaseNumber || node.isThreePhase || node.phase === 0;
  }

  applyServices(): void {
    this.isLoadingContent = true;
    this.navService.setAppBarProgress(true);
    this.smartChargingService
      .applyServices(this.hub._key, this.nodeServiceMap)
      .pipe(
        catchError(error => this.managePostServiceError(error)),
        mergeMap(() => this.smartChargingService.getHub(this.hub._key))
      )
      .subscribe(hub => {
        this.isLoadingContent = false;
        this.editServiceMode = false;
        this.nodeServiceMap.clear();
        this.hub = hub;
        this.refreshSubNodes(hub);
        this.navService.setAppBarProgress(false);
        this.changeDetectorRef.markForCheck();
      });
  }

  private managePostServiceError(error: any): Observable<any> {
    this.isLoadingContent = false;
    this.isCommunicationError = true;
    switch (error.status) {
      case 400:
        if (error.error === 'Mismatch') {
          this.errorMessage = 'smartCharging.getConf.MISMATCH';
          break;
        }
        this.errorMessage = 'smartCharging.getConf.BAD_REQUEST';
        break;
      case 404:
        this.errorMessage = 'smartCharging.getConf.NOT_FOUND';
        break;
      case 408:
        this.errorMessage = 'smartCharging.getConf.TIMEOUT';
        break;
    }
    return of(null);
  }

  getService(element: EnergyNode): string {
    const service = this.nodeServiceMap.get(element.chargePointId);
    if (!service) {
      if (!element.serviceKey) {
        this.nodeServiceMap.set(this.extractNodeCorrectId(element), basicVersioningId);
      }
    }
    return this.nodeServiceMap.get(element.chargePointId);
  }

  updateService(elementId, service): void {
    this.nodeServiceMap.set(elementId, service);
  }

  extractNodeCorrectId(element: EnergyNode) {
    return element.chargePointId ? element.chargePointId : element.nodeId;
  }

  noServiceToApply(): boolean {
    return this.nodeServiceMap.keys().next().done;
  }

  hasChanged(element): string {
    if (this.nodeServiceMap.get(element.chargePointId)) {
      return 'changed';
    } else {
      return 'unchanged';
    }
  }

  getIcon(element: EnergyNode): string {
    if (!element.errors || element.errors.length === 0) {
      return 'ok-icon';
    } else if (element.errors.some(error => error.type === 'CONNECTOR_MISMATCH')) {
      return 'warning-connector';
    } else if (
      element.errors.some(error => error.type === 'UNKNOWN_EVSE' || error.type === 'RETIRED_EVSE')
    ) {
      return 'warning-evse';
    }
  }

  getTooltip(element: EnergyNode): string {
    if (!element.errors || element.errors.length === 0) {
      return 'smartCharging.SYNC_OK';
    } else if (element.errors.some(error => error.type === 'CONNECTOR_MISMATCH')) {
      return 'smartCharging.SYNC_CONN_MISMATCH';
    } else if (element.errors.some(error => error.type === 'UNKNOWN_EVSE')) {
      return 'smartCharging.SYNC_EVSE_MISMATCH';
    } else if (element.errors.some(error => error.type === 'RETIRED_EVSE')) {
      return 'smartCharging.SYNC_EVSE_RETIRED';
    } else {
      return '';
    }
  }

  getServiceKey(node: EnergyNode): string {
    if (node?.errors.some(err => err.type === 'RETIRED_EVSE')) {
      return 'NONE';
    }
    return !node.serviceKey ? basicVersioningId : node.serviceKey;
  }

  onEditServiceClick(): void {
    this.editServiceMode = true;
  }

  onCancelEditServiceClick(): void {
    this.editServiceMode = false;
    this.nodeServiceMap = new Map<string, string>();
    this.refreshSubNodes(this.hub);
  }
}
