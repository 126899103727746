import { IdentityProvider } from '@app/auth/identity-provider';
import { UserProfileWrapper } from '@app/core';
import { Moment } from 'moment';

export interface FeatureToggle {
  enabled: boolean;
}

export interface Features {
  administrationProfiles: AdministrationProfileFeature;
  banner: BannerFeature;
  cognitoAuthentication: CognitoAuthenticationFeature;
}

export class AdministrationProfileFeature implements FeatureToggle {
  enabled: boolean;
  profiles: Array<UserProfileWrapper<string>>;

  constructor(feature: AdministrationProfileFeature) {
    this.enabled = feature.enabled;
    this.profiles = feature.profiles;
  }
}

export class BannerFeature implements FeatureToggle {
  enabled: boolean;
  endDate?: Moment;
  startDate?: Moment;

  constructor(feature: BannerFeature) {
    this.enabled = feature.enabled;
    this.endDate = feature.endDate;
    this.startDate = feature.startDate;
  }
}

export class CognitoAuthenticationFeature implements FeatureToggle {
  authorizeEndpoint: string;
  enabled: boolean;
  identityProviders: Array<IdentityProvider>;
  redirectUri: string;

  constructor(feature: CognitoAuthenticationFeature) {
    this.authorizeEndpoint = feature.authorizeEndpoint;
    this.enabled = feature.enabled;
    this.identityProviders = feature.identityProviders.map(
      provider => new IdentityProvider(provider)
    );
    this.redirectUri = feature.redirectUri;
  }
}
