import {
  Component,
  ChangeDetectorRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  NgZone
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';

import { forkJoin, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AppNavigationService } from '@app/navigation';
import { chipInOut, chipListInOut, FilterAutocompleteOption, FilterOption } from '@app/shared';

export const INPUT_FILTER_OPTIONS: FilterOption[] = [
  { name: 'alert.filter.Alert type', param: 'alertType', type: 'option' },
  { name: 'alert.Severity', param: 'severity', type: 'option' },
  { name: 'alert.filter.Number of alerts to show', param: 'limit' },
  { name: 'dataviz.filter.CPO', param: 'cpo', type: 'option' },
  { name: 'dataviz.filter.CPO group', param: 'cpoGroup', type: 'option' },
  { name: 'alert.Equipment ID', param: 'equipmentId', type: 'option', sort: true },
  { name: 'dataviz.filter.Location', param: 'location', type: 'option' },
  { name: 'alert.Error / Vendor error code', param: 'errorCode', type: 'option', sort: true }
];

const ALERT_TYPE_OPTIONS: FilterAutocompleteOption[] = [
  { display: 'alert.type.COMM_ALERT', value: 'COMM_ALERT', translate: true },
  { display: 'alert.type.STATUS_ALERT', value: 'STATUS_ALERT', translate: true },
  {
    display: 'alert.type.ILLEGAL_OCCUPANCY_ALERT',
    value: 'ILLEGAL_OCCUPANCY_ALERT',
    translate: true
  },
  { display: 'alert.type.IT_SERVICE_STATUS', value: 'IT_SERVICE_STATUS', translate: true },
  { display: 'alert.type.UNDEF', value: 'UNDEF', translate: true },
  { display: 'alert.type.EMVCO_PROCESS', value: 'EMVCO_PROCESS', translate: true },
  {
    display: 'alert.type.INCONSISTENT_REFERENTIAL',
    value: 'INCONSISTENT_REFERENTIAL',
    translate: true
  }
];

const SEVERITY_LEVEL_OPTIONS: FilterAutocompleteOption[] = [
  { display: 'alert.severity.Signal', value: 'Signal', translate: true },
  { display: 'alert.severity.Info', value: 'Info', translate: true },
  { display: 'alert.severity.Warning', value: 'Warning', translate: true },
  { display: 'alert.severity.Critical', value: 'Critical', translate: true }
];

const ERROR_CODE_OPTIONS: FilterAutocompleteOption[] = [
  {
    display: 'alert.errorCode.ChargeStopped:VehicleProtocolError',
    value: 'vendor.ChargeStopped:VehicleProtocolError',
    translate: true
  },
  {
    display: 'alert.errorCode.CommunicationError',
    value: 'vendor.CommunicationError',
    translate: true
  },
  {
    display: 'alert.errorCode.ConnectorLockFailure',
    value: 'ConnectorLockFailure',
    translate: true
  },
  {
    display: 'alert.errorCode.DoorNotClosed',
    value: 'vendor.DoorNotClosed',
    translate: true
  },
  {
    display: 'alert.errorCode.EmergencyStop',
    value: 'vendor.EmergencyStop',
    translate: true
  },
  {
    display: 'alert.errorCode.EVCommunicationError',
    value: 'EVCommunicationError',
    translate: true
  },
  { display: 'alert.errorCode.GroundFailure', value: 'GroundFailure', translate: true },
  {
    display: 'alert.errorCode.IllegalParking',
    value: 'vendor.IllegalParking',
    translate: true
  },
  {
    display: 'alert.errorCode.SensorParking',
    value: 'vendor.SensorParking',
    translate: true
  },
  {
    display: 'alert.errorCode.InternalError',
    value: 'vendor.InternalError',
    translate: true
  },
  { display: 'alert.errorCode.Mode3Error', value: 'Mode3Error', translate: true },
  {
    display: 'alert.errorCode.OverCurrentFailure',
    value: 'OverCurrentFailure',
    translate: true
  },
  { display: 'alert.errorCode.OverVoltage', value: 'OverVoltage', translate: true },
  { display: 'alert.errorCode.PowerOutage', value: 'vendor.PowerOutage', translate: true },
  {
    display: 'alert.errorCode.PowerSwitchFailure',
    value: 'PowerSwitchFailure',
    translate: true
  },
  { display: 'alert.errorCode.ReaderFailure', value: 'ReaderFailure', translate: true },
  { display: 'alert.errorCode.ResetFailure', value: 'ResetFailure', translate: true },
  { display: 'alert.errorCode.UnderVoltage', value: 'UnderVoltage', translate: true }
];

@Component({
  selector: 'app-widget-alert-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  animations: [chipInOut, chipListInOut]
})
export class WidgetAlertFilterComponent implements OnDestroy, OnInit {
  inputFilters: FilterOption[] = INPUT_FILTER_OPTIONS.map(f => Object.assign({}, f));
  selectedInputFilter: FilterOption;

  appliedFilters: FilterOption[] = [];

  @ViewChild('stringInput', { static: true }) stringInput: MatInput;

  stringInputControl = new FormControl();
  autoCompleteOptions: Observable<FilterAutocompleteOption[]>;
  _autoCompleteOptions: FilterAutocompleteOption[];

  private _destroyed = new Subject<void>();
  private cpoGroupOptions: FilterAutocompleteOption[];
  private cpoOptions: FilterAutocompleteOption[];
  private locationOptions: FilterAutocompleteOption[];
  private equipmentOptions: FilterAutocompleteOption[];

  constructor(
    private _ngZone: NgZone,
    private _changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      appliedFilters: FilterOption[];
      cpoGroupOptions: FilterAutocompleteOption[];
      cpoOptions: FilterAutocompleteOption[];
      locationOptions: FilterAutocompleteOption[];
      equipmentOptions: FilterAutocompleteOption[];
    },
    private translate: TranslateService,
    private navService: AppNavigationService
  ) {}

  ngOnInit() {
    this.appliedFilters = this.data.appliedFilters || [];
    this.cpoGroupOptions = this.data.cpoGroupOptions || [];
    this.cpoOptions = this.data.cpoOptions || [];
    this.locationOptions = this.data.locationOptions || [];
    this.equipmentOptions = this.data.equipmentOptions || [];

    if (
      !this.data ||
      !this.data.cpoGroupOptions ||
      !this.data.cpoOptions ||
      !this.data.locationOptions ||
      !this.data.equipmentOptions
    ) {
      this._ngZone.runOutsideAngular(() => {
        forkJoin([
          this.navService.loadLocationAutoCompleteOptions(),
          this.navService.loadEvseAutoCompleteOptions(),
          this.navService.loadCpoAutoCompleteOptions(),
          this.navService.loadCpoGroupAutoCompleteOptions()
        ])
          .pipe(takeUntil(this._destroyed))
          .subscribe(result => {
            this.locationOptions = result[0];
            this.equipmentOptions = result[1];
            this.cpoOptions = result[2];
            this.cpoGroupOptions = result[3];
            this._ngZone.run(() => this.onSelectedInputChange());
          });
      });
    }

    this.autoCompleteOptions = this.stringInputControl.valueChanges.pipe(
      takeUntil(this._destroyed),
      startWith(''),
      map(value => this._filterAutocompleteOptions(value))
    );

    this.selectedInputFilter = this.inputFilters[0];
    this.onSelectedInputChange();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  displayOptionFn(option: FilterAutocompleteOption) {
    if (!option) {
      return '';
    }
    if (this.selectedInputFilter.type !== 'option') {
      return option.value;
    }
    if (option.translate) {
      return this.translate.instant(option.display);
    }
    return option.display;
  }

  onSelectedInputChange(): void {
    if (this.selectedInputFilter) {
      switch (this.selectedInputFilter.param) {
        case 'alertType':
          this._autoCompleteOptions = ALERT_TYPE_OPTIONS;
          break;
        case 'severity':
          this._autoCompleteOptions = SEVERITY_LEVEL_OPTIONS;
          break;
        case 'cpoGroup':
          this._autoCompleteOptions = this.cpoGroupOptions;
          break;
        case 'cpo':
          this._autoCompleteOptions = this.cpoOptions;
          break;
        case 'location':
          this._autoCompleteOptions = this.locationOptions;
          break;
        case 'equipmentId':
          this._autoCompleteOptions = this.equipmentOptions;
          break;
        case 'errorCode':
          this._autoCompleteOptions = ERROR_CODE_OPTIONS;
          break;
        default:
          this._autoCompleteOptions = [];
      }
      this.stringInputControl.patchValue(this.stringInputControl.value);
    }
  }

  addInputFilter() {
    if (!this.selectedInputFilter) {
      return;
    }
    let value = this.stringInputControl.value;
    if (typeof value === 'string') {
      value = { display: value, value };
    }
    if (!this.appliedFilters) {
      this.appliedFilters = [];
    }
    const inputFilter = {
      name: this.selectedInputFilter.name,
      param: this.selectedInputFilter.param,
      type: this.selectedInputFilter.type,
      value
    };

    const exist = this.appliedFilters.findIndex(val => val.value.value === inputFilter.value.value);
    if (exist <= -1) {
      this.appliedFilters.push(inputFilter);
    }

    this.stringInput.value = '';
    this.stringInputControl.patchValue('');

    this._changeDetectorRef.markForCheck();
  }

  removeInputFilter(index: number) {
    this.appliedFilters.splice(index, 1);
    this._changeDetectorRef.markForCheck();
  }

  clearInputFilters() {
    this.appliedFilters = [];
    this._changeDetectorRef.markForCheck();
  }

  applyFilter() {
    this.dialogRef.close(this.appliedFilters);
  }

  private _filterAutocompleteOptions(value: string): FilterAutocompleteOption[] {
    if (!this._autoCompleteOptions) {
      return [];
    }
    if (!value) {
      return this._autoCompleteOptions;
    }
    if (typeof value === 'string') {
      value = value.toLocaleLowerCase();
    }
    const res = this._autoCompleteOptions.filter(
      o => (o.translateValue || o.display || o.value).toLowerCase().indexOf(value) > -1
    );
    return res;
  }
}
