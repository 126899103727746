import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

const ANIMATION_TRANSITION = AnimationDurations.ENTERING + ' ' + AnimationCurves.STANDARD_CURVE;

@Component({
  selector: 'app-animated-menu',
  templateUrl: 'animated-menu.component.html',
  styleUrls: ['animated-menu.component.scss'],
  animations: [
    trigger('container', [
      state('hamburger', style({})),
      state('arrow', style({ transform: 'rotate(180deg)' })),
      transition('hamburger => arrow', animate(ANIMATION_TRANSITION)),
      transition(
        'arrow => hamburger',
        animate(ANIMATION_TRANSITION, style({ transform: 'rotate(360deg)' }))
      )
    ]),
    trigger('firstLine', [
      state('hamburger', style({})),
      state(
        'arrow',
        style({
          transform: 'translate(3.329px, 1.751px) rotate(45deg) scaleX(0.62)'
        })
      ),
      transition('hamburger <=> arrow', animate(ANIMATION_TRANSITION))
    ]),
    trigger('secondLine', [
      state('hamburger', style({})),
      state(
        'arrow',
        style({
          transform: 'translate(-0.45px, 0) scaleX(0.82)'
        })
      ),
      transition('hamburger <=> arrow', animate(ANIMATION_TRANSITION))
    ]),
    trigger('thirdLine', [
      state('hamburger', style({})),
      state(
        'arrow',
        style({
          transform: 'translate(3.329px, -1.751px) rotate(-45deg) scaleX(0.62)'
        })
      ),
      transition('arrow <=> hamburger', animate(ANIMATION_TRANSITION))
    ])
  ]
})
export class AnimatedMenuComponent {
  @Input() state: string;
}
