import { NgModule } from '@angular/core';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { AppTranslateModule, SharedModule } from '@app/shared';
import { TransactionModule } from '@app/transaction';
import { WidgetModule } from '@app/widget';
import { AlertBaseModule } from '@app/alert/base/alert-base.module';
import { InfraEvseInfoComponent } from './evse-info/evse-info.component';
import { InfraRoutingModule } from './infrastructure-routing.module';
import { InfrastructureBaseModule } from './base/infrastructure-base.module';

import { InfraMainViewComponent } from './main-view/main-view.component';
import { InfraLocationInfoComponent } from './location-info/location-info.component';
import { InfraCpoInfoComponent } from './cpo-info/cpo-info.component';
import { RfidCardCredentielDialogComponent } from './common/rfid-card-credentiel-dialog/rfid-card-credentiel-dialog.component';
import { AddRfidCardCredentielDialogComponent } from './common/add-rfid-card-credentiel-dialog/add-rfid-card-credentiel-dialog.component';
import { MassImportRfidCardCredentielDialogComponent } from './common/mass-import-rfid-card-credentiel-dialog/mass-import-rfid-card-credentiel-dialog.component';
import { NetworkBaseModule } from '@app/network/base/network-base.module';
import { SmartChargingBaseModule } from '@app/smart-charging/base/smart-charging-base.module';
import { InfraNetworkInfoComponent } from './network-info/network-info.component';
import { InfraEvseConfigComponent } from './evse-config/evse-config.component';
import { SmartChargingServiceListComponent } from '@app/smart-charging/services/service-list/service-list.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationDialogComponent } from './notification/notification-dialog.component';
import { EmailsComponent } from './notification/emails.component';
import { SmsComponent } from './notification/sms.component';
import { TriggerComponent } from './notification/trigger.component';
import { EventRecordComponent } from './event-record/event-record.component';
import { HistoryDetailsComponent } from './event-record/history-details/history-details.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { InfraEvControllerComponent } from '@app/infrastructure/ev-controller/infra-ev-controller.component';
import { EvControllerSummaryHeaderComponent } from '@app/infrastructure/ev-controller/ev-controller-summary-header/ev-controller-summary-header.component';

@NgModule({
  imports: [
    AppTranslateModule.forChild([
      'infra',
      'alert',
      'transaction',
      'widget',
      'network',
      'smartCharging'
    ]),
    AlertBaseModule,
    CommonModule,
    InfrastructureBaseModule,
    InfraRoutingModule,
    LeafletModule,
    NetworkBaseModule,
    SharedModule,
    SmartChargingBaseModule,
    TransactionModule,
    WidgetModule
  ],
  declarations: [
    InfraEvseInfoComponent,
    InfraMainViewComponent,
    InfraLocationInfoComponent,
    InfraCpoInfoComponent,
    InfraNetworkInfoComponent,
    InfraEvControllerComponent,
    EvControllerSummaryHeaderComponent,
    // Dialogs
    RfidCardCredentielDialogComponent,
    AddRfidCardCredentielDialogComponent,
    MassImportRfidCardCredentielDialogComponent,
    InfraEvseConfigComponent,
    NotificationComponent,
    NotificationDialogComponent,
    TriggerComponent,
    EmailsComponent,
    SmsComponent,
    EventRecordComponent,
    HistoryDetailsComponent
  ],
  exports: [InfraLocationInfoComponent],
  entryComponents: [
    RfidCardCredentielDialogComponent,
    AddRfidCardCredentielDialogComponent,
    MassImportRfidCardCredentielDialogComponent,
    SmartChargingServiceListComponent,
    NotificationDialogComponent
  ]
})
export class InfrastructureModule {
  constructor(sanitizer: DomSanitizer, iconRegistry: MatIconRegistry) {
    ['ic_ev_controller', 'ic_device_hub'].forEach(icon => {
      iconRegistry.addSvgIcon(
        icon,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/svg/${icon}.svg`)
      );
    });
  }
}
