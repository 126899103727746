import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

export const routerOutletTranslate = trigger('routerOutletTranslate', [
  state('noSearch', style({ transform: 'translateY(144px)' })),

  state('search', style({ transform: 'translateY(208px)' })),

  transition(
    'noSearch => search',
    animate(`${AnimationDurations.COMPLEX} ${AnimationCurves.STANDARD_CURVE}`)
  ),

  transition(
    'search => noSearch',
    animate(`${AnimationDurations.COMPLEX} 1200ms ${AnimationCurves.STANDARD_CURVE}`)
  )
]);

export const logoFadeIn = trigger('logoFadeIn', [
  transition(':enter', [
    style({ opacity: '0' }),
    group([
      animate(`1s ${AnimationDurations.COMPLEX} ${AnimationCurves.STANDARD_CURVE}`),
      query('@*', animateChild(), { optional: true })
    ])
  ])
]);

export const fabLeave = trigger('fabLeave', [transition(':leave', query('@*', animateChild()))]);
