import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  NgZone
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AlertDisplay, AlertService } from '@app/alert';
import { Alert, ChargingStation, Cpo, Evse, ChargingLocation, Transaction } from '@app/core';
import { AppNavigationService } from '@app/navigation';
import { fadeInOut } from '@app/shared';
import { TxDisplay, TxService } from '@app/transaction';
import { InfrastructureService } from '@app/infrastructure';
import { actions } from '../base/evse-display-header/evse-actions-animations';
import {
  getEvseAvatarClass,
  getEvseAvatarIcon,
  getPlugIcon
} from '../base/evse-display-header/evse-display-header.component';
import { InfraEvse } from '@app/core/park/evse/infraEvse';

const BLACK_LIST_KEY = ['TARIFF_GROUP'];
@Component({
  selector: 'app-infra-evse-info',
  templateUrl: 'evse-info.component.html',
  styleUrls: ['evse-info.component.scss'],
  animations: [actions, fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfraEvseInfoComponent implements OnDestroy, OnInit {
  error: any;
  cpo: Cpo;
  location: ChargingLocation;
  chargingStation: ChargingStation;
  evse: Evse;

  showActions: boolean;
  info: any;
  extraInfo: any[];

  emspList: string;
  alertDisplays: AlertDisplay[];
  txDisplays: TxDisplay[];

  accessToAlert: boolean;
  evseEditAccess: boolean;

  private _destroyed = new Subject();
  private evseId: string;
  private keepBackUrlWhenDestroy: boolean;

  constructor(
    public translate: TranslateService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private navService: AppNavigationService,
    private alertService: AlertService,
    private txService: TxService,
    private infraService: InfrastructureService,
    public router: Router
  ) {}

  ngOnInit() {
    this.navService.setBackUrlFallback('/infrastructure');
    this.navService.navItems.pipe(takeUntil(this._destroyed)).subscribe(items => {
      this.accessToAlert = items.findIndex(item => item.path.indexOf('alert') > -1) > -1;
      this.evseEditAccess = items.findIndex(item => item.path.indexOf('park') > -1) > -1;
    });

    this.route.params.subscribe(params => {
      this.evseId = params.id;
      this.fetchEvse(params.id);
    });
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();

    if (!this.keepBackUrlWhenDestroy) {
      this.navService.setBackUrlFallback(undefined);
    }
  }

  trackAlertFn(_i: number, item: AlertDisplay) {
    return item.alert._id;
  }

  toggleActions() {
    this.showActions = !this.showActions;
    this.changeDetectorRef.markForCheck();
  }

  getEvseAvatarClass(evse: Evse) {
    return getEvseAvatarClass(evse);
  }

  getEvseAvatarIcon(evse: Evse) {
    return getEvseAvatarIcon(evse);
  }

  getPlugIcon(connectorType: string) {
    return getPlugIcon(connectorType);
  }

  private fetchEvse(id: string) {
    this.navService.setAppBarProgress(true);
    this.error = undefined;
    this.changeDetectorRef.markForCheck();
    this.ngZone.runOutsideAngular(() =>
      forkJoin([this.infraService.getLocationToEvse(id), this.infraService.getEvseLiveStatus(id)])
        .pipe(takeUntil(this._destroyed))
        .subscribe(
          result => this.ngZone.run(() => this.onEvseLoaded(result[0], result[1])),
          err =>
            this.ngZone.run(() => {
              this.navService.setAppBarProgress(false);
              this.error = err;
              this.changeDetectorRef.markForCheck();
            })
        )
    );
  }

  openInfraLink(): void {
    this.router.navigateByUrl(`/park/evse/${this.evse._key}`);
  }

  onLinkClick(e: MouseEvent) {
    if ((e.button !== 0 && e.button !== 1) || e.metaKey || e.ctrlKey) {
      return;
    }
    this.keepBackUrlWhenDestroy = true;
    this.navService.setBackUrlFallback(`/infrastructure/evse/${this.evse._key}`);
  }

  private onEvseLoaded(path: any[], info: any) {
    this.navService.setAppBarProgress(false);
    this.info = info;
    if (path) {
      path.forEach(element => {
        if (element._id) {
          if (element._id.startsWith('cpo/')) {
            this.cpo = element;
          }
          if (element._id.startsWith('location/')) {
            this.location = element;
          }
          if (element._id.startsWith('charging_station/')) {
            this.chargingStation = element;
          }
          if (element._id.startsWith('evse/')) {
            this.evse = element;
            if (this.evse.emsps) {
              this.emspList = this.evse.emsps
                .filter(e => e.enabled === true)
                .map(e => e.name)
                .join(', ');
            }
          }
        }
      });
    }

    if (this.info) {
      this.extraInfo = [];
      for (const key in this.info.extraInfo) {
        if (
          this.info.extraInfo.hasOwnProperty(key) &&
          BLACK_LIST_KEY.findIndex(k => k === key) < 0
        ) {
          this.extraInfo.push({ key, value: this.info.extraInfo[key] });
        }
      }

      if (this.info.openAlerts) {
        const alertList = this.info.openAlerts as Alert[];
        setTimeout(() => {
          this.alertDisplays = alertList.map(a => this.alertService.getAlertDisplay(a));
          this.changeDetectorRef.detectChanges();
        });
      }
      if (this.info.recentTransactions) {
        const txList = this.info.recentTransactions as Transaction[];
        this.txDisplays = txList.map(tx => this.txService.getTransactionDisplay(tx));
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  getServiceStatus(evse: InfraEvse): string {
    return InfraEvse.determineServiceStatus(evse);
  }

  showConfiguration() {
    this.router.navigate(['infrastructure', 'evse', this.evseId, 'config']);
  }
}
