import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { parseQueryParams } from '@app/shared';
import { constants } from 'buffer';
import { Observable } from 'rxjs';
import { EventRecordDTO } from './event-record';

@Injectable({ providedIn: 'root' })
export class EventRecordService {
  private eventRecordApiUrl = 'api/event-record';

  constructor(private http: HttpClient) {}

  getEvseEventRecords(id: string, maxSize: string): Observable<EventRecordDTO> {
    const params = Object.assign({ limit: maxSize }, parseQueryParams({ range: 'last7Days' }));
    return this.http.get<EventRecordDTO>(`${this.eventRecordApiUrl}/evse/${id}`, { params });
  }

  getLocationEventRecords(id: string, maxSize: string): Observable<EventRecordDTO> {
    const params = Object.assign({ limit: maxSize }, parseQueryParams({ range: 'last7Days' }));
    return this.http.get<EventRecordDTO>(`${this.eventRecordApiUrl}/location/${id}`, { params });
  }

  getEventRecords(params: HttpParams): Observable<EventRecordDTO> {
    return this.http.get<EventRecordDTO>(`${this.eventRecordApiUrl}/`, { params });
  }
}
