import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthenticationService } from '../auth.service';
import { CognitoAuthenticationAdapter } from './cognito-authentication-adapter';
import { CognitoAuthenticationService } from './cognito-authentication.service';
import { CognitoHttpService } from './cognito-http.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    CognitoHttpService,
    CognitoAuthenticationService,
    { provide: AuthenticationService, useClass: CognitoAuthenticationAdapter }
  ]
})
export class CognitoAuthenticationModule {}
