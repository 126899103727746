import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  OnInit,
  ChangeDetectorRef,
  NgZone,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Alert } from '@app/core';
import { fadeInOut, swipeInOut } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AlertDisplay, AlertService, AlertHistoryDisplay } from '../../alert.service';
import { chevron, showHideHistory } from './alert-display-content-animation';
import { AlertEvent } from '@app/core/infrastructure/alert-event';
import { LoadingService } from '@app/shared/loading-manager/loading-service.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDeleteTicketDialogComponent } from '../delete-ticket-dialog/delete-ticket-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from '@app/shared/confirm-dialog/confirm-dialog.component';

const MAX_SIZE = 500;

@Component({
  selector: 'app-alert-display-content',
  templateUrl: 'alert-display-content.component.html',
  styleUrls: ['alert-display-content.component.scss'],
  animations: [fadeInOut, swipeInOut, chevron, showHideHistory]
})
export class AlertDisplayContentComponent implements OnDestroy, OnInit {
  @Input() display: AlertDisplay;
  @Input() alertIdLink: boolean;
  @Input() infraLink: boolean;
  @Input() historySingleSide: boolean;
  @Output() alertChange = new EventEmitter<Alert>();

  @ViewChild('inputBox') inputBox: ElementRef;

  displayAlertHistory: AlertHistoryDisplay[];
  alertHistoryEvents: AlertEvent[];

  commentMaxSize = MAX_SIZE;
  commentControl = new FormControl('');

  showHistory: boolean;
  inAdd: any;
  showme = new Map();
  alertIdParam: string;
  fsTickectAddAccess: boolean;
  error: string;

  private _destroyed = new Subject();

  constructor(
    public translate: TranslateService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private service: AlertService,
    public loadingService: LoadingService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.display.alert?.orgId) {
      this.checkFsTicketOrgSync(this.display.alert.orgId);
    }
    if (this.display.alert.history) {
      const ids: string[] = [];
      this.display.alert.history.forEach(h => {
        if (h.eventId) {
          ids.push(h.eventId);
        }
      });
      this.ngZone.runOutsideAngular(() =>
        this.service
          .getAlertEvents(ids)
          .subscribe(data => this.ngZone.run(() => this.onAlertEventLoaded(data)))
      );
    }
    this.alertIdParam = this.route.snapshot.queryParamMap.get('id');
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  saveComment() {
    this.service
      .saveComment(this.display.alert, this.commentControl.value)
      .pipe(takeUntil(this._destroyed))
      .subscribe(alert => {
        this.commentControl.reset();
        this.commentControl.setValue(alert.comment);
        this.alertChange.emit(alert);
      });
  }

  cancelCommentEdit() {
    this.commentControl.reset();
    this.commentControl.setValue(this.display.alert.comment || '');
  }

  onAddFreshServiceTicketId(ticketId: string): void {
    this.loadingService.show();
    if (this.checkIfTicketIsAlreadyLinked(ticketId)) {
      this.error = 'alert.freshService.error.ALREADY_LINKED';
      this.loadingService.hide();
      return;
    }
    if (ticketId) {
      this.service
        .addFsTicketToAlert(this.display.alert, ticketId)
        .pipe(takeUntil(this._destroyed))
        .subscribe(
          res => {
            if (!this.display.alert.tickets) {
              this.display.alert.tickets = [];
            }
            if (res === true) {
              this.display.alert.tickets.push(ticketId);
              this.inputBox.nativeElement.value = '';
              this.error = null;
              this.loadingService.hide();
              this.changeDetectorRef.markForCheck();
            } else {
              this.dialog
                .open(ConfirmDialogComponent, {
                  data: {
                    title: 'alert.freshService.CLOSED_TICKET_DIALOG',
                    button: 'common.Confirm'
                  }
                })
                .afterClosed()
                .subscribe(isConfirm => {
                  if (isConfirm) {
                    this.service.addFsTicketToAlert(this.display.alert, ticketId, true).subscribe(
                      () => {
                        this.display.alert.tickets.push(ticketId);
                        this.inputBox.nativeElement.value = '';
                        this.error = null;
                        this.loadingService.hide();
                        this.changeDetectorRef.markForCheck();
                      },
                      error1 => (this.error = 'alert.freshService.error.BAD_ASSET')
                    );
                  }
                  this.loadingService.hide();
                  this.changeDetectorRef.markForCheck();
                });
            }
          },
          err => {
            this.loadingService.hide();
            if (err.status === 404) {
              this.error = 'alert.freshService.error.NOT_FOUND';
            } else if (err.status === 409) {
              this.error = 'alert.freshService.error.CONFLICT';
            } else if (err.status === 400) {
              this.error = 'alert.freshService.error.BAD_ASSET';
            } else {
              this.error = 'alert.freshService.error.INTERNAL_ERROR';
            }
          }
        );
    }
  }

  private checkIfTicketIsAlreadyLinked(ticketId): boolean {
    return this.display.alert.tickets?.includes(ticketId);
  }

  mouseEnter(item: string) {
    this.showme.clear();
    this.showme.set(item, true);
  }

  mouseLeave(item: string) {
    this.showme.clear();
    this.showme.set(item, false);
  }

  goToFsTicket(item: string) {
    this.service.goToFsTicket(item);
  }

  deleteTicket(alert: Alert, ticket: string) {
    this.dialog
      .open(AlertDeleteTicketDialogComponent)
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.deleteAlertTicketLink(alert, ticket);
        }
      });
  }

  private deleteAlertTicketLink(alert: Alert, ticket: string) {
    this.service
      .deleteAlertTicketLink(alert._id, ticket, alert.equipmentId)
      .pipe(takeUntil(this._destroyed))
      .subscribe(
        result => {
          if (result) {
            this.alertChange.emit(alert);
            this.display.alert.tickets.splice(this.display.alert.tickets.indexOf(ticket), 1);
            this.changeDetectorRef.markForCheck();
          }
        },
        () => (this.error = 'alert.freshService.error.BAD_ASSET')
      );
  }

  private checkFsTicketOrgSync(cpoId: string) {
    this.service
      .checkFreshServiceOrgSync(cpoId)
      .pipe(takeUntil(this._destroyed))
      .subscribe(res => {
        if (res) {
          this.fsTickectAddAccess = true;
        }
      });
  }

  private onAlertEventLoaded(listIds: AlertEvent[]) {
    this.displayAlertHistory = this.display.alert.history.map(h => {
      if (listIds) {
        this.alertHistoryEvents = listIds;
        const alertEvent = listIds.find(e => e._id.indexOf(h.eventId) > -1);
        if (alertEvent) {
          h.typeEvent = alertEvent.type;
          if (h.typeEvent.indexOf('STATUS_NOTIFICATION') > -1) {
            if (alertEvent.details && alertEvent.details.errorCode) {
              h.errorCodeEvent = alertEvent.details.errorCode;
            }
          }
        }
      }
      const display = this.service.getAlertHistoryDisplay(h);

      return display;
    });
    this.changeDetectorRef.markForCheck();
  }
}
