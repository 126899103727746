import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { forkJoin, Subject, timer } from 'rxjs';
import { UserGroup } from '@app/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@app/user';
import { ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { AppNavigationService } from '@app/navigation';
import { UserGroupDialogComponent } from '@app/user/user-group-list/user-group-dialog/user-group-dialog.component';

const DEFAULT_COLUMNS = ['_key', 'actions'];
const MIN_LOADING_TIME = 300;

@Component({
  selector: 'app-user-group-list',
  templateUrl: './user-group-list.component.html',
  styleUrls: ['./user-group-list.component.scss']
})
export class UserGroupListComponent implements OnInit, OnDestroy {
  @Input() isAdmin = false;
  userKey: string;

  readonly displayedColumns = DEFAULT_COLUMNS;

  dataSource = new MatTableDataSource<UserGroup>();
  resultsLength: number;

  private queryParams: any;

  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private sort: MatSort;

  private addDialogRef: MatDialogRef<UserGroupDialogComponent>;
  private editDialogRef: MatDialogRef<UserGroupDialogComponent>;

  private _destroyed = new Subject();

  constructor(
    private route: ActivatedRoute,
    private navService: AppNavigationService,
    private dialog: MatDialog,
    private userService: UserService
  ) {
    this.navService.userId.pipe(takeUntil(this._destroyed)).subscribe(userId => {
      this.userKey = userId.replace('user/', '');
    });
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this._destroyed), debounceTime(500)).subscribe(params => {
      this.resultsLength = 0;
      this.queryParams = params;
      this.loadUserGroupData();
    });
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  onAdd(): void {
    if (this.addDialogRef) {
      return;
    }

    this.addDialogRef = this.dialog.open(UserGroupDialogComponent, {
      disableClose: true,
      width: '50%',
      data: { userKey: this.userKey }
    });
    this.addDialogRef
      .afterClosed()
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {
        this.addDialogRef = undefined;
        this.loadUserGroupData();
      });
  }

  onEdit(userGroupEditing: UserGroup): void {
    if (this.editDialogRef) {
      return;
    }
    this.userService
      .getUserGroupById(userGroupEditing._key)
      .pipe(takeUntil(this._destroyed))
      .subscribe(userGroup => {
        this.editDialogRef = this.dialog.open(UserGroupDialogComponent, {
          disableClose: true,
          width: '50%',
          data: {
            userKey: this.userKey,
            userGroup: {
              _key: userGroup._key,
              profiles: userGroup.profiles || [],
              themes: userGroup.themes || [],
              userGroups: userGroup.userGroups || [],
              cpoGroups: userGroup.cpoGroups || [],
              cpos: userGroup.cpos || [],
              emsps: userGroup.emsps || [],
              fleets: userGroup.fleets || [],
              locations: userGroup.locations || [],
              administrationProfiles: userGroup.administrationProfiles || []
            }
          }
        });
        this.editDialogRef
          .afterClosed()
          .pipe(takeUntil(this._destroyed))
          .subscribe(result => {
            this.editDialogRef = undefined;
            if (result) {
              this.loadUserGroupData();
            }
          });
      });
  }

  private loadUserGroupData() {
    this.navService.setAppBarProgress(this.resultsLength === 0);
    const queryParams = Object.assign({}, this.queryParams);
    forkJoin([
      this.userService.getEligibleGroups(this.userKey, new HttpParams({ fromObject: queryParams })),
      timer(MIN_LOADING_TIME)
    ])
      .pipe(takeUntil(this._destroyed))
      .subscribe(([userGroups, _]) => {
        this.navService.setAppBarProgress(false);
        this.resultsLength = userGroups.length;
        this.dataSource.data = userGroups;
        this.resultsLength = userGroups.length;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }
}
