import { Injectable } from '@angular/core';
import { UserService } from '@app/user';
import { Observable, of, pipe, throwError, UnaryFunction } from 'rxjs';
import { concatMap, map, mapTo, tap } from 'rxjs/operators';
import { CognitoHttpService } from './cognito-http.service';
import { AccessToken } from './models/access-token';
import { AccessTokenDto } from './models/fetch-tokens.dto';
import { UserType } from '@app/core/user-data/user-type';

@Injectable()
export class CognitoAuthenticationService {
  accessToken: AccessToken;
  accessTokenStr: string;
  userType: UserType;
  userScopes: string[];

  constructor(private httpService: CognitoHttpService, private userService: UserService) {
    this.userType = UserType[localStorage.getItem('AUTHENTICATED_USER_TYPE') || ''];
  }

  authenticate(authorizationCode: string, authenticationId: string): Observable<AccessToken> {
    this.userType = localStorage.getItem('AUTHENTICATED_USER_TYPE') as UserType;
    if (!authorizationCode) {
      return throwError(Error('Missing authorizationCode'));
    }
    return this.httpService
      .authenticate(authorizationCode, authenticationId, this.userType)
      .pipe(this.updateAccessToken(), this.fetchUserScopes());
  }

  isLoggedIn(): boolean {
    return !!this.accessToken;
  }

  revokeToken(): Observable<void> {
    return this.httpService.revokeToken(this.userType).pipe(
      tap(() => {
        this.accessToken = undefined;
        this.accessTokenStr = undefined;
        this.userType = undefined;
        localStorage.removeItem('AUTHENTICATED_USER_TYPE');
      })
    );
  }

  refreshToken(): Observable<AccessToken> {
    if (!this.userType) {
      return throwError(() => new Error(`userType is empty. User is not logged in.`));
    }
    return this.httpService
      .refreshToken(this.userType)
      .pipe(this.updateAccessToken(), this.fetchUserScopes());
  }

  private updateAccessToken(): UnaryFunction<Observable<AccessTokenDto>, Observable<AccessToken>> {
    return pipe(
      tap(token => (this.accessTokenStr = token.accessToken)),
      tap(token => (this.accessToken = this.parseToken(token.accessToken))),
      mapTo(this.accessToken)
    );
  }

  private parseToken(accessToken: string): AccessToken {
    const base64UrlPayload = accessToken.split('.')[1];
    const base64Payload = this.mapBase64UrlToBase64(base64UrlPayload);
    const payload = JSON.parse(atob(base64Payload));
    return new AccessToken({
      ...payload,
      scope: []
    });
  }

  private mapBase64UrlToBase64(base64Url: string): string {
    return base64Url.replace(/-/g, '+').replace(/_/g, '/');
  }

  private fetchUserScopes(): UnaryFunction<Observable<AccessToken>, Observable<AccessToken>> {
    return pipe(
      concatMap(() => this.userService.getUserScope()),
      map(scope => scope?.split(' ') || []),
      tap(scopes => (this.userScopes = scopes)),
      mapTo(this.accessToken)
    );
  }
}
