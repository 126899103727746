import { NgModule } from '@angular/core';
import { AppTranslateModule, SharedModule } from '@app/shared';
import { HubDisplayHeaderComponent } from '@app/smart-charging/base/hub-header-content/hub-display-header.component';
import { HubDetailsComponent } from '@app/smart-charging/base/hub-details/hub-details.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { SmartChargingChartComponent } from '@app/smart-charging/base/chart/chart.component';
import { CommonModule } from '@angular/common';
import { HubSettingsPowerFormComponent } from './hub-settings-power-form/hub-settings-power-form.component';
import { StaticCeilingComponent } from './hub-form/ceiling/static-ceiling/static-ceiling.component';
import { SmartChargingServiceListComponent } from '@app/smart-charging/services/service-list/service-list.component';
import { WidgetModule } from '@app/widget';
import { HubStrategyComponent } from './advanced-setting/hub-strategy/hub-strategy.component';
import { HubPeriodComponent } from './hub-form/hub-period/hub-period.component';
import { HubEcoPeriodsComponent } from '@app/smart-charging/base/advanced-setting/eco-periods/hub-eco-periods.component';
import { FilterChartComponent } from '@app/smart-charging/base/chart/filter-chart/filter-chart.component';
import { SelectAllComponent } from './chart/filter-chart/evse-filter/select-all/select-all.component';
import { ChartDialogComponent } from './chart/chart-dialog/chart-dialog.component';

@NgModule({
  imports: [
    AppTranslateModule.forChild(['infra', 'smartCharging', 'common']),
    CommonModule,
    NgxMatFileInputModule,
    SharedModule,
    WidgetModule
  ],
  declarations: [
    HubDisplayHeaderComponent,
    HubDetailsComponent,
    SmartChargingChartComponent,
    HubSettingsPowerFormComponent,
    StaticCeilingComponent,
    SmartChargingServiceListComponent,
    HubStrategyComponent,
    HubPeriodComponent,
    HubEcoPeriodsComponent,
    FilterChartComponent,
    SelectAllComponent,
    ChartDialogComponent
  ],
  entryComponents: [ChartDialogComponent],
  exports: [
    HubDisplayHeaderComponent,
    HubDetailsComponent,
    HubSettingsPowerFormComponent,
    HubDetailsComponent,
    SmartChargingChartComponent,
    SmartChargingServiceListComponent,
    HubStrategyComponent,
    HubEcoPeriodsComponent,
    FilterChartComponent
  ]
})
export class SmartChargingBaseModule {
  constructor(sanitizer: DomSanitizer, iconRegistry: MatIconRegistry) {
    iconRegistry.addSvgIcon(
      'device_hub',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_device_hub.svg')
    );
    iconRegistry.addSvgIcon(
      'ev_station_outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_ev_station_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'max_power',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_power.svg')
    );
    iconRegistry.addSvgIcon(
      'time_power',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/smartCharging/ic_time_power.svg')
    );
  }
}
