import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { FilterAutocompleteOption, FilterOption, SearchFilter } from '@app/shared';
import { AppNavigationService } from '@app/navigation';

export const INPUT_FILTER_OPTIONS: FilterOption[] = [
  { name: 'transaction.ID token', param: 'tokenId' },
  { name: 'transaction.Location', param: 'location', type: 'option', sort: true },
  { name: 'transaction.filter.EVSE ID', param: 'evseId', type: 'option', sort: true },
  { name: 'transaction.Transaction ID', param: 'uuid' },
  { name: 'transaction.Gireve transaction ID', param: 'gireveSessionId' },
  { name: 'transaction.filter.CPO', param: 'cpo', type: 'option', sort: true },
  { name: 'transaction.filter.CPO group', param: 'cpoGroup', type: 'option', sort: true },
  { name: 'transaction.eMSP', param: 'emsp', type: 'option', sort: true },
  { name: 'transaction.Authorization channel', param: 'emspChannel', type: 'option', sort: true },
  { name: 'transaction.Status', param: 'status', type: 'option', sort: true }
];

export const FEATURE_OPTIONS: FilterOption[] = [
  { name: 'transaction.filter.Compliant', param: 'compliant' },
  { name: 'transaction.filter.Incompliant', param: 'incompliant' },
  { name: 'transaction.filter.Technically incompliant', param: 'technicallyIncompliant' },
  { name: 'transaction.filter.Authorization rejected', param: 'rejected' }
];

const STATUS_OPTIONS: FilterAutocompleteOption[] = [
  { display: 'transaction.status.RemoteFailed', value: 'RemoteFailed', translate: true },
  { display: 'transaction.status.RemoteStartNoShow', value: 'RemoteStartNoShow', translate: true },
  {
    display: 'transaction.status.RemoteStartExpired',
    value: 'RemoteStartExpired',
    translate: true
  },
  { display: 'transaction.status.Remote', value: 'Remote', translate: true },
  {
    display: 'transaction.status.AuthorizeTerminated',
    value: 'AuthorizeTerminated',
    translate: true
  },
  { display: 'transaction.status.AuthorizeExpired', value: 'AuthorizeExpired', translate: true },
  {
    display: 'transaction.status.TransactionStartTerminated',
    value: 'TransactionStartTerminated',
    translate: true
  },
  {
    display: 'transaction.status.TransactionStopReceived',
    value: 'TransactionStopReceived',
    translate: true
  },
  {
    display: 'transaction.status.TransactionStoppedWithError',
    value: 'TransactionStoppedWithError',
    translate: true
  }
];
export class TxFilter extends SearchFilter {
  inputFilters: FilterOption[] = INPUT_FILTER_OPTIONS.map(f => Object.assign({}, f));
  features: FilterOption[] = FEATURE_OPTIONS.map(f => Object.assign({}, f));

  private cpoOptions: FilterAutocompleteOption[];
  private cpoGroupOptions: FilterAutocompleteOption[];
  private locationOptions: FilterAutocompleteOption[];
  private evseOptions: FilterAutocompleteOption[];
  private emspOptions: FilterAutocompleteOption[];
  private emspChannelOptions: FilterAutocompleteOption[];

  constructor(private navService: AppNavigationService) {
    super();
  }

  getInputAutoCompleteOptions(selected: FilterOption): Observable<FilterAutocompleteOption[]> {
    if (selected) {
      switch (selected.param) {
        case 'cpo':
          if (!this.cpoOptions) {
            return this.navService.loadCpoAutoCompleteOptions();
          } else {
            return of(this.cpoOptions);
          }
        case 'cpoGroup':
          if (!this.cpoGroupOptions) {
            return this.navService.loadCpoGroupAutoCompleteOptions();
          } else {
            return of(this.cpoGroupOptions);
          }
        case 'location':
          if (!this.locationOptions) {
            return this.navService.loadLocationAutoCompleteOptions();
          } else {
            return of(this.locationOptions);
          }
        case 'evseId':
          if (!this.evseOptions) {
            return this.navService.loadEvseAutoCompleteOptions();
          } else {
            return of(this.evseOptions);
          }
        case 'status':
          return of(STATUS_OPTIONS);
        case 'emsp':
          if (!this.emspOptions) {
            return this.navService.loadEmspNetworkAutoCompleteOptions();
          } else {
            return of(this.emspOptions);
          }
        case 'emspChannel':
          if (!this.emspChannelOptions) {
            return this.navService.loadEmspChannelAutoCompleteOptions();
          } else {
            return of(this.emspChannelOptions);
          }
        default:
          return undefined;
      }
    }
    return undefined;
  }

  onFeatureChipClick(filter: FilterOption) {
    filter.value = filter.value ? undefined : true;
    if (filter.param === 'compliant' && filter.value) {
      this.removeFeatureFilterByParam('incompliant');
      this.removeFeatureFilterByParam('technicallyIncompliant');
    }
    if (
      (filter.param === 'incompliant' || filter.param === 'technicallyIncompliant') &&
      filter.value
    ) {
      this.removeFeatureFilterByParam('compliant');
    }
  }

  applyFeatureFilter(feature: FilterOption, params: any) {
    switch (feature.param) {
      case 'compliant':
        params.compliant = 'true';
        break;
      case 'incompliant':
        params.compliant = 'false';
        break;
      case 'technicallyIncompliant':
        params.technicallyCompliant = 'false';
        break;
      default:
        params[feature.param] = feature.value;
    }
  }

  parseOptionFilterParam(
    filter: FilterOption,
    value: any
  ): FilterAutocompleteOption | Observable<FilterAutocompleteOption> {
    let $options: Observable<FilterAutocompleteOption[]>;
    switch (filter.param) {
      case 'cpo':
        $options = this.cpoOptions
          ? of(this.cpoOptions)
          : this.navService.loadCpoAutoCompleteOptions();
        break;
      case 'cpoGroup':
        $options = this.cpoGroupOptions
          ? of(this.cpoGroupOptions)
          : this.navService.loadCpoGroupAutoCompleteOptions();
        break;
      case 'location':
        $options = this.locationOptions
          ? of(this.locationOptions)
          : this.navService.loadLocationAutoCompleteOptions();
        break;
      case 'evseId':
        $options = this.evseOptions
          ? of(this.evseOptions)
          : this.navService.loadEvseAutoCompleteOptions();
        break;
      case 'status':
        return STATUS_OPTIONS.find(o => o.value === value);
      case 'emsp':
        $options = this.emspOptions
          ? of(this.emspOptions)
          : this.navService.loadEmspNetworkAutoCompleteOptions();
        break;
      case 'emspChannel':
        $options = this.emspChannelOptions
          ? of(this.emspChannelOptions)
          : this.navService.loadEmspChannelAutoCompleteOptions();
        break;
      default:
        return undefined;
    }
    if ($options) {
      return $options.pipe(map(options => options.find(o => o.value === value)));
    }
    return undefined;
  }

  parseFeatureParam(key: string, value: any): string {
    if (key === 'compliant') {
      if (value === 'true') {
        return 'compliant';
      } else {
        return 'incompliant';
      }
    }
    if (key === 'technicallyCompliant' && value === 'false') {
      return 'technicallyIncompliant';
    }
    if (key === 'rejected' && value === true) {
      return 'rejected';
    }
    return undefined;
  }
}
