import { Component, Input } from '@angular/core';
import { ChartData } from '@app/smart-charging/base/chart/chart.component';
import { Unit } from '@app/smart-charging/base/chart/filter-chart/available-units';
import { TranslateService } from '@ngx-translate/core';
import { ChartColorScheme } from '@app/shared/interface/shared-type';

interface DataModel {
  color: string;
  min: number;
  max: number;
  name: Date | string;
  series: string;
  value: number;
}

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent {
  @Input() readonly model: DataModel;
  @Input() readonly colors: ChartColorScheme;
  @Input() readonly customColors: any[];
  @Input() readonly data: ChartData[];
  @Input() readonly selectedUnit: Unit;

  constructor(public translate: TranslateService) {}

  getColor(): string {
    if (this.model.color) {
      return this.model.color;
    }
    const index = this.data.findIndex(chartData => chartData.name === this.model.series);
    return this.colors.domain[index];
  }
}
