import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-element-dialog',
  templateUrl: './add-element-dialog.component.html'
})
export class AddElementDialogComponent {
  elements: any[];
  attribute: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddElementDialogComponent>
  ) {
    this.elements = this.data.elements;
    this.attribute = this.data.attribute;
  }

  isSelected(element: any): boolean {
    return this.data.selectedElements?.some(e => e[this.attribute] === element[this.attribute]);
  }
}
