import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Alert } from '@app/core';
import { AlertAcknowledgeDialogComponent } from '../acknowledge-dialog/acknowledge-dialog.component';
import { AlertDisplay, AlertService } from '../../alert.service';

@Component({
  selector: 'app-alert-display-header',
  templateUrl: 'alert-display-header.component.html',
  styleUrls: ['alert-display-header.component.scss']
})
export class AlertDisplayHeaderComponent implements OnInit, OnDestroy {
  @Input() display: AlertDisplay;
  @Input() ackButton: boolean;
  @Input() launchButton: boolean;
  @Input() fromInfra: boolean;
  @Input() username: string;
  @Input() isUserAdmin: boolean;

  @Output() alertChange = new EventEmitter<Alert>();

  private _destroyed = new Subject();
  alertIdParam: string;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    private service: AlertService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.alertIdParam = this.route.snapshot.queryParamMap.get('id');
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  goToAlertDetails(alertId: string) {
    if (this.alertIdParam) {
      const link = `${window.location.origin}/webmanager/alertes/view?sites=${this.display.alert.stationId}`;
      window.open(link, '_blank');
    } else {
      this.router.navigate(['/alert'], { queryParams: { id: alertId } });
    }
  }

  showAcknowledgeDialog(): void {
    const isAcknowledged = !!this.display.alert.acknowledge;
    const canUserAcknowledge =
      this.isUserAdmin || this.display.alert.acknowledge.contact.name === this.username;

    if (isAcknowledged) {
      if (!canUserAcknowledge) {
        return;
      }
      this.openDialogAndHandleResult(true, this.unacknowledgeAlert.bind(this));
    } else {
      this.openDialogAndHandleResult(false, this.acknowledgeAlert.bind(this));
    }
  }

  openDialogAndHandleResult(acknowledged: boolean, action: () => void): void {
    this.dialog
      .open(AlertAcknowledgeDialogComponent, { data: { acknowledged } })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          action();
        }
      });
  }

  goToFsTicket(item: string) {
    this.service.goToFsTicket(item);
  }

  private acknowledgeAlert() {
    this.service
      .acknowledgeAlert(this.display.alert)
      .pipe(takeUntil(this._destroyed))
      .subscribe(alert => this.alertChange.emit(alert));
  }

  private unacknowledgeAlert() {
    this.service
      .unacknowledgeAlert(this.display.alert)
      .pipe(takeUntil(this._destroyed))
      .subscribe(alert => this.alertChange.emit(alert));
  }
}
