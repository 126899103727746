import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/auth/auth.service';
import { FeatureDto } from '@app/commissioning/model/feature-dto';
import { UserProfileWrapper } from '@app/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from './environment';
import {
  AdministrationProfileFeature,
  BannerFeature,
  CognitoAuthenticationFeature,
  Features
} from './environment-features.model';
import { FeatureCode } from './feature-code';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private features: Features = {
    administrationProfiles: this.initializeAdministrationProfiles(),
    banner: this.initializeBanner(),
    cognitoAuthentication: this.initializeCognitoAuthentication()
  };

  private featuresApiUrl = `api/features`;
  private featuresDto: FeatureDto[] = [];

  constructor(private http: HttpClient, private auth: AuthenticationService) {}

  // TODO: merge this method with isFeatureEnabledNew
  isFeatureEnabled(feature: keyof Features): boolean {
    return this.features[feature].enabled;
  }

  getFeature<T extends keyof Features>(feature: T): Features[T] {
    return this.features[feature];
  }

  private initializeAdministrationProfiles(): AdministrationProfileFeature {
    const profileNames = environment.administrationProfileNames.split(',');
    const profileValues = environment.administrationProfileValues.split(',');
    return new AdministrationProfileFeature({
      enabled: JSON.parse(environment.administrationProfileEnabled.toLowerCase()),
      profiles: profileValues.map(
        (profileValue, i) =>
          new UserProfileWrapper({
            value: profileValue,
            name: profileNames[i]
          })
      )
    });
  }

  private initializeBanner(): BannerFeature {
    const banner = environment.banner;
    return new BannerFeature({
      enabled: banner.enabled === 'True',
      endDate: banner.endDate ? moment(banner.endDate) : undefined,
      startDate: banner.startDate ? moment(banner.startDate) : undefined
    });
  }

  initializeFeatures(): Observable<FeatureDto[]> {
    return this.fetchAllFeatures().pipe(
      tap(featuresDto => {
        this.featuresDto = featuresDto;
      }),
      catchError(err => {
        console.error('Error calling feature flag API (' + this.featuresApiUrl + ') : ', err);
        return of([]);
      })
    );
  }

  private fetchAllFeatures(): Observable<FeatureDto[]> {
    return this.http
      .get<FeatureDto[]>(this.featuresApiUrl)
      .pipe(map(rawData => rawData.map(feature => new FeatureDto(feature))));
  }

  getUserGrantedScopes() {
    const grantedScopesItem = this.auth.getGrantedScopes();
    let scopesUnsplited: string;

    if (
      !grantedScopesItem ||
      !(grantedScopesItem instanceof Array) ||
      (grantedScopesItem as any[]).length === 0
    ) {
      scopesUnsplited = '';
    } else {
      scopesUnsplited = (grantedScopesItem as string[])[0];
    }
    return scopesUnsplited.split(/\s+/);
  }

  isFeatureEnabledNew(featureCode: FeatureCode): boolean {
    return this.featuresDto
      .filter(feature => feature.isEnabled)
      .map(feature => feature.code)
      .includes(featureCode);
  }

  private initializeCognitoAuthentication(): CognitoAuthenticationFeature {
    return new CognitoAuthenticationFeature({
      authorizeEndpoint: environment.authenticationAuthorizeEndpoint,
      enabled: true,
      identityProviders: environment.identityProviders,
      redirectUri: environment.authenticationRedirectUri
    });
  }
}
