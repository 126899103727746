import { Injectable } from '@angular/core';
import { CanActivate, Route, Router, UrlTree } from '@angular/router';
import { FeatureCode } from '../../../environments/feature-code';
import { ConfigService } from '../../../environments/config.service';

@Injectable({
  providedIn: 'root'
})
export class LocationEvControllerFeatureFlagGuard implements CanActivate {
  constructor(private configService: ConfigService, private router: Router) {}

  canActivate(): boolean | UrlTree {
    const featureEnabled = this.configService.isFeatureEnabledNew(FeatureCode.LOCATION_GET_EVC);

    return featureEnabled ? true : this.router.createUrlTree(['/infrastructure']);
  }
}
