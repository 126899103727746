export class SetChargingProfileRequest {
  /**
   * Required. The connector to which the charging profile applies. If connectorId = 0,
   * the message contains an overall limit for the Charge Point.
   */
  connectorId: number;
  /**
   * Required. The charging profile to be set at the Charge Point.
   */
  csChargingProfiles: ChargingProfile;

  constructor(setChargingProfileRequest: Partial<SetChargingProfileRequest>) {
    this.connectorId = setChargingProfileRequest.connectorId;
    this.csChargingProfiles = new ChargingProfile(setChargingProfileRequest.csChargingProfiles);
  }
}

class ChargingProfile {
  /**
   * Required. Unique identifier for this profile
   */
  chargingProfileId: number;
  /**
   * Optional. Only valid if ChargingProfilePurpose is set to TxProfile,
   * the transactionId MAY be used to match the profile to a specific
   * transaction.
   */
  transactionId?: number;
  /**
   * Required. Value determining level in hierarchy stack of profiles.
   * Higher values have precedence over lower values. Lowest level is
   * 0.
   */
  stackLevel: number;
  /**
   * Required. Defines the purpose of the schedule transferred by this
   * message.
   */
  chargingProfilePurpose: ChargingProfilePurposeType;
  /**
   * Required. Indicates the kind of schedule.
   */
  chargingProfileKind: ChargingProfileKindType;
  /**
   * Optional. Indicates the start point of a recurrence.
   */
  recurrencyKind?: RecurrencyKindType;
  /**
   * Optional. Point in time at which the profile starts to be valid. If
   * absent, the profile is valid as soon as it is received by the Charge
   * Point.
   */
  validFrom?: Date;
  /**
   * Optional. Point in time at which the profile stops to be valid. If
   * absent, the profile is valid until it is replaced by another profile.
   */
  validTo?: Date;
  /**
   * Required. Contains limits for the available power or current over
   * time.
   */
  chargingSchedule: ChargingSchedule;

  constructor(chargingProfile: Partial<ChargingProfile>) {
    this.chargingProfileId = chargingProfile.chargingProfileId;
    this.transactionId = chargingProfile.transactionId;
    this.stackLevel = chargingProfile.stackLevel;
    this.chargingProfilePurpose = chargingProfile.chargingProfilePurpose;
    this.chargingProfileKind = chargingProfile.chargingProfileKind;
    this.recurrencyKind = chargingProfile.recurrencyKind;
    this.validFrom = chargingProfile.validFrom ? new Date(chargingProfile.validFrom) : undefined;
    this.validTo = chargingProfile.validTo ? new Date(chargingProfile.validTo) : undefined;
    this.chargingSchedule = new ChargingSchedule(chargingProfile.chargingSchedule);
  }
}

export enum ChargingProfilePurposeType {
  /**
   * Configuration for the maximum power or current available for an entire Charge Point.
   */
  ChargePointMaxProfile = 'ChargePointMaxProfile',
  /**
   * Default profile *that can be configured in the Charge Point. When a new transaction is started, this profile
   * SHALL be used, unless it was a transaction that was started by a RemoteStartTransaction.req with a
   * ChargeProfile that is accepted by the Charge Point.
   */
  TxDefaultProfile = 'TxDefaultProfile',
  /**
   * Profile with constraints to be imposed by the Charge Point on the current transaction, or on a new transaction
   * when this is started via a RemoteStartTransaction.req with a ChargeProfile. A profile with this purpose SHALL
   * cease to be valid when the transaction terminates.
   */
  TxProfile = 'TxProfile'
}

export enum ChargingProfileKindType {
  /**
   * Schedule periods are relative to a fixed point in time defined in the schedule.
   */
  Absolute = 'Absolute',
  /**
   * The schedule restarts periodically at the first schedule period.
   */
  Recurring = 'Recurring',
  /**
   * Schedule periods are relative to a situation-specific start point (such as the start of a Transaction) that is determined by the
   * charge point.
   */
  Relative = 'Relative'
}

export enum RecurrencyKindType {
  /**
   * The schedule restarts every 24 hours, at the same time as in the startSchedule.
   */
  Daily = 'Daily',
  /**
   * The schedule restarts every 24 hours, at the same time as in the startSchedule.
   */
  Weekly = 'Weekly'
}

class ChargingSchedule {
  /**
   * Optional. Duration of the charging schedule in seconds. If the
   * duration is left empty, the last period will continue indefinitely or
   * until end of the transaction in case startSchedule is absent.
   */
  duration?: number;
  /**
   * Optional. Starting point of an absolute schedule. If absent the
   * schedule will be relative to start of charging.
   */
  startSchedule?: Date;
  /**
   * Required. The unit of measure Limit is expressed in.
   */
  chargingRateUnit: ChargingRateUnitType;
  /**
   * Required. List of ChargingSchedulePeriod elements defining
   * maximum power or current usage over time. The startSchedule of
   * the first ChargingSchedulePeriod SHALL always be 0.
   */
  chargingSchedulePeriod: Array<ChargingScheduledPeriod>;
  /**
   * Optional. Minimum charging rate supported by the electric
   * vehicle. The unit of measure is defined by the chargingRateUnit.
   * This parameter is intended to be used by a local smart charging
   * algorithm to optimize the power allocation for in the case a
   * charging process is inefficient at lower charging rates. Accepts at
   * most one digit fraction
   */
  minChargingRate?: number;

  constructor(chargingSchedule: Partial<ChargingSchedule>) {
    this.duration = chargingSchedule.duration;
    this.startSchedule = chargingSchedule.startSchedule
      ? new Date(chargingSchedule.startSchedule)
      : undefined;
    this.chargingRateUnit = chargingSchedule.chargingRateUnit;
    this.chargingSchedulePeriod = chargingSchedule.chargingSchedulePeriod.map(
      chargingSchedulePeriod => new ChargingScheduledPeriod(chargingSchedulePeriod)
    );
    this.minChargingRate = chargingSchedule.minChargingRate;
  }
}

export enum ChargingRateUnitType {
  /**
   * Watts (power).
   * This is the TOTAL allowed charging power.
   * If used for AC Charging, the phase current should be calculated via: Current per phase = Power / (Line Voltage * Number of
   * Phases). The "Line Voltage" used in the calculation is not the measured voltage, but the set voltage for the area (hence, 230 of
   * 110 volt). The "Number of Phases" is the numberPhases from the ChargingSchedulePeriod.
   * It is usually more convenient to use this for DC charging.
   * Note that if numberPhases in a ChargingSchedulePeriod is absent, 3 SHALL be assumed.
   */
  W = 'W',
  /**
   * Amperes (current).
   * The amount of Ampere per phase, not the sum of all phases.
   * It is usually more convenient to use this for AC charging.
   */
  A = 'A'
}

class ChargingScheduledPeriod {
  /**
   * Required. Start of the period, in seconds from the start of schedule. The value of
   * StartPeriod also defines the stop time of the previous period.
   */
  startPeriod: number;
  /**
   * Required. Charging rate limit during the schedule period, in the applicable
   * chargingRateUnit, for example in Amperes or Watts. Accepts at most one digit
   * fraction
   */
  limit: number;
  /**
   * Optional. The number of phases that can be used for charging. If a number of
   * phases is needed, numberPhases=3 will be assumed unless another number is
   * given.
   */
  numberPhases?: number;

  constructor(chargingSchedulepPeriod: Partial<ChargingScheduledPeriod>) {
    this.startPeriod = chargingSchedulepPeriod.startPeriod;
    this.limit = chargingSchedulepPeriod.limit;
    this.numberPhases = chargingSchedulepPeriod.numberPhases;
  }
}
