import { Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { TxDisplay } from '../tx.service';

@Component({
  selector: 'app-tx-display-header',
  templateUrl: 'tx-display-header.component.html',
  styleUrls: ['tx-display-header.component.scss']
})
export class TxDisplayHeaderComponent {
  @Input() display: TxDisplay;
  @Input() emi3Link: boolean;
  @Input() hideChips: boolean;

  showActions: boolean;

  constructor(public translate: TranslateService) {}

  toggleActions(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.showActions = !this.showActions;
  }

  isOngoingStatus(): boolean {
    return this.display.tx.status === 'TransactionStartTerminated' ? true : false;
  }
}
