import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  applyTimeRange,
  TimeRange,
  TimeRangeEnum
} from '@app/core/smart-charging/chart/time-range';
import { MatDialog } from '@angular/material/dialog';
import { EnergyNode } from '@app/core/smart-charging/energy-node';
import { TranslateService } from '@ngx-translate/core';
import {
  AVAILABLE_UNITS,
  Unit,
  UnitDatabaseField
} from '@app/smart-charging/base/chart/filter-chart/available-units';
import { DialogPeriodComponent } from '@app/smart-charging/filter-dialogs/dialog-period/dialog-period.component';

export interface ChartFilter {
  deviceId?: string[];
  timeRange?: TimeRange;
  field?: string;
  nbOfPoint?: number;
  displayPowerCaps?: boolean;
}

@Component({
  selector: 'app-filter-chart',
  templateUrl: './filter-chart.component.html',
  styleUrls: ['./filter-chart.component.scss']
})
export class FilterChartComponent implements OnChanges {
  @Input() evseFromHub: EnergyNode[];
  @Input() colors: { domain };

  @Output() filterChange = new EventEmitter<ChartFilter>();
  @Output() downloadData = new EventEmitter<ChartFilter>();

  filterValue: ChartFilter = {};
  hasUnitFilterChanged: boolean;
  availableUnits: Unit[] = AVAILABLE_UNITS;

  private readonly defaultTimeRangeValue = applyTimeRange(TimeRangeEnum.LAST_2_DAYS);
  private readonly defaultUnit = UnitDatabaseField.POWER;
  private readonly defaultNbPoint = 25;
  private readonly MS_PER_DAY = 1000 * 60 * 60 * 24;

  constructor(private dialog: MatDialog, private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.evseFromHub && this.evseFromHub) {
      this.evseFromHub?.sort((a, b) => a.evseName?.localeCompare(b.evseName));
      this.initFilter();
    }
    this.onFilterChange();
  }

  private initFilter(): void {
    this.filterValue = {
      field: this.defaultUnit,
      timeRange: this.defaultTimeRangeValue,
      deviceId: this.mapEvseValue(),
      nbOfPoint: this.defaultNbPoint
    };
  }

  onFilterChange(): void {
    this.filterChange.emit(this.filterValue);
  }

  openTimeDialog(): void {
    const dialogPeriod = this.dialog.open(DialogPeriodComponent, {
      data: this.filterValue.timeRange,
      autoFocus: false,
      restoreFocus: false,
      disableClose: true
    });

    dialogPeriod.afterClosed().subscribe(result => {
      if (result) {
        this.filterValue.timeRange = result;
        this.filterValue.nbOfPoint = this.computePointNumberByTimeRange(result);
        this.onFilterChange();
      }
    });
  }

  private computePointNumberByTimeRange(timeRange: any): number {
    const hoursPerDay = 24;
    const maxDaysPerMonth = 31;
    const maxNbPoints = 100;
    const daysDiff = this.getNbDayDiffBetweenTwoDates(timeRange.startDate, timeRange.stopDate);
    if (daysDiff === 0) {
      return hoursPerDay;
    } else if (daysDiff <= 7) {
      return daysDiff * hoursPerDay;
    } else if (daysDiff <= maxDaysPerMonth) {
      return maxDaysPerMonth;
    }
    return maxNbPoints;
  }

  private getNbDayDiffBetweenTwoDates(start: Date, stop: Date): number {
    const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
    const utc2 = Date.UTC(stop.getFullYear(), stop.getMonth(), stop.getDate());
    return Math.floor((utc2 - utc1) / this.MS_PER_DAY);
  }

  hasTimeRangeFilterChanged(): boolean {
    return this.filterValue.timeRange !== this.defaultTimeRangeValue;
  }

  hasEvseFilterChanged(): boolean {
    if (this.evseFromHub && !this.filterValue.deviceId) {
      return true;
    }
    return this.filterValue?.deviceId?.length < this.evseFromHub?.length;
  }

  displaySelected(): string {
    if (this.filterValue.deviceId && this.filterValue.deviceId.length === this.evseFromHub.length) {
      return `${this.translate.instant(
        'smartCharging.EVSE-SELECT-PLACEHOLDER'
      )} (${this.translate.instant('All')})`;
    }

    if (this.filterValue.deviceId) {
      return this.filterValue.deviceId[0];
    }
    return '';
  }

  onEvseSelectionChange(): void {
    if (this.filterValue.deviceId && this.filterValue.deviceId.length === 0) {
      delete this.filterValue.deviceId;
      return;
    }
    this.onFilterChange();
  }

  getColorByIndex(index: number): string {
    return this.colors.domain[index];
  }

  mapEvseValue(): string[] {
    return this.evseFromHub.map(node => node.chargePointId);
  }

  onUnitFilterChange(): void {
    this.hasUnitFilterChanged = true;
    this.onFilterChange();
  }

  onDownloadData(): void {
    this.downloadData.emit(this.filterValue);
  }

  displayPowerCaps(): void {
    this.filterValue.displayPowerCaps = !this.filterValue.displayPowerCaps;
    this.onFilterChange();
  }
}
