import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartData } from '@app/smart-charging/base/chart/chart.component';
import { Point } from '@app/smart-charging/smart-charging.service';
import { Unit } from '@app/smart-charging/base/chart/filter-chart/available-units';
import { ChartColorScheme } from '@app/shared/interface/shared-type';

export interface DialogData {
  data: ChartData[];
  colorScheme: ChartColorScheme;
  customColors?: any[];
  refLines?: Point[];
  staticCeiling: number;
  selectedUnit: Unit;
  yAxisLabel: string;
  xMin: Date;
  xMax: Date;
}

@Component({
  templateUrl: './chart-dialog.component.html',
  styleUrls: ['./chart-dialog.component.scss']
})
export class ChartDialogComponent implements OnInit {
  chartData: DialogData;

  constructor(@Inject(MAT_DIALOG_DATA) data: DialogData) {
    this.chartData = data;
  }

  ngOnInit(): void {}
}
