import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent {
  @Input() mandatoryFields: string[];
  @Input() optionalFields?: string[];
  @Input() fields: string[];

  datasource = new MatTableDataSource();

  constructor() {}

  isMandatory(field: string): boolean {
    return this.mandatoryFields.includes(field);
  }
}
