import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CsvReaderService } from './csvReader.service';

/**
 * This component is used to map csv file into a list.
 *
 * To use : use the <app-import-button-map-csv-to-list> selector.
 *
 * Inputs : - columnNames : a list containing the names of columns in the csv file.
 *          - onlyIconButton : a boolean to know if you want an icon or an icon with text.
 *          - expectedListOfLines : boolean to know if you want to get a line as an object or a column as un object.
 *
 * Output : - resultOutput : an event emitter than return a list.
 *
 *
 * Depends on the boolean 'expectedListOfLine', the output can be a list of lines (listOfLines) if it's true,
 * OR the output can be a list of columns (listOfColumns).
 *
 * Example :
 *
 * <app-import-button-map-csv-to-list [columnNames]="variableThanContainAListOfColumnNames"
 *                                    [onlyIconButton]="trueOrFalse"
 *                                    [expectedListOfLines]="trueOrFalse"
 *                                    (resultOutput)="getResult($event)">
 *
 * </app-import-button-map-csv-to-list>
 *
 *
 * if expectedListLines is true (listOfLines), the resutOutput will be in that form :
 *  [['L2C1','L2C2','L2C3','L2C4'],
 *   ['L3C1','L3C2','L3C3','L3C4'],
 *   ['L4C1','L4C2','L4C3','L4C4']]
 *
 * if expectedListLines is false (listOfColumns), the resutOutput will be in that form :
 *  [{columnName:'ColumnName1',
 *    values: ['L2C1','L3C1','L4C1','L5C1','L6C1',]},
 *   {columnName:'ColumnName2',
 *    values: ['L2C2','L3C2','L4C2','L5C2','L6C2',]}]
 *
 *
 * Don't forget to create a method that get the output event is ts file.
 */

export class ListOfColumns {
  columnName: string;
  values: string[];

  constructor(columnName: string, values: string[]) {
    this.columnName = columnName;
    this.values = values;
  }
}

@Component({
  selector: 'app-import-button-map-csv-to-list',
  templateUrl: './import-button-map-csv-to-list.component.html'
})
export class ImportButtonMapCsvToListComponent implements OnInit {
  @ViewChild('fileInput') fileInput;

  @Input()
  columnNames: string[] = [];
  @Input()
  onlyIconButton: boolean;
  @Input()
  expectedListOfLines: boolean;

  @Output()
  resultOutput: EventEmitter<ListOfColumns[]> = new EventEmitter();

  listOfColumns: ListOfColumns = {
    columnName: '',
    values: []
  };
  listOfLines = [];

  listOfColumnsResult: ListOfColumns[] = [];
  listOfLinesResult = [];

  itemList: any[];
  file: File;

  action = this.translateService.instant('common.Close');

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private csvReaderService: CsvReaderService,
    private snackbar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  async changeListener(files: FileList): Promise<void> {
    this.file = files[0];

    if (this.isExtensionValid()) {
      this.csvReaderService.readCsv(files).then(value => {
        this.itemList = value;
        if (this.itemList.length === 0) {
          this.snackbar.open(
            this.translateService.instant('common.Error reading file'),
            this.action
          );
        } else if (!this.isFileMatchesColumnNames(Object.keys(this.itemList[0]))) {
          this.snackbar.open(
            this.translateService.instant(
              'common.Incorrect column names, it is expected to have : '
            ) + this.columnNames,
            this.action
          );
        } else {
          if (this.expectedListOfLines) {
            this.fillListOfLines();
            this.resultOutput.emit(this.listOfLinesResult);
          } else {
            this.fillListOfColumns();
            this.resultOutput.emit(this.listOfColumnsResult);
          }
          if (this.checkIfNoResultFound()) {
            this.snackbar.open(
              this.translateService.instant('common.Error reading file'),
              this.action
            );
          }
          this.changeDetectorRef.markForCheck();
        }
      });
    } else {
      this.snackbar.open(
        this.translateService.instant('common.Invalid format : must be CSV seperated with comma'),
        this.action
      );
    }
  }

  private isExtensionValid(): boolean {
    return this.file.name.substring(this.file.name.lastIndexOf('.')) === '.csv';
  }

  private isFileMatchesColumnNames(itemKeys: string[]): boolean {
    return this.columnNames.some(name => itemKeys.includes(name));
  }

  private fillListOfColumns(): void {
    this.listOfColumnsResult = [];
    this.columnNames.forEach(name => {
      this.listOfColumns = new ListOfColumns(name, []);
      this.itemList
        .filter(item => item[name])
        .forEach(item => this.listOfColumns.values.push(item[name]));
      this.listOfColumnsResult.push(this.listOfColumns);
    });
  }

  private fillListOfLines(): void {
    this.listOfLinesResult = [];
    this.itemList.forEach(item => {
      this.listOfLines = [];
      this.columnNames.forEach(name => {
        if (item[name]) {
          this.listOfLines.push(item[name]);
        } else {
          this.listOfLines.push('');
        }
      });
      this.listOfLinesResult.push(this.listOfLines);
    });
  }

  private checkIfNoResultFound(): boolean {
    let resultBoolean = true;
    this.listOfColumnsResult.forEach(list => {
      if (list.values.length !== 0) {
        resultBoolean = false;
      }
    });
    if (!resultBoolean) {
      return resultBoolean;
    }
    this.listOfLinesResult.forEach(list => {
      if (list.length !== 0) {
        resultBoolean = false;
      }
    });
    return resultBoolean;
  }
}
