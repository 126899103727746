export enum TokenStatusType {
  LOADED = 'LOADED',
  IMPORTED = 'IMPORTED',
  TOKEN_ORDER_REQUEST_ACCEPTED = 'TOKEN_ORDER_REQUEST_ACCEPTED',
  SENT_WAITING = 'SENT_WAITING',
  SENT_VALIDATED = 'SENT_VALIDATED',
  SENT_FAILED = 'SENT_FAILED',
  DELIVERY_CONFIRMED = 'DELIVERY_CONFIRMED',
  RESEND = 'RESEND'
}
