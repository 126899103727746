import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-shared-rules',
  templateUrl: './shared-rules.component.html',
  styleUrls: ['./shared-rules.component.scss']
})
export class SharedRulesComponent {
  @Input() particularCasesTemplate: TemplateRef<any>;
  @Input() mandatoryFieldToUpdate = 'id';

  constructor() {}
}
