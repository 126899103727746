import { Component, Input } from '@angular/core';
import { NetworkDisplayApi } from '@app/network/network.service';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-network-display-header',
  templateUrl: 'network-display-header.component.html',
  styleUrls: ['network-display-header.component.scss']
})
export class NetworkDisplayHeaderComponent {
  @Input() display: NetworkDisplayApi;
  @Input() locationId: string;

  constructor(public translate: TranslateService) {}
}
