import {
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  BaseChartComponent,
  calculateViewDimensions,
  ColorHelper,
  LineSeriesComponent,
  ViewDimensions
} from '@swimlane/ngx-charts';
import { scaleLinear, scalePoint, scaleTime } from 'd3-scale';
import * as shape from 'd3-shape';
import * as moment from 'moment';

@Component({
  selector: 'sim-consumption-chart', // tslint:disable-line: component-selector
  templateUrl: './sim-consumption-chart.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SimConsumptionChartComponent extends BaseChartComponent {
  @ViewChild(LineSeriesComponent, { static: false }) lineSeriesComponent: LineSeriesComponent;

  @Input() curve: any = shape.curveLinear;
  @Input() curveArea: any = shape.curveLinear;
  @Input() legend = false;
  @Input() legendTitle = 'Legend';
  @Input() xAxis;
  @Input() yAxis;
  @Input() showXAxisLabel;
  @Input() showYAxisLabel;
  @Input() showRightYAxisLabel;
  @Input() xAxisLabel;
  @Input() yAxisLabel;
  @Input() yAxisLabelRight;
  @Input() tooltipDisabled = false;
  @Input() gradient: boolean;
  @Input() showGridLines = true;
  @Input() activeEntries: any[] = [];
  @Input() schemeType: string;
  @Input() xAxisTickFormatting: any;
  @Input() yAxisTickFormatting: any;
  @Input() yRightAxisTickFormatting: any;
  @Input() roundDomains = false;
  @Input() colorSchemeLine: any[];
  @Input() contractLimitSeries: any;
  @Input() yAxisScaleFactor: any;
  @Input() yRightAxisScaleFactor: any;
  @Input() rangeFillOpacity: number;
  @Input() animations = true;
  @Input() stacked = true;
  @Input() legendDomain: any[];

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate', { static: false }) tooltipTemplate: TemplateRef<any>;
  @ContentChild('seriesTooltipTemplate', { static: false })
  seriesTooltipTemplate: TemplateRef<any>;

  dims: ViewDimensions;
  transform: string;
  colors: ColorHelper;
  colorsLine: ColorHelper;
  margin: any[] = [0, 0, 0, 0];
  xAxisHeight = 0;
  yAxisWidthLeft = 0;
  yAxisWidthRight = 0;
  scaleType;
  xScale;
  yScaleLeft;
  yScaleRight;
  xDomain;
  yDomainLeft;
  yDomainRight;
  seriesDomain;
  scaledAxis;
  combinedSeries;
  xSet;
  filteredDomain;
  hoveredVertical;
  yOrientLeft = 'left';
  yOrientRight = 'right';
  legendOptions: any;
  legendSpacing = 0;
  bandwidth;
  barPadding = 8;

  trackBy(_index, item): string {
    return item.name;
  }

  update(): void {
    super.update();
    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidthLeft + this.yAxisWidthRight + 50,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: 'below'
    });

    if (!this.yAxis) {
      this.legendSpacing = 0;
    } else if (this.showYAxisLabel && this.yAxis) {
      // this.legendSpacing = 100;
      this.legendSpacing = 0;
    } else {
      // this.legendSpacing = 40;
      this.legendSpacing = 0;
    }

    this.xDomain = this.getXDomain();
    if (this.filteredDomain) {
      this.xDomain = this.filteredDomain;
    }

    this.updateStackedArea();

    this.yDomainLeft = this.getYDomainLeft();
    this.seriesDomain = this.getSeriesDomain();

    this.xScale = this.getXScale(this.xDomain, this.dims.width);
    this.yScaleLeft = this.getYScale(this.yDomainLeft, this.dims.height);

    this.setColors();
    this.legendOptions = this.getLegendOptions();

    this.transform = `translate(${this.dims.xOffset - 50} , ${this.margin[0]})`;
  }

  deactivateAll() {
    this.activeEntries = [...this.activeEntries];
    for (const entry of this.activeEntries) {
      this.deactivate.emit({ value: entry, entries: [] });
    }
    this.activeEntries = [];
  }

  @HostListener('mouseleave')
  hideCircles(): void {
    this.hoveredVertical = null;
    this.deactivateAll();
  }

  updateHoveredVertical(item): void {
    this.hoveredVertical = item.value;
    this.deactivateAll();
  }

  updateDomain(domain): void {
    this.filteredDomain = domain;
    this.xDomain = this.filteredDomain;
    this.xScale = this.getXScale(this.xDomain, this.dims.width);
  }

  updateStackedArea() {
    for (let i = 0; i < this.xSet.length; i++) {
      const val = this.xSet[i];
      let d0 = 0;
      for (const group of this.results) {
        let d = this.findInSeries(group.series, val);
        if (d) {
          d.d0 = d0;
          d.d1 = d0 + d.value;
          d0 += d.value;
        } else {
          d = { name: val, value: 0, d0, d1: d0 };
          if (i > 0) {
            const prevVal = this.xSet[i - 1];
            const prevD = this.findInSeries(group.series, prevVal);
            if (prevD) {
              d.value = prevD.value;
              d.d1 = d.d0 + d.value;
              d0 += d.value;
            }
          }
          group.series.push(d);
        }
      }
      for (const group of this.contractLimitSeries) {
        let d = this.findInSeries(group.series, val);
        if (d) {
          d.d0 = d0;
          d.d1 = d.value;
          d0 += d.value;
        } else {
          d = { name: val, value: 0, d0, d1: d0 };
          if (i > 0) {
            const prevVal = this.xSet[i - 1];
            const prevD = this.findInSeries(group.series, prevVal);
            if (prevD) {
              d.value = prevD.value;
              d.d1 = d.d0 + d.value;
              d0 += d.value;
            }
          }
          group.series.push(d);
        }
      }
    }
  }

  findInSeries(series: any[], val: any) {
    return series.find(item => {
      let a = item.name;
      let b = val;
      if (this.scaleType === 'time') {
        a = a.valueOf();
        b = b.valueOf();
      }
      return a === b;
    });
  }

  getSeriesDomain(): any[] {
    this.combinedSeries = [];
    this.combinedSeries = this.combinedSeries.concat(this.results);
    this.combinedSeries = this.combinedSeries.concat(this.contractLimitSeries);
    return this.combinedSeries.map(d => d.name);
  }

  isDate(value): boolean {
    if (value instanceof Date) {
      return true;
    }
    if (moment.isMoment(value)) {
      return true;
    }

    return false;
  }

  getScaleType(values): string {
    let date = true;
    let num = true;

    for (const value of values) {
      if (!this.isDate(value)) {
        date = false;
      }

      if (typeof value !== 'number') {
        num = false;
      }
    }

    if (date) {
      return 'time';
    }
    if (num) {
      return 'linear';
    }
    return 'ordinal';
  }

  getXDomain(): any[] {
    let values = [];

    for (const results of this.results) {
      for (const d of results.series) {
        if (values.indexOf(d.name) < 0) {
          values.push(d.name);
        }
      }
    }
    for (const results of this.contractLimitSeries) {
      for (const d of results.series) {
        if (values.indexOf(d.name) < 0) {
          values.push(d.name);
        }
      }
    }
    this.scaleType = this.getScaleType(values);
    let domain = [];

    if (this.scaleType === 'time') {
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else if (this.scaleType === 'linear') {
      values = values.map(v => Number(v));
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else {
      domain = values;
    }

    this.xSet = values.sort((a, b) => {
      if (this.scaleType === 'time') {
        a = a.valueOf();
        b = b.valueOf();
      }
      return a - b;
    });
    return domain;
  }

  getYDomainLeft() {
    const values = [];
    if (this.results) {
      for (const val of this.xSet) {
        let d1 = 0;
        for (const group of this.results) {
          const d = this.findInSeries(group.series, val);
          if (d.d1 > d1) {
            d1 = d.d1;
          }
        }

        values.push(d1);
      }
    }
    if (this.contractLimitSeries) {
      for (const val of this.xSet) {
        let d1 = 0;
        for (const group of this.contractLimitSeries) {
          const d = this.findInSeries(group.series, val);
          if (d.d1 > d1) {
            d1 = d.d1;
          }
        }
        values.push(d1);
      }
    }
    const min = Math.min(0, ...values);
    const max = Math.max(...values);
    if (this.yAxisScaleFactor) {
      const minMax = this.yAxisScaleFactor(min, max);
      return [Math.min(0, minMax.min), minMax.max];
    } else {
      return [min, max];
    }
  }

  getXScale(domain, width): any {
    let scale;
    if (this.bandwidth === undefined) {
      this.bandwidth = this.dims.width - this.barPadding;
    }

    if (this.scaleType === 'time') {
      scale = scaleTime()
        .range([0, width])
        .domain(domain);
    } else if (this.scaleType === 'linear') {
      scale = scaleLinear()
        .range([0, width])
        .domain(domain);

      if (this.roundDomains) {
        scale = scale.nice();
      }
    } else if (this.scaleType === 'ordinal') {
      scale = scalePoint()
        .range([this.bandwidth / 2, width - this.bandwidth / 2])
        .domain(domain);
    }

    return scale;
  }

  getYScale(domain, height): any {
    const scale = scaleLinear()
      .range([height, 0])
      .domain(domain);

    return this.roundDomains ? scale.nice() : scale;
  }

  onClick(data) {
    this.select.emit(data);
  }

  setColors(): void {
    let domain;
    if (this.schemeType === 'ordinal') {
      domain = this.xDomain;
    } else {
      domain = this.yDomainLeft;
    }
    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
    this.colorsLine = new ColorHelper(
      this.colorSchemeLine,
      this.schemeType,
      domain,
      this.customColors
    );
  }

  getLegendOptions() {
    const opts = { scaleType: this.schemeType, colors: undefined, domain: [], title: undefined };
    if (opts.scaleType === 'ordinal') {
      opts.domain = this.legendDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.legendDomain;
      opts.colors = this.colors.scale;
    }
    return opts;
  }

  updateLineWidth(width): void {
    this.bandwidth = width;
  }

  updateYAxisWidthLeft({ width }): void {
    this.yAxisWidthLeft = width;
    this.update();
  }

  updateYAxisWidthRight({ width }): void {
    this.yAxisWidthRight = width;
    this.update();
  }

  updateXAxisHeight({ height }): void {
    this.xAxisHeight = height;
    this.update();
  }

  onActivate(item) {
    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value && d.series === item.series;
    });
    if (idx > -1) {
      return;
    }

    this.activeEntries = [item, ...this.activeEntries];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(item) {
    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value && d.series === item.series;
    });

    this.activeEntries.splice(idx, 1);
    this.activeEntries = [...this.activeEntries];

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }
}
