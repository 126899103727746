import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { defer, EMPTY, forkJoin, iif, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { AppNavigationService } from '@app/navigation';
import { InfrastructureService } from '@app/infrastructure/infrastructure.service';
import { fadeInOut } from '@app/shared';
import { InsensitiveSearch, Subtask, Task, TaskOperation } from '@app/core';
import { TaskService } from '@app/task';
import { params, targetTable } from './create-new-animations';
import { MatTable } from '@angular/material/table';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { chevron } from '@app/alert/base/alert-display-content/alert-display-content-animation';
import { HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  SameTask,
  SameTaskDialogComponent
} from '@app/shared/same-task-dialog/same-task-dialog.component';
import { InfraSearchResponse } from '@app/core/infrastructure/infra-search-response';
import {
  CsvTarget,
  InvalidTarget,
  OperationOption,
  TargetAutoCompleteOption,
  TargetDisplay
} from '@app/task/create-new/model/task-creation';
import { OPERATIONS } from '@app/task/create-new/operation-options';
import { FeatureCode } from '../../../environments/feature-code';
import { ConfigService } from '../../../environments/config.service';

@Component({
  selector: 'app-task-create-new-bis',
  templateUrl: './create-new.component.html',
  styleUrls: ['./create-new.component.scss'],
  animations: [fadeInOut, targetTable, params, chevron]
})
export class TaskCreateNewComponent implements AfterViewInit, OnInit, OnDestroy {
  targetControl: FormControl;
  targetHints$: Observable<TargetAutoCompleteOption[]>;
  operationControl: FormControl;
  operationOptions: OperationOption[] = OPERATIONS;
  targetDisplays: TargetDisplay[] = [];
  invalidTargetsDisplay: InvalidTarget[] = [];
  params: any = {};
  progress: boolean;
  afterInit: boolean;
  afterInitInvalid: boolean;
  showExtendedParam = false;
  sharepoint = false;
  createTaskInTheFuture: boolean;
  columnNameExpected = ['evse', 'location', 'cpo'];
  columnNameExpectedForEvcUpdate = ['IP', 'Port'];
  itemList: any[];
  enableMaintenanceTypeControl: FormControl;

  private _sameTaskDialog: MatDialogRef<SameTaskDialogComponent>;

  private _targetSearch: string;
  @ViewChild('targetTable', { static: false }) private _targetTable: MatTable<TargetDisplay>;
  private _destroyed = new Subject();
  @ViewChild('invalidTargetTable', { static: false }) private _invalidTargetTable: MatTable<
    CsvTarget
  >;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private navService: AppNavigationService,
    private infraService: InfrastructureService,
    private taskService: TaskService,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private configService: ConfigService
  ) {
    this.targetControl = fb.control('');
    this.operationControl = fb.control('');
    this._activateFeatureClearLocalBadge();
  }

  private static isBeforeNow(date: Date): boolean {
    return date && date.getTime() < Date.now();
  }

  private _activateFeatureClearLocalBadge(): OperationOption[] {
    const featureEnabled = this.configService.isFeatureEnabledNew(FeatureCode.CLEAR_LOCAL_BADGES);
    if (featureEnabled) {
      return this.operationOptions;
    }
    return (this.operationOptions = OPERATIONS.filter(opOption => {
      return opOption.operation !== 'CLEAR_LOCAL_BADGES';
    }));
  }

  ngOnInit(): void {
    this.navService.setBackUrlFallback('/task');

    this.targetHints$ = this.targetControl.valueChanges.pipe(
      takeUntil(this._destroyed),
      filter(value => typeof value === 'string'),
      debounceTime(500),
      switchMap(value => {
        this._targetSearch = value;
        return this.infraService.search({ keyword: value }).pipe(catchError(() => of({} as any)));
      }),
      map(result => this._createTargetAutoCompleteOptions(result))
    );
    this._clearAndInit();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  ngAfterViewInit(): void {
    this.afterInit = true;
  }

  private _clearAndInit(): void {
    this.operationControl.valueChanges.subscribe((option: OperationOption) => {
      this.params = {};
      if (option.operation === 'UPDATE_FIRMWARE') {
        this.params.retrieveDate = new Date();
        this.params.retries = 3;
        this.params.retryInterval = 60;
      } else if (option.operation === 'GET_DIAGNOSTICS') {
        this.params.retries = 3;
        this.params.retryInterval = 60;
      } else if (option.operation === 'EVC_UPDATE') {
        this.params.FTP_path = '/TotalEnergies/EVController';
      } else if (option.operation === 'ENABLE_MAINTENANCE') {
        this.enableMaintenanceTypeControl = this.fb.control(null, Validators.required);
        this.params['blocked'] = undefined;
      }
    });
  }

  addTarget(
    targetInput: MatInput,
    targetAutoTrigger: MatAutocompleteTrigger,
    option: TargetAutoCompleteOption
  ): void {
    setTimeout(() => targetAutoTrigger.openPanel());
    targetInput.value = this._targetSearch;
    const index = this.targetDisplays.findIndex(t => t.id.indexOf(option.value) > -1);
    if (index < 0) {
      switch (option.type) {
        case 'evse':
          this._pushEvseInTargetDisplays(
            option.value,
            option.display,
            option.record.station,
            option.record.orgName
          );
          this.afterInit = true;
          if (this._targetTable) {
            this._targetTable.renderRows();
          }
          break;
        case 'location':
          this._fetchEvseForLocation(option.value);
          break;
        case 'cpo':
          this._fetchEvseForCpo(option.value);
          break;
      }
      this.changeDetectorRef.markForCheck();
    }
  }

  private _fetchEvseForCpo(cpoId: string): void {
    this.infraService
      .getCpo(cpoId, true)
      .pipe(takeUntil(this._destroyed))
      .subscribe(cpo => {
        cpo.locations.forEach(location => {
          location.chargingStations.forEach(cs => {
            cs.evses.forEach(evse => {
              this._pushEvseInTargetDisplays(evse._key, evse.name, location.name, cpo.name);
            });
          });
        });
        this.afterInit = true;
        if (this._targetTable) {
          this._targetTable.renderRows();
        }
      });
  }

  private _fetchEvseForLocation(locationId: string): void {
    this.infraService
      .getLocation(locationId)
      .pipe(takeUntil(this._destroyed))
      .subscribe(location => {
        location.chargingStations.forEach(cs => {
          cs.evses.forEach(evse => {
            this._pushEvseInTargetDisplays(evse._key, evse.name, location.name, location.cpo.name);
          });
        });
        this.afterInit = true;
        if (this._targetTable) {
          this._targetTable.renderRows();
        }
      });
  }

  private _pushEvseInTargetDisplays(
    evseId: string,
    evseName: string,
    locationName: string,
    cpoName: string
  ): void {
    this.targetDisplays.push({
      id: evseId,
      name: evseName,
      location: locationName,
      cpo: cpoName
    });
  }

  private _checkIfEvseAlreadyExistsInTargetTable(key: string): boolean {
    return (
      this.targetDisplays.find(targetDisplaysValue => targetDisplaysValue.id === key) === undefined
    );
  }

  removeTarget(index: number): void {
    this.targetDisplays.splice(index, 1);
    this._targetTable.renderRows();
  }

  clearTargets(): void {
    this.targetDisplays = [];
    this.invalidTargetsDisplay = [];
    if (this._targetTable) {
      this._targetTable.renderRows();
    }
    if (this._invalidTargetTable) {
      this._invalidTargetTable.renderRows();
    }
  }

  private _createTargetAutoCompleteOptions(
    result: InfraSearchResponse
  ): TargetAutoCompleteOption[] {
    const hints: TargetAutoCompleteOption[] = [];
    if (result.hits) {
      result.hits.sort((a, b) => {
        return b.score - a.score;
      });

      result.hits.forEach(record => {
        if (!hints.find(h => h.display === record._id)) {
          hints.push({
            svgIcon: 'ev-station-outline',
            display: record.name,
            value: record._key,
            type: 'evse',
            record
          });
        }
        if (!hints.find(h => h.display === record.station)) {
          hints.push({
            fontIcon: 'mdi-map-marker-outline',
            display: record.station,
            value: record.stationID,
            type: 'location'
          });
        }
        if (!hints.find(h => h.display === record.orgName)) {
          hints.push({
            fontIcon: 'mdi-domain',
            display: record.orgName,
            value: record.org,
            type: 'cpo'
          });
        }
      });
    }
    return hints;
  }

  private _handleFileSearch(jsonFile: any): void {
    this.infraService.searchEvseFromJson(jsonFile).subscribe(item => {
      this._handleFileSearchErrors(item);
      this._handleFileSearchEvses(item);
      if (this.invalidTargetsDisplay.length > 0) {
        this.afterInitInvalid = true;
        if (this._invalidTargetTable) {
          this._invalidTargetTable.renderRows();
        }
      }
      if (this.targetDisplays.length > 0) {
        this.afterInit = true;
        if (this._targetTable) {
          this._targetTable.renderRows();
        }
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  private _handleFileSearchEvses(item: InsensitiveSearch): void {
    for (const valid of item.evse) {
      if (valid instanceof Array) {
        for (const res of valid) {
          if (this._checkIfEvseAlreadyExistsInTargetTable(res.id)) {
            this._pushEvseInTargetDisplays(res.id, res.name, res.location, res.cpo);
          }
        }
      } else {
        if (this._checkIfEvseAlreadyExistsInTargetTable(valid.id)) {
          this._pushEvseInTargetDisplays(valid.id, valid.name, valid.location, valid.cpo);
        }
      }
    }
  }

  private _handleFileSearchErrors(item: InsensitiveSearch): void {
    for (const invalid of item.errors) {
      if (invalid instanceof Array) {
        for (const result of invalid) {
          this.invalidTargetsDisplay.push({
            target: { targetId: result.id, targetType: result.type },
            error: 'not found'
          });
        }
      } else {
        this.invalidTargetsDisplay.push({
          target: { targetId: invalid.id, targetType: invalid.type },
          error: 'not found'
        });
      }
    }
  }

  getResult(event: any): void {
    for (const list of event) {
      for (const value of list.values) {
        const truncatedJsonFile = {
          evse: [],
          location: [],
          cpo: [],
          ipPort: [],
          evseSize: 0,
          locationSize: 0,
          cpoSize: 0
        };
        switch (list.columnName) {
          case 'evse':
            truncatedJsonFile.evse.push(value);
            truncatedJsonFile.evseSize++;
            break;
          case 'location':
            truncatedJsonFile.location.push(value);
            truncatedJsonFile.locationSize++;
            break;
          case 'cpo':
            truncatedJsonFile.cpo.push(value);
            truncatedJsonFile.cpoSize++;
            break;
        }
        this._handleFileSearch(truncatedJsonFile);
      }
    }
  }

  getResultForEvcUpdate(event: any): void {
    for (const line of event) {
      this.targetDisplays.push({
        id: line[0] + ':' + line[1],
        name: null,
        location: null,
        cpo: null
      });
    }
    this._targetTable.renderRows();
    this.changeDetectorRef.markForCheck();
  }

  toggleExecutionDateButton(checked: boolean): void {
    this.createTaskInTheFuture = checked;
    if (checked) {
      this.params.retrieveDate = new Date();
    }
  }

  isDisable(): boolean {
    return (
      this.targetDisplays.length === 0 ||
      !this.operationControl.value ||
      (this.createTaskInTheFuture &&
        TaskCreateNewComponent.isBeforeNow(this.params?.retrieveDate)) ||
      this.isEnableMaintenanceTypeInvalid()
    );
  }

  private isEnableMaintenanceTypeInvalid(): boolean {
    return (
      this.operationControl.value.operation === 'ENABLE_MAINTENANCE' &&
      this.enableMaintenanceTypeControl.invalid
    );
  }

  launch(): void {
    this.progress = true;
    iif(
      () =>
        this.operationControl.value.operation === 'UPDATE_FIRMWARE' ||
        this.operationControl.value.operation === 'GET_DIAGNOSTICS' ||
        this.operationControl.value.operation === 'ENABLE_MAINTENANCE',
      this._createTaskAndCancelExistingIfNecessary$(),
      this._createTask$()
    ).subscribe(taskId => this._navigateToSpecificTask(taskId));
  }

  isEvcUpdateOperation(): boolean {
    return this.operationControl.value.operation === 'EVC_UPDATE';
  }

  private _createTaskAndCancelExistingIfNecessary$(): Observable<string> {
    const getTasksParams = {
      targets: this.targetDisplays.map(target => target.id),
      operation: [this.operationControl.value.operation],
      status: ['EXECUTING']
    };

    return this._getSameTasks$(getTasksParams).pipe(
      switchMap((sameTasks: SameTask[]) =>
        iif(
          () => sameTasks.length > 0,
          defer(() => this._cancelExistingAndCreateTaskIfConfirmClick$(sameTasks)),
          this._createTask$()
        )
      )
    );
  }

  private _cancelExistingAndCreateTaskIfConfirmClick$(sameTasks: SameTask[]): Observable<string> {
    this._sameTaskDialog = this.matDialog.open(SameTaskDialogComponent, {
      width: '60%',
      data: { sameTasks }
    });

    return this._sameTaskDialog.afterClosed().pipe(
      switchMap((confirmClick: boolean) => {
        if (confirmClick) {
          const taskIdsToCancel: string[] = [
            ...new Set(sameTasks.map(sameTask => sameTask.task._id))
          ];

          return forkJoin(
            taskIdsToCancel.map(taskIdToCancel => this.taskService.cancelTask$(taskIdToCancel))
          ).pipe(switchMap(() => this._createTask$()));
        } else if (confirmClick === false) {
          return of(sameTasks[0].task._id);
        } else {
          return EMPTY;
        }
      })
    );
  }

  private _getSameTasks$(getTasksParams: {
    targets: string[];
    operation: string[];
    status: string[];
  }): Observable<SameTask[]> {
    return this.taskService.getTasks(new HttpParams({ fromObject: getTasksParams })).pipe(
      map((tasks: Task[]) => ({ tasks, tasksIds: [...new Set(tasks.map(task => task._id))] })),
      map((result: { tasks: Task[]; tasksIds: string[] }) =>
        result.tasksIds.map(id => result.tasks.find(task => task._id === id))
      ),
      map((tasks: Task[]) => this._findSameTasksWithoutTarget(tasks))
    );
  }

  private _findSameTasksWithoutTarget(tasks: Task[]): SameTask[] {
    const sameTasks: SameTask[] = [];
    tasks.forEach((task: Task) => {
      task.subtasks.forEach((subtask: Subtask) => {
        if (
          subtask.status !== 'DONE' &&
          subtask.status !== 'FAILED' &&
          subtask.status !== 'CANCELLED'
        ) {
          sameTasks.push({ task, subtask, target: undefined });
        }
      });
    });
    return sameTasks;
  }

  private _createTask$(): Observable<string> {
    if (this.params.retrieveDate && !this.createTaskInTheFuture) {
      this.params.retrieveDate = new Date();
    }

    const operation = this.operationControl.value.operation;
    if (operation === 'ENABLE_MAINTENANCE') {
      this.params['blocked'] = this.enableMaintenanceTypeControl.value;
    }

    const taskToCreate: Task = {
      targets: this.mapTargets(),
      operation,
      params: this.params
    };
    return this.taskService
      .createTask$(taskToCreate)
      .pipe(map(createTaskResult => createTaskResult.taskId));
  }

  private mapTargets(): string[] {
    return this.targetDisplays.map(target => {
      if (this.operationControl.value.operation === 'EVC_UPDATE') {
        return target.id;
      }
      return 'evse/' + target.id;
    });
  }

  private _navigateToSpecificTask(taskId: string): Promise<boolean> {
    return this.router.navigate(['task'], { queryParams: { id: taskId } });
  }

  onLocationParamChange() {
    if (this.sharepoint) {
      this.params.location = 'SHAREPOINT';
    } else {
      this.params.location = '';
    }
    this.changeDetectorRef.detectChanges();
  }
  getMaintenanceTypeDescriptionKey(): string {
    if (this.enableMaintenanceTypeControl.value === false) {
      return 'task.SUSPENDED_DESCRIPTION';
    } else if (!this.enableMaintenanceTypeControl.value) {
      return '';
    }
    return 'task.BLOCKED_DESCRIPTION';
  }
}
