import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs/operators';

@Injectable()
export class LocalePaginatorIntl extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();

    translate.onLangChange.pipe(startWith(translate.currentLang)).subscribe(() => this.localize());
  }

  private localize(): void {
    this.itemsPerPageLabel = this.translate.instant('paginator.Items per page:');
    this.previousPageLabel = this.translate.instant('paginator.Previous page');
    this.nextPageLabel = this.translate.instant('paginator.Next page');
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return this.translate.instant('paginator.{page} of {total}', { page: 0, total: length });
      }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;

      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

      return this.translate.instant('paginator.{start} - {end} of {total}', {
        start: startIndex + 1,
        end: endIndex,
        total: length
      });
    };

    this.changes.next();
  }
}
