import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PowerTimeLine } from '@app/core/smart-charging/chart/power-time-line';
import * as moment from 'moment';
import * as shape from 'd3-shape';
import { ChartColorScheme } from '@app/shared/interface/shared-type';

@Component({
  selector: 'app-power-combo-area-line',
  templateUrl: './power-combo-area-line.component.html'
})
export class PowerComboAreaLineComponent implements OnChanges {
  @Input() timelineValue: PowerTimeLine[] = [];
  @Input() xMin: Date;
  @Input() xMax: Date;
  @Input() colorScheme: ChartColorScheme;

  curve: any = shape.curveStepBefore;
  view: any[] = [800, 500];

  // options
  legend = true;
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = false;
  xAxisLabel = 'Date';
  yAxisLabel = 'smartCharging.POWER_LEGEND';
  legendTitle = 'smartCharging.LEGEND_TITLE';
  timeline = true;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.prop) {
      this.timelineValue = changes.prop.currentValue;
    }
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  /*onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }*/

  xTickFormat(date: Date): string {
    return moment(date).format('DD MMM - HH mm');
  }

  /*@HostListener('window:resize', ['$event'])
  onResize(uiEvent) {
    this.view = [uiEvent.target.innerWidth / 1.75, 500];
  }*/
}
