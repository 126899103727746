import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Network } from '@app/core/infrastructure/network';

export interface NetworkApi {
  items: Network[];
  totalCount: number;
}

export interface NetworkDisplayApi {
  network: Network;
  avatar: string;
  activationDate: string;
  contractStartDate: string;
  forfaitName: string;
  forfaitLimit: number;
  avatarClass: string;
}

@Injectable({ providedIn: 'root' })
export class NetworkService {
  private networkApiUrl = 'api/network';

  constructor(private http: HttpClient) {}

  getNetworks(params?: HttpParams): Observable<NetworkApi> {
    return this.http.get<NetworkApi>(this.networkApiUrl, { params });
  }

  getNetwork(params?: HttpParams): Observable<Network> {
    return this.http.get<Network>(this.networkApiUrl, { params });
  }

  getNetworkDisplay(networkObj: Network): NetworkDisplayApi {
    return {
      network: networkObj,
      avatar: this.getAvatar(networkObj),
      activationDate: networkObj.simDetails?.activationDate,
      contractStartDate: networkObj.simDetails?.contractStartDate,
      forfaitName: networkObj.simDetails?.forfaitName,
      forfaitLimit: this.getForfaitLimit(networkObj.simDetails?.forfaitName),
      avatarClass: this.getAvatarClass(networkObj)
    };
  }
  getForfaitLimit(forfaitName: string): number {
    switch (forfaitName) {
      case 'XU-503':
        return 1;
      case 'XU-504':
        return 3;
      case 'XU-505':
        return 5;
      case 'XU-506':
      case 'XI-524':
        return 10;
      case 'XU-507':
      case 'XI-525':
        return 20;
      case 'XU-508':
      case 'XI-497':
        return 30;
      case 'XU-509':
        return 60;
      case 'XU-510':
      case 'XC-931':
        return 90;
      case 'XU-511':
        return 500;
      case 'XU-512':
        return 700;
      case 'XU-513':
        return 1000;
      case 'XS-896':
        return 4000;
    }
    return null;
  }

  getAvatar(network: Network) {
    let networkType: string;
    switch (network.operator) {
      case 'WIRELESS':
      case 'UNKNOWN':
        networkType = 'mdi-sim';
        break;
      default:
        networkType = 'mdi-ethernet';
        break;
    }
    return networkType;
  }

  getAvatarClass(network: Network): string {
    if (network.simDetails) {
      if (network.simDetails?.cancellationDate) {
        return 'inactive';
      }
      return 'active';
    }
    return 'network-icon';
  }
}
