import { animate, style, transition, trigger } from '@angular/animations';

export const slideInOutFromTop = trigger('slideInOutFromTop', [
  transition(':enter', [
    style({ transform: 'translateY(-56px)', opacity: 0 }),
    animate('300ms cubic-bezier(.4, 0, 1, 1)')
    // query('@*', animateChild(), { optional: true })
  ]),

  transition(':leave', [
    animate(
      '300ms cubic-bezier(0, 0, .2, 1)',
      style({ transform: 'translateY(-56px)', opacity: 0 })
    )
  ])
]);
