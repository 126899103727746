import { animate, style, transition, trigger } from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

export const fabShowHide = trigger('fabShowHide', [
  transition(':enter', [
    style({ transform: 'translateY(56px)' }),
    animate(
      `${AnimationDurations.ENTERING} ${AnimationCurves.DECELERATION_CURVE}`,
      style({ transform: 'translateY(0)' })
    )
  ]),

  transition(':leave', [
    style({ transform: 'translateY(0)' }),
    animate(
      `${AnimationDurations.EXITING} ${AnimationCurves.ACCELERATION_CURVE}`,
      style({ transform: 'translateY(56px)' })
    )
  ])
]);
