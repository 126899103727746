import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-stepper-buttons',
  templateUrl: './stepper-buttons.component.html',
  styleUrls: ['./stepper-buttons.component.scss']
})
export class StepperButtonsComponent {
  @Input() loading = false;
  @Input() showNext = true;
  @Input() nextDisabled = false;
  @Input() nextTranslateKey = 'common.NEXT';
  @Input() previousLogo: string;
  @Input() previousTranslateKey = 'common.BACK';

  @Output() next = new EventEmitter<void>();
  @Output() previous = new EventEmitter<void>();
}
