import {
  animate,
  animateChild,
  group,
  query,
  stagger,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

export const toolbarFadeIn = trigger('toolbarFadeIn', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(
      `500ms ${AnimationDurations.ENTERING} ${AnimationCurves.STANDARD_CURVE}`,
      style({ opacity: 1 })
    )
  ])
]);

export const menuButtonSlide = trigger('menuButtonSlide', [
  state('show', style({ opacity: 1, transform: 'none' })),
  state('hide', style({ opacity: 0, transform: 'translateX(-32px)' })),

  transition(
    'show <=> hide',
    group([
      query('@*', animateChild(), { optional: true }),
      animate('400ms cubic-bezier(.25, .8, .25, 1)')
    ])
  )
]);

export const searchBarAnim = trigger('searchBarAnim', [
  transition(':enter', [query('@searchInOut', animateChild(), { delay: 500, optional: true })]),
  transition(':leave', [query('@searchInOut', animateChild(), { delay: 500, optional: true })])
]);

export const actionInOut = trigger('actionInOut', [
  transition(
    ':increment',
    query(
      ':enter',
      [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        stagger(-50, [
          animate(
            `${AnimationDurations.ENTERING} ${AnimationCurves.DECELERATION_CURVE}`,
            style({ transform: 'translateX(0)', opacity: 1 })
          )
        ])
      ],
      { optional: true }
    )
  ),

  transition(
    ':decrement',
    query(
      ':leave',
      [
        style({ transform: 'translateX(0)', opacity: 1 }),
        stagger(50, [
          animate(
            `${AnimationDurations.EXITING} ${AnimationCurves.DECELERATION_CURVE}`,
            style({ transform: 'translateX(100%)', opacity: 0 })
          )
        ])
      ],
      { optional: true }
    )
  )
]);
