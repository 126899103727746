import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, shareReplay } from 'rxjs/operators';
import { AuthenticationService } from '../auth.service';
import { SKIP_AUTH_HEADER } from '../interceptor/skip-auth-header';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  /**
   * Ensures refresh endpoint isn't called multiple times
   * when HTTP requests are made in parallel.
   */
  private refreshToken = this.authService
    .refreshTokenObservable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true, windowTime: 2_000 }));

  constructor(private authService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (req.headers.has(SKIP_AUTH_HEADER) || error.status !== 401) {
          return throwError(error);
        }
        return this.refreshToken.pipe(
          concatMap(() => {
            const requestWithNewToken = req.clone({
              headers: new HttpHeaders({
                Authorization: `Bearer ${this.authService.getAccessToken()}`
              })
            });
            return next.handle(requestWithNewToken);
          }),
          catchError(() => {
            return this.authService.logOutObservable();
          }),
          catchError(() => of(undefined))
        );
      })
    );
  }
}
