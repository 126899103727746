import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChargingCpo, ChargingLocation, Evse, InsensitiveSearch } from '@app/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location } from '@app/core/infrastructure/location';
import { InfraAutoCompleteResponse } from '@app/core/infrastructure/infra-auto-complete-response';
import { InfraSearchResponse } from '@app/core/infrastructure/infra-search-response';
import { ServiceStatus } from '@app/core/infrastructure/evse-service-status';
import { map } from 'rxjs/operators';

export interface LocationApi {
  items: ChargingLocation[];
  totalCount: number;
}

export interface LocalToken {
  _id: string;
  _key: string;
  internalId: string;
  cpoId: string;
  label: string;
  tokenId: string;
  status: string;
  surname: string;
  name: string;
  tokenBcId: string;
}

export interface NewAndOldLocalToken {
  new: LocalToken;
  oldLocationIds: string[];
}

@Injectable({ providedIn: 'root' })
export class InfrastructureService {
  private infraApiUrl = 'api/infrastructure';
  private localTokenUrl = `${this.infraApiUrl}/localToken`;

  private _isNewSearchEngine = new BehaviorSubject<boolean>(true);
  readonly isNewSearchEngine = this._isNewSearchEngine.asObservable();

  constructor(private http: HttpClient) {}

  setIsNewSearchEngine(newSearchEngine: boolean): void {
    this._isNewSearchEngine.next(newSearchEngine);
  }

  search(params: any): Observable<InfraSearchResponse> {
    const url = this._isNewSearchEngine.getValue()
      ? `${this.infraApiUrl}/v2/search`
      : `${this.infraApiUrl}/v1/search`;
    return this.http
      .get<InfraSearchResponse>(url, { params })
      .pipe(map(response => this.toDisplayedInfraSearchResponse(response)));
  }

  searchEvseFromJson(params: any): Observable<InsensitiveSearch> {
    const url = `${this.infraApiUrl}/evse/search`;
    return this.http.get<any>(url, { params });
  }

  getHints(params: any): Observable<InfraAutoCompleteResponse> {
    const url = `${this.infraApiUrl}/hint`;
    return this.http.get<InfraAutoCompleteResponse>(url, { params });
  }

  getLocation(id: string): Observable<ChargingLocation> {
    const url = `${this.infraApiUrl}/location/${id}`;
    return this.http.get<ChargingLocation>(url, { params: { fetch: 'true' } });
  }

  /**
   * Method to get evses of a station
   *
   * @param  params Params
   * @return           API's response
   */
  getLocations(params?: HttpParams): Observable<LocationApi> {
    const url = `${this.infraApiUrl}/location`;
    return this.http.get<LocationApi>(url, { params });
  }

  getLocationCpo(_key: string): Observable<Location[]> {
    const url = `${this.infraApiUrl}/cpo/${_key}/locations`;
    return this.http.get<Location[]>(url);
  }

  getLocationCpoGroup(_key: string): Observable<Location[]> {
    const url = `${this.infraApiUrl}/cpoGroup/${_key}/locations`;
    return this.http.get<Location[]>(url);
  }

  getLocationToEvse(id: string): Observable<any[]> {
    const url = `${this.infraApiUrl}/evse/${id}`;
    return this.http.get<any[]>(url, { params: { location: 'true' } });
  }

  getEvseLiveStatus(id: string): Observable<any> {
    const url = `${this.infraApiUrl}/evse/${id}/status`;
    return this.http.get<any>(url);
  }

  getEvseCurrentConfig(id: string): Observable<any> {
    const url = `${this.infraApiUrl}/evse/${id}/config`;
    return this.http.get<any>(url);
  }

  getEvseRefConfig(id: string): Observable<any> {
    const url = `${this.infraApiUrl}/evse/${id}/reference`;
    return this.http.get<any>(url);
  }

  saveCurrentConfigAsReferenceConfig(id: string): Observable<any> {
    const url = `${this.infraApiUrl}/evse/${id}/updateRefConf`;
    return this.http.post(url, null);
  }

  /**
   * Method to start session
   *
   * @param  profileId  Profile's id
   * @param  evse       Evse
   * @param  comment    Comment
   * @return            API's response
   */
  startSession(evse: Evse, profileId: string, comment: string): Observable<string> {
    const url = `${this.infraApiUrl}/evse/${evse._key}/requestStart`;
    return this.http.post(url, { profile: profileId, comment }, { responseType: 'text' });
  }

  /**
   * Method to stop session
   *
   * @param  evse     Evse
   * @return          API's response
   */
  stopSession(evse: Evse): Observable<string> {
    const url = `${this.infraApiUrl}/evse/${evse._key}/requestStop`;
    return this.http.post(url, null, { responseType: 'text' });
  }

  getCpo(id: string, fetch: any): Observable<ChargingCpo> {
    const url = `${this.infraApiUrl}/cpo/${id}`;
    return this.http.get<ChargingCpo>(url, { params: { fetch } });
  }

  getLocalTokensByCpoId(cpoId: string): Observable<LocalToken[]> {
    return this.http.get<LocalToken[]>(`${this.localTokenUrl}/cpo`, { params: { cpoId } });
  }

  getLocalTokensByLocationId(locationId: string): Observable<LocalToken[]> {
    return this.http.get<LocalToken[]>(`${this.localTokenUrl}/location`, {
      params: { locationId }
    });
  }

  saveLocalToken(
    cpoId: string,
    locationId: string,
    label: string,
    tokenId: string,
    active: boolean,
    internalId: string
  ): Observable<NewAndOldLocalToken> {
    return this.http.post<NewAndOldLocalToken>(this.localTokenUrl, {
      cpoId,
      locationId,
      label,
      tokenId,
      active,
      internalId
    });
  }

  updateLocalToken(
    internalId: string,
    label: string,
    tokenId: string,
    active: boolean,
    cpoId: string,
    locationId: string
  ): Observable<NewAndOldLocalToken> {
    const url = `${this.localTokenUrl}/${internalId}`;
    return this.http.put<NewAndOldLocalToken>(url, { label, tokenId, active, cpoId, locationId });
  }

  private toDisplayedInfraSearchResponse(response: InfraSearchResponse): InfraSearchResponse {
    response.hits = response.hits.map(hit => this.toDisplayedHit(hit));
    return response;
  }

  private toDisplayedHit(hit?: any) {
    const displayedStatus =
      hit?.serviceStatus === ServiceStatus.SUSPENDED && hit?.blocked === true
        ? ServiceStatus.BLOCKED
        : hit?.serviceStatus;
    return { ...hit, serviceStatus: displayedStatus };
  }
}
