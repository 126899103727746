import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChip, MatChipList } from '@angular/material/chips';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { isObservable, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { filter, map, startWith, take, takeUntil } from 'rxjs/operators';
import { chipInOut, chipListInOut, fadeInOut } from '../animation';
import { FilterAutocompleteOption } from './filter-autocomplete-option';
import { FilterOption } from './filter-option';
import { filterTips } from './filter-tips-animations';
import { SearchFilter } from './search-filter';
import { AppNavigationService } from '@app/navigation';
import { DateRange, MatDateRangeInput } from '@angular/material/datepicker';

@Component({
  selector: 'app-base-filter',
  templateUrl: 'base-filter.component.html',
  styleUrls: ['base-filter.component.scss'],
  animations: [chipInOut, chipListInOut, fadeInOut, filterTips],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() searchFilter: SearchFilter;
  @Input() allowDuplicate: boolean;
  @Input() onlyTips: boolean;

  @ViewChild('stringInput', { static: false }) stringInput: MatInput;
  @ViewChild('dateInput', { static: false }) dateInput: MatInput;
  @ViewChild('rangeInput', { static: false }) rangeInput: MatInput;
  @ViewChild('rangeList', { static: false }) protected rangeList: MatChipList;

  selectedInputFilter: FilterOption;
  filteredInputFilters: FilterOption[];
  stringInputControl = new FormControl();
  dateInputControl = new FormControl();
  startRangeInputControl = new FormControl();
  endRangeInputControl = new FormControl();
  autoCompleteOptions$: Observable<FilterAutocompleteOption[]>;
  _autoCompleteOptions: FilterAutocompleteOption[];
  customRange = this.getBlankCustomRange();
  _tips: FilterOption[] = [];

  protected _destroyed = new Subject();
  private clearSearchFilterSubscription: Subscription;

  constructor(
    public translate: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _router: Router,
    private _appNavigationService: AppNavigationService
  ) {}

  ngOnInit(): void {
    this.filteredInputFilters = this.searchFilter?.inputFilters || [];

    this._appNavigationService.getCpoHints().subscribe(cpoHints => {
      if (cpoHints.length <= 1) {
        this.filteredInputFilters = this.filteredInputFilters.filter(
          (value: FilterOption) => value.param !== 'cpo'
        );
      }
    });

    this._route.queryParams
      .pipe(takeUntil(this._destroyed), take(1))
      .subscribe(queryParams => this.parseQueryParams(queryParams));

    this.autoCompleteOptions$ = this.stringInputControl.valueChanges.pipe(
      takeUntil(this._destroyed),
      startWith(''),
      map(value => this._filterAutocompleteOptions(value))
    );

    if (this.filteredInputFilters.length > 0) {
      this.selectedInputFilter = this.filteredInputFilters[0];
      this.onSelectedInputChange();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.searchFilter?.currentValue) {
      const currentValue = changes.searchFilter.currentValue;
      currentValue.appliedInputFilters = [];
      this.clearSearchFilterSubscription?.unsubscribe();
      this.clearSearchFilterSubscription = this.searchFilter.clearFilter
        .pipe(filter(clear => clear))
        .subscribe(() => {
          this.clearInputFilters();
          this.applyFilter();
        });
    }
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  onSelectedInputChange(): void {
    if (this.searchFilter) {
      const $options =
        this.searchFilter.getInputAutoCompleteOptions(this.selectedInputFilter) || of([]);
      $options.pipe(takeUntil(this._destroyed)).subscribe(options => {
        this._autoCompleteOptions = options;
        this.stringInputControl.patchValue('');
        this._changeDetectorRef.markForCheck();
      });
    }
  }

  onFeatureChipClick(feature: FilterOption): void {
    if (this.searchFilter) {
      this.searchFilter.onFeatureChipClick(feature);
    }
  }

  displayOptionFn(option: FilterAutocompleteOption): string {
    if (!option) {
      return '';
    }
    if (this.selectedInputFilter.type !== 'option') {
      return option.value;
    }
    if (option.translate) {
      return this.translate.instant(option.display);
    }
    return option.display;
  }

  addInputFilter(...inputs: (MatInput | MatDateRangeInput<any>)[]): void {
    if (!this.searchFilter || !this.selectedInputFilter) {
      return;
    }
    const filterValue = this.formatFilterValueFromInputs(inputs);
    const inputFilter = this.setFilterValue(filterValue);
    this.stringInputControl.setValue('');
    this.dateInputControl.setValue('');
    this.startRangeInputControl.setValue('');
    this.endRangeInputControl.setValue('');
    this.searchFilter?.onInputFilterAdded(inputFilter);
    this._changeDetectorRef.markForCheck();
  }

  onSelectFilterOption(option: FilterAutocompleteOption): void {
    if (!this.searchFilter || !this.selectedInputFilter) {
      return;
    }
    const inputFilter = this.setFilterValue(option);
    this.stringInputControl.patchValue('');
    this.dateInputControl.patchValue('');
    this.startRangeInputControl.patchValue('');
    this.endRangeInputControl.patchValue('');
    this.searchFilter?.onInputFilterAdded(inputFilter);
    this._changeDetectorRef.markForCheck();
  }

  removeInputFilter(index: number): void {
    if (this.searchFilter) {
      this.searchFilter.removeInputFilter(index);
      this._changeDetectorRef.markForCheck();
    }
  }

  clearInputFilters(): void {
    if (this.searchFilter) {
      this.searchFilter.appliedInputFilters = [];
      this._changeDetectorRef.markForCheck();
    }
  }

  onRangeChipClick(chip: MatChip): void {
    if (chip.toggleSelected()) {
      this.customRange = this.getBlankCustomRange();
    }
  }

  onCustomRangeChange(): void {
    this.rangeList?._setSelectionByValue(undefined);
  }

  applyFilter(): void {
    if (!this.searchFilter) {
      return;
    }
    const queryParams: Record<string, string[] | string> = {};
    if (this.rangeList && this.rangeList.selected) {
      const selectedChip = this.rangeList.selected as MatChip;
      queryParams.range = selectedChip.value.param;
    }
    if (this.customRange.start) {
      queryParams.range =
        this.customRange.start.toISOString() + ',' + this.customRange.end.toISOString();
    }
    if (this.searchFilter.features) {
      this._applyFeatureFilters(queryParams);
    }
    if (this.searchFilter.appliedInputFilters) {
      for (const inputFilter of this.searchFilter.appliedInputFilters) {
        if (inputFilter.value) {
          const type = inputFilter.type || 'string';
          switch (type) {
            case 'string':
              queryParams[inputFilter.param] = inputFilter.value;
              break;
            case 'date':
              queryParams[inputFilter.param] = (inputFilter.value as Moment).toISOString();
              break;
            case 'dateRange':
              const range = inputFilter.value as { begin: Moment; end: Moment };
              queryParams[inputFilter.param] =
                range.begin.startOf('day').toISOString() +
                ',' +
                range.end.endOf('day').toISOString();
              break;
            case 'option':
            default:
              queryParams[inputFilter.param] = (queryParams[inputFilter.param] ?? []).concat(
                (inputFilter.value as FilterAutocompleteOption).value
              );
              break;
          }
        }
      }
    }

    this._router.navigate([], {
      replaceUrl: true,
      queryParams
    });
  }

  private _applyFeatureFilters(params: any): void {
    if (this.searchFilter && this.searchFilter.features) {
      this.searchFilter.features
        .filter(f => f.value)
        .forEach(f => this.searchFilter.applyFeatureFilter(f, params));
    }
  }

  private parseQueryParams(queryParams): void {
    if (this.searchFilter) {
      this.searchFilter.appliedInputFilters = [];
    }

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      if (value instanceof Array) {
        value.forEach(v => this.parseQueryKeyValue(key, v));
      } else {
        this.parseQueryKeyValue(key, value);
      }
    }
  }

  private parseQueryKeyValue(key: string, value: any): void {
    if (key === 'range') {
      this._parseTimeRangeFilter(value);
      return;
    }
    const featParam = this._parseFeatureParam(key, value);
    if (featParam) {
      if (this.searchFilter && this.searchFilter.features) {
        timer(0)
          .pipe(map(() => this.searchFilter.features.find(f => f.param === featParam)))
          .subscribe(feat => {
            if (feat) {
              feat.value = true;
              this._tips.push(feat);
              this._changeDetectorRef.markForCheck();
            }
          });
      }
    } else {
      this._parseInputFilter(key, value);
    }
  }

  private _parseTimeRangeFilter(value: string): void {
    if (!this.searchFilter || !this.searchFilter.ranges) {
      return;
    }
    const range = this.searchFilter.ranges.find(f => f.param === value);
    if (range) {
      this.rangeList?._setSelectionByValue(range);
      this._tips.push({ ...range, value: true });
      return;
    }
    if (!value.includes(',')) {
      return;
    }
    const dateRange = value.split(',');
    this.customRange = new DateRange(moment(dateRange[0]), moment(dateRange[1]));
    this._tips.push({
      name: 'searchFilter.Time range',
      param: 'range',
      type: 'dateRange',
      value: this.customRange
    });
  }

  private _parseInputFilter(key: string, value: any): void {
    if (!this.searchFilter || !this.filteredInputFilters) {
      return;
    }
    const inputFilter = this.filteredInputFilters.find(f => f.param === key);
    if (inputFilter) {
      const type = inputFilter.type || 'string';
      let filterValue;
      switch (type) {
        case 'option':
          filterValue = this._parseOptionFilterParam(inputFilter, value);
          if (!filterValue) {
            filterValue = value;
          }
          break;
        case 'date':
          filterValue = moment(value);
          break;
        case 'dateRange':
          const range = value.split(',');
          if (range.length === 2) {
            filterValue = { begin: moment(range[0]), end: moment(range[1]) };
          }
          break;
        default:
          filterValue = value;
      }
      const $filterValue: Observable<any> = isObservable(filterValue)
        ? filterValue
        : of(filterValue);
      $filterValue.subscribe(v => {
        if (!v && type === 'option') {
          v = { display: value, value };
        }
        const applied = {
          name: inputFilter.name,
          param: inputFilter.param,
          type: inputFilter.type,
          value: v
        };

        const existFilter = this.searchFilter.appliedInputFilters.findIndex(val => {
          if (type === 'option') {
            return val.param === applied.param && val.value.value === applied.value.value;
          } else {
            return val.param === applied.param && val.value === applied.value;
          }
        });
        if (existFilter <= -1) {
          this.searchFilter.appliedInputFilters.push(applied);
        }

        this.searchFilter.appliedInputFilters.sort((a, b) => {
          if (a.param > b.param) {
            return 1;
          }
          if (a.param < b.param) {
            return -1;
          }
          return 0;
        });
        this._tips.push(applied);
        this._changeDetectorRef.markForCheck();
      });
    }
  }

  private _parseFeatureParam(key: string, value: any): string {
    if (this.searchFilter) {
      return this.searchFilter.parseFeatureParam(key, value);
    }
    return undefined;
  }

  private _filterAutocompleteOptions(value: string): FilterAutocompleteOption[] {
    if (!this._autoCompleteOptions) {
      return [];
    }
    this._autoCompleteOptions.forEach(o => {
      if (o.translate) {
        o.translateValue = this.translate.instant(o.display);
      }
    });
    let options = [];
    if (!value) {
      options = [].concat(this._autoCompleteOptions);
    } else if (typeof value === 'string') {
      value = value.toLowerCase();
      options = this._autoCompleteOptions.filter(
        o => (o.translateValue || o.display || o.value).toLowerCase().indexOf(value) > -1
      );
    }
    const sort = this.selectedInputFilter ? this.selectedInputFilter.sort : false;
    if (sort) {
      options = options.sort((a, b) => {
        const first = (a.translateValue || a.display || a.value).toLowerCase();
        const second = (b.translateValue || b.display || b.value).toLowerCase();
        return first.localeCompare(second);
      });
    }
    return options;
  }

  private _parseOptionFilterParam(
    inputFilter: FilterOption,
    value: any
  ): FilterAutocompleteOption | Observable<FilterAutocompleteOption> {
    if (this.searchFilter) {
      return this.searchFilter.parseOptionFilterParam(inputFilter, value);
    }
  }

  private formatFilterValueFromInputs(inputs: (MatInput | MatDateRangeInput<any>)[]): unknown {
    if (this.selectedInputFilter.type === 'option') {
      const inputValue = this.stringInputControl.value;
      return typeof inputValue === 'string'
        ? { display: inputValue, value: inputValue }
        : inputValue;
    }

    for (const input of inputs) {
      if (input.value) {
        return input.value;
      }
    }

    return undefined;
  }

  private setFilterValue(option: FilterAutocompleteOption | unknown): FilterOption {
    const appliedFilterParam = this.searchFilter.appliedInputFilters.find(
      appliedFilter => appliedFilter.param === this.selectedInputFilter.param
    );
    if (
      appliedFilterParam &&
      !this.allowDuplicate &&
      !this.paramAcceptsValueList(this.selectedInputFilter.param)
    ) {
      appliedFilterParam.value = option;
      return appliedFilterParam;
    }
    const newFilter = {
      name: this.selectedInputFilter.name,
      param: this.selectedInputFilter.param,
      type: this.selectedInputFilter.type,
      value: option
    };
    this.addFilterIfNotAppliedYet(newFilter);
    return newFilter;
  }

  private addFilterIfNotAppliedYet(inputFilter: FilterOption) {
    const valueAlreadyExist = this.searchFilter.appliedInputFilters.some(appliedFilter => {
      if (inputFilter.type === 'option') {
        return appliedFilter.value.value === inputFilter.value.value;
      }
      return appliedFilter.value === inputFilter.value;
    });
    if (!valueAlreadyExist) {
      this.searchFilter.appliedInputFilters.push(inputFilter);
    }
  }

  private paramAcceptsValueList(param: string): boolean {
    return ['cpoGroup', 'cpo', 'equipmentId', 'evseId', 'emsp', 'location'].includes(param);
  }

  stringInputShouldBeHidden(): boolean {
    return (
      this.selectedInputFilter &&
      this.selectedInputFilter.type &&
      this.selectedInputFilter.type !== 'string' &&
      this.selectedInputFilter.type !== 'option'
    );
  }

  getBlankCustomRange(): DateRange<Moment> {
    return new DateRange<Moment>(undefined, undefined);
  }
}
