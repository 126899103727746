import { NgZone } from '@angular/core';

import { FilterOption, SearchFilter } from '@app/shared';
import { AppNavigationService } from '@app/navigation';

export const LOCATION_INPUT_FILTER_OPTIONS: FilterOption[] = [
  { name: 'infra.eventRecord.Transaction ID', param: 'transactionLink' },
  { name: 'infra.eventRecord.Alert ID', param: 'alertLink' },
  { name: 'infra.eventRecord.Equipment ID', param: 'evseId' }
];

export const EVSE_INPUT_FILTER_OPTIONS: FilterOption[] = [
  { name: 'infra.eventRecord.Transaction ID', param: 'transactionLink' },
  { name: 'infra.eventRecord.Alert ID', param: 'alertLink' }
];

export class EventRecordHistoryFilter extends SearchFilter {
  inputFilters: FilterOption[];
  features: FilterOption[];

  constructor(
    private ngZone: NgZone,
    private navService: AppNavigationService,
    private type: string
  ) {
    super();
    this.type === 'location'
      ? (this.inputFilters = LOCATION_INPUT_FILTER_OPTIONS.map(f => Object.assign({}, f)))
      : (this.inputFilters = EVSE_INPUT_FILTER_OPTIONS.map(f => Object.assign({}, f)));
  }
}
