import { Component, Input, OnChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { Transaction } from '@app/core';
import { CreditCardReceiptDialogComponent } from '../credit-card-receipt/credit-card-receipt-dialog.component';
import { TxDisplay } from '../tx.service';
import { showChart } from './tx-chart-animations';
import { ChartColorScheme } from '@app/shared/interface/shared-type';

@Component({
  selector: 'app-tx-display-content',
  templateUrl: 'tx-display-content.component.html',
  styleUrls: ['tx-display-content.component.scss'],
  animations: [showChart]
})
export class TxDisplayContentComponent implements OnChanges {
  @Input() txDisplay: TxDisplay;
  @Input() chartWidth: number;
  @Input() colorScheme: ChartColorScheme;
  @ViewChild('successfulCopy', { static: true }) successfulCopy: TemplateRef<any>;

  averagePower: number;

  private showEichrechtCopyButtons = false;

  private publicKey = '';
  private dataSet = '';

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar
  ) {}

  ngOnChanges() {
    let chargingDuration = 0;
    if (this.txDisplay.chargeDuration) {
      const stop = this.txDisplay.chargeDuration.stop || moment();
      chargingDuration = stop.diff(moment(this.txDisplay.chargeDuration.start));
    }
    if (chargingDuration > 0) {
      this.averagePower = (this.txDisplay.consumption * 3600 * 1000) / chargingDuration;
    } else {
      this.averagePower = 0;
    }
  }

  openEmvcoReceiptDialog(tx: Transaction): void {
    if (tx.emvcoReceipt) {
      this.dialog.open(CreditCardReceiptDialogComponent, {
        minWidth: '50%',
        data: {
          txId: tx.uuid,
          receipt: tx.emvcoReceipt
        }
      });
    }
  }

  private copyPublicKey(): void {
    this.publicKey = this.txDisplay.tx.lawCompliance[0].publicKey;
    this.clipboard.copy(this.publicKey);
  }

  private copyDataSet(): void {
    this.dataSet = this.txDisplay.tx.lawCompliance[0].encodedData;
    this.clipboard.copy(this.dataSet);
  }

  private openSnackBar(message: string) {
    this.snackBar.openFromTemplate(this.successfulCopy, {
      duration: 2000
    });
  }
}
