import {
  AfterContentInit,
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { MatFormField } from '@angular/material/form-field';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fadeInOut } from '../animation';

@Component({
  selector: 'app-editable-form-field',
  templateUrl: 'editable-form-field.component.html',
  styleUrls: ['editable-form-field.component.scss'],
  animations: [fadeInOut]
})
export class EditableFormFieldComponent implements AfterContentInit, OnChanges, OnDestroy {
  private _destroyed = new Subject<void>();

  @Input() color = 'accent';
  @Input() icon?: string;
  @Input() iconColor?: string;

  @Input() placeholder: string;
  @Input() editableOnInit: boolean;

  @ContentChild(MatFormField, { static: false }) formField: MatFormField;

  focused = false;
  errorState = false;
  private _editable = false;

  ngAfterContentInit() {
    this.setDisabledState(!this.editable && !this.editableOnInit);
    if (this.formField) {
      this.formField._control.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => {
        this.focused = this.formField._control.focused;
        this.errorState = this.formField._control.errorState;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.editable) {
      this.setDisabledState(!this.editable);
    }
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  @Input()
  get editable() {
    return this._editable;
  }

  set editable(editable: boolean) {
    this._editable = editable;
    this.setDisabledState(!editable);
  }

  private setDisabledState(isDisabled: boolean) {
    if (this.formField) {
      const control = this.formField._control;
      if (control.ngControl && control.ngControl.control) {
        if (isDisabled) {
          control.ngControl.control.disable();
        } else {
          control.ngControl.control.enable();
        }
      }
      (control as any).disabled = isDisabled;
    }
  }
}
