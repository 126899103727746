import { NgZone } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { FilterAutocompleteOption, FilterOption, SearchFilter } from '@app/shared';
import { AppNavigationService } from '@app/navigation';

export const INPUT_FILTER_OPTIONS: FilterOption[] = [
  { name: 'alert.filter.Alert type', param: 'alertType', type: 'option' },
  { name: 'alert.Severity', param: 'severity', type: 'option' },
  { name: 'alert.filter.Severity greater than...', param: 'severityGte', type: 'option' },
  { name: 'alert.filter.Severity less than...', param: 'severityLte', type: 'option' },
  { name: 'alert.filter.CPO', param: 'cpo', type: 'option', sort: true },
  { name: 'alert.filter.CPO group', param: 'cpoGroup', type: 'option', sort: true },
  { name: 'alert.Alert ID', param: 'id', type: 'string' },
  { name: 'alert.Equipment ID', param: 'equipmentId', type: 'option', sort: true },
  { name: 'alert.Location', param: 'location', type: 'option', sort: true },
  { name: 'alert.Error / Vendor error code', param: 'errorCode', type: 'option', sort: true }
];

export const FEATURE_OPTIONS: FilterOption[] = [
  { name: 'alert.alertStatus.Opened', param: 'opened' },
  { name: 'alert.alertStatus.Closed', param: 'closed' },
  { name: 'alert.Acknowledged', param: 'acked' },
  { name: 'alert.filter.Not acknowledged', param: 'nack' }
];

const ALERT_TYPE_OPTIONS: FilterAutocompleteOption[] = [
  { display: 'alert.type.COMM_ALERT', value: 'COMM_ALERT', translate: true },
  { display: 'alert.type.STATUS_ALERT', value: 'STATUS_ALERT', translate: true },
  {
    display: 'alert.type.ILLEGAL_OCCUPANCY_ALERT',
    value: 'ILLEGAL_OCCUPANCY_ALERT',
    translate: true
  },
  { display: 'alert.type.IT_SERVICE_STATUS', value: 'IT_SERVICE_STATUS', translate: true },
  { display: 'alert.type.UNDEF', value: 'UNDEF', translate: true },
  { display: 'alert.type.EMVCO_PROCESS', value: 'EMVCO_PROCESS', translate: true },
  {
    display: 'alert.type.INCONSISTENT_REFERENTIAL',
    value: 'INCONSISTENT_REFERENTIAL',
    translate: true
  }
];

const ERROR_CODE_OPTIONS: FilterAutocompleteOption[] = [
  {
    display: 'alert.errorCode.ChargeStopped:VehicleProtocolError',
    value: 'vendor.ChargeStopped:VehicleProtocolError',
    translate: true
  },
  {
    display: 'alert.errorCode.CommunicationError',
    value: 'vendor.CommunicationError',
    translate: true
  },
  {
    display: 'alert.errorCode.ConnectorLockFailure',
    value: 'ConnectorLockFailure',
    translate: true
  },
  {
    display: 'alert.errorCode.DoorNotClosed',
    value: 'vendor.DoorNotClosed',
    translate: true
  },
  {
    display: 'alert.errorCode.EmergencyStop',
    value: 'vendor.EmergencyStop',
    translate: true
  },
  {
    display: 'alert.errorCode.EVCommunicationError',
    value: 'EVCommunicationError',
    translate: true
  },
  { display: 'alert.errorCode.GroundFailure', value: 'GroundFailure', translate: true },
  { display: 'alert.errorCode.IllegalParking', value: 'vendor.IllegalParking', translate: true },
  { display: 'alert.errorCode.SensorParking', value: 'vendor.SensorParking', translate: true },
  {
    display: 'alert.errorCode.InternalError',
    value: 'vendor.InternalError',
    translate: true
  },
  { display: 'alert.errorCode.Mode3Error', value: 'Mode3Error', translate: true },
  {
    display: 'alert.errorCode.OverCurrentFailure',
    value: 'OverCurrentFailure',
    translate: true
  },
  { display: 'alert.errorCode.OverVoltage', value: 'OverVoltage', translate: true },
  { display: 'alert.errorCode.PowerOutage', value: 'vendor.PowerOutage', translate: true },
  {
    display: 'alert.errorCode.PowerSwitchFailure',
    value: 'PowerSwitchFailure',
    translate: true
  },
  { display: 'alert.errorCode.ReaderFailure', value: 'ReaderFailure', translate: true },
  { display: 'alert.errorCode.ResetFailure', value: 'ResetFailure', translate: true },
  { display: 'alert.errorCode.UnderVoltage', value: 'UnderVoltage', translate: true }
];

const SEVERITY_LEVEL_OPTIONS: FilterAutocompleteOption[] = [
  { display: 'alert.severity.Signal', value: 'Signal', translate: true },
  { display: 'alert.severity.Info', value: 'Info', translate: true },
  { display: 'alert.severity.Warning', value: 'Warning', translate: true },
  { display: 'alert.severity.Critical', value: 'Critical', translate: true }
];

export class AlertFilter extends SearchFilter {
  inputFilters: FilterOption[] = INPUT_FILTER_OPTIONS.map(f => Object.assign({}, f));
  features: FilterOption[] = FEATURE_OPTIONS.map(f => Object.assign({}, f));

  private cpoOptions: FilterAutocompleteOption[];
  private cpoGroupOptions: FilterAutocompleteOption[];
  private locationOptions: FilterAutocompleteOption[];
  private evseOptions: FilterAutocompleteOption[];

  constructor(private ngZone: NgZone, private navService: AppNavigationService) {
    super();
  }

  getInputAutoCompleteOptions(selected: FilterOption) {
    if (selected) {
      switch (selected.param) {
        case 'cpo':
          if (!this.cpoOptions) {
            return this.navService.loadCpoAutoCompleteOptions();
          } else {
            return of(this.cpoOptions);
          }
        case 'cpoGroup':
          if (!this.cpoGroupOptions) {
            return this.navService.loadCpoGroupAutoCompleteOptions();
          } else {
            return of(this.cpoGroupOptions);
          }
        case 'location':
          if (!this.locationOptions) {
            return this.navService.loadLocationAutoCompleteOptions();
          } else {
            return of(this.locationOptions);
          }
        case 'equipmentId':
          if (!this.evseOptions) {
            return this.navService.loadEvseAutoCompleteOptions();
          } else {
            return of(this.evseOptions);
          }
        case 'alertType':
          return of(ALERT_TYPE_OPTIONS);
        case 'severity':
        case 'severityGte':
        case 'severityLte':
          return of(SEVERITY_LEVEL_OPTIONS);
        case 'errorCode':
          return of(ERROR_CODE_OPTIONS);
        default:
          return undefined;
      }
    }
    return undefined;
  }

  onFeatureChipClick(filter: FilterOption) {
    filter.value = filter.value ? undefined : true;
    switch (true) {
      case filter.param.indexOf('opened') > -1 && filter.value:
        this.removeFeatureFilterByParam('closed');
        break;
      case filter.param.indexOf('closed') > -1 && filter.value:
        this.removeFeatureFilterByParam('opened');
        break;
      case filter.param.indexOf('acked') > -1 && filter.value:
        this.removeFeatureFilterByParam('nack');
        break;
      case filter.param.indexOf('nack') > -1 && filter.value:
        this.removeFeatureFilterByParam('acked');
        break;
    }
  }

  applyFeatureFilter(feature: FilterOption, params: any) {
    switch (feature.param) {
      case 'opened':
        params.status = 'Opened';
        break;
      case 'closed':
        params.status = 'Closed';
        break;
      case 'nack':
        params.acked = 'false';
        break;
      default:
        params[feature.param] = feature.value;
    }
  }

  parseOptionFilterParam(
    filter: FilterOption,
    value: any
  ): FilterAutocompleteOption | Observable<FilterAutocompleteOption> {
    let $options: Observable<FilterAutocompleteOption[]>;
    switch (filter.param) {
      case 'cpo':
        $options = this.cpoOptions
          ? of(this.cpoOptions)
          : this.navService.loadCpoAutoCompleteOptions();
        break;
      case 'cpoGroup':
        $options = this.cpoGroupOptions
          ? of(this.cpoGroupOptions)
          : this.navService.loadCpoGroupAutoCompleteOptions();
        break;
      case 'location':
        $options = this.locationOptions
          ? of(this.locationOptions)
          : this.navService.loadLocationAutoCompleteOptions();
        break;
      case 'equipmentId':
        $options = this.evseOptions
          ? of(this.evseOptions)
          : this.navService.loadEvseAutoCompleteOptions();
        break;
      case 'alertType':
        return ALERT_TYPE_OPTIONS.find(o => o.value === value);
      case 'severity':
      case 'severityGte':
      case 'severityLte':
        return SEVERITY_LEVEL_OPTIONS.find(o => o.value === value);
      case 'errorCode':
        return ERROR_CODE_OPTIONS.find(o => o.value === value);
      default:
        return undefined;
    }
    if ($options) {
      return $options.pipe(map(options => options.find(o => o.value === value)));
    }
    return undefined;
  }

  parseFeatureParam(key: string, value: any): string {
    if (key === 'status') {
      if (value === 'Opened') {
        return 'opened';
      } else {
        return 'closed';
      }
    }
    if (key === 'acked' && value === 'true') {
      return 'acked';
    }
    if (key === 'acked' && value === 'false') {
      return 'nack';
    }
    return undefined;
  }
}
