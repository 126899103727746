import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { InfrastructureService } from '@app/infrastructure';
import { combineLatest, forkJoin, of, Subject, timer } from 'rxjs';
import { concatMap, debounceTime, takeUntil, timeout } from 'rxjs/operators';

import { AppNavigationService, KeywordSearchAutocompleteOption } from '@app/navigation';
import { fadeInOut } from '@app/shared';
import { WidgetLocationMapComponent } from '@app/widget';
import { footerAnim, infraPanel, listAnim, noMoreFadeIn } from './main-view-animations';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ChartColorScheme } from '@app/shared/interface/shared-type';
import { InfraAutoCompleteResponse } from '@app/core/infrastructure/infra-auto-complete-response';
import { InfraSearchResponse } from '@app/core/infrastructure/infra-search-response';

export interface InfraAvatar {
  color?: string;
  fontIcon?: string;
  svgIcon?: string;
  tip?: string;
}

export interface InfraDisplay {
  hit: any;
  avatar: InfraAvatar;
}

const SEARCH_BOX_PLACE_HOLDER = 'infra.Search by charge point ID, location, eMI³...';

const LOADING_SIZE = 20;
const MIN_LOADING_TIME = 300;

const PREF_LAYOUT = 'infra-layout';
const PREF_OPTION = 'infra-widget-option';

@Component({
  selector: 'app-infra-main-view',
  templateUrl: 'main-view.component.html',
  styleUrls: ['main-view.component.scss'],
  animations: [fadeInOut, footerAnim, listAnim, noMoreFadeIn, infraPanel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfraMainViewComponent implements AfterViewInit, OnDestroy, OnInit {
  hasQuery = true;
  searchError: any;
  displays: InfraDisplay[] = [];

  colorScheme: ChartColorScheme = { domain: [''] };
  currentScheme: any = { domain: [''] };
  energyScheme: any = { domain: [''] };

  viewInit: boolean;
  querying: boolean;
  totalCount = -1;
  loadedCount = 0;

  layoutOption: any = {};
  showMap = true;
  showList = false;
  @ViewChild(WidgetLocationMapComponent, { static: false }) map: WidgetLocationMapComponent;

  @ViewChild('primaryColorGetter', { static: false }) primaryColorGetter: ElementRef;
  @ViewChild('accentColorGetter', { static: false }) accentColorGetter: ElementRef;

  private _destroyed = new Subject();
  private queryParams: any;

  constructor(
    public translate: TranslateService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private scrollDispatcher: ScrollDispatcher,
    private navService: AppNavigationService,
    private infraService: InfrastructureService
  ) {}

  ngOnInit() {
    this.navService.setAppBarSearchEnabled(true);
    this.navService.setAppBarSearchPlaceHolder(SEARCH_BOX_PLACE_HOLDER);

    this.scrollDispatcher
      .scrolled()
      .pipe(takeUntil(this._destroyed))
      .subscribe(scrollable => {
        if (scrollable) {
          const nativeEl = scrollable.getElementRef().nativeElement;
          if (nativeEl.scrollTop + nativeEl.offsetHeight > nativeEl.scrollHeight - 48) {
            this.onScrolled();
          }
        }
      });
    combineLatest([
      this.route.queryParams.pipe(takeUntil(this._destroyed), debounceTime(500)),
      this.infraService.isNewSearchEngine
    ])
      .pipe(
        concatMap(([params, isNewSearchEngine]) => {
          this.displays = [];
          this.searchError = undefined;
          this.totalCount = -1;
          this.loadedCount = 0;
          this.queryParams = params;
          this.hasQuery = Object.keys(params).length > 0;
          this.fetchMoreResults();
          return isNewSearchEngine ? of(undefined) : this.infraService.getHints(params);
        })
      )
      .subscribe(result => this.onSearchHints(result));

    const layoutPref = localStorage.getItem(PREF_LAYOUT);
    if (layoutPref) {
      switch (layoutPref) {
        case 'map':
          this.showMap = true;
          this.showList = false;
          break;
        case 'list':
          this.showMap = false;
          this.showList = true;
          break;
        case 'map-list':
          this.showMap = true;
          this.showList = true;
          break;
      }
    }
    const widgetOptionPref = localStorage.getItem(PREF_OPTION);
    if (widgetOptionPref) {
      this.layoutOption = JSON.parse(widgetOptionPref);
    }
  }

  ngAfterViewInit() {
    this.viewInit = true;
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();

    this.navService.setAppBarSearchEnabled(false);
    this.navService.setAppBarSearchAutoOptions(undefined);
  }

  trackByFn(_index: number, item: InfraDisplay) {
    return item.hit._id;
  }

  onScrolled() {
    if (this.querying || this.loadedCount === this.totalCount) {
      return;
    }
    this.ngZone.run(() => this.fetchMoreResults());
  }

  toggleMap(event: MatButtonToggleChange) {
    this.showMap = event.source.checked;
    this.saveLayoutPreference();
  }

  toggleInfraList(event: MatButtonToggleChange) {
    this.showList = event.source.checked;
    if (this.map) {
      if (event.source.checked) {
        this.map.openDrawer();
      } else {
        this.map.closeDrawer();
      }
    }
    this.saveLayoutPreference();
  }

  onWidgetOptionChanged(option: any) {
    this.layoutOption = option;
    localStorage.setItem(PREF_OPTION, JSON.stringify(this.layoutOption));
  }

  private fetchMoreResults(): void {
    if (!this.queryParams.keyword || this.queryParams.keyword === '') {
      this.querying = false;
      this.changeDetectorRef.markForCheck();
      return;
    }

    const queryParams = Object.assign({}, this.queryParams, {
      limit: LOADING_SIZE + '',
      skip: this.loadedCount + ''
    });

    this.navService.setAppBarProgress(true);
    this.querying = true;

    forkJoin([
      this.infraService.search(new HttpParams({ fromObject: queryParams })),
      timer(MIN_LOADING_TIME)
    ])
      .pipe(takeUntil(this._destroyed), timeout(30000))
      .subscribe(
        res => this.onResult(res[0]),
        (err: HttpErrorResponse) => this.onSearchError(err),
        () => {
          this.querying = false;
          this.navService.setAppBarProgress(false);
          this.changeDetectorRef.markForCheck();
        }
      );

    this.changeDetectorRef.markForCheck();
  }

  private onSearchHints(result: InfraAutoCompleteResponse) {
    const hints: KeywordSearchAutocompleteOption[] = [];
    if (result?.hits) {
      result.hits.forEach(record => {
        if (!hints.find(h => h.display === record._key)) {
          hints.push({
            display: record._key,
            value: `"${record._key}"`,
            svgIcon: 'ev-station-outline'
          });
        }
        if (!hints.find(h => h.display === record.station)) {
          hints.push({
            display: record.station,
            value: `"${record.station}"`,
            fontIcon: 'mdi-map-marker-outline'
          });
        }
        if (!hints.find(h => h.display === record.orgName)) {
          hints.push({
            display: record.orgName,
            value: `"${record.orgName}"`,
            fontIcon: 'mdi-domain'
          });
        }
      });
    }
    this.navService.setAppBarSearchAutoOptions(hints);
  }

  private onResult(result: InfraSearchResponse) {
    this.querying = false;
    this.navService.setAppBarProgress(false);
    this.totalCount = result.totalCount;
    this.sortResultsByScore(result.hits);

    result.hits.forEach(hit => {
      const i = this.displays.findIndex(d => d.hit._id === hit._key);
      const display = {
        hit: {
          _id: hit._key,
          _type: 'evse',
          _source: {
            station: hit.station,
            stationID: hit.stationID,
            org: hit.org,
            orgName: hit.orgName,
            orgGroup: hit.orgGroup
          }
        },
        avatar: this.getAvatar(hit)
      };

      if (i > -1) {
        this.displays[i] = display;
      } else {
        if (this.displays.findIndex(d => d.hit._id === hit.org) < 0) {
          const cpoHit = {
            _id: hit.org,
            _type: 'cpo',
            _source: {
              station: hit.station,
              stationID: hit.stationID,
              org: hit.org,
              orgName: hit.orgName,
              orgGroup: hit.orgGroup
            }
          };
          const cpoDisplay = {
            hit: cpoHit,
            avatar: this.getAvatar(cpoHit)
          };
          this.displays.push(cpoDisplay);
        }
        if (this.displays.findIndex(d => d.hit._id === hit.stationID) < 0) {
          const locationHit = {
            _id: hit.stationID,
            _type: 'location',
            _source: {
              station: hit.station,
              stationID: hit.stationID,
              org: hit.org,
              orgName: hit.orgName
            }
          };
          const locationDisplay = {
            hit: locationHit,
            avatar: this.getAvatar(locationHit)
          };
          this.displays.push(locationDisplay);
        }
        this.displays.push(display);
        this.loadedCount++;
      }
    });
    this.changeDetectorRef.markForCheck();
  }

  private onSearchError(err: HttpErrorResponse) {
    this.querying = false;
    this.navService.setAppBarProgress(false);

    this.searchError = err;
    this.changeDetectorRef.markForCheck();
  }

  private sortResultsByScore(hits: any[]) {
    hits.sort((a, b) => {
      return b._score - a._score;
    });
  }

  private getAvatar(data: any): InfraAvatar {
    switch (data._type) {
      case 'location':
        return { fontIcon: 'mdi-map-marker-outline' };
      case 'cpo':
        return { fontIcon: 'mdi-domain' };
      default:
        return { svgIcon: 'ev-station-outline' };
    }
  }

  private saveLayoutPreference() {
    if (this.showMap && this.showList) {
      localStorage.setItem(PREF_LAYOUT, 'map-list');
    } else if (this.showMap) {
      localStorage.setItem(PREF_LAYOUT, 'map');
    } else if (this.showList) {
      localStorage.setItem(PREF_LAYOUT, 'list');
    } else {
      localStorage.removeItem(PREF_LAYOUT);
    }
  }
}
